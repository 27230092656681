import { Button, Stack } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router";

function AccountCenterSummaryCommands(){
    const navigate = useNavigate();
    return <React.Fragment>
        <Stack direction='row' gap={1}>
            <Button variant="outlined" color='success' onClick={()=>{navigate(-1)}}>Go Back</Button>
        </Stack>

    </React.Fragment>
}
export {AccountCenterSummaryCommands}