import { CircularProgress } from "@mui/material";
import React from "react";

function Loading(){
    return <React.Fragment>
        <div className='center-align'>
            <h3>Loading...</h3>
            <CircularProgress className='center-align' 
            color='inherit'/></div>
    </React.Fragment>

}

export {Loading};