import { TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { editPolicyDetails } from "../../../../services/caseManService/model/policy/PolicySlice";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";

function PolicyNumberEdit(){
    const dispatch = useAppDispatch();
    const policy = useAppSelector((state)=>state.policy.policy);
    
    return <React.Fragment>
        <TextField label='Policy Number' value={policy.policyNumber} 
            onChange={(e)=>dispatch(editPolicyDetails(new ReduxUpdate("policyNumber",e.target.value)))}/>
    </React.Fragment>
}
export {PolicyNumberEdit}