import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react"
import { useNavigate, useParams } from "react-router";
import { RefundWorklistResults } from "../features/worklist/RefundWorklistResults";
import { CenterNameView } from "../../../components/orgComponents";

function RefundWorklist(){
    const {status} = useParams();
    const navigate = useNavigate();
    const {center} = useParams();
   
    return <React.Fragment>
        <Grid container justifyContent='space-between' marginBottom='10px'>
            <Grid item sm={4.75}>
                <CenterNameView centerId={center!}/>
            </Grid>
            <Grid item sm={4}>
                <Typography variant='h5' className="psn-green">{status + ' Worklist'}</Typography>
            </Grid>
            <Grid item sm={3}>
                <Stack direction='row' gap={1} justifyContent='flex-end'>
                    <Button variant='outlined' startIcon={<KeyboardDoubleArrowLeft/>} color='success' onClick={()=>navigate(-1)}>Back</Button>
                </Stack>
            </Grid>
        </Grid>
        <RefundWorklistResults/>
    </React.Fragment>
}

export{RefundWorklist}