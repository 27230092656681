import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { GridButton } from "../../../../../components";
import { caseManageApi } from "../../../../../services/caseManService/caseManage-api";
import { useIncidentWitnessRemoveMutation } from "../../../../../services/varianceService/variance-api";
import { IncidentWitnessRemoveCommand } from "../../../../../services/varianceService/commands/WitnessRemoveCommand";

function WitnessDeleteBtn(props: GridCellProps) {
    
    const varianceId = useAppSelector(
        (state) => state.varianceRequest.varianceRequest.id
      );
  const [remove] = useIncidentWitnessRemoveMutation();
const handleRemove = () => {
    const command = new IncidentWitnessRemoveCommand(varianceId, props.dataItem.id);
    remove(command);
  };


  return (
    <React.Fragment>
      <GridButton
        color="error"
        startIcon={<DeleteIcon />}
        size="small"
        onClick={() => {
          handleRemove();
        }}
      >
      </GridButton>
    </React.Fragment>
  );
}

export { WitnessDeleteBtn };
