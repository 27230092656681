import React, { useState } from "react"
import { useParams } from "react-router"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { useFindWorklistQuery } from "../../../../services/refundService/refund-api";
import { process, State } from "@progress/kendo-data-query";
import { RefundWorklistCommandCell } from "./RefundWorklistCommandCell";
import { RefundWorklistRequest } from "../../../../services/refundService/commands";

function RefundWorklistResults(){
    const {status,center} = useParams();
    const [dataState, setDataState] = useState<State>({});
    const {data} = useFindWorklistQuery(new RefundWorklistRequest(center!,status!));
    const mapCases=()=>{
        return data?.map(i => ({...i, refundStartDate:new Date(i.refundStartDate)}))
    }
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

    return <React.Fragment>
        <Grid data={process(mapCases()??[].slice(0),dataState)}{...dataState} 
           
            onDataStateChange={dataStateChange} style={{height:'800px'}}>
                <Column cell={RefundWorklistCommandCell} width='100px'/>
                <Column title='Account' field='accountNumber' columnMenu={ColumnMenu} width='150px'/>
                <Column title='Last Name' field='lastName' columnMenu={ColumnMenu} width='225px'/>
                <Column title='First Name' field='firstName' columnMenu={ColumnMenu} width='225px'/>
                <Column title='Refund Start Date' field='refundStartDate' columnMenu={ColumnMenu} width='200px' format="{0:d}"/>
                {status !== 'Accounts Payable' &&
                    <Column title='Amount' field='amount' columnMenu={ColumnMenu} width='150px' format="{0:c}"/>
                }
                {status==='Accounts Payable' &&
                    <Column title='Refund Status' field='refundStatus' columnMenu={ColumnMenu} width='200px'/>}
                {status === 'Accounts Payable' &&
                    <Column title='Sage Status' field='sageStatus' columnMenu={ColumnMenu} width='200px'/>}
                {status === 'Posting' &&
                    <Column title='Sage Status' field='sageStatus' columnMenu={ColumnMenu} width='200px'/>}
                <Column title='Priority' field='priority' columnMenu={ColumnMenu} width='150px'/>
        </Grid>
    </React.Fragment>
}

export {RefundWorklistResults}