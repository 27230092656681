import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { CenterNameView } from "../../../components/orgComponents";
import { VarianceInitiate } from "../../variance/features/initiate/VarianceInitiate";
import { useNavigate, useParams } from "react-router";
import { VarianceSearch } from "../features/search/VarianceSearch";
import { VarianceDashboardCards } from "../components/VarianceDashboardCards";
import { VarianceMyWorklistResults } from "../features/worklist/VarianceMyWorklistResults";

function VarianceDashboard() {
  const { center } = useParams();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" spacing={2} marginBottom='20px'>
        <Grid item sm={3}>
          <CenterNameView centerId={center!} />
        </Grid>
        <Grid item sm={3}>
          <VarianceSearch />
        </Grid>
        <Grid item sm={3}>
          <Stack direction="row" gap={1}>
            <VarianceInitiate />
            <Button
              variant="outlined"
              startIcon={<KeyboardDoubleArrowLeft />}
              color="success"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="h5" className="psn-green" textAlign="center">
        Incident Reports
      </Typography>
      <Stack direction="row" justifyContent="center" gap={2}>
        <VarianceDashboardCards title="In Progress" queryParam="Started" />
        <VarianceDashboardCards title="Under Review" queryParam="Pending" />
        <VarianceDashboardCards title="Assigned to Department" queryParam="Assigned" />
        <VarianceDashboardCards title="Awaiting Approval" queryParam="PendingApproval" />
        <VarianceDashboardCards title="Requires Completion" queryParam="Approved" />
        <VarianceDashboardCards title="Completed" queryParam="Complete" />

      </Stack>
        <Stack direction="row" justifyContent="center" gap={2} margin={"20px"}>
          <VarianceMyWorklistResults />
        </Stack>
    </React.Fragment>
  );
}

export { VarianceDashboard };
