import { Stack, Typography } from "@mui/material"
import React from "react"
import { DxCodeEdit } from "./DxCodeEdit"

function DiagnosisCodes(){
    return <React.Fragment>
        <Typography variant='h6' className="psn-green" marginLeft='10px'>Diagnosis Codes</Typography>
        <Stack direction='row' margin='10px' gap={1}>
            <DxCodeEdit label="Dx Code 1" field="diagnosis1"/>
            <DxCodeEdit label="Dx Code 2" field="diagnosis2"/>
            <DxCodeEdit label="Dx Code 3" field="diagnosis3"/>
            <DxCodeEdit label="Dx Code 4" field="diagnosis4"/>
        </Stack>
    </React.Fragment>
}

export {DiagnosisCodes}