import { Box, Stack } from "@mui/material";
import React from "react";
import { IncidentSubTypes } from "../IncidentSubTypes";
import { SurgicalProcedueImplantOptions } from "./SurgicalProcedueImplantOptions";
import { SterilityOptions } from "./SterilityOptions";
import { IncorrectProcedureOptions } from "./IncorrectProcedureOptions";
import { PatientSafetyOptions } from "./PatientSafetyOptions";
import { DocumentationOptions } from "./DocumentationOptions";
import { MedicalComplicationOptions } from "./MedicalComplicationOptions";
import { OtherOptions } from "./OtherOptions";

function SugicalProcedureDetails(props: { canEdit: boolean }) {
  return (
    <React.Fragment>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="baseline"
      >
        <IncidentSubTypes canEdit={props.canEdit}
          formName="Incorrect Procedure Performed"
          options={IncorrectProcedureOptions}
        />
        <IncidentSubTypes canEdit={props.canEdit}
          formName="Implant Issue"
          options={SurgicalProcedueImplantOptions}
        />
        <IncidentSubTypes canEdit={props.canEdit}
          formName="Sterility and Cleanliness"
          options={SterilityOptions}
        />
        <IncidentSubTypes canEdit={props.canEdit}
          formName="Patient Safety and Consent Issues"
          options={PatientSafetyOptions}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="baseline"
      >
        <IncidentSubTypes canEdit={props.canEdit}
          formName="Counting and Documentation Errors"
          options={DocumentationOptions}
        />
        <IncidentSubTypes canEdit={props.canEdit}
          formName="Other Issues"
          options={OtherOptions}
          freeformName="SurgicalProcedureOtherIssue"
        />
        <IncidentSubTypes canEdit={props.canEdit}
          formName="Medical Complications"
          options={MedicalComplicationOptions}
        />
      </Stack>
    </React.Fragment>
  );
}

export { SugicalProcedureDetails };
