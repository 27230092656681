import { Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react"
import { useAppDispatch } from "../../../../app/hooks"
import { toggleVerifyLookupModal } from "../../../../app/slices/VerificationModuleSlice";
import { LookupResults } from "./LookupResults";

function Lookup(){
    const dispatch = useAppDispatch();
    const [searchText, setSearchText] = useState('');
    const [skip, setSkip] = React.useState(true)

    return <React.Fragment>
        <Stack direction='row'>
            <TextField label='Name or Account #' name='searchText' value={searchText}
             onChange={(e)=>{setSearchText(e.currentTarget.value);setSkip(true);}}
             onKeyDown={(e)=>{if(e.key==='Enter'){dispatch(toggleVerifyLookupModal()); setSkip(false)}}}
             size='small' sx={{backgroundColor:'white'}}/>
            
            <Button color='primary' variant="outlined" 
                onClick={()=>{dispatch(toggleVerifyLookupModal()); setSkip(false)}} 
                disabled={searchText === ''} size='small'>Search</Button>
        </Stack>
        <LookupResults searchText={searchText} skip={skip}/>
    </React.Fragment>
}

export {Lookup}