import React from "react"
import { PDFExport } from "@progress/kendo-react-pdf";
import { useAppSelector } from "../../../../../app/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './PrintableEstimate.css'
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import { useParams } from "react-router";
import LegentLogo from '../../../../../images/LegentLogo.png';
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { Button, Grid } from "@mui/material";
import { PrintOutlined } from "@mui/icons-material";

function PrintableEstimate(){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    const pdfExportComponent = React.useRef<PDFExport>(null);
    const verify = useAppSelector((state)=> state.verification.verification);

    return <React.Fragment>
        <Button variant="outlined" color='primary' className="me-2"
            onClick={()=> {
                if(pdfExportComponent.current){
                    pdfExportComponent.current.save();
                }
            }}><PrintOutlined/></Button>

        <div style={{position:'absolute', left:'-100000px',top:0}}>
            <PDFExport paperSize='a4' margin='1cm' ref={pdfExportComponent} fileName={schedule.data?.lastName + ', ' + schedule.data?.firstName + ' ' + schedule.data?.accountNumber + '.pdf'}>
                <div>
                    <div style={{textAlign:'center'}}><img style={{width:'30%'}} src={LegentLogo} alt=''/></div>
                    <div style={{textAlign:'center'}}>Patient Liability Estimate</div><br/>
                    <h6 style={{textAlign:'left', fontSize:'10pt'}}>{'Patient Name: ' + schedule.data?.firstName + ' ' + schedule.data?.lastName }</h6>
                    <h6 style={{textAlign:'left', fontSize:'10pt'}}>{'Account Number: ' + verify.accountNumber}</h6>
                    <h6 style={{textAlign:'left',fontSize:'10pt'}}>{'Date of Estimate: ' + formatDate(new Date(),"MM/dd/yyy")}</h6>
                    <br/>
                    <div style={{fontSize:'8pt',textAlign:'left'}}>The costs below are the amounts we estimate will be the patient portion of the
                        bill after billing your insurance.  Please contact your insurer directly for
                        more information about your out-of pocket obligations.  If you have any
                        questions, please reach out to the facility.
                    </div>
                    <br/>
                    <div style={{textAlign:'center', marginBottom:'5px',fontSize:'10pt',textDecoration:'underline'}}>Patient Insurance Details</div>
                    <div style={{textAlign:'left'}}>
                            <Grid container>
                                <Grid item sm={5}><div style={{fontSize:'10pt',textDecoration:'underline'}}>Payer</div></Grid>
                                <Grid item sm={3}><div style={{fontSize:'10pt',textDecoration:'underline'}}>Group</div></Grid>
                                <Grid item sm={3}><div style={{fontSize:'10pt',textDecoration:'underline'}}>Policy</div></Grid>
                            </Grid>
                        {schedule.data?.insurances.map(i => (
                            <Grid container>
                                <Grid item sm={5}><div style={{fontSize:'9pt'}}>{i.payer}</div></Grid>
                                <Grid item sm={3}><div style={{fontSize:'9pt'}}>{i.groupNumber}</div></Grid>
                                <Grid item sm={3}><div style={{fontSize:'9pt'}}>{i.policy}</div></Grid>
                            </Grid>
                        ))}
                    </div>
                    <br/>
                    <div style={{textAlign:'center', marginBottom:'5px', fontSize:'10pt', textDecoration:'underline'}}>Procedures Scheduled</div>
                    <div style={{textAlign:'left'}}>
                        <Grid container>
                            <Grid item sm={3}><div style={{fontSize:'10pt',textDecoration:'underline'}}>Procedure Code</div></Grid>
                            <Grid item sm={9}><div style={{fontSize:'10pt',textDecoration:'underline'}}>Description</div></Grid>
                        </Grid>
                       {verify.procedures.map(i => (
                            <Grid container>
                                <Grid item sm={3}><div style={{fontSize:'9pt'}}>{i.code}</div></Grid>
                                <Grid item sm={9}><div style={{fontSize:'9pt'}}>{i.description}</div></Grid>
                            </Grid>
                       ))}
                    </div>
                    <br/>
                    <br/>
                    <Grid container>
                        <Grid item sm={12} style={{textAlign:'center', fontSize:'10pt', textDecoration:'underline'}}>Total Estimated Out of Pocket Cost</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6} style={{fontSize:'9pt'}}>Deductible / Deductible Met:</Grid>
                        <Grid item sm={6} style={{textAlign:'left',fontSize:'9pt'}}>{formatNumber(verify.benefits.deductible,'c') + ' / ' + formatNumber(verify.benefits.deductibleMet,'c')}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6} style={{fontSize:'9pt'}}>Out of Pocket / Out of Pocket Met:</Grid>
                        <Grid item sm={6} style={{textAlign:'left',fontSize:'9pt'}}>{formatNumber(verify.benefits.outOfPocket,'c') + ' / ' + formatNumber(verify.benefits.outOfPocketMet,'c')}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6} style={{fontSize:'9pt'}}>Coinsurance:</Grid>
                        <Grid item sm={6} style={{textAlign:'left',fontSize:'9pt'}}>{formatNumber(verify.benefits.coInsurance/100,'p')}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6} style={{fontSize:'9pt'}}>Copay Due:</Grid>
                        <Grid item sm={6} style={{textAlign:'left',fontSize:'9pt'}}>{formatNumber(verify.patientResponsibility.copayDue,'c')}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6} style={{fontSize:'9pt'}}>Confirmed Payments:</Grid>
                        <Grid item sm={6} style={{textAlign:'left',fontSize:'9pt'}}>{'-' + formatNumber(verify.benefits.paidToPhysician,'c')}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6} style={{fontSize:'9pt'}}>Additional Uncovered:</Grid>
                        <Grid item sm={6} style={{textAlign:'left',fontSize:'9pt'}}>{formatNumber(verify.benefits.uncoveredAmount,'c')}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6} style={{fontSize:'9pt',fontWeight:'bold'}}><u>Total:</u></Grid>
                        <Grid item sm={6} style={{textAlign:'left',fontSize:'9pt'}}>{formatNumber(verify.patientResponsibility.collectFromPatient,'c')}</Grid>
                    </Grid>

                    <br/>
                    <br/>
                    <div style={{textAlign:'left'}}>Patient Signature: ___________________________</div><br/>
                    <div style={{textAlign:'left'}}>Date: _____________________________________</div><br/>

                    <div style={{border:'1px solid black',textAlign:'center',fontSize:'6pt'}}>
                        The above estimate represents the information provided to us at the time of the request and is not a guarantee 
                        of payment.  The estimate does not include unforseen charges to your insurance coverage or deductibles.  Please
                        be aware that any additional charges (labs, radiology, professional fees, etc) ordered by the physicians may not
                        be included in this pricing.  Thank you.
                    </div>
                </div>
            </PDFExport>

        
        </div>
    </React.Fragment>
}

export {PrintableEstimate}