import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Stack, Switch, Typography } from "@mui/material";
import { editRefund } from "../../../../../services/refundService/model/refundRequest/RefundRequestSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { RefundLocked } from "../../../specs/RefundLocked";

function RefundEscalateSwitch(){
    const dispatch = useAppDispatch();
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const priority = useAppSelector((state)=>state.refundRequest.refundRequest?.priority);

    return <React.Fragment>
        <Stack gap={0}>
            <Typography variant='body2' style={{color:'gray', marginBottom:'-10px', marginLeft:"10px"}}>Expedite</Typography>
            <Switch color='error' checked={priority==='EXPEDITE'}
                sx={{marginBottom:'-15px'}}
                disabled={new RefundLocked().IsSatisfiedBy(refund)}
                onChange={(e) => dispatch(editRefund(new ReduxUpdate("priority",e.target.checked?'EXPEDITE':'STANDARD')))}/>
        </Stack>
    </React.Fragment>
}

export {RefundEscalateSwitch}