import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Stack, TextField } from "@mui/material";
import { editAccountAttributes } from "../../../../../services/accountService/model/account/AccountSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { AccountBookedProceduresList } from "./AccountBookedProceduresList";
import { formatNumber } from "@progress/kendo-intl";

function BookedReimbursement(){
    const dispatch = useAppDispatch();
    const account = useAppSelector((state)=> state.account.account);

    return <React.Fragment>
        <br/>
        <Stack direction='row' marginBottom='10px' marginLeft='10px' gap={1}>        
            <TextField label='Booked Revenue' value={formatNumber(account.bookedProcedures.bookedRevenue,'c')} disabled />
            <TextField label='Booked Charges' value={formatNumber(account.bookedProcedures.bookedCharges,'c')} disabled />
            <TextField label='Booked Implant Cost' value={formatNumber(account.bookedProcedures.bookedImplantCost,'c')} disabled />

            <TextField label='Revenue Variance Note' sx={{width:'600px'}} value={account.attributes.revenueVarianceNote}
                onChange={(e)=> dispatch(editAccountAttributes(new ReduxUpdate('revenueVarianceNote',e.target.value)))} />
        </Stack>
        <AccountBookedProceduresList/>
    </React.Fragment>
}
export {BookedReimbursement}