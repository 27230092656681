import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { useNavigate, useParams } from "react-router";
import { useFindCaseManWorklistQuery } from "../../../../services/caseManService/caseManage-api";
import { CaseManWorklistRequest } from "../../../../services/caseManService/commands";
import { CaseManWorklist } from "../../../../services/caseManService/model/worklist/CaseManWorklist";
import { process, State } from "@progress/kendo-data-query";
import { Button, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CaseManViewCell } from "./CaseManViewCell";

function CaseManWorklistResults(){
    const {type,center} = useParams();
    const {data} = useFindCaseManWorklistQuery(new CaseManWorklistRequest(type!,center!));
    const navigate = useNavigate();
    const mapCases=():CaseManWorklist[]=>{
        let cases:CaseManWorklist[]=data===undefined ? [] : data?.map(i => ({...i, serviceDate: new Date(i.serviceDate)}))
        return cases;
    }
    const defaultGridState:State = {sort:[{field:'serviceDate',dir:'asc'}]};
    const [dataState, setDataState] = useState(defaultGridState);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginBottom='5px'>
            <Typography className="psn-green" variant='h6'>{type + ' Worklist'}</Typography>
            <Button variant='outlined' onClick={()=>navigate(-1)} color='success'><FontAwesomeIcon icon={'arrow-left'}/><span className="ms-1">Go Back</span></Button>
        </Stack>

        <Grid data={process(mapCases(),dataState)}
            style={{height:'750px'}} onDataStateChange={dataStateChange}>
            <Column cell={CaseManViewCell} width={'100px'}/>
            <Column title='Patient' field='patient' width={'200px'}/>
            <Column title='Account' field='account' width={'150px'}/>
            <Column title='Service Date' field='serviceDate' format='{0:MM/dd/yyyy}' filter="date" width={'150px'}/>
            <Column title='Physician' field='physician' width={'175px'}/>
            <Column title='Admit Type' field="admitType" width={'150px'}/>
            <Column title='Admit Source' field='admitSource' width={'200px'}/>
            <Column title='Payer' field="payer" width={'250x'}/>
        </Grid>
    </React.Fragment>
}

export {CaseManWorklistResults}