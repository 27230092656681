import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { Button } from "@mui/material";
import { useCaseManStatusUpdateMutation } from "../../../../../../services/caseManService/caseManage-api";
import { CaseManStatusUpdateCommand } from "../../../../../../services/caseManService/commands";

function ApproveReviewBtn(){
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    const [saveStatus] = useCaseManStatusUpdateMutation();
    const handleSave=()=>{
        let command = new CaseManStatusUpdateCommand(caseManage,'Clinical','Approved');
        saveStatus(command);
    }
    return <React.Fragment>
        <Button variant='outlined' color='success'
            onClick={()=>handleSave()}
        >Approve</Button>
    </React.Fragment>
}
export {ApproveReviewBtn}
