import { MenuItem, TextField } from "@mui/material";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editAuthorizationStatus } from "../../../../../services/verifyService/model/verification/VerificationSlice";

function DischargeNotice(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);
    return <React.Fragment>
        <TextField label='IP/Discharge Notice Required' name='ipDischargeRequired' 
            value={String(verification.authorization.ipDischargeRequired)} size='small'
            onChange={(e)=>dispatch(editAuthorizationStatus(e))}
            InputLabelProps={{shrink:true}} select>
                <MenuItem value='No'>No</MenuItem>
                <MenuItem value='Yes'>Yes</MenuItem>
        </TextField>
    </React.Fragment>
}

export {DischargeNotice}