import React from "react";
import { DirectorResponseActions } from "./DirectorResponseActions";
import { DirectorResponseEditor } from "./DirectorResponseEditor";
import { MaterialsManageOptions } from "./MaterialsManagement/MaterialsManageOptions";
import { DirResponseSubTypes } from "./DirResponseSubTypes";
import { useAppSelector } from "../../../../../app/hooks";
import { AdverseDrugEventOptions } from "./AdverseDrugEvent/AdverseDrugEventOptions";
import { EquipmentEventOptions } from "./Equipment/EquipmentEventOptions";
import { FallResponseActions } from "./Falls/FallResponseActions";
import { useMyProfileQuery } from "../../../../../services/profileService/profile-api";

function DirectorResponse() {
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const { data } = useMyProfileQuery();
  const canEdit =():boolean=>{
    if(incident.status === "Complete"){return false;}
    if(data?.role === 'Admin'){return true;}
    if(data?.role === 'Quality Team' && incident.status === "Approved"){return true;}
    if(data?.claimsAccess!.find((p) => p.code === "IncidentAdmin") != undefined){return true;}
    if(data?.upn === incident?.assignedTo?.upn && incident.status === "Assigned"){return true;}    
    return false;
}
  return (
    <React.Fragment>
      {incident.incidentType === "ADVERSE_DRUG" && (
        <DirResponseSubTypes
          options={AdverseDrugEventOptions}
          formName="Details:"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "EQUIPMENT_FAILURE" && (
        <DirResponseSubTypes
          options={EquipmentEventOptions}
          formName="Details:"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "FALL" && <FallResponseActions canEdit={canEdit()} />}
      {incident.incidentType === "MATERIALS_MANAGEMENT" && (
        <DirResponseSubTypes
          options={MaterialsManageOptions}
          formName="Cause For Error:"
          canEdit={canEdit()}
        />
      )}
      <DirectorResponseActions canEdit={canEdit()}/>
      <DirectorResponseEditor canEdit={canEdit()} />
    </React.Fragment>
  );
}

export { DirectorResponse };
