import { Switch } from "@progress/kendo-react-inputs"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { editSingleCaseAgreement } from "../../../../../services/verifyService/model/verification/VerificationSlice";

function SingleCaseAgreementAttribute(){
    const verify = useAppSelector((state)=>state.verification.verification);
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <div>
            <label>Single Case Agreement</label><br/>
            <Switch name="singleCaseAgreement" value={verify.singleCaseAgreement?.agreementRequired??false}
             checked={verify.singleCaseAgreement?.agreementRequired??false}
             onChange={(e)=>dispatch(editSingleCaseAgreement(new ReduxUpdate('agreementRequired',e.target.value)))}/>
        </div>
    </React.Fragment>
}
export {SingleCaseAgreementAttribute}