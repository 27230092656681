import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react"
import { useNavigate, useParams } from "react-router"
import { useAppDispatch } from "../../../../app/hooks";
import { GridButton } from "../../../../components";
import { radiologyApi } from "../../../../services/radiologyService/radiology-api";


function RadWorklistCommandCell(props:GridCellProps){
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {center} = useParams();

    return <React.Fragment>
        <GridButton size='small' variant="outlined" color='primary'
            onClick={()=>{dispatch(radiologyApi.util.invalidateTags(['radRequest'])); navigate('/RadEdit/' + center + '/' + props.dataItem.requestId)}}>View</GridButton>    
    </React.Fragment>
}

export {RadWorklistCommandCell}