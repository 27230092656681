import { Stack, Typography } from "@mui/material"
import { grey } from "@mui/material/colors";
import { MultiSelect } from "@progress/kendo-react-dropdowns"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { editPolicyDetails } from "../../../../services/caseManService/model/policy/PolicySlice";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";

function ProcedureLinking(){
    const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
        const noData = (
          <div>Type any value follwed by the enter key</div>
        );
    
        return React.cloneElement(element, { ...element.props }, noData);
      };
    const dispatch = useAppDispatch();
    const policy = useAppSelector((state)=>state.policy.policy);
    return <React.Fragment>
        <Stack>
          <fieldset>
            <div style={{color:'grey'}}>Enter all procedure codes this policy applies to</div>
            <MultiSelect fillMode='outline' rounded='large' style={{width:'450px', marginTop:'0px'}} allowCustom placeholder='None' listNoDataRender={listNoDataRender}
              value={policy.effectiveProcedureCodes} onChange={(e)=>dispatch(editPolicyDetails(new ReduxUpdate('effectiveProcedureCodes',e.target.value)))}/>
          </fieldset>
        </Stack>
    </React.Fragment>
}
export{ProcedureLinking}