import { Grid, GridColumn as Column,GridCellProps, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { useNavigate} from 'react-router'
import { orgApi, useCenterListQuery } from "../../../../services/orgService/org-api";
import { useAppDispatch } from '../../../../app/hooks';
import { process, State } from "@progress/kendo-data-query";
import {ColumnMenu} from '../../../../utils/ColumnMenu'
import { GridButton } from '../../../../components';



function CenterListGrid(){
    const {data} = useCenterListQuery();
    const [dataState, setDataState] = useState<State>({});
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
    
    return <React.Fragment>
        <Grid className='center-list-grid card-body-grid' data={process(data === undefined ? [] :data.slice(0),dataState)}{...dataState} style={{height:'500px'}}
            onDataStateChange={dataStateChange}>
            <Column cell={ViewCommand} width={'60px'}/>
            <Column title='Code' field='code'width='150px' columnMenu={ColumnMenu}/>
            <Column title='Name' field='name' columnMenu={ColumnMenu} width='350px'/>
            <Column title='Type' field='centerType' columnMenu={ColumnMenu} width="150px"/>
            <Column title='PAS' field='pas' columnMenu={ColumnMenu} width='100px'/>
        </Grid>
    </React.Fragment>
}

function ViewCommand(props:GridCellProps){
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    return <td>
        <GridButton size='small' sx={{marginLeft:'0px', paddingLeft:'0px', marginBottom:'0px', justifyContent:'flex-start'}}
            onClick={()=>{navigate('/OrgEdit/' + props.dataItem.id);
                        dispatch(orgApi.util.invalidateTags(['center']));}}
        >View</GridButton>
        </td>
    }

export {CenterListGrid}