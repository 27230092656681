export class AmountDue{
    deductibleDue:number;
    coInsuranceDue:number;
    coPayDue:number;
    totalPatientDue:number;

    constructor(deductibleDue:number, coInsuranceDue:number,
        coPayDue:number, totalPatientDue:number){
            this.deductibleDue = deductibleDue;
            this.coInsuranceDue = coInsuranceDue;
            this.coPayDue = coPayDue;
            this.totalPatientDue = totalPatientDue;
        }
}