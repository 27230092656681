export interface OutOfPeriodAdjustment {
    id:string;
    period:string;
    year:string;
    amount:number;
    reason:string;
    createdBy?:string;
    createdDate?:Date;
    isApproved?:boolean;
    approvedBy?:string;
    approvedDate?:Date;

}

export function DefaultOutOfPeriodAdjustment():OutOfPeriodAdjustment{
    return {id:'', period:'', year:'', amount:0, reason:''}
}