import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react"
import { useNavigate } from "react-router"
import { useAppDispatch } from "../../../../../app/hooks";
import { varianceApi } from "../../../../../services/varianceService/variance-api";

function IncidentWorklistBack(){
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    return <React.Fragment>
        <Button startIcon={<KeyboardDoubleArrowLeft/>} color='success' variant='outlined' 
            onClick={()=>{navigate(-1);
                dispatch(varianceApi.util.invalidateTags(['worklist','variance']));
                }}>Go Back</Button>
    </React.Fragment>
}

export{IncidentWorklistBack}