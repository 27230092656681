import { Stack } from "@mui/material"
import React from "react"
import { CaseManNoteAddDialog } from "./CaseManNoteAddDialog"
import { CaseManNoteList } from "./CaseManNoteList"

function CaseManNotes(){
    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' marginRight='10px' marginBottom='10px' marginTop='5px'>
            <CaseManNoteAddDialog/>
        </Stack>
        <CaseManNoteList/>
    </React.Fragment>
}
export {CaseManNotes}