import { TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editCheckInfo } from "../../../../../services/refundService/model/refundRequest/RefundRequestSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { RefundLocked } from "../../../specs/RefundLocked";

function CheckPayableToEdit(){
    const dispatch = useAppDispatch();
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);

    return <React.Fragment>
        <TextField label='Check payable to' value={refund.checkInfo.payableTo}
            disabled={new RefundLocked().IsSatisfiedBy(refund) && refund.status !== 'REISSUE'}
            onChange={(e)=>dispatch(editCheckInfo(new ReduxUpdate('payableTo',e.target.value)))}/>
    </React.Fragment>
}

export {CheckPayableToEdit}