import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { IconButton, Stack, TextField, Tooltip } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { useSaveAccountStatusMutation } from "../../../../../services/accountService/account-api";
import { AccountStatusChangeCommand } from "../../../../../services/accountService/commands";

function AccountStatusView(){
    const account = useAppSelector((state) => state.account.account);
    const [save] = useSaveAccountStatusMutation();
    const handleSave=(status:string)=>{
        let command = new AccountStatusChangeCommand(account.id, status);
        save(command);
    }
    return <React.Fragment>
        <Stack direction='row' gap={0}>
            <TextField label='Case Status' variant='outlined' 
                value={account.encounterInfo.status=='SCHED'?'Active':account.encounterInfo.status} disabled/>
            
            {account.encounterInfo.status === 'SCHED' &&
            <Tooltip title='Cancel Case'>
            <IconButton color='error' onClick={()=>{handleSave('CANCEL')}}>
                <CancelIcon/>
            </IconButton>
            </Tooltip>}

            {account.encounterInfo.status === 'CANCEL' &&
            <Tooltip title='Reactivate Case'>
            <IconButton color='info' onClick={()=>{handleSave('SCHED')}}>
                <SettingsBackupRestoreIcon/>
            </IconButton>
            </Tooltip>
            }
        </Stack>
    </React.Fragment>
}
export {AccountStatusView}