import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Button, Dialog, DialogActions, DialogContent, Stack, Switch, TextField, Typography } from "@mui/material";
import { toggleCallViewModal } from "../../../../../app/slices/VerificationModuleSlice";
import { formatDate } from "@progress/kendo-intl";

function CallView(){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.verificationModule.CallViewModal);
    const verification = useAppSelector((state)=>state.verification.verification);
    const selectedCall = useAppSelector((state)=>state.verificationModule.SelectedCall);
    const call = verification.calls.find(p => p.callNumber === selectedCall);

    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogContent>
                <Stack direction='column' marginBottom='10px'>
                    <Typography variant='body2' color='gray'>Patient Reached</Typography>
                    <Switch value ={call?.patientReached} checked={call?.patientReached} disabled/>
                </Stack>
                <Stack direction='row' gap={1} marginBottom='10px'>
                    <TextField label='Called By' value={call?.calledBy} disabled/>
                    <TextField label='Note Time' value={formatDate(new Date(call?.callDate!),'g')} disabled/>
                </Stack>
                <TextField label='Note' multiline minRows={5} value={call?.callNote} sx={{width:'460px', maxWidth:'100%'}} disabled />
            <DialogActions>
                <Button onClick={()=>dispatch(toggleCallViewModal(''))}>Close</Button>
            </DialogActions>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}
export {CallView}