import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../../utils/AdalConfig";
import { RefundService } from "../../utils/ServiceLocator";
import { NotePostCommand, RefundApprovalPostCommand, RefundApprovalPostResult, RefundDocRemoveCommand, RefundPostCommand, RefundRejectPostCommand, RefundRejectPostResult, RefundResubmitPostCommand, RefundStartCommand, RefundStatusPostCommand, RefundWorklistRequest, RefundWorklistSearchRequest } from "./commands";
import { RefundRequest, Worklist } from "./model";
import { RefundNote } from "./model/refundRequest/RefundNote";
import { RefundDocument } from "./model/refundRequest/RefundDocuments";

export const refundApi = createApi({
    reducerPath: 'refundApi',
    baseQuery: fetchBaseQuery({
        baseUrl: RefundService,
        prepareHeaders:(headers,{getState}) =>{
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }
    }),
    tagTypes:['refund','worklist','search','dupcheck'],
    endpoints:(builder) => ({
        startRefund:builder.mutation<RefundRequest,RefundStartCommand>({
            query:(body) =>{
                return {url:'RefundStart',method:'POST', body:body,}
            },
        }),
        findWorklist:builder.query<Worklist[], RefundWorklistRequest>({
            query:(request: RefundWorklistRequest) => 'refundWorklist?centerId=' + request.centerId +'&queryParams=' + request.queryParam,
            providesTags:['worklist']
        }),
        findRefundRequest:builder.query<RefundRequest,string>({
            query:(id:string) => 'refundRequest?refundId=' + id,
            providesTags:['refund']
        }),
        refundWorklistSearch:builder.query<Worklist[],RefundWorklistSearchRequest>({
            query:(request:RefundWorklistSearchRequest) => 'search?centerId=' + request.centerId +'&searchCriteria=' + request.searchCriteria,
            providesTags:['search']
        }),
        refundDuplicateCheck:builder.query<boolean,string>({
            query:(request:string) => 'duplicateCheck?request=' + request,
            providesTags:['dupcheck']
        }),
        refundSave:builder.mutation<RefundRequest, RefundPostCommand>({
            query:(body) =>{
                return {url:'Refund', method:'POST', body:body,}
            },
            invalidatesTags:['refund', 'worklist']
        }),
        noteCreate:builder.mutation<RefundNote,NotePostCommand>({
            query:(body) =>{
                return {url:'noteCreate', method:'POST', body:body,}
            }
        }),
        noteUpdate:builder.mutation<RefundNote,NotePostCommand>({
            query:(body) =>{
                return {url:'noteUpdate', method:'POST', body:body,}
            }
        }),
        documentRemove:builder.mutation<RefundDocument,RefundDocRemoveCommand>({
            query:(body) =>{            
                return {url:'documentRemove', method:'POST', body:body,}
            }
        }),
        refundStatusUpdate:builder.mutation<RefundRequest,RefundStatusPostCommand>({
            query:(body) =>{
                return {url:'RefundStatusUpdate', method:'POST', body:body,}
            }, invalidatesTags:['worklist']
        }),
        refundApprove:builder.mutation<RefundApprovalPostResult,RefundApprovalPostCommand>({
            query:(body) =>{
                return {url:'RefundApprove', method:'POST', body:body,}
            }
        }),
        refundReject:builder.mutation<RefundRejectPostResult,RefundRejectPostCommand>({
            query:(body) =>{
                return {url:'RefundReject', method:'POST', body:body,}
            }
        }),
        refundResubmit:builder.mutation<RefundRequest,RefundResubmitPostCommand>({
            query:(body) =>{
                return {url:'ResubmitApprovals', method:'POST', body:body,}
            }
        }),
        
    })
});

export const {useStartRefundMutation, useFindWorklistQuery, useFindRefundRequestQuery, useRefundSaveMutation,
    useNoteCreateMutation, useNoteUpdateMutation, useDocumentRemoveMutation, useRefundStatusUpdateMutation,
    useRefundApproveMutation, useRefundRejectMutation, useRefundResubmitMutation, 
    useRefundWorklistSearchQuery, useRefundDuplicateCheckQuery} = refundApi;