export class CenterAddCommand{
    contractId: string;
    centerCode:string;
    centerName:string;
    
    constructor(contractId:string, centerCode:string, centerName:string){
        this.contractId = contractId;
        this.centerCode = centerCode;
        this.centerName = centerName;
    }
}