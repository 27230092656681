import { TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { editDiagnosisCodes } from "../../../../../../services/verifyService/model/verification/VerificationSlice";
import { ReduxUpdate } from "../../../../../../utils/ReduxUpdateCommands";

type ownProps = {
    label: string;
    field:string;}

function DxCodeEdit(props:ownProps){
    const dispatch = useAppDispatch();
    const verify = useAppSelector((state)=>state.verification.verification);

    return <React.Fragment>
        <TextField label={props.label} sx={{width:'125px'}} variant='outlined' value={(verify.diagnosisCodes as any)[props.field]}
            onChange={(e)=>dispatch(editDiagnosisCodes(new ReduxUpdate(props.field,e.target.value)))}/>
    </React.Fragment>
}
export {DxCodeEdit}