import {
  FormGroup,
  Box,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";
import { editDirectorResponseFallActions } from "../../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { DefaultDirectorResponseFallAction, DirectorResponseFallAction } from "../../../../../../services/varianceService/model/varianceRequest/DirectorResponseFallAction";

function FallResponseActions(props: { canEdit: boolean }) {
  const dispatch = useAppDispatch();
  const dirResponseActionFallState = useAppSelector(
    (state) => state.varianceRequest.varianceRequest.directorResponseAction?.fallAction
  );
  const [dirResponseFallAction, setDirResponseFallAction] = useState(
    dirResponseActionFallState
  );
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newDirResponseFallAction: DirectorResponseFallAction =
      dirResponseFallAction || DefaultDirectorResponseFallAction();
    newDirResponseFallAction = {
      ...newDirResponseFallAction,
      [e.target.name]:
        (e.target as HTMLInputElement).type === "checkbox"
          ? (e.target as HTMLInputElement).checked
          : (e.target as HTMLInputElement).value,
    };
    setDirResponseFallAction(newDirResponseFallAction);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: 3,
        }}
      >
        <FormGroup>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "10px",
            }}
          >
            <Grid container  direction="row">
              <Grid item xs={4}>
                <FormControlLabel
                  label="Were interventions documented?"
                  disabled={!props.canEdit}
                  control={
                    <Checkbox
                      checked={dirResponseFallAction?.interventionDocumented}
                      name="interventionDocumented"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseFallActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  label="Were toileting needs involved?"
                  disabled={!props.canEdit}
                  control={
                    <Checkbox
                      checked={dirResponseFallAction?.toiletingNeeds}
                      name="toiletingNeeds"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseFallActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  label="Was foreign substance on floor?"
                  disabled={!props.canEdit}
                  control={
                    <Checkbox
                      checked={dirResponseFallAction?.foreignSubstance}
                      name="foreignSubstance"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseFallActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {dirResponseFallAction?.foreignSubstance && (
                  <TextField
                    id="standard-basic"
                    name="foreignSubstanceDetails"
                    label="Details"
                    variant="standard"
                    disabled={!props.canEdit}
                    value={dirResponseFallAction?.foreignSubstanceDetails}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        editDirectorResponseFallActions({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  label="Other environmental hazards?"
                  disabled={!props.canEdit}
                  control={
                    <Checkbox
                      checked={dirResponseFallAction?.hazardEnviroment}
                      name="hazardEnviroment"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseFallActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {dirResponseFallAction?.hazardEnviroment && (
                  <TextField
                    id="standard-basic"
                    name="hazardEnviromentDetails"
                    label="Details"
                    variant="standard"
                    disabled={!props.canEdit}
                    value={dirResponseFallAction?.hazardEnviromentDetails}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        editDirectorResponseFallActions({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  label="Any injuries identified related to fall?"
                  disabled={!props.canEdit}
                  control={
                    <Checkbox
                      checked={dirResponseFallAction?.injuriesIdentified}
                      name="injuriesIdentified"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseFallActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {dirResponseFallAction?.injuriesIdentified && (
                  <TextField
                    id="standard-basic"
                    name="injuriesIdentifiedDetails"
                    label="Details"
                    variant="standard"
                    disabled={!props.canEdit}
                    value={dirResponseFallAction?.injuriesIdentifiedDetails}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        editDirectorResponseFallActions({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}              
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Was patient identified as risk for falls?"
                  disabled={!props.canEdit}
                  control={
                    <Checkbox
                      checked={dirResponseFallAction?.patientFallRisk}
                      name="patientFallRisk"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseFallActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {!dirResponseFallAction?.patientFallRisk && (
                  <TextField
                    id="standard-basic"
                    name="patientFallRiskDetails"
                    label="Details"
                    variant="standard"
                    disabled={!props.canEdit}
                    value={dirResponseFallAction?.patientFallRiskDetails}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        editDirectorResponseFallActions({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}
              </Grid>
                <Grid item xs={6}>
                <FormControlLabel
                  label="Any medications or imaging ordered related to fall?"
                  disabled={!props.canEdit}
                  control={
                    <Checkbox
                      checked={dirResponseFallAction?.medsImagingOrdered}
                      name="medsImagingOrdered"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editDirectorResponseFallActions({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {dirResponseFallAction?.medsImagingOrdered && (
                  <TextField
                    id="standard-basic"
                    name="medsImagingOrderedDetails"
                    label="Details"
                    variant="standard"
                    disabled={!props.canEdit}
                    value={dirResponseFallAction?.medsImagingOrderedDetails}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        editDirectorResponseFallActions({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}
              </Grid>      
            </Grid>
          </Box>
        </FormGroup>
      </Box>
    </React.Fragment>
  );
}
export { FallResponseActions };
