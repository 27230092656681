import { GridCellProps} from "@progress/kendo-react-grid";
import React from "react";
import { useNavigate} from 'react-router'
import { useAppDispatch } from "../../../../app/hooks";
import { GridButton } from "../../../../components";
import { toggleLookupUserModal } from "../../../../app/slices/ProfileModuleSlice";

function LookupUserGridCommand(props:GridCellProps){
    let navigate = useNavigate();
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <GridButton className='ms-1 mt-1' variant='outlined' color='primary'
            onClick={()=>{navigate('/userProfile/' + props.dataItem.id); dispatch(toggleLookupUserModal()); }}>View</GridButton>
    </React.Fragment>
}

export {LookupUserGridCommand}