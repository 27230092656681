import { Button } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { useCaseManStatusUpdateMutation } from "../../../../../../services/caseManService/caseManage-api";
import { CaseManStatusUpdateCommand } from "../../../../../../services/caseManService/commands";

function DenyReviewBtn(){
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    const [saveStatus] = useCaseManStatusUpdateMutation();
    const handleSave=()=>{
        let command = new CaseManStatusUpdateCommand(caseManage,'Clinical','Denied');
        saveStatus(command);
    }
    return <React.Fragment>
        <Button variant='outlined' color='error'
            onClick={()=>handleSave()}
            disabled={caseManage.clinicalReviewStatus === 'Denied'}
        >Deny</Button>
    </React.Fragment>
}

export{DenyReviewBtn}