import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { togglePhysicianEditModal } from "../../../../app/slices/OrgModuleSlice";
import { PhysicianSaveCommand } from "../../../../services/orgService/commands";
import {Physician} from "../../../../services/orgService/model";
import { usePhysicianQuery, usePhysicianSaveMutation, useSpecialtiesQuery } from "../../../../services/orgService/org-api";

type PhysicianEditModalProps={
    isNew:boolean,
    center:string
}

function PhysicianEditModal(props:PhysicianEditModalProps){
    const dispatch = useAppDispatch();
    const physicianId = useAppSelector((state) => state.orgModule.selectedPhysician);
    const {data} = usePhysicianQuery(physicianId);
    const specialty = useSpecialtiesQuery();
    const [physicianSave]=usePhysicianSaveMutation();
    const [physician, setPhysician] = useState(new Physician());
    useEffect(()=> {setPhysician(data!)}, [data,physicianId]);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|SelectChangeEvent<string>) =>{
        let newDoc:Physician = physician;
        newDoc = ({...newDoc, [e.target.name]:e.target.value})
        setPhysician(newDoc)
    }
    const modalOpen = useAppSelector((state)=>state.orgModule.physicianEditModal);


return <React.Fragment>
    <Dialog open={modalOpen}>
        <DialogTitle>{physician?.lastName + ', ' + physician?.firstName}</DialogTitle>
        <DialogContent>
            <Stack direction='row' sx={{marginTop:'10px'}} gap={2}>
                <FormControl>
                    <InputLabel shrink={true}>Specialty</InputLabel>
                    <Select name='specialty' value={physician?.specialty} onChange={(e)=>handleChange(e)} size='small'>
                        {specialty.data?.map((e)=><MenuItem key={e.value} value={e.name}>{e.name}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField label='NPI' name='npi' value={physician?.npi} onChange={(e)=>handleChange(e)} size='small' InputLabelProps={{shrink:true}}/>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button color='secondary' onClick={()=>{dispatch(togglePhysicianEditModal(''));}}>Close</Button>
            <Button onClick={()=>{physicianSave(new PhysicianSaveCommand(physician)); dispatch(togglePhysicianEditModal(''));}}>Save</Button>
        </DialogActions>
    </Dialog>
</React.Fragment> 
}

export {PhysicianEditModal}