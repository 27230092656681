import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { formatDate, formatNumber } from "@telerik/kendo-intl";
import { Stack } from "@mui/system";
import { Alert, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Typography } from "@mui/material";
import { toggleCallAddModal, toggleCallViewModal } from "../../../../../app/slices/VerificationModuleSlice";
import {Headset} from '@mui/icons-material';
import { GridButton } from "../../../../../components";
import { CallView } from "./CallView";
import { AbnGenerator } from "./AbnGenerator";

function CallList(){
    const dispatch = useAppDispatch();
    const verify = useAppSelector((state) => state.verification.verification);
    const convertedCalls = () => {
        let calls = verify.calls.map((obj) => {
            return {...obj, callDate: new Date(obj.callDate)};
        })
        return calls;
    }
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginLeft='10px' marginRight='10px' marginBottom='20px'>
            <Typography className="psn-green" variant='h6'>Call Log</Typography>
            <Typography variant='body1'><b>Amount Due:</b><span style={{color:'red'}}>{' ' + formatNumber(verify.patientResponsibility.collectFromPatient,'c')}</span></Typography>
            <Stack direction='row' gap={1}>
                <Button startIcon={<Headset/>} sx={{height:'35px'}} variant='outlined' onClick={()=>dispatch(toggleCallAddModal())}>Log a Call</Button>
                <AbnGenerator/>
            </Stack>
            
        </Stack>
        

        {verify.calls.length === 0&&
            <Stack marginLeft='10px' marginRight='10px' marginTop='10px'>
                <Alert color='warning'>No Calls currently logged for this case.</Alert>
            </Stack>
        }

        {verify.calls.length > 0 &&
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead sx={{backgroundColor:'whiteSmoke'}}>
                        <TableRow>
                            <TableCell width='100px'></TableCell>
                            <TableCell>Outcome</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Called By</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {convertedCalls().sort((a,b) => b.callDate.getTime() - a.callDate.getTime()).map(i => (
                            <TableRow>
                                <TableCell><GridButton onClick={()=>dispatch(toggleCallViewModal(i.callNumber))}>Details</GridButton></TableCell>
                                <TableCell>{i.outcome}</TableCell>
                                <TableCell>{formatDate(new Date(i.callDate ?? ''),'MM/dd/yyyy')}</TableCell>
                                <TableCell>{i.calledBy}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        }
        <CallView/>
    </React.Fragment>
}

export {CallList}