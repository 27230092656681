import { Verification } from "../../../services/verifyService/model";
import { CompositeSpecification } from "../../../utils/Specification";

export class RequiresBCBSAuth extends CompositeSpecification<Verification>{
    IsSatisfiedBy(candidate: Verification): boolean {
        let authRequired = false;
        candidate.procedures.forEach(procedure => {
            if(codeArray.includes(procedure.code)){
                authRequired = true;
            }
        });
        return authRequired;
    }
    
}

const codeArray = ['15824','15826','19294','19296','19297','19298','19316','19318','20555','20930','20931','20932','20933',
'20934','20936','20937','20938','20939','20974','20975','21085','21110','21125','21127','21141','21142','21143','21145',
'21146','21147','21150','21151','21154','21155','21159','21160','21188','21193','21194','21195','21196','21198','21199',
'21206','21208','21209','21210','21215','21230','22206','22207','22208','22210','22212','22214','22216','22220','22222',
'22224','22226','22510','22511','22512','22513','22514','22515','22532','22533','22534','22548','22551','22552','22554',
'22556','22558','22585','22590','22595','22600','22610','22612','22614','22630','22632','22633','22634','22800','22802',
'22804','22808','22810','22812','22818','22819','22830','22840','22841','22842','22843','22844','22845','22846','22847',
'22848','22849','22853','22854','22856','22857','22858','22859','22860','22861','22862','22864','22865','23105','23107',
'23120','23410','23412','23415','23420','23430','23440','23450','23455','23460','23462','23465','23466','23470','23472',
'23473','23474','23700','27096','27120','27122','27125','27130','27132','27134','27137','27138','27279','27280','27331',
'27332','27333','27334','27335','27345','27403','27405','27407','27409','27412','27415','27416','27425','27427','27428',
'27429','27437','27438','27440','27441','27442','27443','27445','27446','27447','27486','27487','27488','28446','29805',
'29806','29807','29819','29820','29821','29822','29823','29824','29825','29826','29827','29828','29860','29861','29862',
'29863','29866','29867','29868','29870','29871','29873','29874','29875','29876','29877','29879','29880','29881','29882',
'29883','29884','29885','29886','29887','29888','29889','29892','29914','29915','29916','30120','30130','30140','30400',
'30410','30420','30430','30435','30450','30520','30999','31296','31297','31299','31643','32701','32851','32852','32853',
'32854','33935','33945','36516','38204','38205','38206','38207','38230','38232','38240','38241','38242','38243','41019',
'43647','43648','43881','44135','44136','47135','48160','48554','50360','50365','50380','55860','55862','55865','55874',
'55875','55920','57155','57156','58346','61796','61797','61798','61799','61800','61850','61863','61864','61867','61868',
'62280','62281','62282','62292','62320','62321','62322','62323','62325','62327','62350','62351','62360','62361','62362',
'62380','63001','63003','63005','63012','63015','63016','63017','63020','63030','63035','63040','63042','63043','63044',
'63045','63046','63047','63048','63050','63051','63052','63053','63055','63056','63057','63075','63076','63081','63082',
'63085','63086','63087','63088','63090','63091','63101','63102','63103','63185','63190','63191','63200','63250','63252',
'63265','63267','63270','63272','63275','63277','63280','63282','63285','63287','63290','63300','63301','63302','63303',
'63304','63305','63306','63307','63308','63620','63621','63650','63655','63663','63664','63685','63688','64451','64479',
'64480','64483','64484','64490','64491','64492','64493','64494','64495','64510','64520','64561','64581','64625','64633',
'64634','64635','64636','64716','64732','64734','64771','64999','67218','67900','69714','69717','69930','70336','70450',
'70460','70470','70480','70481','70482','70486','70487','70488','70490','70491','70492','70496','70498','70540','70542',
'70543','70544','70545','70546','70547','70548','70549','70551','70552','70553','70554','70555','71250','71260','71270',
'71271','71275','71550','71551','71552','71555','72125','72126','72127','72128','72129','72130','72131','72132','72133',
'72141','72142','72146','72147','72148','72149','72156','72157','72158','72159','72191','72192','72193','72194','72195',
'72196','72197','72198','73200','73201','73202','73206','73218','73219','73220','73221','73222','73223','73225','73700',
'73701','73702','73706','73718','73719','73720','73721','73722','73723','73725','74150','74160','74170','74174','74175',
'74176','74177','74178','74181','74182','74183','74185','74261','74262','74263','74712','74713','75557','75559','75561',
'75563','75565','75571','75572','75573','75574','75635','76376','76377','76380','76390','76391','76873','76965','77014',
'77046','77047','77048','77049','77078','77084','77295','77301','77316','77317','77318','77338','77370','77371','77372',
'77373','77385','77386','77387','77402','77407','77412','77424','77425','77432','77435','77469','77470','77520','77522',
'77523','77525','77750','77761','77762','77763','77767','77768','77770','77771','77772','77778','77790','78012','78013',
'78014','78015','78016','78018','78020','78070','78071','78072','78075','78102','78103','78104','78185','78195','78201',
'78202','78215','78216','78226','78227','78230','78231','78232','78258','78261','78262','78264','78265','78266','78278',
'78290','78291','78300','78305','78306','78315','78429','78430','78431','78432','78433','78445','78451','78452','78453',
'78454','78456','78457','78458','78459','78466','78468','78469','78472','78473','78481','78483','78491','78492','78494',
'78496','78579','78580','78582','78597','78598','78600','78601','78605','78606','78608','78609','78610','78630','78635',
'78645','78650','78660','78700','78701','78707','78708','78709','78725','78730','78740','78761','78800','78801','78802',
'78803','78804','78811','78812','78813','78814','78815','78816','78830','78831','78832','79005','79101','79403','81120',
'81121','81162','81163','81164','81165','81166','81167','81168','81170','81171','81172','81173','81174','81175','81176',
'81177','81178','81179','81180','81181','81182','81183','81184','81185','81186','81187','81188','81189','81190','81191',
'81192','81193','81194','81200','81201','81202','81203','81204','81205','81208','81209','81210','81212','81215','81216',
'81217','81218','81219','81221','81222','81223','81224','81225','81226','81227','81228','81229','81230','81231','81232',
'81233','81234','81235','81236','81237','81238','81239','81240','81242','81244','81245','81246','81247','81248','81249',
'81250','81251','81252','81253','81254','81255','81256','81257','81258','81259','81260','81261','81262','81263','81264',
'81265','81266','81269','81270','81271','81272','81273','81274','81275','81276','81277','81278','81279','81283','81284',
'81285','81286','81287','81288','81289','81290','81291','81292','81293','81294','81295','81296','81297','81298','81299',
'81300','81301','81302','81303','81304','81305','81306','81307','81308','81309','81310','81311','81312','81313','81314',
'81315','81316','81317','81318','81319','81320','81321','81322','81323','81324','81325','81326','81327','81328','81330',
'81331','81332','81333','81334','81335','81336','81337','81338','81339','81340','81341','81342','81343','81344','81345',
'81346','81347','81348','81349','81350','81351','81352','81353','81355','81357','81360','81361','81362','81363','81364',
'81400','81401','81402','81403','81404','81405','81406','81407','81408','81410','81411','81412','81413','81414','81415',
'81416','81417','81418','81419','81422','81425','81426','81427','81430','81431','81432','81433','81434','81435','81436',
'81437','81438','81439','81440','81441','81442','81443','81445','81448','81449','81450','81451','81455','81456','81457',
'81458','81459','81460','81462','81463','81464','81465','81470','81471','81479','81493','81504','81518','81519','81520',
'81521','81522','81523','81525','81529','81540','81541','81542','81546','81551','81552','81554','81595','90283','90284',
'90378','90867','90868','90869','90870','92633','93303','93304','93306','93307','93308','93312','93313','93314','93315',
'93316','93317','93320','93321','93325','93350','93351','93352','95807','95808','95810','95811','95980','96105','96110',
'96112','96113','96116','96121','96125','96127','96130','96131','96132','96133','96136','96137','96138','96139','96146',
'97151','97152','97153','97154','97155','97156','97157','97158','99183','0001U','0004M','0005U','0006M','0007M','0011M',
'0012M','0012U','0013M','0013U','0014U','0016M','0016U','0017M','0017U','0018U','0019U','0022U','0023U','0026U','0027U',
'0029U','0030U','0031U','0032U','0033U','0034U','0036U','0037U','0040U','0042T','0045U','0046U','0047U','0048U','0049U',
'0050U','0055U','0056U','0060U','0069U','0070U','0071U','0072U','0073U','0074U','0075U','0076U','0078U','0079U','0087U',
'0088U','0089U','0090U','0094U','0095T','0098T','0101U','0102U','0103U','0111U','0113U','0114U','0118U','0120U','0129U',
'0130U','0131U','0132U','0133U','0134U','0135U','0136U','0137U','0138U','0153U','0154U','0155U','0156U','0157U','0158U',
'0159U','0160U','0161U','0162U','0163T','0164T','0165T','0169U','0170U','0171U','0203U','0204U','0205U','0209U','0211U',
'0212U','0213T','0213U','0214T','0214U','0215T','0215U','0216T','0216U','0217T','0217U','0218T','0218U','0228U','0229U',
'0230U','0231U','0232U','0233U','0234U','0235U','0236U','0237U','0238U','0239U','0242U','0244U','0245U','0250U','0252U',
'0253U','0254U','0258U','0260U','0262U','0264U','0265U','0266U','0267U','0268U','0269U','0270U','0271U','0272U','0273U',
'0274U','0276U','0277U','0278U','0285U','0286U','0287U','0288U','0289U','0290U','0291U','0292U','0293U','0294U','0296U',
'0297U','0298U','0299U','0300U','0306U','0307U','0313U','0314U','0315U','0317U','0318U','0319U','0320U','0326U','0327U',
'0329U','0331U','0332U','0333U','0334U','0335U','0336U','0339U','0340U','0341U','0343U','0345U','0347U','0348U','0349U',
'0350U','0355U','0356U','0362T','0362U','0363U','0364U','0368U','0373T','0378U','0379U','0380U','0388U','0389U','0391U',
'0392U','0394T','0395T','0396U','0397U','0400U','0401U','0420U','0422U','0423U','0424U','0425U','0426U','0428U','0433U',
'0434U','0437U','0438U','0584T','0585T','0586T','0633T','0634T','0635T','0636T','0637T','0638T','0648T','0649T','A0430',
'A0435','A4290','A9508','A9513','A9528','A9531','A9543','A9590','A9600','A9602','A9604','A9606','A9607','A9800','C8900',
'C8901','C8902','C8903','C8905','C8906','C8908','C8909','C8910','C8911','C8912','C8913','C8914','C8918','C8919','C8920',
'C8931','C8932','C8933','C8934','C8935','C8936','C9094','C9142','C9146','C9147','C9148','C9163','C9165','C9257','C9359',
'C9362','C9399','E0745','E0748','E0749','E0765','G0219','G0235','G0252','G0277','G0289','G0339','G0340','G0341','G0342',
'G0343','G0458','G6001','G6002','G6003','G6004','G6005','G6006','G6007','G6008','G6009','G6010','G6011','G6012','G6013',
'G6014','G6015','G6016','G6017','G9143','J0129','J0180','J0202','J0219','J0221','J0222','J0223','J0224','J0490','J0491',
'J0517','J0565','J0567','J0584','J0585','J0586','J0587','J0588','J0598','J0638','J0641','J0642','J0717','J0775','J0791',
'J0881','J0882','J0885','J0888','J0896','J0897','J1290','J1300','J1301','J1302','J1303','J1305','J1306','J1322','J1325',
'J1411','J1428','J1442','J1447','J1448','J1449','J1458','J1459','J1551','J1554','J1555','J1556','J1557','J1558','J1559',
'J1561','J1562','J1566','J1568','J1569','J1572','J1575','J1576','J1599','J1602','J1675','J1726','J1743','J1745','J1746',
'J1786','J1823','J1931','J1950','J2182','J2278','J2323','J2326','J2350','J2356','J2357','J2502','J2506','J2507','J2562',
'J2786','J2820','J2840','J2860','J2941','J3032','J3060','J3121','J3145','J3241','J3245','J3262','J3285','J3315','J3358',
'J3380','J3385','J3397','J3398','J3399','J3490','J3590','J7178','J7330','J7340','J9019','J9021','J9022','J9023','J9029',
'J9032','J9035','J9037','J9039','J9042','J9043','J9047','J9055','J9057','J9061','J9063','J9064','J9118','J9119','J9144',
'J9145','J9153','J9155','J9173','J9176','J9177','J9179','J9202','J9203','J9204','J9205','J9207','J9217','J9218','J9219',
'J9223','J9225','J9226','J9227','J9228','J9229','J9259','J9264','J9266','J9269','J9271','J9272','J9273','J9274','J9281',
'J9286','J9295','J9298','J9299','J9301','J9302','J9303','J9306','J9308','J9309','J9311','J9312','J9313','J9316','J9317',
'J9321','J9325','J9331','J9332','J9345','J9347','J9348','J9349','J9350','J9352','J9353','J9354','J9355','J9356','J9358',
'J9359','J9380','J9999','L8600','L8614','L8615','L8616','L8617','L8618','L8619','L8621','L8622','L8623','L8624','L8627',
'L8628','L8629','L8690','L8691','L8693','Q2041','Q2042','Q2043','Q2049','Q2050','Q2053','Q2054','Q2055','Q2056','Q3001',
'Q4081','Q5101','Q5103','Q5104','Q5105','Q5106','Q5107','Q5108','Q5109','Q5110','Q5111','Q5112','Q5113','Q5114','Q5115',
'Q5116','Q5117','Q5118','Q5119','Q5120','Q5121','Q5122','Q5123','Q5125','Q5126','Q5127','Q5129','Q5130','S0157','S0189',
'S2053','S2054','S2060','S2065','S2102','S2112','S2120','S2140','S2142','S2150','S3800','S3840','S3841','S3842','S3844',
'S3845','S3846','S3849','S3850','S3852','S3853','S3854','S3861','S3865','S3866','S3870','S8030','S8037'];