import { Stack } from "@mui/material"
import React from "react"
import { RefundUploadDialog } from "./RefundUploadDialog"
import { RefundDocumentList } from "./RefundDocumentList"

function RefundUploads(){
    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' marginTop='5px' marginRight='5px'>
            <RefundUploadDialog/>
        </Stack>
        <RefundDocumentList/>
    </React.Fragment>
}

export {RefundUploads}