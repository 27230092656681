import { Switch } from "@progress/kendo-react-inputs"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editCaseManDocumentRequest } from "../../../../../services/caseManService/model/caseMan/CaseManagementSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function PtNotesRequest(){
    const dispatch = useAppDispatch();
    const caseMan = useAppSelector((state) => state.caseManagement.caseManagement);

    return <React.Fragment>
        <div>
            <label>P.T. Notes</label><br/>
            <Switch value={caseMan.documentRequest?.physicalTherapyNote}
                checked={caseMan.documentRequest?.physicalTherapyNote}
                onChange={(e)=>dispatch(editCaseManDocumentRequest(new ReduxUpdate('physicalTherapyNote',e.target.value)))}/>
        </div>
    </React.Fragment>
}
export {PtNotesRequest}