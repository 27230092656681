import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { useVarianceSaveMutation, useVarianceStatusUpdateMutation } from "../../../../../../services/varianceService/variance-api";
import { Cancel } from "@mui/icons-material";
import { Alert, Button, Snackbar } from "@mui/material";
import { VarianceStatusPostCommand } from "../../../../../../services/varianceService/commands";
import { VariancePostCommand } from "../../../../../../services/varianceService/commands/VariancePostCommand";

type ownProps={
    setNotify:Function;
    setMessage:Function;}

function VarianceCancelBtn(props:ownProps){
    const variance = useAppSelector((state)=>state.varianceRequest.varianceRequest);
    const [varianceSave] = useVarianceSaveMutation();
    const [saveStatus, result] = useVarianceStatusUpdateMutation();
    const handleCancel=async ()=>{
        await varianceSave(new VariancePostCommand(variance));
        await saveStatus(new VarianceStatusPostCommand(variance?.id,"Cancelled"))
        .unwrap()
        .then(()=>{props.setNotify(true);props.setMessage("Incident Cancelled")})
    }

    return <React.Fragment>
        <Button variant='outlined' color='error' startIcon={<Cancel/>}
            onClick={()=>handleCancel()}>Cancel Incident</Button>
        
        <Snackbar open={result.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(result.error)}</Alert>
        </Snackbar>
        
    </React.Fragment>
}

export {VarianceCancelBtn}