import React, { useEffect } from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Window } from "@progress/kendo-react-dialogs";
import { toggleWaystarModal } from "../../../../../app/slices/VerificationModuleSlice";
import { useEligibilitySummaryQuery } from "../../../../../services/verifyService/verify-api";
import { EligibilitySummaryRequest } from "../../../../../services/verifyService/commands";
import { Loading } from "../../../../shared/features/Loading";
import { Typography } from "@mui/material";

function WaystarBenefitsSummary(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const isOpen = useAppSelector((state) => state.verificationModule.WaystarModal);
    const selectedPayer = useAppSelector((state) => state.verificationModule.SelectedPayer);  
    const[skip,setSkip] = React.useState(!isOpen);
    const {data, isFetching} = useEligibilitySummaryQuery(new EligibilitySummaryRequest(id!,selectedPayer.payerCode),{skip,})
    useEffect(()=>setSkip(!isOpen),[isOpen])
    return <React.Fragment>
        {isOpen &&(
            <Window title='Benefits' onClose={()=>dispatch(toggleWaystarModal(selectedPayer))} 
                initialTop={0} initialLeft={window.visualViewport!.width - 800} initialHeight={800} initialWidth={800}>
                {isFetching &&
                    <div>
                        <Typography marginBottom='10px' variant='h6'>Contacting Payer, this may take a minute...</Typography>
                        <Loading/>
                    </div>
                }
                {!isFetching &&
                    <div dangerouslySetInnerHTML={{__html: data?.text ?? ""}}></div>
                }
            </Window>
        )}    
    </React.Fragment>
}

export {WaystarBenefitsSummary}