import React from "react";
import { useAppSelector } from "../../../../../app/hooks"
import { TextField } from "@mui/material";
import { formatNumber } from "@progress/kendo-intl";

function AccountAdjustmentView(){
    const account = useAppSelector((state) => state.account.account);
    return <React.Fragment>
        <TextField label='Adjustments' 
            value={formatNumber(account.transactionHeader.adjustments,'c')}
            disabled
            />
    </React.Fragment>
}
export {AccountAdjustmentView}