import { Stack } from "@mui/material"
import React from "react"

function RefundsIncoming(){
    return <React.Fragment>
        <Stack justifyContent='center' alignItems='center'>
            <iframe title="RadiologyAuthsOutstanding" width="1350" height="800" 
                src="https://app.powerbi.com/reportEmbed?reportId=ddcf7f19-3856-48a4-b086-957c5ab855d6&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac&navContentPaneEnabled=false" 
                allowFullScreen={true}></iframe>
        </Stack>
    </React.Fragment>
}

export {RefundsIncoming}