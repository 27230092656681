import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react"
import { useNavigate } from "react-router";
import { useAppSelector } from "../../../../../app/hooks";
import { FeeScheduleDeleteCommand } from "../../../../../services/contractService/commands";
import { useFeeScheduleDeleteMutation } from "../../../../../services/contractService/contract-api";

type ownProps ={
    isOpen:boolean;
    toggleModal:Function;
}

function DeleteConfirmation(props:ownProps){
    const feeSchedule = useAppSelector((state)=>state.feeSchedule.feeSchedule);
    const navigate = useNavigate();
    const [deleteSchedule] = useFeeScheduleDeleteMutation();
    const remove =()=>{
        let command = new FeeScheduleDeleteCommand(feeSchedule.id);
        deleteSchedule(command);
    }

    return <React.Fragment>
        <Dialog open={props.isOpen}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <Alert color='error'>
                    You are about to delete this fee schedule and all assosciated codes.  This action is permanent and irreversible.  Would you like to proceed?
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>props.toggleModal(false)}>Cancel</Button> 
                <Button color='error' onClick={()=> {remove(); props.toggleModal(false); navigate(-1); }}>Confirm</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {DeleteConfirmation}