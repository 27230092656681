import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { TextField } from "@mui/material";
import { editCenterV2 } from "../../../../services/orgService/model/center/CenterSlice";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";

function AuthNotificationEdit(){
    const center = useAppSelector((state)=>state.center.center);
    const dispatch = useAppDispatch();
    return <React.Fragment>
        <TextField label='Auth Notification Email' value={center.authNotificationAddress} type='email' sx={{width:'300px'}}
            onChange={(e)=>dispatch(editCenterV2(new ReduxUpdate("authNotificationAddress",e.target.value)))}/>
    </React.Fragment>
}

export {AuthNotificationEdit}