import { Stack } from "@mui/material"
import React from "react"

function RadiologyAuthOutstanding(){
    return <React.Fragment>
        <Stack justifyContent='center' alignItems='center'>
            <iframe title="RadiologyAuthsOutstanding" width="1350" height="800" src="https://app.powerbi.com/reportEmbed?reportId=1a493f51-1006-4e65-bd0d-201edf529a91&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac" allowFullScreen={true}></iframe>
        </Stack>
    </React.Fragment>
}
export {RadiologyAuthOutstanding}