import { Stack } from "@mui/system"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { MedicareRateList } from "./MedicareRateList";
import { MedicareRateModal } from "./MedicareRateModal";

function MedicareConfig(){
    const dispatch = useAppDispatch();
    const center = useAppSelector((state) => state.center.center)

    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' margin='5px'>
            <MedicareRateModal/>
        </Stack>
        <MedicareRateList/>
    </React.Fragment>
}

export {MedicareConfig}