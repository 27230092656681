import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { Alert, Grid, TextField } from "@mui/material";
import { formatNumber } from "@progress/kendo-intl";

function AccountNonSurgProcList(){
    const account = useAppSelector((state)=>state.account.account);

    return <React.Fragment>
        {account.nonSurgical.length === 0 ? <Alert severity='info'>No non-surgical procedures found for this case</Alert>: 
        <div>
            <Grid container spacing={1} marginLeft='5px'>
                <Grid item sm={1}><h6>CPT</h6></Grid>
                <Grid item sm={1}><h6>Rev Code</h6></Grid>
                <Grid item sm={2}><h6>GL</h6></Grid>
                <Grid item sm={1}><h6>Qty</h6></Grid>
                <Grid item sm={2}><h6>Charge Amount</h6></Grid>
            </Grid>   
        </div>}
        {account.nonSurgical.map(i => (
            <Grid container spacing={1} sx={{mb:1}} marginLeft='5px'>
                <Grid item sm={1}><TextField value={i.cpt} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                <Grid item sm={1}><TextField value={i.revCode} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                <Grid item sm={2}><TextField value={i.gl} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                <Grid item sm={1}><TextField value={i.qty} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
                <Grid item sm={2}><TextField value={formatNumber(i.chargeAmount ?? 0,'c')} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/></Grid>
            </Grid>
        ))}
    </React.Fragment>
}
export {AccountNonSurgProcList}