import React, { useState } from "react"
import { useParams } from "react-router";
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import { AccountSummaryRequest } from "../../../../services/accountService/commands";
import { useFindAdjustmentsByPeriodQuery } from "../../../../services/accountService/account-api";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { AdjustmentGridCommandCell } from "./AdjustmentGridCommandCell";
import { AdjustmentGridApprovalStatusCell } from "./AdjustmentGridApprovalStatusCell";

function AdjustmentGrid(){
    const {center, month, year} = useParams();
    const {data} = useFindAdjustmentsByPeriodQuery(new AccountSummaryRequest(center!, Number(month!), Number(year!)));
    const mapAccounts=()=>{
        let accounts = data === undefined ? [] : data.map(i => ({...i, serviceDate: new Date(i.serviceDate!)}))
        return accounts;
    }
    const defaultGridState:State = {sort:[{field:'serviceDate',dir:'asc'}]};
    const [dataState, setDataState] = useState(defaultGridState);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
    return <React.Fragment>
        <Grid data={process(mapAccounts(),dataState)} style={{height:'750px'}} 
            onDataStateChange={dataStateChange}>
            <Column cell={AdjustmentGridCommandCell} width={'75px'}/>
            <Column title='Account' field='accountNumber' width={'150px'} columnMenu={ColumnMenu}/>
            <Column title='Patient' field='patientName' width={'200px'} columnMenu={ColumnMenu}/>
            <Column title='Service Date' field='serviceDate' format='{0:MM/dd/yyyy}' filter="date" width={'150px'} columnMenu={ColumnMenu}/>
            <Column title='Payer' field='payer' width={'250px'} columnMenu={ColumnMenu}/>
            <Column title='VT' field='visitType' width={'100px'} columnMenu={ColumnMenu}/>
            <Column title='SC' field='serviceCode' width={'100px'} columnMenu={ColumnMenu}/>
            <Column title='Calc' field='payerCalcType' width={'125px'} columnMenu={ColumnMenu}/>
            <Column title='Adjustments' field='adjustmentsThisPeriod' format='{0:c}' width={'150px'} columnMenu={ColumnMenu}/>   
            <Column cell={AdjustmentGridApprovalStatusCell}/>       
        </Grid>
    </React.Fragment>
}
export {AdjustmentGrid}