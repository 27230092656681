import { Stack, Typography } from "@mui/material"
import React from "react"
import { ComboCaseAttribute } from "./ComboCaseAttribute"
import { SingleCaseAgreementAttribute } from "./SingleCaseAgreementAttribute"
import { ZeroReimbursementAttribute } from "./ZeroReimbursementAttribute"

function BenefitsAttributes(){
    return <React.Fragment>
        <Typography sx={{borderBottom:'2px solid gray'}} variant='h6' className="psn-green">Benefits Attributes</Typography>
        <Stack gap={2} direction='row' marginTop='5px' marginRight='15px' justifyContent='space-around'>
            <ComboCaseAttribute/>
            <SingleCaseAgreementAttribute/>
            <ZeroReimbursementAttribute/>
        </Stack>
    </React.Fragment>
}
export{BenefitsAttributes}