import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,MenuItem,SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import {Stack } from "@mui/system";
import React, { useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleCptScheduleItemAddModal } from "../../../../../app/slices/ContractModuleSlice";
import { CptAddCommand } from "../../../../../services/contractService/commands";
import { Cpt } from "../../../../../services/contractService/model";
import { contractApi, useCptAddMutation, useFeeScheduleFindQuery } from "../../../../../services/contractService/contract-api";


function CptItemAdd(){
    const {feeScheduleId} = useParams();
    const feeSchedule = useFeeScheduleFindQuery(feeScheduleId!);
    const isOpen = useAppSelector((state)=>state.contractModule.cptScheduleItemAddModal)
    const dispatch = useAppDispatch();
    const [cpt,setCpt] = useState(new Cpt())
    const [saveCpt] = useCptAddMutation();
    const handleChange = (e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|SelectChangeEvent<string>)=>{
        const {name,value} = e.target;
        let newCpt:Cpt = cpt;
        newCpt = ({...newCpt,[name]:value});
        setCpt(newCpt);
    }
    const handleMpd = (e:React.ChangeEvent<HTMLInputElement>) =>{
        const {name,checked} = e.target;
        let newCpt = cpt;
        newCpt = ({...newCpt,[name]:checked})
        setCpt(newCpt)
    }

    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle><Typography variant='h6' className='psn-green'>Add a New CPT</Typography></DialogTitle>
            <DialogContent>
                <Stack direction='row' gap={1} marginTop='10px'>
                    <TextField label='CPT' name='code' value={cpt.code} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>

                    <TextField label='APC Code' name='apcCode' value={cpt.apcCode} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>
                </Stack>
                <Stack direction='row' marginTop='10px'>
                    <TextField label='Description' name='name' value={cpt.name} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}} fullWidth multiline/>
                </Stack>
                <Stack direction='row' marginTop='10px' gap={1}>
                    <TextField label='Category' name='category' select value={cpt.category}  onChange={(e)=>handleChange(e)} size='small' sx={{width:'200px', maxWidth:'100%'}}>
                        <MenuItem value='Unknown'>Unknown</MenuItem>
                        <MenuItem value='Ortho Proc'>Ortho Proc</MenuItem>
                        <MenuItem value='Cath/PRCA'>Cath/PRCA</MenuItem>
                        <MenuItem value='APC Grouped'>APC Grouped</MenuItem>
                        <MenuItem value='Office Procedure'>Office Procedure</MenuItem>
                        <MenuItem value='Non Grouped'>Non Grouped</MenuItem>
                        <MenuItem value='Incidental'>Incidental</MenuItem>
                    </TextField>
                    {feeSchedule.data?.feeSubType === 'Flat Rate' &&
                    <TextField type='number' label='Rate' name='baseRate' value={cpt.baseRate} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>}
                    {feeSchedule.data?.feeSubType === 'Grouped' &&
                    <TextField label='Grouper Pointer' name='grouperPointer' value={cpt.grouperPointer} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>}
                    <TextField label='Relative Weight' name='relativeWeight' value={cpt.relativeWeight} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>
                </Stack>
                <Stack direction='row' marginTop='10px'>
                <FormControlLabel
                     control = {<Switch size="small" value={cpt.multiProcedureDiscount} checked={cpt.multiProcedureDiscount} onChange={(e)=>handleMpd(e)} name='multiProcedureDiscount'/>}
                     label='MPD' labelPlacement="top"/> 
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>{setCpt(new Cpt());dispatch(toggleCptScheduleItemAddModal())}}>Cancel</Button>
                <Button 
                    onClick={()=> {saveCpt(new CptAddCommand(feeScheduleId!,cpt.code,cpt.name,cpt.category,cpt.apcCode,cpt.baseRate.toString(), cpt.grouperPointer??'',cpt.multiProcedureDiscount??false,cpt.relativeWeight));
                                    dispatch(toggleCptScheduleItemAddModal());dispatch(contractApi.util.invalidateTags(['feeScheduleItemCount']))}}>Save</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export{CptItemAdd}