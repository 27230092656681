import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useAppDispatch } from "../../../../../app/hooks"
import { toggleGrouperEditModal } from "../../../../../app/slices/ContractModuleSlice";
import { GridButton } from "../../../../../components"

function GrouperEditCommandCell(props: GridCellProps){
    const dispatch = useAppDispatch();
    return <React.Fragment>
        <GridButton onClick={()=> dispatch(toggleGrouperEditModal(props.dataItem.id))}>View</GridButton>
    </React.Fragment>
}

export {GrouperEditCommandCell}