import React from "react"
import { useNavigate, useParams } from "react-router";
import { useFindAccountByPeriodQuery } from "../../../../../services/accountService/account-api";
import { AccountSummaryRequest } from "../../../../../services/accountService/commands";
import { Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { formatNumber } from "@progress/kendo-intl";

function AccountAccrualCard(){
    const navigate = useNavigate();
    const {center, month, year} = useParams();
    const {data} = useFindAccountByPeriodQuery(new AccountSummaryRequest(center!, Number(month!), Number(year!)));
    const accuredRev = data === undefined ? 0 : data.reduce((acc, item)=>acc+item.finalRevenue!,0);

    return <React.Fragment>
         <Card sx={{width:'300px', position:'relative', overflow: 'visible'}}>
            <Button 
                variant='contained' 
                color="primary" 
                sx={{
                    position: 'absolute', 
                    bottom: '-15px', 
                    right: '-15px', 
                    backgroundColor:'#62BCBD',
                    borderRadius: '20px',
                }} onClick={()=>navigate('/RevRecSummary/'+center + '/'+month+'/'+year)}>View</Button>
            <CardHeader title="Accrued Revenue" sx={{backgroundColor:'#62BCBD', color:'white', height:'40px'}}/>
            <CardContent>
                <Stack direction="row" spacing={2} justifyContent='center'>
                    <Typography className='psn-green' variant="h6">{formatNumber(accuredRev,'c')}</Typography>
                </Stack>
            </CardContent>
            </Card> 
    </React.Fragment>
}
export {AccountAccrualCard}