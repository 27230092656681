import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import React, { useState } from "react"
import { useParams } from "react-router"
import { useRuleListQuery } from "../../../../../services/contractService/contract-api";
import { process, State } from "@progress/kendo-data-query";
import { RuleEditCommandCell } from "./RuleEditCommandCell";

function RuleList(){
    const {id} = useParams();
    const {data} = useRuleListQuery(id!)
    const [dataState, setDataState] = useState({});
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

    return <React.Fragment>
        <Grid data={process(data === undefined ? []: data!, dataState)}
            style={{height:'500px'}} onDataStateChange={dataStateChange}>
                <Column cell={RuleEditCommandCell} width='100px'/>
                <Column title='Rule Type' field='ruleType' width='200px'/>
                <Column title='Description' field='description'/>
        </Grid>
    </React.Fragment>
}

export {RuleList}