import { Alert, Button, Snackbar } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { usePolicySaveMutation } from "../../../../../services/caseManService/caseManage-api";
import { PolicyPostCommand } from "../../../../../services/caseManService/commands";
import { useNavigate, useParams } from "react-router";
import { Guid } from "js-guid";

function PolicySaveBtn(){
    const {id} = useParams();
    const navigate = useNavigate();
    const policy = useAppSelector((state)=>state.policy.policy);
    const [save, status] = usePolicySaveMutation();
    const [notify, setNotify] = useState(false);
    useEffect(()=>{setNotify(status.isSuccess)},[status.isSuccess]);
    
    const savePolicy = ()=>{
        let newPolicy = {...policy};
        id == 'new' ?newPolicy.id = Guid.newGuid().toString():newPolicy.id = id!;
        const command = new PolicyPostCommand(newPolicy);
        save(command).unwrap().then((data)=>{
            navigate('/policyDetail/'+newPolicy.id);
        });
    }

    return <React.Fragment>
        <Button variant='outlined' color='primary' onClick={()=>savePolicy()}>Save</Button>

        <Snackbar open={notify} onClose={()=>setNotify(false)} color='success' autoHideDuration={6000} message='Policy Saved Successfully!'/>
        <Snackbar open={status.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(status.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}
export {PolicySaveBtn}

