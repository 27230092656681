export interface Service{
    id:string;
    centerId:string;
    code: string;
    description:string;
}

export function DefaultService():Service{
    return {
        id:'',centerId:'',code:'',description:''
    }
}
