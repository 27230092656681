import React from "react"
import { useAppSelector } from "../../../../../../app/hooks"
import { Alert, Button, Snackbar } from "@mui/material";
import { PostAdd } from "@mui/icons-material";
import { useRefundSaveMutation, useRefundStatusUpdateMutation } from "../../../../../../services/refundService/refund-api";
import { RefundPostCommand, RefundStatusPostCommand } from "../../../../../../services/refundService/commands";
import { RefundCheckInfoValid } from "../../../../specs/RefundCheckInfoValid";

type ownProps ={
    setNotify:Function;
    setMessage:Function;}

function RefundSubmitForApproval(props:ownProps){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const [refundSave] = useRefundSaveMutation();
    const [saveStatus, result] = useRefundStatusUpdateMutation();
    const [isValid, setIsValid] = React.useState(true);
    const command = new RefundStatusPostCommand(refund?.caseId,refund?.id,"APPROVE");
    const handleSave=async ()=>{
        await refundSave(new RefundPostCommand(refund));
        if(new RefundCheckInfoValid().IsSatisfiedBy(refund)){
            setIsValid(true);
            await saveStatus(command).unwrap()
            .then(()=>{props.setNotify(true);
            props.setMessage('Refund Request Submitted for Approval')});}
        else{setIsValid(false);}
    }

    return <React.Fragment>
        <Button variant='outlined' color='success' startIcon={<PostAdd/>}
            onClick={()=>{handleSave()}}>{result.isLoading?'Submitting':'Submit for Approval'}</Button>

        <Snackbar open={result.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(result.error)}</Alert>
        </Snackbar>
        <Snackbar open={!isValid} onClose={()=>setIsValid(true)} color='error' autoHideDuration={6000}>
            <Alert severity='error'>{'Invalid Refund: Confirm Check Info'}</Alert>
        </Snackbar>
        
    </React.Fragment>
}


export {RefundSubmitForApproval}