import { Stack, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { useCenterQuery } from "../../../../services/orgService/org-api";

function CenterHeader(){
    const {id} = useParams()
    const {data} = useCenterQuery(id!);
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' sx={{marginBottom:'5px', marginTop:'5px'}}>
            <Typography>{data?.streetAddress1 + '  ' + data?.streetAddress2}</Typography>
            <Typography>{data?.city + ', ' + data?.state + '    ' + data?.zip}</Typography>
        </Stack>
    </React.Fragment>
}
export {CenterHeader}