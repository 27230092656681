import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { EligibilityCalcRequest, EligibilityCalcResponse, PatientResponsibility } from './model';
import { getToken } from '../../utils/AdalConfig';
import { CalculatorService } from '../../utils/ServiceLocator';

export const calculatorApi = createApi({
    reducerPath:'calculatorApi',
    baseQuery:fetchBaseQuery({
        baseUrl: CalculatorService,
        prepareHeaders:(headers,{getState}) => {
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }       
    }),
    endpoints:(builder)=>({
        calculateResp:builder.mutation<PatientResponsibility,PatientResponsibility>({
            query:(body) => {
                return{url:'PatientResponsibility',method:'POST',body:body,}
            },
        }),
        calculateEligibility:builder.mutation<EligibilityCalcResponse,EligibilityCalcRequest>({
            query:(body) => {
                return{url:'CalcEligibility',method:'POST',body:body,}
            },
        })
    })
})

export const{useCalculateRespMutation, useCalculateEligibilityMutation} = calculatorApi