import { Stack, Box, Typography, IconButton, Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { editVariance } from "../../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { IncidentTypeOptions } from "./IncidentTypeOptions";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { setVarianceSelectedIncidentTypes } from "../../../../../../app/slices/VarianceModuleSlice";

function IncidentTypeSelection(props: { canEdit: boolean }){
    const dispatch = useAppDispatch();
    const incident = useAppSelector(
      (state) => state.varianceRequest.varianceRequest
    );
    const [incidentEdit, setIncidentEdit] = useState(incident?.incidentType === "UNKNOWN" || incident?.incidentType === undefined);
    const getLabel = (value: string) => {
      const option = IncidentTypeOptions.find((option) => option.value === value);
      return option ? option.label : "";
    };
    return (
      <React.Fragment>      
      <Box>
      {incidentEdit === false && (
      <Stack direction="row">
      <Typography variant="h6" className="psn-green">
      Incident Type:
      &nbsp;
      </Typography>
      <Typography variant="h6" className="psn-green">
      {getLabel(incident.incidentType)}
      </Typography>
      <IconButton
      disabled={!props.canEdit}
      size="small"
      onClick={() => setIncidentEdit(!incidentEdit)}
      >
      <ModeEditOutlinedIcon fontSize="small" />
      </IconButton>
      </Stack>
      )}
      {incidentEdit === true && (
      <Stack direction="row">
      <Autocomplete
      disabled={!props.canEdit}
      sx={{ width: 300 }}
      onChange={(_event, value) => {
      dispatch(
        editVariance({ name: "incidentType", value: value?.value! })
      );
      dispatch(setVarianceSelectedIncidentTypes(value?.value!));
    }
      }
      options={IncidentTypeOptions}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
      <TextField {...params} label="Choose an Incident Type" />
      )}
      />
      <IconButton
      disabled={!props.canEdit}
      size="small"
      onClick={() => {
        setIncidentEdit(!incidentEdit);
      }}
      >
      <CheckCircleOutlineOutlinedIcon fontSize="small" />
      </IconButton>
      </Stack>
      )}
      </Box>
      </React.Fragment>
    );
}

export {IncidentTypeSelection}