import { MenuItem, TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { editPolicyDetails } from "../../../../services/caseManService/model/policy/PolicySlice";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";

function PolicyCategory(){
    const dispatch = useAppDispatch();
    const policy = useAppSelector((state)=>state.policy.policy);
    return <React.Fragment>
        <TextField select label='Category' sx={{width:'300px'}} value={policy.category}
         onChange={(e)=>dispatch(editPolicyDetails(new ReduxUpdate("category",e.target.value)))} >
            <MenuItem value='Arthroscopy'>Arthroscopies</MenuItem>
            <MenuItem value='Cochlear'>Cochlear Implants</MenuItem>
            <MenuItem value='ComputerAssisted'>Computer Assisted</MenuItem>
            <MenuItem value='Inspire'>Inspire Procedures</MenuItem>
            <MenuItem value='Mild'>MILD Procedures</MenuItem>
            <MenuItem value='PainPump'>Pain Pumps</MenuItem>
            <MenuItem value='Spine'>Spine</MenuItem>
            <MenuItem value='Stimulators'>Stimulators</MenuItem>
            <MenuItem value='TotalJoint'>Total Joints</MenuItem>
        </TextField>
    </React.Fragment>
}
export{PolicyCategory}