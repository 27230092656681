
import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { ClaimRemoveCommand } from "../../../../services/profileService/commands";
import { Profile } from "../../../../services/profileService";
import { useRemoveClaimMutation } from "../../../../services/profileService/profile-api";
import { GridButton } from "../../../../components";
import DeleteIcon from '@mui/icons-material/Delete';

interface ownProps extends GridCellProps{
    profile:Profile;
}

function ClaimsAccessRemoveCommand(props:ownProps){
    const [removeClaim] = useRemoveClaimMutation();
    const handleSave=()=>{
        const command = new ClaimRemoveCommand(props.profile!.identity, props.dataItem.code);
        removeClaim(command);
    }
    return <React.Fragment>
        <GridButton className="ms-1 mt-1" variant="outlined" color='error'
         onClick={()=>handleSave()}><DeleteIcon/></GridButton>
    </React.Fragment>
}

export {ClaimsAccessRemoveCommand}