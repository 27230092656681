import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { useAppSelector } from "../../../../../../app/hooks";
import { Button, Dialog, Typography} from "@mui/material";
import { useSameDayScheduledFindQuery } from "../../../../../../services/verifyService/verify-api";
import { SameDayScheduledCommand } from "../../../../../../services/verifyService/commands/SameDayScheduledCommand";
import { useParams } from "react-router";
import { useCaseFindQuery } from "../../../../../../services/scheduleService/schedule-api";
import { process, State } from "@progress/kendo-data-query";
import { ColumnMenu } from "../../../../../../utils/ColumnMenu";
import { formatDate, formatNumber } from "@progress/kendo-intl";



function ApprovalComparison(){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    const verification = useAppSelector((state) => state.verification.verification);
    const [isopen, setIsOpen] = React.useState(false);
    const {data, isFetching} = useSameDayScheduledFindQuery(new SameDayScheduledCommand(verification?.center!,schedule.data?.serviceDate!,schedule.data?.physician.lastName+', ' + schedule.data?.physician.firstName));
    const defaultGridState:State = {sort:[{field:'serviceDate',dir:'asc'}]};
    const [dataState, setDataState] = useState(defaultGridState);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };


    return <React.Fragment>
        <Button variant='outlined' color='secondary' onClick={()=>setIsOpen(true)}>Physician Utilization</Button>
        <Dialog open={isopen} onClose={()=>setIsOpen(false)} maxWidth='xl'>
        <Typography margin='5px'  variant='h6'>{'Physician utilization for Dr. ' +  schedule.data?.physician.lastName + ' on service date ' + formatDate(new Date(schedule.data?.serviceDate!),'MM/dd/yyyy')}</Typography>
        <Grid data = {process(data??[],dataState)}
         style={{height:'750px'}} onDataStateChange={dataStateChange}>
            <Column title='Account' field='accountNumber' columnMenu={ColumnMenu}/>
            <Column title='Patient' field='patient' columnMenu={ColumnMenu}/>
            <Column title='Physician' field='physician' columnMenu={ColumnMenu}/>
            <Column title='Reimbursement' field='expectedReimbursement' columnMenu={ColumnMenu} format='{0:c}'/>
            <Column title='Contribution' field='totalContribution' columnMenu={ColumnMenu} format='{0:c}'/>
            <Column title='Margin' field='contributionMargin' columnMenu={ColumnMenu} format="{0:p}"/>
        </Grid>
        </Dialog>
    </React.Fragment>
}
export {ApprovalComparison}