import { Add } from '@mui/icons-material';
import { Stack,Button } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { toggleQuickAddModal } from '../../../app/slices/ContractModuleSlice';
import { useMyProfileQuery } from '../../../services/profileService/profile-api';
import { ContractList, QuickAdd } from '../features';
import { ContractAlert } from '../../../components/ContractAlerts';

function ContractHome(){
    const {} = useMyProfileQuery();
    const dispatch = useAppDispatch();
    return <React.Fragment>
            <ContractAlert queryParam='Missing Centers'/>
            <ContractAlert queryParam='Unknown CalcType'/>
            <ContractAlert queryParam='Unknown Financial Class'/>
            <ContractAlert queryParam='Fee Schedule Error'/>
            <Stack direction='row' justifyContent='flex-end'>
                <Button variant='outlined' startIcon={<Add/> }onClick={()=>dispatch(toggleQuickAddModal())}> Add a Contract</Button>
            </Stack> 
            <ContractList/>
            <QuickAdd/>
    </React.Fragment>
}

export {ContractHome}
