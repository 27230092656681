import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useNavigate } from "react-router"
import { useAppDispatch } from "../../../../app/hooks";
import { toggleCaseLookup } from "../../../../app/slices/RadModuleSlice";
import { GridButton } from "../../../../components"

function LookupResultsCommand(props:GridCellProps){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <GridButton
            onClick={()=>{dispatch(toggleCaseLookup()); navigate('/RadEdit/'+props.dataItem.centerId + '/'+props.dataItem.radRequestId)}}
            >View</GridButton>
    </React.Fragment>
}

export{LookupResultsCommand}