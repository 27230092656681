import React from "react"
import { useMyProfileQuery } from "../../../../services/profileService/profile-api"
import { Card, CardActions, CardContent, Grid } from "@mui/material";
import VarianceIcon from '../../../../images/appicons/Variance.png';

function IncidentReportingCard(){
    const {data} = useMyProfileQuery();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin'){return true;}
        if(data?.claimsAccess.find(p => p.code === 'IncidentEdit') != undefined){return true;}
        return false;
    }

    return <React.Fragment>
        {canAccess() &&
            <Grid item sm={3}>
                <Card className='card-img-body center-align'>
                    <CardContent className='card-img-main'>
                        <a className='btn img-button' href={'/VarianceHome'}><img className='card-img' src={VarianceIcon} alt=''/></a>
                    </CardContent>
                    <CardActions className='card-img-footer'><h6>Incident Reporting</h6></CardActions>
                </Card>
            </Grid>
        }
    </React.Fragment>
}

export{IncidentReportingCard}