export interface Physician{
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
}

export function defaultPhysician():Physician{
    return {
        id:'',firstName:'',lastName:'',npi:''
    }
}