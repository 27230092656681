import React, { useState } from "react"
import {process, State } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useAppDispatch } from "../../../../app/hooks";
import { useServiceListQuery } from "../../../../services/orgService/org-api";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { Stack } from "@mui/system";
import { ServiceAdd } from "./ServiceAdd";
import { EditServiceCell } from "./EditServiceCell";
import { ServiceEdit } from "./ServiceEdit";
import { ServiceExport } from "./ServiceExport";
import { ServiceImport } from "./ServiceImport";

type ownProps={
    center:string;
}

function ServiceList(props:ownProps){
    const {data} = useServiceListQuery(props.center);
    const [dataState, setDataState] = useState({});

    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };


    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' marginTop='5px'>
            <Stack direction='row' gap={1}>
                <ServiceExport center={props.center}/>
                <ServiceImport center={props.center}/>
            </Stack>
            <ServiceAdd center={props.center}/>
        </Stack>
        <Grid className='center-list-grid card-body-grid' data={process(data===undefined?[]:data.slice(0),dataState)}
            style={{height:'500px'}} onDataStateChange={dataStateChange}>
                <Column cell={EditServiceCell} width='100px'/>
                <Column title='Code' field='code' columnMenu={ColumnMenu} width='300px'/>
                <Column title='Description' field='description' columnMenu={ColumnMenu}/>
        </Grid>
        <ServiceEdit center={props.center}/>
    </React.Fragment>
}

export {ServiceList}