import { TextField } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks"

function RefundStatusView(){
    const refund = useAppSelector((state) => state.refundRequest.refundRequest);
    return <React.Fragment>
        <TextField label='Status Code' value={refund.status} disabled/>
    </React.Fragment>
}

export {RefundStatusView}