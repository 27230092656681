import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AccountModuleState{
    AccountDetailTabStripPosition:number;
    Period:string;
    Year:string;
}

const initialState:AccountModuleState={AccountDetailTabStripPosition:0 ,Period:new Date().getMonth().toString(), Year:new Date().getFullYear().toString()};

export const AccountModuleSlice = createSlice({
    name:'AccountModuleSlice',
    initialState,
    reducers:{
        setAccountDetailTabStripPosition:(state, action:PayloadAction<number>) =>{
            state.AccountDetailTabStripPosition = action.payload;
        },
        setAccountPeriod:(state, action:PayloadAction<string>) =>{
            state.Period = action.payload;
        },
        setAccountYear:(state, action:PayloadAction<string>) =>{
            state.Year = action.payload;
        }
    }
});

export const {setAccountDetailTabStripPosition, setAccountPeriod, setAccountYear}= AccountModuleSlice.actions;
export default AccountModuleSlice.reducer;