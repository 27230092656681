import React from "react"
import { AccountExpectedProceduresList } from "./AccountExpectedProceduresList"
import { Stack } from "@mui/material"
import { TotalExpectedReimbursement } from "./TotalExpectedReimbursement"
import { TotalImplantCost } from "./TotalImplantCost"

function ExpectedReimbursement(){
    return <React.Fragment>
        <Stack direction='row' gap={10} alignItems='center' marginLeft='5px' marginBottom='20px' marginTop='10px'>
            <TotalExpectedReimbursement/>
            <TotalImplantCost/>
            <div/>
        </Stack>
        <AccountExpectedProceduresList/>
        
    </React.Fragment>
}
export {ExpectedReimbursement}