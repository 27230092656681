import { Add } from "@mui/icons-material"
import { Button, Dialog, DialogContent, DialogTitle, MenuItem, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { toggleRuleModal } from "../../../../../app/slices/ContractModuleSlice";
import { contractApi, useRuleFindQuery } from "../../../../../services/contractService/contract-api";
import { ApcCarveOuts } from "./ruleTemplates/ApcCarveOuts";
import { PercentOfFeeSchedule } from "./ruleTemplates/PercentOfFeeSchedule";
import { DrgConversions } from "./ruleTemplates/DrgConversions";
import { StatusBasedCaseRate } from "./ruleTemplates/StatusBasedCaseRate";
import { MedicarePercentByFeeType } from "./ruleTemplates/MedicarePercentByFeeType";
import { RateBasedOnFeeSchedule } from "./ruleTemplates/RateBasedOnFeeSchedule";
import { StatusBasedComprehensive } from "./ruleTemplates/StatusBasedComprehensive";
import { PerDayAddOn } from "./ruleTemplates/PerDayAddOn";

function RuleModal(){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.contractModule.ruleModal);
    const selectedRule = useAppSelector((state)=>state.contractModule.selectedRule);
    const [skip,setSkip] = useState(true);
    const {data} = useRuleFindQuery(selectedRule!,{skip,});
    const [ruletype,setRuletype] = useState("NotSelected")
    const [ruleDesc,setRuleDesc] = useState("");
    const [ruleString,setRuleString] = useState('');
    useEffect(()=>setSkip(selectedRule==='0'?true:false),[selectedRule]);
    useEffect(()=>setRuletype(selectedRule === '0' ? 'NotSelected' :data?.ruleType ?? 'NotSelected'),[selectedRule,data]);
    useEffect(()=>setRuleDesc(selectedRule === '0' ? '': data?.description ??''),[selectedRule,data])
    useEffect(()=>setRuleString(selectedRule === '0' ? '' : data?.ruleString ??''),[selectedRule,data]);
    return <React.Fragment>
        <Button startIcon={<Add/>} variant='outlined' onClick={()=>{dispatch(toggleRuleModal('0'));}}>Add Rule</Button>

        <Dialog open={isOpen} maxWidth='xl'>
            <DialogTitle>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>Rule Configuration</Typography>
                    <Button color='secondary' onClick={()=>{setSkip(true);dispatch(toggleRuleModal('0'));dispatch(contractApi.util.invalidateTags(['rule']))}}>Close</Button>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='10px' marginBottom='10px'>
                    <TextField select label='Rule Type' value={ruletype} onChange={(e)=>setRuletype(e.target.value)} size='small'
                        sx={{width:'300px'}} disabled={selectedRule!='0'}>
                        <MenuItem value='NotSelected'>Not Selected</MenuItem>
                        <MenuItem value='ApcCarveouts'>APC Carveouts</MenuItem>
                        <MenuItem value='DrgConversion'>DRG Conversion Rates</MenuItem>
                        <MenuItem value='MedicarePercentByFeeType'>Medicare Percent by Fee Type</MenuItem>
                        <MenuItem value='PercentOfFeeSchedule'>Percent of Fee Schedule</MenuItem>
                        <MenuItem value='RateBasedOnFeeSchedule'>Rate Based On Fee Schedule</MenuItem>
                        <MenuItem value="StatusBasedCaseRate">Status Based Case Rate</MenuItem>    
                        <MenuItem value='StatusBasedComprehensive'>Status Based Comprehensive</MenuItem>
                        <MenuItem value='PerDayAddOn'>Per Day Add On</MenuItem>
                    </TextField>  
                </Stack>
                <Stack direction='row' marginBottom='10px'>
                    <TextField label='Description' value={ruleDesc} onChange={(e)=>setRuleDesc(e.target.value)} size='small'
                        InputLabelProps={{shrink:true}} sx={{width:'400px'}}/>
                </Stack>
                <hr/>

                {TemplateSelector(ruletype, ruleString, selectedRule, ruleDesc)}
            </DialogContent>
        </Dialog>
    </React.Fragment>
}
function TemplateSelector(type:string, rule:string, ruleId:string, ruleDescription:string){
    switch (type) {
        case 'ApcCarveouts':
            return <ApcCarveOuts ruleString={rule} ruleId={ruleId} ruleDescription={ruleDescription}/>
        case 'PercentOfFeeSchedule':
            return <PercentOfFeeSchedule ruleString={rule} ruleId={ruleId} ruleDescription={ruleDescription}/>
        case 'DrgConversion':
            return <DrgConversions ruleString={rule} ruleId={ruleId} ruleDescription={ruleDescription}/>
        case'StatusBasedCaseRate':
            return <StatusBasedCaseRate ruleString={rule} ruleId={ruleId} ruleDescription={ruleDescription}/>
        case 'MedicarePercentByFeeType':
            return <MedicarePercentByFeeType ruleString={rule} ruleId={ruleId} ruleDescription={ruleDescription}/>   
        case 'RateBasedOnFeeSchedule':
            return <RateBasedOnFeeSchedule ruleString={rule} ruleId={ruleId} ruleDescription={ruleDescription}/>
        case 'StatusBasedComprehensive':
            return <StatusBasedComprehensive ruleString={rule} ruleId={ruleId} ruleDescription={ruleDescription}/>
        case 'PerDayAddOn':
            return <PerDayAddOn ruleString={rule} ruleId={ruleId} ruleDescription={ruleDescription}/>
        default:
        break;
    }
}
export {RuleModal}

