import { Button } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { useCaseManStatusUpdateMutation } from "../../../../../../services/caseManService/caseManage-api";
import { CaseManStatusUpdateCommand } from "../../../../../../services/caseManService/commands";

function CodingApproveBtn(){
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    const [saveStatus] = useCaseManStatusUpdateMutation();
    const handleSave=()=>{
        let command = new CaseManStatusUpdateCommand(caseManage,'Coding','Approved');
        saveStatus(command);
    }

    return <React.Fragment>
        <Button color='success' variant='outlined'
            onClick={()=>handleSave()}
        >APPROVE</Button>
    </React.Fragment>
}
export {CodingApproveBtn}