import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../../app/hooks";
import { GridButton } from "../../../../components";

function CaseManSearchCommandCell(props:GridCellProps){
    const navigate = useNavigate();
    
    return <React.Fragment>
        <GridButton  size='small' className="mt-1 ms-2" color="primary" 
            onClick={()=>{navigate('/CaseManDetail/'+props.dataItem.caseId); }}>View</GridButton>
    </React.Fragment>
}
export {CaseManSearchCommandCell}