import { ThemeOptions } from "@mui/material";
import { createTheme } from "@mui/material/styles";

export const psnTheme = createTheme({
    components:{
            MuiTextField:{
                defaultProps:{
                    size:'small',
                    InputLabelProps:{
                        shrink:true
                    }
                }
            }
        }
    
})