import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, Switch, TextField } from "@mui/material";
import React, { useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleCarveOutAddModal } from "../../../../../app/slices/ContractModuleSlice";
import { CarveOutAddCommand } from "../../../../../services/contractService/commands";
import { useCarveOutAddMutation } from "../../../../../services/contractService/contract-api";

function CarveOutsAdd(){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.contractModule.carveOutAddModal)
    const [code,setCode] = useState('');
    const [description, setDescription] = useState('');
    const [rate, setRate] = useState(0);
    const [useMpr, setUseMpr] = useState(false);
    const[isCaseRate,setIsCaseRate] = useState(false);
    const [addCarveOut] = useCarveOutAddMutation();
    const saveCarveOut=()=>{
        let command = new CarveOutAddCommand(id!,code,description,rate,useMpr,isCaseRate)
        addCarveOut(command);
    }
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>Add a Carve Out</DialogTitle>
            <DialogContent>
                <Stack marginTop='10px' gap={2}>
                    <TextField label='Code' value={code} onChange={(e)=> setCode(e.target.value)}
                        size='small' InputLabelProps={{shrink:true}} sx={{width:'150px'}} inputProps={{maxLength:6}}/>
                    <TextField label='Description' value={description} onChange={(e)=>setDescription(e.target.value)}
                        size='small' InputLabelProps={{shrink:true}} sx={{width:'500px'}} multiline/>
                    <TextField type='number' label='Rate' value={rate} defaultValue={0} onChange={(e)=> setRate(Number(e.target.value))}
                        size='small' InputLabelProps={{shrink:true}} sx={{width:'150px'}}/>
                    <Stack direction='row'>
                        <FormControlLabel
                            control = {<Switch size="small" value={useMpr} checked={useMpr} onChange={(e)=>setUseMpr(e.currentTarget.checked)} name='applyMultiProcedureDisc'/>}
                            label='Apply MPD' labelPlacement="top"/> 
                        <FormControlLabel
                            control = {<Switch size="small" value={isCaseRate} checked={isCaseRate} onChange={(e)=>setIsCaseRate(e.currentTarget.checked)} name='isCaseRate'/>}
                            label='Is Case Rate' labelPlacement="top"/> 
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=> dispatch(toggleCarveOutAddModal())}>Close</Button>
                <Button onClick={()=>{saveCarveOut();dispatch(toggleCarveOutAddModal())}}>Save</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {CarveOutsAdd}