export class CarveOutEditCommand{
    id:string;
    contractId:string;
    code:string;
    description:string;
    rate:number;
    applyMultiProcedureDisc:boolean;
    isCaseRate:boolean;

    constructor(id:string, contractId:string,code:string,description:string,rate:number,applyMultiProcedureDisc:boolean, isCaseRate:boolean){
        this.id = id;
        this.contractId = contractId;
        this.code = code;
        this.description = description;
        this.rate = rate;
        this.applyMultiProcedureDisc = applyMultiProcedureDisc;
        this.isCaseRate = isCaseRate;
    }

}