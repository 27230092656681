import { Card, CardContent, CardHeader, Typography } from "@mui/material"
import React from "react"
import { InsuranceView } from "./InsuranceView"

type ownProps={visitId:string}
function InsuranceViewWithCard(props:ownProps){
    return <React.Fragment>
        <Card className="mt-2">
            <CardHeader sx={{marginBottom:'0px', paddingBottom:'3px',paddingTop:'3px', borderBottom:'solid', borderColor:'gray'}} title ={<Typography variant='subtitle1' color='gray'>Insurance</Typography>}/>
            <CardContent>
                <InsuranceView visitId={props.visitId}/>
            </CardContent>
        </Card>
    </React.Fragment>
}

export {InsuranceViewWithCard}