import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { TextField } from "@mui/material";
import { editSingleCaseAgreement } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function ScaNotesEdit(){
    const dispatch = useAppDispatch();
    const verify = useAppSelector((state) => state.verification.verification);
    return <React.Fragment>
        <TextField label='Notes' value={verify.singleCaseAgreement?.notes} sx={{width:'800px'}}
            multiline minRows={5}
            onChange={(e) => dispatch(editSingleCaseAgreement(new ReduxUpdate('notes',e.target.value)))}/>
    </React.Fragment>
}
export {ScaNotesEdit}