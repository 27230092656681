import { MenuItem, TextField } from "@mui/material"
import React from "react"
import { useCenterFindQuery, usePhysicianListQuery } from "../../../../../services/orgService/org-api";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editPhysician } from "../../../../../services/scheduleService/model/ScheduleSlice";
import { defaultPhysician } from "../../../../../services/scheduleService/model/schedule/Physician";


type ownProps={
    center:string;}

function PhysicianSelector(props:ownProps){
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state)=>state.schedule.schedule);
    const physicians = usePhysicianListQuery(props.center);
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;
    let physiciansCopy = physicians.data === undefined?[]:[...physicians.data];
    const handleChange=(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        let physician = physiciansCopy?.find(i=>i.code === e.target.value);    
        let physicianCopy = {...physician,id:physician?.code!,lastName:physician?.lastName!,firstName:physician?.firstName!,npi:physician?.npi!}; 
        dispatch(editPhysician(physicianCopy));
    }

    return <React.Fragment>
        <TextField label='Physician' sx={{width:'225px'}} 
            value={schedule.physician.id} disabled={readonly}
            onChange={(e)=>{handleChange(e)}} select>
                {physiciansCopy?.sort((a,b)=>a.lastName>b.lastName?1:-1)
                    .map(i =>(<MenuItem key={i.code} value={i.code}>{i.lastName + ', ' + i.firstName}</MenuItem>))}
            </TextField>
    </React.Fragment>
}

export {PhysicianSelector}