import { Upload } from "@mui/icons-material";
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material"
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { togglePayerUpload } from "../../../../app/slices/OrgModuleSlice";
import { orgApi } from "../../../../services/orgService/org-api";
import { OrganizationService } from "../../../../utils/ServiceLocator";

type ownProps = {
    center:string;
}

function PayerImport(props:ownProps){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.orgModule.payerUploadModal);
    const [selectedFile, setSelectedFile] = useState();
	const [uploadComplete, setUploadComplete] = useState(false);
	const [uploadInProgress, setUploadInProgress] = useState(false);
    const changeHandler = (event:any) =>{
        setSelectedFile(event.target.files[0]);
    }
    const handleSubmission=()=>{
        const formData = new FormData();
        setUploadInProgress(true);
        formData.append('File',selectedFile!);
        fetch(OrganizationService + 'UploadPayerEndpoint/UploadPayer/' + props.center,{method:'POST',body:formData})
            .then((response) => {response.json(); setUploadComplete(true); setUploadInProgress(false);dispatch(orgApi.util.invalidateTags(['payers']))})
            .catch((error) => {console.log('Error',error);});
    }

    return <React.Fragment>
        <Button variant='outlined' startIcon={<Upload/>} onClick={()=>dispatch(togglePayerUpload())}>Import</Button>

        <Dialog open={isOpen} maxWidth='lg'>
            <DialogTitle>Upload Payers</DialogTitle>
            <DialogContent>
                <DialogContent>
                    <Stack gap={2}>
                        <Alert color='info'>Payers must be uploaded in the same format as the export file. <br></br>
                        </Alert>
                        {uploadInProgress &&
					        <Alert color='warning'>We're processing your request. This may take a few minutes.</Alert>}
					    {uploadComplete === true &&
						    <Alert color="success">Upload Completed Successfully</Alert>}
                        <input type='file' name='file' onChange={changeHandler}/>
                    </Stack>
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>{setUploadComplete(false);dispatch(togglePayerUpload());}}>Close</Button>
                <Button 
                    onClick={handleSubmission} 
                    disabled={selectedFile === undefined || uploadInProgress}>
                        {!uploadInProgress ? 'Submit' : <CircularProgress/>}</Button>
            </DialogActions>
        </Dialog>

    </React.Fragment>
}

export {PayerImport}