import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Stack, Switch, Typography } from "@mui/material";
import { RefundLocked } from "../../../specs/RefundLocked";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import {editRefundDetails } from "../../../../../services/refundService/model/refundRequest/RefundRequestSlice";

function CreditCardSwitch(){
    const dispatch = useAppDispatch();
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const isCreditCard = useAppSelector((state)=>state.refundRequest.refundRequest?.refundDetail.creditCardRefund);

    return <React.Fragment>
        <Stack gap={0}>
            <Typography variant='body2' style={{color:'gray', marginBottom:'-10px', marginLeft:"10px"}}>Credit Card Refund</Typography>
            <Switch color='secondary' checked={isCreditCard}
                sx={{marginBottom:'-15px'}}
                disabled={new RefundLocked().IsSatisfiedBy(refund)}
                onChange={(e) => dispatch(editRefundDetails(new ReduxUpdate("creditCardRefund",e.target.checked)))}/>
        </Stack>
    </React.Fragment>
}

export {CreditCardSwitch}