import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { 
  toggleWitnessModal,
} from "../../../../../app/slices/VarianceModuleSlice";
import { Individual } from "../../../../../services/varianceService/model";
import { Add } from "@mui/icons-material"
import { IncidentWitnessAddCommand } from "../../../../../services/varianceService/commands/WitnessAddCommand";
import { useIncidentWitnessAddMutation } from "../../../../../services/varianceService/variance-api";


function WitnessEditDialog(){
  const variance = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const [initiate] = useIncidentWitnessAddMutation();
  const [witness, setWitness] = useState<Individual>(new Individual());
  const command = new IncidentWitnessAddCommand(witness);
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    let newIndividual: Individual = witness;
    newIndividual = { ...newIndividual, [e.target.name]: e.target.value };
    newIndividual.varianceId = variance.id;
    setWitness(newIndividual);
  };
  const witnessModalOpen = useAppSelector(
    (state) => state.varianceModule.IncidentWitnessModalOpen
  );
  
  const dispatch = useAppDispatch();
  const saveWitness = () => {
    initiate(command);
    dispatch(toggleWitnessModal());
    setWitness(new Individual());
  }

  return (
    <React.Fragment>
      <Stack direction="row" justifyContent={"flex-end"} sx={{ margin: '20px' }}>
        <Button startIcon={<Add/>} variant="outlined" onClick={() => dispatch(toggleWitnessModal())}>
          Add Witness
        </Button>
      </Stack>
      <Dialog
        open={witnessModalOpen}
        onClose={toggleWitnessModal}
      >
        <DialogTitle>Enter Witness Details</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            fullWidth
            name="firstName"
            value={witness?.firstName}
            onChange={(e) => {
              handleChange(e);              
            }}
          />
          <TextField
            margin="dense"
            label="Last Name"
            fullWidth
            name="lastName"
            value={witness?.lastName}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <TextField
            margin="dense"
            label="Mobile Phone"
            type="mobilePhone"
            fullWidth
            name="mobilePhone"
            value={witness?.mobilePhone}
            onChange={(e) => {
                handleChange(e);
            }}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            name="email"
            value={witness?.email}
            onChange={(e) => {
                handleChange(e);
            }}
          />         
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(toggleWitnessModal());
            }}
          >
            Cancel
          </Button>
            <Button
              onClick={() => {
                saveWitness() }
              }
            >Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export { WitnessEditDialog };
