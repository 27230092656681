import { Card, CardContent, CardHeader, Stack, TextField, Typography } from "@mui/material"
import React from "react"
import { AccountVisitTypeAttr } from "./AccountVisitTypeAttr"
import { AccountServiceCodeAttr } from "./AccountServiceCodeAttr"
import { AccountChargesView } from "./AccountChargesView"
import { AccountRoomChargesView } from "./AccountRoomChargesView"
import { AccountContractualAdjustmentView } from "./AccountContractualAdjustmentView"
import { AccountAdjustmentView } from "./AccountAdjustmentView"
import { AccountPatientPaymentsView } from "./AccountPatientPaymentsView"
import { AccountInsurancePaymentsView } from "./AccountInsurancePaymentsView"
import { AccountStatusView } from "./AccountStatusView"
import { AccountServiceTypeEdit } from "./AccountServiceTypeEdit"
import { AccountVarianceView } from "./AccountVarianceView"

function AccountAttributes(){
    return <React.Fragment>
        <Card className='mt-2'>
            <CardHeader  sx={{marginBottom:'0px', paddingBottom:'3px',paddingTop:'3px', borderBottom:'solid', borderColor:'gray'}} 
                title ={<Typography variant='subtitle1' color='gray'>Attributes</Typography>}/>
                <CardContent>
                    <Stack direction='row' gap={1}>
                        <AccountVisitTypeAttr/>
                        <AccountServiceCodeAttr/>
                        <AccountServiceTypeEdit/>
                        <AccountStatusView/>
                    </Stack>
                    <Stack direction='row' gap={1} marginTop='10px'>
                        <AccountChargesView/>
                        <AccountRoomChargesView/>
                        <AccountContractualAdjustmentView/>
                        <AccountAdjustmentView/>
                        <AccountPatientPaymentsView/>
                        <AccountInsurancePaymentsView/>
                    </Stack>
                    <Stack direction='row' gap={1} marginTop='10px'>
                        <AccountVarianceView/>
                    </Stack>
                </CardContent>
        </Card>
    </React.Fragment>
}
export {AccountAttributes}