import { Stack, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../app/hooks"
import { RuleDeleteCommand, RulePostCommand } from "../../../../../../services/contractService/commands";
import { contractApi, useRuleDeleteMutation, useRuleSaveMutation } from "../../../../../../services/contractService/contract-api";
import { RuleCommands } from "./RuleCommands";

type ownProps={
    ruleId:string;
    ruleString?:string;
    ruleDescription?:string;
}
function ApcCarveOuts(props:ownProps){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [rule,setRule] = useState({code:'',description:'',price:0})
    const [saveRule] = useRuleSaveMutation();
    const [deleteRule] = useRuleDeleteMutation();
    const save=()=>{
        let command = new RulePostCommand(id!,props.ruleId,'ApcCarveouts',0, JSON.stringify(rule),props.ruleDescription ?? '');
        saveRule(command).unwrap().then(()=>{dispatch(contractApi.util.invalidateTags(['ruleList']))});
    }
    const ruleDelete=()=>{deleteRule(new RuleDeleteCommand(props.ruleId))};
    useEffect(() => {
        if(props.ruleString !== ''){
            let r:{code:string,description:string,price:number} = JSON.parse(props.ruleString!);
            setRule(r);
        }},[props.ruleString, props.ruleId]
    )
    return <React.Fragment>
        <Stack direction='row' gap={1}>
            <TextField label='Code' size='small' value={rule.code} 
                onChange={(e)=>setRule({...rule,code:e.target.value})} 
                sx={{width:'100px'}} InputLabelProps={{shrink:true}}/>
            <TextField label='Description' size='small' value={rule.description} 
                onChange={(e)=>setRule({...rule,description:e.target.value})}
                InputLabelProps={{shrink:true}}/>
            <TextField type='number' label='Conv. Price' size='small' value={rule.price} 
                onChange={(e)=>setRule({...rule,price:Number(e.target.value)})} 
                sx={{width:'100px'}} inputProps={{step:0.01}} InputLabelProps={{shrink:true}}/>
        </Stack>
        <RuleCommands saveCommand={save} deleteCommand={ruleDelete} isNewRule={props.ruleId === '0' ? true:false}/>
    </React.Fragment>
}

export {ApcCarveOuts}