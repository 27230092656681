import { useState } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { VarianceDocument } from "../../../../../../services/varianceService/model/varianceRequest/VarianceDocuments";
import { getToken } from "../../../../../../utils/AdalConfig";
import { VarianceService } from "../../../../../../utils/ServiceLocator";
import { addVarianceDocument } from "../../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import { Upload } from "@mui/icons-material";
import React from "react";
import { Loading } from "../../../../../shared";
import { toggleIncidentDocumentDialog } from "../../../../../../app/slices/VarianceModuleSlice";
import { DocumentSelector } from "./DocumentSelector";


function VarianceUploadBtn() {
    const {Id}=useParams();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state)=>state.varianceModule.IncidentDocumentDialogOpen);

    const [selectedFile, setSelectedFile] = useState<File|null>();
    const [uploading, setUploading] = useState(false);
    const [notify, setNotify] = useState(false);
    
    const handleSubmission= async()=>{
        try{
        setUploading(true);
        const formData = new FormData();
        formData.append('File', selectedFile!);
        const response = await fetch(VarianceService + 'DocumentUploadEndpoint/DocumentUpload/'+Id,{method:'POST',body:formData, headers:{'Authorization': 'Bearer ' + getToken()}});
        const data:VarianceDocument = await response.json();
        await dispatch(addVarianceDocument(data));
        dispatch(toggleIncidentDocumentDialog());
        setUploading(false);
        }
        catch(error){
            setUploading(false);
            setNotify(true);
        }
    }

  return <React.Fragment>
  <Button
      variant='outlined' startIcon={<Upload/>} 
      onClick={()=>dispatch(toggleIncidentDocumentDialog())}>Upload</Button>

  <Dialog open={isOpen}>
      <DialogTitle>Upload Document</DialogTitle>
      <DialogContent>
          {uploading && <Loading/>}
          {!uploading &&
          <DocumentSelector file={selectedFile!} setFile={setSelectedFile}/>}
      </DialogContent>
      <DialogActions>
          <Button onClick={()=>dispatch(toggleIncidentDocumentDialog())}>Cancel</Button>
          <Button onClick={()=>handleSubmission()}>Save</Button>
      </DialogActions>
  </Dialog>
  <Snackbar open={notify} onClose={()=>setNotify(false)} color='error' autoHideDuration={6000}>
      <Alert color='error'>{'Cannot upload this document.'}</Alert>
  </Snackbar>
</React.Fragment>
}

export { VarianceUploadBtn };