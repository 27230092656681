import { RefundRequest } from "../../../services/refundService/model";
import { CompositeSpecification } from "../../../utils/Specification";

export class RefundCheckInfoValid extends CompositeSpecification<RefundRequest>{
    IsSatisfiedBy(candidate: RefundRequest): boolean {
        if(candidate.checkInfo.payableTo.length === 0)
            return false;
        if(candidate.checkInfo.streetAddress1.length === 0)
            return false;
        if(candidate.checkInfo.city.length === 0)
            return false;
        if(candidate.checkInfo.state.length === 0)
            return false;
        if(candidate.checkInfo.zip.length === 0)    
            return false;

        return true;
    }
}