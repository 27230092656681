import { Table } from "@mui/material";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout"
import React from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { toggleContractDetailTab } from "../../../app/slices/ContractModuleSlice";
import { useContractFindQuery } from "../../../services/contractService/contract-api";
import { Header, Rates, Centers, Cpt, CarveOuts, MedicareConfig, Rules } from "../features"
import { Drg } from "../features/contractDetail/drg/Drg";


function ContractDetail(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const tabIndex = useAppSelector((state) => state.contractModule.contractDetailTab);
    const toggleTab = (e:TabStripSelectEventArguments) => dispatch(toggleContractDetailTab(e.selected));
    const {data} = useContractFindQuery(id!);
    return <React.Fragment>
        <Header/>
        <TabStrip selected={tabIndex} onSelect={toggleTab}>
            <TabStripTab title='Centers'>
                <Table/>
                <Centers/>
            </TabStripTab>
            <TabStripTab title={'DRG'}>
                <Table/>
                <Drg/>
            </TabStripTab>
            <TabStripTab title={'CPT'}>
                <Table/>
                <Cpt/>
            </TabStripTab>
            <TabStripTab title={'Carve Outs'}>
                <Table/>
                <CarveOuts/>
            </TabStripTab>
            <TabStripTab title='Rates'>
                <Table/>
                <Rates/>
            </TabStripTab>
            <TabStripTab title='Medicare Based Config'>
                <Table/>
                <MedicareConfig/>
            </TabStripTab>
            <TabStripTab title='Rules'>
                <Table/>
                <Rules/>
            </TabStripTab>
        </TabStrip>
    </React.Fragment>
}

export {ContractDetail}