import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useAppDispatch } from "../../../../../app/hooks"
import { toggleRuleEditModal, toggleRuleModal } from "../../../../../app/slices/ContractModuleSlice";
import { GridButton } from "../../../../../components";

function RuleEditCommandCell(props: GridCellProps){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <GridButton size='small' onClick={()=>dispatch(toggleRuleModal(props.dataItem.ruleId))}>View</GridButton>    
    </React.Fragment>
}

export {RuleEditCommandCell}