import {
  Stack,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { VarianceSearchResults } from "./VarianceSearchResults";
import { VarianceWorklistSearchRequest } from "../../../../services/varianceService/commands";
import { useVarianceWorklistSearchQuery } from "../../../../services/varianceService/variance-api";

function VarianceSearch() {
  const { center } = useParams();
  const [searchCriteria, setSearchCriteria] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [skip, setSkip] = useState<boolean>(true);
  const { data } = useVarianceWorklistSearchQuery(
    new VarianceWorklistSearchRequest(center!, searchCriteria),
    { skip }
  );
  useEffect(() => {
    setSkip(!isOpen);
  }, [isOpen]);

  return (
    <React.Fragment>
      <Stack direction="row" gap={0.5}>
        <TextField
          placeholder="Worklist Search"
          value={searchCriteria}
          onChange={(e) => setSearchCriteria(e.target.value)}
        />        
          <Button
            variant="outlined"
            disabled={searchCriteria.length === 0}
            onClick={() => setIsOpen(true)}
          >
            Search
          </Button>
        <Dialog open={isOpen} maxWidth="xl">
          <DialogTitle>Search Results</DialogTitle>
          <DialogContent>
            <VarianceSearchResults data={data!} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </React.Fragment>
  );
}

export { VarianceSearch };
