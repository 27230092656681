import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react"
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useAppSelector } from "../../../app/hooks";
import { CommandBar, VisitDetails } from "../features";
import { PatientDetails } from "../features/radDetail/patientDetails/PatientDetails";
import { usePostRadRequestMutation } from "../../../services/radiologyService/radiology-api";
import { PostRadRequestCommand } from "../../../services/radiologyService/commands";
import { resetRadRequest } from "../../../services/radiologyService/model/radRequest/RadRequestSlice";

function CreateRadRequest(){
    const dispatch = useDispatch();
    const radRequest = useAppSelector((state) => state.radRequest.radRequest);
    const {center} = useParams();
    const [saveRadRequest,status] = usePostRadRequestMutation();

    const save =()=>{
        let request = {...radRequest};
        request.centerId = center!;
        let command = new PostRadRequestCommand(request);
        saveRadRequest(command).unwrap()
            .then(() => alert("Successfully saved case.  Enter another or press the back button to return to main screen."))
            .then(() => {dispatch(resetRadRequest())});

    }


    return <React.Fragment>
        <CommandBar save={save}/>
        <PatientDetails/>

        <Card>
            <CardHeader sx={{marginBottom:'0px', paddingBottom:'3px',paddingTop:'3px', borderBottom:'solid', borderColor:'gray'}}
                 title ={<Typography variant='h6' color='gray'>Procedure Details</Typography>}/>
            <CardContent>
                <VisitDetails/>
            </CardContent>
        </Card>
        
    </React.Fragment>
}

export {CreateRadRequest}