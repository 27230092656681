export const AdverseDrugOptions = [
    {
        description: "Allergy Known to Patient",
        name: "AllergyKnown",
    },
    {
        description: "Allergy Unknown to Patient",
        name: "AllergyUnknown",
    },
    {
        description: "Drug-Dietary Supplement Interaction",
        name: "DrugDietaryInteraction",
    },
    {
        description: "Drug-Disease Interaction",
        name: "DrugDiseaseInteraction",
    },
    {
        description: "Drug-Drug Interaction",
        name: "DrugDrugInteraction",
    },
    {
        description: "Drug-Food Interaction",
        name: "DrugFoodInteraction",
    },
];