import React from "react"
import { useCenterFindQuery} from "../../services/orgService/org-api";
import { Typography } from "@mui/material";

type ownProps={
    centerId:string;
}

function CenterNameView(props:ownProps){
    const {data} = useCenterFindQuery(props.centerId);

    return<React.Fragment>
        <Typography variant='h5' className='psn-green'>{data?.shortName}</Typography>
    </React.Fragment>
}

export {CenterNameView}