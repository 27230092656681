export const MaterialsOptions = [
    {
        description: "Critical equipment/supply delivery delayed",
        name: "CriticalEquipmentSupplyDeliveryDelayed",
    },
    {
        description: "Equipment/supplies delivered damaged",
        name: "EquipmentSuppliesDeliveredDamaged",
    },
    {
        description: "Human Tissue Integrity Compromised",
        name: "HumanTissueIntegrityCompromised",
    },
];