import { SchedulerEditItem, SchedulerEditItemProps } from "@progress/kendo-react-scheduler"
import React from "react"
import { useNavigate } from "react-router"
import { useAppDispatch } from "../../../../app/hooks";
import { scheduleApi } from "../../../../services/scheduleService/schedule-api";

function ScheduleItem(props: SchedulerEditItemProps){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return <React.Fragment>
        <SchedulerEditItem style={{...props.style, fontSize:'10px', height:'auto'}} 
            {...props} title={props.dataItem.lastName + ', ' + props.dataItem.firstName + '\r\n'+'(' + props.dataItem.account +')'} 
            onClick={()=>{navigate('/ScheduleDetail/' + props.dataItem.caseId);dispatch(scheduleApi.util.invalidateTags(['case']));}} />
    </React.Fragment>
}

export {ScheduleItem}
