import { KeyboardDoubleArrowLeft, Save } from "@mui/icons-material";
import { Alert, Button, Snackbar, Stack } from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router"
import { scheduleApi, useScheduleCreateMutation } from "../../../../../services/scheduleService/schedule-api";
import { SchedulePostCommand } from "../../../../../services/scheduleService/commands";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { ProcedureMapper } from "./ProcedureMapper";
import { ContainsAtLeastOneService, IsSavingDisabled } from "../../../specs";

type ownProps={
    center:string;
}
function CreateCommandBar(props:ownProps){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state)=>state.schedule.schedule);
    const [saveSchedule, status] = useScheduleCreateMutation();
    const save=()=>{
        let newSched = {...schedule};
        newSched.center = props.center;
        newSched.id = props.center + '-' + newSched.accountNumber;
        newSched.visitKey = newSched.accountNumber;
        newSched.procedures =  ProcedureMapper(newSched.procedures);
        let command = new SchedulePostCommand(newSched);
        saveSchedule(command).unwrap()
        .then(i => {dispatch(scheduleApi.util.invalidateTags(['scheduledCases']))})
        .then(i => {navigate(-1)});
    }

    return <React.Fragment>
        <Stack direction='row' gap={1}>
            <Button variant='outlined' onClick={()=>navigate(-1)} color='success' startIcon={<KeyboardDoubleArrowLeft/>}>Go Back</Button>
            <Button variant='outlined' onClick={()=>save()} startIcon={<Save/>} disabled={new IsSavingDisabled().IsSatisfiedBy(schedule)}>Save</Button>
        </Stack>
        <Snackbar open={status.isError}>
            <Alert color='error'>{JSON.stringify(status.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {CreateCommandBar}

