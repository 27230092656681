import React, { useEffect } from "react";
import {Scheduler, TimelineView, DayView, WeekView,  SchedulerDataChangeEvent, SchedulerResource, SchedulerGroup, SchedulerViewChangeEvent} from "@progress/kendo-react-scheduler";
import { guid } from "@progress/kendo-react-common";
import { ScheduleForm } from "./ScheduleForm";
import { ScheduleItem } from "./ScheduleItem";
import { ScheduleSlotEditor } from "./ScheduleSlotEditor";
import { useParams } from "react-router";
import { scheduleApi, useProcedureSaveMutation, useScheduledCasesFindQuery } from "../../../../services/scheduleService/schedule-api";
import { ScheduledCases } from "../../../../services/scheduleService/model/ScheduledCases";
import { Loading } from "../../../shared";
import { ProcedureUpdateCommand } from "../../../../services/scheduleService/commands";
import { useAppDispatch } from "../../../../app/hooks";
import { useCenterFindQuery } from "../../../../services/orgService/org-api";

function Schedule(){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const cases = useScheduledCasesFindQuery(id!);
    const readonly = !useCenterFindQuery(id!).data?.allowManualScheduling;
    const orRooms = Array.from(new Set(cases.data?.map(s => s.room))).map(id => {return {text:id,value:id}});
    const [save] = useProcedureSaveMutation();
    const mapCases=():ScheduledCases[] => {
      if(cases.data != null && cases.data != undefined){
        return cases.data!.map(i => ({...i, start: new Date(i.start), end: new Date(i.end)}))
      }
      else return [];
    }
    const displayDate: Date = new Date();
    const [data, setData] = React.useState<any[]>([]);
    const [view, setView] = React.useState("timeline");
    const [resourceGroup, setGroup] = React.useState(true)
    useEffect(()=>setData(mapCases),[cases.data])

    const handleDataChange = ({ created, updated, deleted,}: SchedulerDataChangeEvent) => {
        if(readonly) return; 
        setData((old) => old.filter((item) => deleted.find((current) => current.id === item.id) === undefined)
            .map((item) => updated.find((current) => current.id === item.id) || item)
            .concat(created.map((item) => Object.assign({}, item, { id: guid() })))); 
            save(new ProcedureUpdateCommand(updated[0] as ScheduledCases)).unwrap().then(()=>dispatch(scheduleApi.util.invalidateTags(['scheduledCases'])));
      };

      const handleViewChange = React.useCallback(
        (event: SchedulerViewChangeEvent) => {
          setView(event.value);
          if(event.value === 'timeline') 
            setGroup(true)
          else
            setGroup(false)
        }, []);

     const resources:SchedulerResource[]=[{name:'Rooms',data:orRooms,field:'room' ,valueField:'value',textField:'text'}] 
     const group:SchedulerGroup={resources:['Rooms'],orientation:'vertical'}

    return <React.Fragment>
      {cases.isLoading ? <Loading/> :
        <Scheduler data={data}
          height='800px'
          onDataChange={handleDataChange}
          defaultDate={displayDate}
          defaultView='week'
          editable={true}
          view={view}
          onViewChange={handleViewChange}
          form={ScheduleForm}
          editItem={ScheduleItem}
          editSlot={ScheduleSlotEditor}
          resources={resources} group={resourceGroup?group:undefined}>
        <DayView workDayStart={'06:00'} workDayEnd={'17:00'} slotDuration={30}/>
        <WeekView workDayStart={'06:00'} workDayEnd={'17:00'} slotDuration={30}/>
        <TimelineView workDayStart={'07:00'} workDayEnd={'17:00'} slotDuration={60} slotDivisions={4}/>
      </Scheduler>
      }
    </React.Fragment>
}

export {Schedule}

export const customModelFields = {
    id: "id",
    description: "lastName",
    start: "serviceDate",
    end: "serviceDate",
    title: "lastName",
  };