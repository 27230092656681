import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { AccessRemoveCommand } from "../../../../services/profileService/commands";
import { Profile } from "../../../../services/profileService";
import { useRemoveAccessMutation } from "../../../../services/profileService/profile-api";
import { GridButton } from "../../../../components";
import DeleteIcon from '@mui/icons-material/Delete';

interface ownProps extends GridCellProps {
    profile:Profile;
}

function CenterAccessRemoveCommand(props:ownProps){
    const [removeAccess] = useRemoveAccessMutation();
    const handleSave=()=>{
        const command = new AccessRemoveCommand(props.profile!.identity, props.dataItem.code,props.dataItem.name);
        removeAccess(command);
    }   

    return <React.Fragment>
        <GridButton className='ms-1 mt-1' variant="outlined" onClick={()=>handleSave()}><DeleteIcon/></GridButton>
    </React.Fragment>
}

export {CenterAccessRemoveCommand}