import {process, State } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import React, { useState } from "react"
import { Worklist } from "../../../../services/varianceService/model/worklist/Worklist";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { VarianceSearchSelect } from "./VarianceSearchSelect";

type ownProps = {
  data?: Worklist[];
};

function VarianceSearchResults(props: ownProps) {
  const mapData = (): Worklist[] => {
    let data: Worklist[] | undefined = props.data?.map((i) => ({
      ...i,
      varianceStartDate: new Date(i.varianceStartDate)}));
    return data ?? [];
  }
  const defaultGridState:State = {sort:[{field:'varianceStartDate', dir:'desc'}]};
  const [dataState, setDataState] = useState(defaultGridState);
  const createDataState = (dataState: State) => {
    return {
      dataState: dataState === undefined ? {} :dataState,
    };
  };
  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setDataState(updatedState.dataState);
  };

  return (
    <React.Fragment>
      <Grid
        data={process(mapData(), dataState)}
        style={{ height: "750px" }}
        onDataStateChange={dataStateChange}
      >
        <Column cell={VarianceSearchSelect} width="100px" />
        <Column field="lastName" title="Last Name" columnMenu={ColumnMenu} width="200px" />
        <Column field="firstName" title="First Name" columnMenu={ColumnMenu} width="200px" />
        <Column field="incidentType" title="Type" columnMenu={ColumnMenu} width="200px" />
        <Column field="varianceStartDate" title="Start Date" columnMenu={ColumnMenu} width="200px" format="{0:MM/dd/yyyy}" />
      </Grid>
    </React.Fragment>
  );
}

export { VarianceSearchResults };