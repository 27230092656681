const hostname = window && window.location && window.location.hostname;


let HomeURI;
let ProfileURI;
let Profile;
let Verify;
let Organization;
let Schedule;
let Refund;
let Radiology;
let Contract;
let Calculator;
let CaseManagement;
let External;
let Account;
let Variance;

if(hostname === 'localhost'){
    HomeURI = 'http://localhost:3000';
    ProfileURI = 'http://localhost:3002';
    Profile = 'http://localhost:8001/';
    Organization = 'http://localhost:8000/';
    Schedule = 'http://localhost:8002/';
    Refund = 'http://localhost:8006/';
    Radiology = 'http://localhost:8008/';
    Contract = 'http://localhost:8005/';
    Verify = 'http://localhost:8003/';
    Calculator = 'http://localhost:8007/';
    CaseManagement = 'http://localhost:8012/';
    External = 'http://localhost:8013/';
    Account = 'http://localhost:8014/';
    Variance = 'http://localhost:8015/';

}
else if(hostname === 'apps-test.psnaffiliates.com'){
    HomeURI = 'https://apps-test.psnaffiliates.com';
    ProfileURI = 'https://profile-module.politeflower-12aa39da.eastus.azurecontainerapps.io';
    Profile = 'https://profile-service.politeflower-12aa39da.eastus.azurecontainerapps.io/';
    Organization = 'https://organization-service.politeflower-12aa39da.eastus.azurecontainerapps.io/';
    Schedule = 'https://schedule-service.politeflower-12aa39da.eastus.azurecontainerapps.io/';
    Refund = 'https://refunds-service.politeflower-12aa39da.eastus.azurecontainerapps.io/';
    Radiology = 'https://radiology-service.politeflower-12aa39da.eastus.azurecontainerapps.io/';
    Contract = 'https://contract-service.politeflower-12aa39da.eastus.azurecontainerapps.io/';
    Verify = 'https://verify-service.politeflower-12aa39da.eastus.azurecontainerapps.io/';
    Calculator = 'https://calculators-service.politeflower-12aa39da.eastus.azurecontainerapps.io/';
    CaseManagement = 'https://casemanagement-service.politeflower-12aa39da.eastus.azurecontainerapps.io/';
    External = "https://external-service.politeflower-12aa39da.eastus.azurecontainerapps.io/";
    Account = 'https://account-service.politeflower-12aa39da.eastus.azurecontainerapps.io/';
    Variance = "https://variances-service.politeflower-12aa39da.eastus.azurecontainerapps.io/";
}
else if (hostname === 'apps-stage.psnaffiliates.com'){
    HomeURI = 'https://apps-stage.psnaffiliates.com';
    ProfileURI = 'https://profile-module.whitepond-452ade56.eastus.azurecontainerapps.io';
    Profile = 'https://profile-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    Organization = 'https://organization-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    Schedule = 'https://schedule-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    Refund = 'https://refunds-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    Radiology = 'https://radiology-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    Contract = 'https://contract-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    Verify = 'https://verify-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    Calculator = 'https://calculators-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    CaseManagement = 'https://casemanagement-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    External = 'https://external-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    Account = 'https://account-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
    Variance = 'https://variances-service.whitepond-452ade56.eastus.azurecontainerapps.io/';
}

else if (hostname === 'apps.psnaffiliates.com'){
    HomeURI = 'https://apps.psnaffiliates.com';
    ProfileURI = 'https://profile-module.wonderfulsky-29006ae5.eastus.azurecontainerapps.io';
    Profile = 'https://profile-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    Organization = 'https://organization-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    Schedule = 'https://schedule-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    Refund = 'https://refunds-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    Radiology = 'https://radiology-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    Contract = 'https://contract-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    Verify = 'https://verify-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    Calculator = 'https://calculators-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    CaseManagement = 'https://casemanagement-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    External = 'https://external-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    Account = 'https://account-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
    Variance = 'https://variances-service.wonderfulsky-29006ae5.eastus.azurecontainerapps.io/';
}


export const HomePath = HomeURI;
export const ProfilePath = ProfileURI;
export const ProfileService = Profile;
export const OrganizationService = Organization;
export const ScheduleService = Schedule;
export const RefundService = Refund;
export const RadiologyService = Radiology;
export const ContractService = Contract;
export const VerifyService = Verify;
export const CalculatorService = Calculator;
export const CaseManagementService = CaseManagement;
export const ExternalService = External;
export const AccountService = Account;
export const VarianceService = Variance;