import { MenuItem, TextField } from "@mui/material";
import React from "react"
import { useParams } from "react-router"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { editStatus } from "../../../../services/radiologyService/model/radRequest/RadRequestSlice";



function StatusIndicator(){
    const dispatch=useAppDispatch();
    const {center} = useParams();
    const status = useAppSelector((state)=>state.radRequest.radRequest.status);

    return <React.Fragment>
        <TextField label='Status' sx={{width:'250px'}}
             size='small' InputLabelProps={{shrink:true}} select value={status} onChange={(e)=>dispatch(editStatus(e.target.value))}>
            <MenuItem value='STARTED'>Started</MenuItem>
            <MenuItem value='CANCEL'>Cancelled</MenuItem>
            <MenuItem value='SCHED'>Scheduled</MenuItem>
            <MenuItem value='CHOSEALTERNATE'>Chose Other Facility</MenuItem>
        </TextField> 
    </React.Fragment>
}

export{StatusIndicator}
