import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PayerForCenterRequest } from '../../services/verifyService/commands';

interface VerificationModuleState{
    DetailTab:number;
    CodeLookupModal:boolean;
    InsuranceDetailModal: boolean;
    SelectedPayer: PayerForCenterRequest;
    VerifyLookupModal: boolean;
    NoteAddModal:boolean;
    CallAddModal: boolean;
    WaystarModal: boolean;
    SelectedCall:string;
    CallViewModal:boolean;
    VerifyDocumentDialog:boolean;
}

const initialState:VerificationModuleState={DetailTab:0, CodeLookupModal: false, InsuranceDetailModal: false, NoteAddModal:false,
     VerifyLookupModal:false, CallAddModal:false, SelectedPayer: new PayerForCenterRequest('',''), WaystarModal:false, SelectedCall:'',
     CallViewModal:false, VerifyDocumentDialog:false}

export const VerificationModuleSlice = createSlice({
    name:'verificationModule',
    initialState,
    reducers:{
        toggleDetailTab:(state, action:PayloadAction<number>) => {
            state.DetailTab = action.payload;
        },
        toggleCodeLookup:(state) => {
            state.CodeLookupModal = !state.CodeLookupModal;
        },
        toggleInsuranceDetail:(state, action:PayloadAction<PayerForCenterRequest>) => {
            state.InsuranceDetailModal = !state.InsuranceDetailModal;
            state.SelectedPayer = action.payload;
        },
        toggleVerifyLookupModal:(state) => {
            state.VerifyLookupModal = !state.VerifyLookupModal;
        },
        toggleNoteAddModal:(state) => {
            state.NoteAddModal = !state.NoteAddModal;
        },
        toggleCallAddModal:(state) => {
            state.CallAddModal = !state.CallAddModal;
        },
        toggleWaystarModal:(state, action:PayloadAction<PayerForCenterRequest>) => {
            state.SelectedPayer = action.payload;
            state.WaystarModal = !state.WaystarModal;
        },
        toggleCallViewModal:(state, action:PayloadAction<string>)=>{
            state.SelectedCall = action.payload;
            state.CallViewModal = !state.CallViewModal;
        },
        toggleVerifyDocumentDialog:(state) => 
            {state.VerifyDocumentDialog = !state.VerifyDocumentDialog;}   
    }
})

export const {toggleDetailTab, toggleCodeLookup, toggleInsuranceDetail, toggleVerifyLookupModal, toggleNoteAddModal, toggleCallAddModal, toggleWaystarModal,
    toggleCallViewModal, toggleVerifyDocumentDialog}
     = VerificationModuleSlice.actions;
export default VerificationModuleSlice.reducer;