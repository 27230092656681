import { ProcedureAuthStatuses } from "./ProcedureAuthStatuses";
import { WaystarAuthStatusImages } from "./WaystarAuthStatusImages";

export class WaystarAuthStatus{
    accountNumber:string = '';
    agentName:string = '';
    authStatusAlerts:string[] = [];
    lastRequestDate:Date = new Date();
    MessageId:string = '';
    payerAuthStatus:string = '';
    procedureAuthStatuses:ProcedureAuthStatuses[] = [];
    authStatusImages:WaystarAuthStatusImages = new WaystarAuthStatusImages();
}