import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { orgApi } from '../services/orgService/org-api';
import { profileApi } from '../services/profileService/profile-api';
import { radiologyApi } from '../services/radiologyService/radiology-api';
import { scheduleApi } from '../services/scheduleService/schedule-api';
import {contractApi} from '../services/contractService/contract-api';
import {verifyApi} from '../services/verifyService/verify-api';
import { refundApi } from '../services/refundService/refund-api';
import {caseManageApi} from '../services/caseManService/caseManage-api';
import { externalApi } from '../services/externalService/external-api';
import { accountApi } from '../services/accountService/account-api';
import { varianceApi } from '../services/varianceService/variance-api';
import radModuleReducer from './slices/RadModuleSlice';
import orgModuleReducer from './slices/OrgModuleSlice';
import contractModuleReducer from './slices/ContractModuleSlice';
import profileModuleReducer from './slices/ProfileModuleSlice';
import verificationModuleReducer from './slices/VerificationModuleSlice';
import scheduleModuleReducer from './slices/ScheduleModuleSlice';
import refundModuleReducer from './slices/RefundModuleSlice';
import caseManModuleReducer from './slices/CaseManModuleSlice';
import accountModuleReducer from './slices/AccountModuleSlice';
import radRequestReducer from '../services/radiologyService/model/radRequest/RadRequestSlice';
import centerReducer from '../services/orgService/model/center/CenterSlice'
import contractReducer from '../services/contractService/model/contract/ContractSlice';
import feeScheduleReducer from '../services/contractService/model/feeSchedule/FeeScheduleSlice';
import verificationReducer from '../services/verifyService/model/verification/VerificationSlice';
import scheduleReducer from '../services/scheduleService/model/ScheduleSlice';
import refundRequestReducer from '../services/refundService/model/refundRequest/RefundRequestSlice';
import caseManagmentReducer from '../services/caseManService/model/caseMan/CaseManagementSlice';
import policyReducer from '../services/caseManService/model/policy/PolicySlice';
import accountReducer from '../services/accountService/model/account/AccountSlice';
import varianceRequestReducer from '../services/varianceService/model/varianceRequest/VarianceRequestSlice';
import varianceModuleReducer from './slices/VarianceModuleSlice';
import incidentNotificationReducer from '../services/orgService/model/variance/IncidentNotificationSlice';

export const store = configureStore({
  reducer: {
    radModule: radModuleReducer,
    profileModule: profileModuleReducer,
    orgModule: orgModuleReducer,
    contractModule: contractModuleReducer,
    verificationModule: verificationModuleReducer,
    scheduleModule: scheduleModuleReducer,
    refundModule: refundModuleReducer,
    caseManModule: caseManModuleReducer,
    accountModule: accountModuleReducer,
    radRequest: radRequestReducer,
    center: centerReducer,
    contract: contractReducer,
    feeSchedule: feeScheduleReducer,
    verification: verificationReducer,
    schedule: scheduleReducer,
    refundRequest: refundRequestReducer,
    caseManagement: caseManagmentReducer,
    policy: policyReducer,
    account: accountReducer,
    varianceModule: varianceModuleReducer,
    varianceRequest: varianceRequestReducer,
    incidentNotification: incidentNotificationReducer,

    [profileApi.reducerPath]: profileApi.reducer,
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [orgApi.reducerPath]: orgApi.reducer,
    [radiologyApi.reducerPath]: radiologyApi.reducer,
    [contractApi.reducerPath]: contractApi.reducer,
    [verifyApi.reducerPath]: verifyApi.reducer,
    [refundApi.reducerPath]: refundApi.reducer,
    [caseManageApi.reducerPath]: caseManageApi.reducer,
    [externalApi.reducerPath]: externalApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [varianceApi.reducerPath]: varianceApi.reducer,
  },
  middleware:(getDefaultMiddleware) => getDefaultMiddleware()
        .concat(profileApi.middleware)
        .concat(scheduleApi.middleware)
        .concat(orgApi.middleware)
        .concat(radiologyApi.middleware)
        .concat(contractApi.middleware)
        .concat(verifyApi.middleware)
        .concat(refundApi.middleware)
        .concat(caseManageApi.middleware)
        .concat(externalApi.middleware)
        .concat(accountApi.middleware)
        .concat(varianceApi.middleware),
});

setupListeners(store.dispatch);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
