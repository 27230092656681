export const MaterialsManageOptions = [
    {
        description: "Lack of knowledge of drug",
        name: "LackOfKnowledgeOfDrug_DR",
    },
    {
        description: "Lack of information about patient (labs)",
        name: "LackOfInformationAboutPatient_DR",
    },
    {
        description: "5 'R's not followed",
        name: "FiveRsNotFollowed_DR",
    },
    {
        description: "Unexpected response",
        name: "UnexpectedResponse_DR",
    },
    {
        description: "Miscommunication of drug orders",
        name: "MiscommunicationOfDrugOrders_DR",
    },
    {
        description: "Inadequate monitoring",
        name: "InadequateMonitoring_DR",
    },
    {
        description: "Infusion pump programming",
        name: "InfusionPumpProgramming_DR",
    },
    {
        description: "Environmental Stress (distractions, extended shifts, etc.)",
        name: "EnvironmentalStress_DR",
    },
    {
        description: "Stocking/delivery problem",
        name: "StockingDeliveryProblem_DR",
    },
    {
        description: "MAR issue (describe below)",
        name: "MARIssue_DR",
    },
    {
        description: "Allergy not documented",
        name: "AllergyNotDocumented_DR",
    },
    {
        description: "Medication ORDERED with documented allergy",
        name: "MedicationOrderedWithDocumentedAllergy_DR",
    },
    {
        description: "Illegible order",
        name: "IllegibleOrder_DR",
    },
    {
        description: "Order deleted on MAR   Order not discontinued on MAR",
        name: "OrderDeletedOnMAR_DR",
    },
    {
        description: "Distribution delayed >4 hours",
        name: "DistributionDelayed_DR",
    },
    {
        description: "Duplicate Therapy",
        name: "DuplicateTherapy_DR",
    },
    {
        description: "Medication not reordered",
        name: "MedicationNotReordered_DR",
    },
    {
        description: "Computation incorrect",
        name: "ComputationIncorrect_DR",
    },
    {
        description: "Given-not charted",
        name: "GivenNotCharted_DR",
    },
    {
        description: "Charted-not given",
        name: "ChartedNotGiven_DR",
    },
    {
        description: "Problems with labeling, packaging",
        name: "ProblemsWithLabelingPackaging_DR",
    },
    {
        description: "Free flow issue",
        name: "FreeFlowIssue_DR",
    },
];
