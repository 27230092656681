import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { toggleLookupUserModal } from "../../../../app/slices/ProfileModuleSlice";
import { profileApi } from "../../../../services/profileService/profile-api";
import { useProfileLookupQuery } from "../../../../services/profileService/profile-api";
import { LookupUserSearchResults } from "./LookupUserSearchResults";

function LookupUser(){
    const modalOpen = useAppSelector((state) => state.profileModule.lookupUserModal);
    const dispatch = useAppDispatch();
    const [searchTerm,setSearchTerm] = useState('');
    const [skip, setSkip] = React.useState(true)
    const {data} = useProfileLookupQuery(searchTerm,{skip,});
 
    
    return <React.Fragment>
        <Dialog open={modalOpen} maxWidth='lg'>
            <DialogTitle>User Lookup</DialogTitle>
            <DialogContent>
                <Stack direction='row' gap={1}>
                    <TextField placeholder="Search" size='small' value={searchTerm} onChange={(e) => {setSearchTerm(e.target.value); setSkip(true)}}/>
                    <Button variant="outlined" disabled={searchTerm === ''} 
                        onClick={()=>{dispatch(profileApi.util.invalidateTags(['profileLookup']));setSkip(false);}}>
                            <FontAwesomeIcon icon='search'/>
                    </Button>
                </Stack> 
                <br/>
                <LookupUserSearchResults searchResults={data === undefined ? [] : data}/>   
            </DialogContent>
            <DialogActions>
                <Stack direction='row' justifyContent='flex-end'>
                    <Button variant='outlined' onClick={()=>{dispatch(toggleLookupUserModal()); setSkip(true)}}>Close</Button>
                </Stack>
            </DialogActions>
        </Dialog>
        
    </React.Fragment>
}

export {LookupUser}