export const FallMedicatedOptions = [
    {
        description: "Diuretic",
        name: "Diuretic",
    },
    {
        description: "Sedative",
        name: "Sedative",
    },
    {
        description: "Antihypertensive",
        name: "Antihypertensive",
    },
    {
        description: "Antidepressant",
        name: "Antidepressant",
    },
];