import React from "react"
import { useAppSelector } from "../../../../../app/hooks";
import ApprovedIcon from '../../../../../images/ApprovedStamp.png';
import DeniedIcon from '../../../../../images/DeniedStamp.png';
import PendingIcon from '../../../../../images/PendingStamp.png';
import { Stack, Typography } from "@mui/material";
import { formatDate } from "@progress/kendo-intl";

function CodingStatusIndicator(){
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    return <React.Fragment>
        <Stack>
            {caseManage.codingReviewStatus === 'Approved' ? <img src={ApprovedIcon}  alt='Approved' width='200px'/> : null}
            {caseManage.codingReviewStatus === 'Denied' ? <img src={DeniedIcon}  alt='Denied' width='200px'/> : null}
            {caseManage.codingReviewStatus === 'Pending' ? <img src={PendingIcon}  alt='Pending' width='200px'/> : null}
            {caseManage.codingReviewBy != '' && caseManage.codingReviewBy != undefined &&
            <div>
                <Typography variant='subtitle1' color='lightgray'>{'Reveiwed By: ' + caseManage.codingReviewBy}</Typography>
                <Typography variant='subtitle1' color='lightgray'>{'Reveiwed On: ' + formatDate(new Date(caseManage.codingReviewDate ?? ''),'MM/dd/yyyy')}</Typography>
            </div>
        }
        </Stack>
    </React.Fragment>
}
export {CodingStatusIndicator}