export const CodeBlueOptions = [
    {
        description: "Code Blue",
        name: "CodeBlue",
    },
    {
        description: "Delay in Code Blue Team Response",
        name: "DelayInCodeBlueTeamResponse",
    },
    {
        description: "Delay in Recognizing Condition",
        name: "DelayInRecognizingCondition",
    },
    {
        description: "Delay in RRT Response",
        name: "DelayInRRTResponse",
    },
    {
        description: "RRT escalated to Code Blue",
        name: "RRTescalatedtoCodeBlue",
    },
    {
        description: "RRT - Rapid Response Team",
        name: "RRT",
    },

];