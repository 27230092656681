import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toggleCenterDetailTab, toggleCenterEditModal } from "../../../app/slices/OrgModuleSlice";
import { useCenterQuery, useUpdateCenterMutation } from "../../../services/orgService/org-api";
import {Loading} from '../../shared/features/Loading';
import {AppConfig, CenterEditModal, CenterHeader, MedicareConfig, PayerList, PhysicianList, ProcedureList, RefundApprovers, ServiceList } from "../features";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { Stack, Typography, Button as Mbutton, IconButton, Snackbar, Alert, Table } from "@mui/material";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Save } from "@mui/icons-material";
import { useMyProfileQuery } from "../../../services/profileService/profile-api";

function OrgEdit(){
    
    const {id} = useParams()
    const navigate = useNavigate();
    const {data, isLoading} = useCenterQuery(id!);
    const profile = useMyProfileQuery();
    const dispatch = useAppDispatch();
    const tabIndex = useAppSelector((state)=>state.orgModule.centerDetailTab);
    const toggleTab = (e: TabStripSelectEventArguments) => dispatch(toggleCenterDetailTab(e.selected)); 
    const center = useAppSelector((state) => state.center.center);
    const [updateCenter, status] = useUpdateCenterMutation();
    const [notify, setNotify] = useState(false)
    useEffect(()=>{setNotify(status.isSuccess)},[status.isSuccess]);

    if(isLoading) return <Loading/>

    return <React.Fragment>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row'>
                    <Typography variant='h6'>{data!.code +'-' + (data?.shortName == null ? data!.name : data?.shortName)}</Typography>
                    <IconButton size='small' onClick={()=>dispatch(toggleCenterEditModal())}><ModeEditOutlinedIcon fontSize="small"/></IconButton>
                </Stack>
                <Stack direction='row' justifyItems='flex-end' gap={2}>
                    <Mbutton variant='outlined' startIcon={<KeyboardDoubleArrowLeftIcon/>}  onClick={()=>navigate('/OrgHome')} color='success'>Go Back</Mbutton>
                    <Mbutton variant='outlined' startIcon={<Save/>} onClick={()=>{updateCenter(center)}}>Save</Mbutton>
                </Stack>               
            </Stack>

            <hr/>
            <CenterHeader/>
           
            <TabStrip className="mt-0" selected={tabIndex} onSelect={toggleTab}>
                        <TabStripTab title='Physicians'>
                            <Table/>
                            <PhysicianList center={data!.code}/>
                        </TabStripTab>
                        <TabStripTab title='Payers'>
                            <Table/>
                            <PayerList center={data!.code}/>
                        </TabStripTab>
                        <TabStripTab title='Services'>
                            <Table/>
                            <ServiceList center={data!.code}/>
                        </TabStripTab>
                        <TabStripTab title='Procedures'>
                            <Table/>
                                <ProcedureList center={data!.code}/>
                        </TabStripTab>
                        {profile.data?.role === "Admin" || profile.data?.role === "RevCycle Admin" &&
                        <TabStripTab title='Medicare Config'>
                            <Table/>
                            <MedicareConfig/>
                        </TabStripTab>
                        }
                        {profile.data?.role === "Admin" &&
                        <TabStripTab title='App Config'>
                            <Table/>
                            <AppConfig/>
                        </TabStripTab>
                        }
                        {profile.data?.role === "Admin" &&
                        <TabStripTab title='Refund Config'>
                            <Table/>
                            <RefundApprovers/>
                        </TabStripTab>
                        }                       
                    </TabStrip>
            <CenterEditModal/>

        <Snackbar open={notify} onClose={()=>setNotify(false)} color='success' autoHideDuration={6000} message='Center successfully saved'/>
        <Snackbar open={status.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(status.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {OrgEdit};
