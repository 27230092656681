import React from "react"
import { Card, CardHeader, CardContent, Stack, Grid, Box } from "@mui/material";

function FacilityScorecard(){
    return <React.Fragment>
        <Stack justifyContent='center' alignItems='center'>
            <iframe title="Scorecard" width="1325" height="745" src="https://app.powerbi.com/reportEmbed?reportId=794f123c-4438-46e5-bc06-02557fa0d8b0&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac&navContentPaneEnabled=false" allowFullScreen={true}></iframe>
        </Stack>
    </React.Fragment>
}

export{FacilityScorecard}