import { Button, Chip, Grid, Stack } from "@mui/material"
import React from "react"
import { OutOfPeriodAdjCard } from "../features/summary/OutOfPeriodAdjCard"
import { AccountGrid } from "../features/summary/AccountGrid"
import { useNavigate, useParams } from "react-router"
import { ArrowBack } from "@mui/icons-material"
import { CenterNameChipView } from "../../../components/orgComponents/CenterNameChipView"
import { RevRecExport } from "../features"
import { RevRecImport } from "../features/summary/import/RevRecImport"
import { ExpectedRevenueCard } from "../features/summary/ExpectedRevenueCard"
import { FinalRevenueCard } from "../features/summary/FinalRevenueCard"
import { AccountCountCard } from "../features/summary/AccountCountCard"

function RevRecSummary(){
    const navigate = useNavigate();
    const {center,month,year} = useParams();
    return <React.Fragment>
        <Grid container>
            <Grid item sm={5} justifyContent='flex-start'>
                <CenterNameChipView centerId={center!}/>
            </Grid>
            <Grid item sm={4} alignItems='center' justifyContent='center'>
                <Chip label={`${month}/${year}`} variant='outlined' color='success' sx={{color:'#62BCBD', fontWeight:'bold'}}/>
            </Grid>
            <Grid item sm={3} justifyContent='end' alignContent='flex-end'>
                <Stack direction='row' gap={.5} justifyContent='flex-end'>
                    <Button variant='outlined' color='success' startIcon={<ArrowBack/>} onClick={()=>navigate(-1)}>Go Back</Button>
                    <RevRecExport/>
                    <RevRecImport/>
                </Stack>
            </Grid>
        </Grid>
        <Stack direction='row' justifyContent='space-between'>
            
            

            
        </Stack>
        <Stack direction = 'row' gap={4}>
            <ExpectedRevenueCard/>
            <FinalRevenueCard/>
            <OutOfPeriodAdjCard/>
            <AccountCountCard/>
        </Stack>
        <AccountGrid/>
        
    </React.Fragment>
}
export {RevRecSummary}