export class GrouperAddCommand{
    contractId: string;
    feeScheduleType:string;
    feeScheduleId:string;
    code: string;
    rate:number;
    isPerDiem:boolean;

    constructor(contractId:string, feeScheduleType:string, feeScheduleId:string, code:string, rate:number, isPerDiem:boolean){
        this.contractId = contractId;
        this.feeScheduleType = feeScheduleType;
        this.feeScheduleId = feeScheduleId;
        this.code = code;
        this.rate = rate;
        this.isPerDiem = isPerDiem;
    }
}