import { Stack } from "@mui/material"
import React from "react"
import { RuleList } from "./RuleList"
import { RuleModal } from "./RuleModal"

function Rules(){
    return <React.Fragment>
        <Stack direction='row' marginTop='5px' marginRight='5px' justifyContent='flex-end'>
            <RuleModal/>
        </Stack>
        <RuleList/>
    </React.Fragment>
}

export {Rules}