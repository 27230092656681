import { Dialog, DialogActions, DialogContent, DialogTitle,Button, Stack, TextField, Typography, FormControlLabel, Switch } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleCarveOutEditModal } from "../../../../../app/slices/ContractModuleSlice";
import { CarveOutDeleteCommand, CarveOutEditCommand } from "../../../../../services/contractService/commands";
import { CarveOut } from "../../../../../services/contractService/model";
import { useCarveOutDeleteMutation, useCarveOutEditMutation, useCarveOutFindQuery } from "../../../../../services/contractService/contract-api";

function CarveOutEdit(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const isOpen = useAppSelector((state)=>state.contractModule.carveOutEditModal);
    const selectedCarveOut = useAppSelector((state)=>state.contractModule.selectedCarveOut);
    const {data} = useCarveOutFindQuery(selectedCarveOut);
    const [carveOutEdit] = useCarveOutEditMutation();
    const [carveOutDelete] = useCarveOutDeleteMutation();
    const [carveOut,setCarveOut] = useState(new CarveOut());
    useEffect(()=>setCarveOut(data?? new CarveOut()),[data]);
    const handleChange=(e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        const {name,value} = e.target;
        let newCarveOut:CarveOut = carveOut;
        newCarveOut = ({...newCarveOut,[name]:value});
        setCarveOut(newCarveOut);
    }
    const handleToggleChange = (e:React.ChangeEvent<HTMLInputElement>) =>{
        const {name,checked} = e.target;
        let newCpt = carveOut;
        newCpt = ({...newCpt,[name]:checked})
        setCarveOut(newCpt)
    }
    const saveCarveout=()=>{
        let command = new CarveOutEditCommand(carveOut.id,id!,carveOut.code,carveOut.description,carveOut.rate,carveOut.applyMultiProcedureDisc,carveOut.isCaseRate);
        carveOutEdit(command);
    }
    const deleteCarveOut=()=>{
        let command = new CarveOutDeleteCommand(carveOut.id);
        carveOutDelete(command);
    }
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant='h6'>{'Edit Carve Out: ' + carveOut.code}</Typography>
                    <Button color='error' onClick={()=>{deleteCarveOut(); dispatch(toggleCarveOutEditModal(''));}}>Delete</Button>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack marginTop='10px' gap={2}>
                    <TextField label='Description' name='description' value={carveOut.description} onChange={(e)=>handleChange(e)}
                        multiline fullWidth sx={{width:'500px'}}/>
                    <TextField type='number' label='Rate' name='rate' value={carveOut.rate} onChange={(e)=>handleChange(e)}
                         sx={{width:'200px'}}/>
                    <Stack direction='row'>
                        <FormControlLabel
                            control = {<Switch size="small" value={carveOut.applyMultiProcedureDisc} checked={carveOut.applyMultiProcedureDisc} onChange={(e)=>handleToggleChange(e)} name='applyMultiProcedureDisc'/>}
                            label='Apply MPD' labelPlacement="top"/> 
                        <FormControlLabel
                            control = {<Switch size="small" value={carveOut.isCaseRate} checked={carveOut.isCaseRate} onChange={(e)=>handleToggleChange(e)} name='isCaseRate'/>}
                            label='Is Case Rate' labelPlacement="top"/> 
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>dispatch(toggleCarveOutEditModal(''))}>Close</Button>
                <Button onClick={()=> {saveCarveout(); dispatch(toggleCarveOutEditModal(''));}}>Save</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {CarveOutEdit}