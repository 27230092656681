import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { formatDate} from "@telerik/kendo-intl";
import { GridButton } from "../../../../../components";
import { toggleCallDetailDialog } from "../../../../../app/slices/RadModuleSlice";

function CallList(){
    const dispatch = useAppDispatch();
    const radRequest = useAppSelector((state) => state.radRequest.radRequest);
    const parseCalls=()=>{
        let calls = radRequest.callLog.map((obj) =>{
            return {...obj,callDate:new Date(obj.callDate),patientReached:obj.patientReached.toString()};
        })
        return calls;
    }

    return <React.Fragment>
        {radRequest.callLog.length > 0 &&
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead sx={{backgroundColor:'whitesmoke'}}>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Patient Reached</TableCell>
                            <TableCell>Called On</TableCell>
                            <TableCell>Called By</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {parseCalls().sort((a,b) => b.callDate.getTime() - a.callDate.getTime()).map(i => (
                            <TableRow key={i.id}>
                                <TableCell><GridButton onClick={()=>dispatch(toggleCallDetailDialog(i.id))}>Details</GridButton></TableCell>
                                <TableCell>{i.patientReached === 'true' ? "Yes": "No"}</TableCell>
                                <TableCell>{formatDate(new Date(i.callDate ?? ''),'MM/dd/yyyy')}</TableCell>
                                <TableCell>{i.calledBy}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        }
    </React.Fragment>
}

export {CallList}