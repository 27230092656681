export class RefundRejectPostCommand{
    caseId:string;
    refundId:string;
    rejecter:string;

    constructor(caseId:string, refundId:string, rejecter:string){
        this.caseId = caseId;
        this.refundId = refundId;
        this.rejecter = rejecter;
    }
}