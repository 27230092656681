import { CaseManCallLog } from "./CaseManCallLog";
import { CaseManCodingDetails, DefaultCaseManCodingDetails } from "./CaseManCodingDetails";
import { CaseManNote } from "./CaseManNote";
import { CaseManPolicyLink } from "./CaseManPolicyLink";
import { ClinicalReview } from "./ClinicalReview";
import { DocumentRequest } from "./DocumentRequest";

export interface CaseManagement{
    id:string;
    center:string;
    accountNumber:string;
    visitKey:string;
    visitNumber?:number;
    mrn?:string;
    lastName:string;
    firstName:string;
    serviceDate?:Date;
    admissionType:string;
    admissionSource:string;
    visitStatus:string;
    physicianId:string;
    physicianName:string;
    physicianNpi:string;
    payerId:string;
    payerName:string;
    clinicalReviewStatus?:string;
    clinicalReviewBy?:string;
    clinicalReviewDate?:Date;
    codingReviewStatus?:string;
    codingReviewBy?:string;
    codingReviewDate?:Date;
    codingDetails:CaseManCodingDetails;
    clinicalReview?:ClinicalReview;
    documentRequest?:DocumentRequest;
    notes:CaseManNote[];
    policyLinks?:CaseManPolicyLink[];
    callLogs:CaseManCallLog[];
}

export function DefaultCaseMan():CaseManagement{
    return {id:'', center:'', accountNumber:'', visitKey:'', lastName:'', firstName:'',
     admissionType:'', admissionSource:'', visitStatus:'', physicianId:'',
     physicianName:'', physicianNpi:'', payerId:'', payerName:'', clinicalReviewStatus:'Incomplete', notes:[], callLogs:[],
    codingDetails: DefaultCaseManCodingDetails()}
}