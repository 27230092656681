
export class GrouperEditCommand{
    feeScheduleId:string;
    grouperId:string;
    code:string;
    rate:number;
    isPerDiem:boolean;

    constructor(feeScheduleId:string, grouperId:string, code:string, rate:number, isPerDiem:boolean){
        this.feeScheduleId = feeScheduleId;
        this.grouperId = grouperId;
        this.code = code;
        this.rate = rate;
        this.isPerDiem = isPerDiem;
    }
}