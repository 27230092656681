import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleGrouperUploadModal } from "../../../../../app/slices/ContractModuleSlice";
import { contractApi } from "../../../../../services/contractService/contract-api";
import GrouperTemplate from '../../../../../templates/GrouperTemplate.png';
import { ContractService } from "../../../../../utils/ServiceLocator";

function GrouperUpload(){
    const dispatch = useAppDispatch();
    const feeSchedule = useAppSelector((state) => state.feeSchedule.feeSchedule);
    const isOpen = useAppSelector((state) => state.contractModule.grouperUploadModal);
    const [selectedFile, setSelectedFile] = useState();
	const [uploadComplete, setUploadComplete] = useState(false);
	const [uploadInProgress, setUploadInProgress] = useState(false);

    const changeHandler = (event:any) =>{
        setSelectedFile(event.target.files[0]);
    }
    
    const handleSubmission = ()=>{
        const formData = new FormData();
        setUploadInProgress(true);
        formData.append('File',selectedFile!);
        fetch(ContractService + 'UploadGrouperEndpoint/UploadGrouper/' + feeSchedule.id,{method:'POST', body:formData})
            .then((response)=>{response.json(); setUploadComplete(true);setUploadInProgress(false);dispatch(contractApi.util.invalidateTags(['feeSchedule']))})
            .catch((error) => {console.log('Error',error);});
    }
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>Upload Groupers</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Alert color='info'>Groupers must be uploaded using a specific format. Please download the template here. <br></br>
                        <a href={GrouperTemplate} download='GrouperTemplate.csv'>Download</a>
                    </Alert>
                    {uploadInProgress &&
					    <Alert color='warning'>We're processing your request. This may take a few minutes.  You can close this window and continue working.</Alert>}
					{uploadComplete === true &&
						<Alert color="success">Upload Completed Successfully</Alert>}
                    <input type='file' name='file' onChange={changeHandler}/>
                </Stack>
                <Stack direction='row' justifyContent='flex-end' gap={1}>
                    <Button variant='outlined' startIcon={uploadInProgress ? '' : ''} onClick={handleSubmission} disabled={selectedFile === undefined || uploadInProgress}>{!uploadInProgress ? 'Submit': <CircularProgress/>}</Button>
                    <Button variant='outlined' onClick={()=>{dispatch(contractApi.util.invalidateTags(['feeSchedule'])); setUploadComplete(false); dispatch(toggleGrouperUploadModal()); }}>Close</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}

export {GrouperUpload}