export const AirwayOptions = [
    {
        description: "Airway Obstructed",
        name: "AirwayObstructed",
    },
    {
        description: "Delivery Mode Incorrect",
        name: "DeliveryModeIncorrect",
    },
    {
        description: "Extubation Unplanned",
        name: "ExtubationUnplanned",
    },
    {
        description: "Extubation Self",
        name: "ExtubationSelf",
    },
    {
        description: "Intubation-Delayed",
        name: "IntubationDelayed",
    },
    {
        description: "Intubation-Difficult",
        name: "IntubationDifficult",
    },
    {
        description: "Intubation-Failed",
        name: "IntubationFailed",
    },
    {
        description: "Intubation-Unplanned",
        name: "IntubationUnplanned",
    },
    {
        description: "Medical Gas Problem",
        name: "MedicalGasProblem",
    },
    {
        description: "Missed Treatment",
        name: "MissedTreatment",
    },
    {
        description: "Rate/flow/Concentration Incorrect",
        name: "RateFlowConcentrationIncorrect",
    },
    {
        description: "Inappropriate Ventilator Issue",
        name: "InappropriateVentilatorIssue",
    },
];
