export const DiagnosticTestErrorOptions = [
    {
        description: "Wrong Patient",
        name: "WrongPatient",
    },
    {
        description: "Wrong Site",
        name: "WrongSite",
    },
    {
        description: "Wrong Test",
        name: "WrongTest",
    },
];