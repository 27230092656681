import { Grid, Stack } from "@mui/material"
import React from "react"
import { PhysicianDisplay, ServiceDateDisplay } from "../../../../../components/encounterItems"
import { RefundTypeSelector } from "./RefundTypeSelector"
import { RefundAmountEdit } from "./RefundAmountEdit"
import { OpenBalancesSelector } from "./OpenBalancesSelector"
import { OpenClaimsSelector } from "./OpenClaimsSelector"
import { RefundStatusView } from "./RefundStatusView"
import { RefundEscalateSwitch } from "./RefundEscalateSwitch"
import { SageStatusView } from "./SageStatusView"
import { CreditCardSwitch } from "./CreditCardSwitch"

function RefundDetails(){
    return <React.Fragment>
        <Grid container>
        <Grid item sm={10.5}>
        <Stack direction = 'row' justifyContent='space-between' gap={1} marginTop='20px' marginLeft='10px' marginRight='10px'>
            <Stack direction='row' gap={1}>
            <ServiceDateDisplay/>
            <PhysicianDisplay/>
            <RefundStatusView/>
            <SageStatusView/>
            </Stack>
        </Stack> 
        <Stack direction='row' gap={1} marginTop='20px' marginLeft='10px'>
            <RefundTypeSelector/>
            <RefundAmountEdit/>
            <OpenBalancesSelector/>
            <OpenClaimsSelector/>
        </Stack>
        </Grid>
        <Grid item sm={1.5}>
        <Stack gap={2} alignItems='normal' marginTop='5px' marginRight='5px'>
                <RefundEscalateSwitch/>
                <CreditCardSwitch/>
            </Stack>
        </Grid>
        </Grid>
    </React.Fragment>
}

export {RefundDetails}