import React from "react"
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../app/hooks"
import { toggleCenterLookupModal } from "../../../../../app/slices/ContractModuleSlice";
import { useCenterListQuery } from "../../../../../services/orgService/org-api";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../../utils/ColumnMenu";
import { CenterAddCommandCell } from "./CenterAddCommandCell";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

function CenterLookup(){
    const isOpen = useAppSelector((state)=> state.contractModule.centerLookupModal);
    const dispatch = useDispatch();
    const {data} = useCenterListQuery();

    return <React.Fragment>
        <Dialog open={isOpen} maxWidth='xl'>
            <DialogContent>
                <Typography variant='h6' className='psn-green'>Add/Remove Centers To Contract</Typography>
                <Grid data={data} style={{height:'500px'}} className='embed-grid'>
                    <Column cell={CenterAddCommandCell} width='75px'/> 
                    <Column title='Code' field='code' columnMenu={ColumnMenu} width={'125px'}/>
                    <Column title='Name' field='name' columnMenu={ColumnMenu}/>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>dispatch(toggleCenterLookupModal())}>Close</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}
export {CenterLookup}