import { Alert } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { usePayerDetailsQuery } from "../../../../../services/orgService/org-api";
import { PayerForCenterRequest } from "../../../../../services/orgService/commands";
import { RequiresMedicareAuth } from "../../../specs/RequiresMedicareAuth";
import { RequiresCignaAuth } from "../../../specs/RequiresCignaAuth";
import { RequiresAetnaAuth } from "../../../specs/RequiresAetnaAuth";
import { RequiresBCBSAuth } from "../../../specs/RequiresBCBSAuth";
import { useParams } from "react-router";
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import { RequiresTriwestAuth } from "../../../specs/RequiresTriwestAuth";

function AuthorizationWarnings(){
    const {id} = useParams();
    const verify = useAppSelector((state) => state.verification.verification);
    const schedule = useCaseFindQuery(id!);
    const primaryPayer = schedule.data?.insurances.find(p => p.priority === 1)
    const calctype = usePayerDetailsQuery(new PayerForCenterRequest(verify.center, primaryPayer?.payerCode??'')).data?.financialClass;

    return <React.Fragment>
        {calctype === 'Medicare' && new RequiresMedicareAuth().IsSatisfiedBy(verify) &&
        <Alert color="error">Medicare requires authorization for one or more procedure codes on this case.</Alert>}
        {(calctype === 'BCBS Trad' || calctype==='BCBS PPO' || calctype==='BCBS NWB' || calctype==='BCBS HMO' || calctype==='BCBS Blue Essential' || calctype==='BCBS BAV') 
            && new RequiresBCBSAuth().IsSatisfiedBy(verify) &&
        <Alert color="error">BCBS requires authorization for one or more procedure codes on this case.</Alert>
        }
        {calctype === 'Aetna' && new RequiresAetnaAuth().IsSatisfiedBy(verify) &&
        <Alert color="error">Aetna requires authorization for one or more procedure codes on this case.</Alert>
        }
        {calctype === 'Cigna' && new RequiresCignaAuth().IsSatisfiedBy(verify) &&
        <Alert color="error">Cigna requires authorization for one or more procedure codes on this case.</Alert>
        }
        {calctype === 'TriWest' && new RequiresTriwestAuth().IsSatisfiedBy(verify) &&
        <Alert color="error">TriWest requires authorization for one or more procedure codes on this case.</Alert>
        }
    </React.Fragment>
}

export {AuthorizationWarnings}