import { Stack } from "@mui/material"
import React from "react"
import { DiagnosisView } from "./DiagnosisView"
import { DiagnosisSupportEdit } from "./DiagnosisSupportEdit"
import { useAppSelector } from "../../../../../../app/hooks";

function DiagnosisArray(){
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    return <React.Fragment>
        <Stack direction='row' gap={1} marginTop='20px' marginLeft='10px'>
            <DiagnosisView label='Dx1' value={caseManage.codingDetails.diagnosisCodes.diagnosis1} name='diagnosis1'/>
            <DiagnosisView label='Dx2' value={caseManage.codingDetails.diagnosisCodes.diagnosis2} name='diagnosis2'/>
            <DiagnosisView label='Dx3' value={caseManage.codingDetails.diagnosisCodes.diagnosis3} name='diagnosis3'/>
            <DiagnosisView label='Dx4' value={caseManage.codingDetails.diagnosisCodes.diagnosis4} name='diagnosis4'/>
        </Stack>
        <Stack direction='row' gap={1} marginTop='20px' marginLeft='10px'>
            <DiagnosisSupportEdit value={caseManage.codingDetails.diagnosisCodes.dx1CoverageStatus} name='dx1CoverageStatus'/>
            <DiagnosisSupportEdit value={caseManage.codingDetails.diagnosisCodes.dx2CoverageStatus} name='dx2CoverageStatus'/>
            <DiagnosisSupportEdit value={caseManage.codingDetails.diagnosisCodes.dx3CoverageStatus} name='dx3CoverageStatus'/>
            <DiagnosisSupportEdit value={caseManage.codingDetails.diagnosisCodes.dx4CoverageStatus} name='dx4CoverageStatus'/>
        </Stack>
    </React.Fragment>
}
export {DiagnosisArray}