import { Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { DiagnosisArray } from "./diagnosisArray/DiagnosisArray"
import { CodingApproveBtn } from "./commands/CodingApproveBtn"
import { CodingDenyBtn } from "./commands/CodingDenyBtn"
import { CodingPendBtn } from "./commands/CodingPendBtn"
import { CodingStatusIndicator } from "./CodingStatusIndicator"
import { CaseManCptList } from "./CaseManCptList"

function CodingReview(){
    return <React.Fragment>
        <Grid container>
            <Grid item sm={8}>
            <Stack marginLeft='10px' marginTop='40px'>
                <Typography variant='h5' className='psn-green' textAlign='left'>Diagnosis Codes</Typography>
                <Typography variant='body2' textAlign='left'> To guarantee medical necessity and accurate documentation, please ensure that all diagnosis codes are thoroughly reviewed and checked. Verify that each code appropriately reflects the patient's condition, supporting the required medical interventions. Comprehensive and precise diagnosis coding is crucial for effective healthcare management. Your attention to detail in confirming the accuracy of these codes plays a vital role in ensuring the quality and necessity of medical services provided. </Typography>
            </Stack>
                <DiagnosisArray/>
                <CaseManCptList/>
            </Grid>
            <Grid item sm={4}>
                <Stack direction='row' justifyContent='flex-end' gap={1} marginTop='10px' marginRight='10px'>
                    <CodingApproveBtn/>
                    <CodingDenyBtn/>
                    <CodingPendBtn/>
                </Stack>
                <Stack direction='row' justifyContent='flex-end' gap={1} marginTop='30px' marginRight='10px'>
                    <CodingStatusIndicator/>
                </Stack>
            </Grid>
        </Grid>

    </React.Fragment>
}
export {CodingReview}