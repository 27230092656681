import { MedicareRate } from "../model/center/MedicareRate";

export class MedicareRateSaveCommand{
    centerId:string;
    rate:MedicareRate;

    constructor(centerId:string, rate:MedicareRate){
        this.centerId = centerId;
        this.rate = rate;
    }
}