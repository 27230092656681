import { Stack, TextField, Typography } from "@mui/material"
import { formatDate } from "@progress/kendo-intl"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks";

function EAR_PayerInfo(){
    const verification = useAppSelector((state)=>state.verification.verification);
    return <React.Fragment>
        <Stack gap={1} direction='row' marginBottom='10px'>
            <TextField label='Agent Name' value={verification?.waystarAuthStatus?.agentName}/>
            <TextField label='Last Request Date' value={formatDate(new Date(verification?.waystarAuthStatus?.lastRequestDate),"MM/dd/yyyy")}/>
            <TextField label='Payer Status' value={verification?.waystarAuthStatus?.payerAuthStatus}/>
        </Stack>
    </React.Fragment>
}
export {EAR_PayerInfo}