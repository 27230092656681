import * as React from 'react';
import ProfileIcon from '../../../images/profileIcon.png';
import logo from '../../../images/psn-logo.svg';
import { ProfilePath, HomePath } from '../../../utils/ServiceLocator';
import { AppBar } from '@mui/material';
import { Stack } from '@mui/system';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header>
                <AppBar position='static' sx={{height:'60px', backgroundColor:'white'}}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <a/>
                            <a href={'/'}><img src={logo} alt='logo'/></a>
                            <a id='profileIcon' href={'/myProfile'} className='my-profile' style={{width:'100px'}}><img className='card-img' src={ProfileIcon} alt='Profile'/></a>
                    </Stack>
                    
                </AppBar>
        </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
