import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { InputAdornment, TextField } from "@mui/material";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { editRefundDetails } from "../../../../../services/refundService/model/refundRequest/RefundRequestSlice";
import { RefundLocked } from "../../../specs/RefundLocked";

function RefundAmountEdit(){
    const dispatch = useAppDispatch();
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    return <React.Fragment>
        <TextField label='Refund Amount' type='number' sx={{width:'225px'}}
            InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}
            value={refund.refundDetail.amount}
            disabled={new RefundLocked().IsSatisfiedBy(refund)}
            onChange={(e)=>dispatch(editRefundDetails(new ReduxUpdate('amount',e.target.value)))}/>
    </React.Fragment>
}

export {RefundAmountEdit}