import React from "react"
import { CenterSelector } from "../../../components/orgComponents"
import { Stack } from "@mui/material"
import { PolicyHomeBtn } from "../features/policyHome/PolicyHomeBtn"
import { useMyProfileQuery } from "../../../services/profileService/profile-api"

function CaseManHome(){
    const profile = useMyProfileQuery();
    return <React.Fragment>
        {profile.data?.claimsAccess.find(p => p.code === 'ClinicalPolicies') != undefined &&
        <Stack direction='row' gap={2} marginTop='10px' justifyContent='flex-end'>
            <PolicyHomeBtn/>
        </Stack>}
        <CenterSelector navigateTo='/caseManDashboard'/>
    </React.Fragment>
}

export {CaseManHome}