import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react"
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../app/hooks";
import { toggleCenterLookupModal } from "../../../../../app/slices/ContractModuleSlice";
import { CenterAddCommand, CenterRemoveCommand } from "../../../../../services/contractService/commands";
import { GridButton } from "../../../../../components";
import { useCenterAttachMutation, useCenterDetachMutation } from "../../../../../services/contractService/contract-api";
import { Button } from "@mui/material";
import { Add, Check } from "@mui/icons-material";

function CenterAddCommandCell(props:GridCellProps){
    const dispatch = useDispatch();
    const contract = useAppSelector((state)=> state.contract.contract)
    const [attach] = useCenterAttachMutation();
    const [detach] = useCenterDetachMutation();
    const handleAttach=()=>{
        const command = new CenterAddCommand(contract.id,props.dataItem.code,props.dataItem.name);
        attach(command).unwrap().then(fullfilled => {dispatch(toggleCenterLookupModal)})
    }
    const handleDetach=()=>{
        const command = new CenterRemoveCommand(contract.id, props.dataItem.code,props.dataItem.name);
        detach(command);
    }

    return <React.Fragment>
        {contract.centers.findIndex(p => p.code == props.dataItem.code) == -1 &&
            <GridButton size='small' color="primary" variant="outlined"
                onClick={()=> handleAttach()}><Add/>Add</GridButton>}
        
        {contract.centers.findIndex(p => p.code == props.dataItem.code) !== -1 &&
            <GridButton className="mt-1 ms-1" variant="outlined" color='warning'
                onClick={()=> handleDetach()}>
                <Check/></GridButton>}
    </React.Fragment>
}

export {CenterAddCommandCell}