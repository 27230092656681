import { Add, Upload } from "@mui/icons-material"
import { Button } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import { useAppDispatch } from "../../../../../app/hooks"
import { toggleGrouperAddModal, toggleGrouperUploadModal } from "../../../../../app/slices/ContractModuleSlice"
import { GrouperAdd } from "./GrouperAdd"
import { GrouperEdit } from "./GrouperEdit"
import { GrouperList } from "./GrouperList"
import { GrouperUpload } from "./GrouperUpload"

function Groupers(){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' gap={1}>
            <Button startIcon={<Add/>} variant='outlined' onClick={()=> dispatch(toggleGrouperAddModal())}>Add Grouper</Button>
            <Button variant='outlined' startIcon={<Upload/>} onClick={()=>dispatch(toggleGrouperUploadModal())}>Upload Groupers</Button>
        </Stack>
        <GrouperList/>
        <GrouperAdd/>
        <GrouperEdit/>
        <GrouperUpload/>
    </React.Fragment>
}

export {Groupers}