export interface RefundApprover {
    id:string;
    centerId:string;
    priority:number;
    approver:string;
    approveGreaterThan:number;
}

export function DefaultRefundApprover():RefundApprover {
    return {
        id:"",
        centerId:"",
        priority:0,
        approver:"",
        approveGreaterThan:0
    }
}