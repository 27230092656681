export class RefundApprovalPostCommand{
    caseId:string;
    refundId:string;
    approver:string;

    constructor(caseId:string,refundId:string,approver:string){
        this.caseId = caseId;
        this.refundId = refundId;
        this.approver = approver;
    }
}