export class AccountOutOfPeriodAdjustmentPostCommand {
    accountNumber: string;
    period: string;
    year: string;
    amount: number;
    reason: string;

    constructor(accountNumber: string, period: string, year: string, amount: number, reason: string) {
        this.accountNumber = accountNumber;
        this.period = period;
        this.year = year;
        this.amount = amount;
        this.reason = reason;
    }
}