import React from "react"
import { useRefundDuplicateCheckQuery } from "../../../../../services/refundService/refund-api";
import { useParams } from "react-router";
import { Alert } from "@mui/material";

function RefundDuplicateCheck(){
    const {caseId} = useParams();
    const dupCheck = useRefundDuplicateCheckQuery(caseId!);
    return <React.Fragment>
        {dupCheck.data &&
        <Alert color='error'>This refund is a possible duplicate.  Please ensure that no other refunds are in process before continuing.</Alert>}
    </React.Fragment>
}
export {RefundDuplicateCheck}