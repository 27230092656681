import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { InputAdornment, TextField } from "@mui/material";
import { editSingleCaseAgreement } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function ScaAmountEdit(){
    const dispatch = useAppDispatch()
    const verify = useAppSelector((state) => state.verification.verification);

    return <React.Fragment>
        <TextField label='Amount' value={verify.singleCaseAgreement?.amount}
            onChange={(e)=> dispatch(editSingleCaseAgreement(new ReduxUpdate('amount', e.target.value)))}
            InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
    </React.Fragment>
}
export {ScaAmountEdit}