export class Location {
  name: string = "";
  center: string = "";
}
export function DefaultLocation(): Location {
  return {
    name: "",
    center: "",
  };
}
