import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { useRefundSaveMutation, useRefundStatusUpdateMutation } from "../../../../../../services/refundService/refund-api";
import { RefundPostCommand, RefundStatusPostCommand } from "../../../../../../services/refundService/commands";
import { Check } from "@mui/icons-material";
import { Alert, Button, Snackbar } from "@mui/material";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";

type ownProps ={
    setNotify:Function;
    setMessage:Function;}

function RefundCompletedBtn(props:ownProps){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const profile = useMyProfileQuery();
    const [refundSave] = useRefundSaveMutation();
    const [saveStatus, result] = useRefundStatusUpdateMutation();
    const command = new RefundStatusPostCommand(refund?.caseId,refund?.id,"COMPLETE");
    const handleSave=async ()=>{
        await refundSave(new RefundPostCommand(refund));
        await saveStatus(command).unwrap()
            .then(()=>{props.setNotify(true);
            props.setMessage('Refund Request Completed')});
    }

    return <React.Fragment>
        {profile.data?.role!=='AcctPay' && 
        <Button variant='outlined' color='success' startIcon={<Check/>}
            onClick={()=>{handleSave()}}>{result.isLoading?'Submitting':'Complete Refund'}</Button>
        }
        <Snackbar open={result.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(result.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}
export {RefundCompletedBtn}