import { Card, CardActions, CardContent, Grid } from '@mui/material';
import React from 'react';
import ContractsLogo from '../../../../images/appicons/Contracts.png';
import { useMyProfileQuery } from '../../../../services/profileService/profile-api';



function ContractsCard(){
    const {data} = useMyProfileQuery();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin' || data?.role === 'Contract Admin' ||
            data?.role === 'RevCycle Admin'){
            return true;
        }
        if(data?.claimsAccess.find(p => p.code === 'ContractWrite') != undefined){
            return true;}

        return false;
    }
    
    return (
        <React.Fragment>
            {canAccess() &&
                <Grid item sm={3}>
                <Card className='card-img-body center-align'>
                    <CardContent className='card-img-main'>
                        <a className='btn img-button' href={'/ContractHome'}><img className='card-img' src={ContractsLogo} alt=''/></a>
                    </CardContent>
                    <CardActions className='card-img-footer'><h6>Contracts</h6></CardActions>
                </Card>
                </Grid>
            }
        </React.Fragment>
    )
}

export {ContractsCard}