import React from "react"
import { Editor, EditorChangeEvent, EditorTools } from '@progress/kendo-react-editor';
import { Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { editPolicyDetails } from "../../../../services/caseManService/model/policy/PolicySlice";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";


function PolicySummaryEdit(){
    const {
        Bold, Italic, Underline,  AlignLeft, AlignRight, AlignCenter, Indent, Outdent,OrderedList,
        UnorderedList, Undo, Redo, Link, Unlink, FontSize, FontName, ForeColor,FormatBlock
        } = EditorTools;
        const CustomForeColor = (props: JSX.IntrinsicAttributes & EditorTools.ForeColorProps & { children?: React.ReactNode; }) => (<ForeColor {...props} colorPickerProps={{ view: 'gradient' }} />)
        ;
        const dispatch = useAppDispatch();
        const policy = useAppSelector((state)=>state.policy.policy);
        const onChange=(e:EditorChangeEvent)=>{
            dispatch(editPolicyDetails(new ReduxUpdate('policySummary',e.html)))
        }
    return <React.Fragment>
        <Stack marginTop='10px'>
            <Typography variant='h6' className='psn-green'>Policy Summary</Typography>
           <Editor tools={[[Bold, Italic, Underline], [Undo, Redo], [Link, Unlink], [AlignLeft, AlignCenter, AlignRight], [OrderedList, UnorderedList, Indent, Outdent], [FontSize, FontName, CustomForeColor,FormatBlock]]} 
            contentStyle={{height: 500}} defaultContent={''} value={policy.policySummary} onChange={(e)=>onChange(e)}/>
        </Stack>
           
    </React.Fragment>

}
export {PolicySummaryEdit}