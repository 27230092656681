import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account, DefaultAccount } from './Account';
import { accountApi } from '../../account-api';
import { ReduxNestedUpdate, ReduxUpdate } from '../../../../utils/ReduxUpdateCommands';
import { OutOfPeriodAdjustment } from './OutOfPeriodAdjustment';

interface AccountState{
    account:Account;
}
const initialStatus: AccountState = {account: DefaultAccount()}

export const AccountSlice = createSlice({
    name: 'account',
    initialState: initialStatus,
    reducers: {
        editAccountEncounterInfo: (state, action: PayloadAction<ReduxUpdate>) => {
            //@ts-ignore
            state.account.encounterInfo[action.payload.name] = action.payload.value;
        },
        editAccountPrimaryPayer: (state, action: PayloadAction<ReduxUpdate>) => {
            //@ts-ignore
            state.account.primaryPayerInfo[action.payload.name] = action.payload.value;
        },
        editAccountAttributes: (state, action: PayloadAction<ReduxUpdate>) => {
            //@ts-ignore
            state.account.attributes[action.payload.name] = action.payload.value;
        },
        editAccountTransactionHeader: (state, action: PayloadAction<ReduxUpdate>) => {
            //@ts-ignore
            state.account.transactionHeader[action.payload.name] = action.payload.value;
        },
        addOutOfPeriodAdjustment: (state, action: PayloadAction<OutOfPeriodAdjustment>) => {
            //@ts-ignore
            state.account.outOfPeriodAdjustments.push(action.payload);
        },
        editAccountFinalProcedures:(state, action:PayloadAction<ReduxNestedUpdate>) => {
            let newProcs = state.account.finalProcedures.filter(p => 1===1);
            const index = newProcs.findIndex(p => p.id === action.payload.index);
            //@ts-ignore
            newProcs[index][action.payload.name] = action.payload.value;
            if(newProcs){
                state.account.finalProcedures = newProcs;
            }
        }

    },
    extraReducers: (builder) => {
        builder.addMatcher(
            accountApi.endpoints.findAccount.matchFulfilled,
            (state, { payload }) => { state.account = payload }
        ).addMatcher(accountApi.endpoints.saveAdjustmentApproval.matchFulfilled,
            (state, { payload }) => {
                const index = state.account.outOfPeriodAdjustments.findIndex(p => p.id === payload.id);
                state.account.outOfPeriodAdjustments[index].isApproved = payload.isApproved;
            }).addMatcher(accountApi.endpoints.saveAccountStatus.matchFulfilled,
                (state,{payload})=>{state.account.encounterInfo.status = payload.encounterInfo.status}
            ).addMatcher(accountApi.endpoints.saveAccountNote.matchFulfilled,
                (state,{payload})=>{state.account.notes.push(payload)}
            ).addMatcher(accountApi.endpoints.saveAccountVarianceApproval.matchFulfilled,
                (state,{payload})=> {state.account.attributes.approvalStatus = payload.approvalStatus})
        }
        })

export const {editAccountEncounterInfo, editAccountPrimaryPayer, addOutOfPeriodAdjustment,
    editAccountFinalProcedures, editAccountAttributes,editAccountTransactionHeader
} = AccountSlice.actions;
export default AccountSlice.reducer;