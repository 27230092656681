import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react"
import { useDispatch } from "react-redux";
import { toggleClaimAccessModal } from "../../../../app/slices/ProfileModuleSlice";
import { Profile } from "../../../../services/profileService";
import { useMyProfileQuery } from "../../../../services/profileService/profile-api";
import { ClaimAccessEdit } from "./ClaimAccessEdit";
import { ClaimsAccessList } from "./ClaimsAccessList";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

type ownProps={
    profile: Profile;
}

function ClaimsAccess(props:ownProps){
    const {data} = useMyProfileQuery();
    const dispatch = useDispatch();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin' || data?.role === 'RevCycle Admin'|| data?.role==='Incident Admin'){
            return true;
        }
        return false;
    }

    return <React.Fragment>
        <Card sx={{padding:'5px', borderRadius:'10px'}}>
                <Stack direction='row' justifyContent='space-between' alignItems='flex-end' marginTop='10px' marginBottom='5px' paddingBottom='5px' borderBottom='3px solid gray'>
                    <h5>Claims</h5>
                    {canAccess() && 
                    <Button variant='outlined' color="primary" size='large'
                        onClick={()=>dispatch(toggleClaimAccessModal())} startIcon={<AddCircleOutlineIcon/>}>
                        Add
                    </Button>}
                </Stack>

                <ClaimsAccessList profile={props.profile}/>
        </Card>
        <ClaimAccessEdit profile={props.profile}/>
    </React.Fragment>
}

export {ClaimsAccess}