import { Alert, Stack } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks";

function EAR_Alerts(){
    const verification = useAppSelector((state)=>state.verification.verification);

    return <React.Fragment>
        {verification?.waystarAuthStatus?.authStatusAlerts.length > 0 &&
        <Stack gap={1} marginBottom='10px'>
            {verification?.waystarAuthStatus?.authStatusAlerts?.map((item,index)=>{
                return <Alert key={index} color='warning'>{item}</Alert>
            })} 
        </Stack>
        }
    </React.Fragment>
}

export{EAR_Alerts}