import {process, State } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import React, { useState } from "react"
import { Worklist } from "../../../../services/refundService/model";
import { RefundSearchSelect } from "./RefundSearchSelect";
import { ColumnMenu } from "../../../../utils/ColumnMenu";

type ownProps={
    data?:Worklist[];
}

function RefundSearchResults(props:ownProps){
    
    const mapData=():Worklist[]=>{
        let data:Worklist[] | undefined =props.data?.map(i => ({...i, refundStartDate: new Date(i.refundStartDate)}));
        return data?? [];}

    const defaultGridState:State = {sort:[{field:'refundStartDate', dir:'desc'}]};
    const [dataState, setDataState] = useState(defaultGridState);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

    return <React.Fragment>
        <Grid data={process(mapData(),dataState)} style={{height:'750px'}}
         onDataStateChange={dataStateChange}>
            <Column cell={RefundSearchSelect} width='100px'/>
            <Column field='accountNumber' title='Account' columnMenu={ColumnMenu} width='200px'/>
            <Column field='lastName' title='Last Name' columnMenu={ColumnMenu} width='200px'/>
            <Column field='firstName' title='First Name' columnMenu={ColumnMenu} width='200px'/>
            <Column field='refundStatus' title='Status' columnMenu={ColumnMenu} width='200px'/>
            <Column field='refundStartDate' title='Start Date' columnMenu={ColumnMenu} width='200px' format='{0:MM/dd/yyyy}'/>
        </Grid>
    </React.Fragment>
}

export {RefundSearchResults}