import React from "react"
import { AccountCenterSummaryList } from "../features/centerSummary/AccountCenterSummaryList"
import { PeriodSelector } from "../features"
import { Typography } from "@mui/material"

function RevRecHome(){
    return <React.Fragment>
        <Typography className='psn-green' variant='h5'>Revenue Recognition</Typography>
        <PeriodSelector/>
        <AccountCenterSummaryList/>
    </React.Fragment>
}
export {RevRecHome}