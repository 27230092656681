import { Insurance } from "./Insurance";
import { Physician, defaultPhysician } from "./Physician";
import { Procedure } from "./Procedure";
import { VisitNote } from "./VisitNotes";

export interface Schedule{
    id: string;
    visitKey:string;
    center:string;
    lastName:string;
    firstName:string;
    middleName:string;
    accountNumber:string;
    serviceDate?: Date; 
    birthDate?: Date | undefined;
    streetAddress1: string;
    streetAddress2: string;
    city:string;
    state:string;
    zipCode:string;
    homePhone:string;
    mobilePhone:string;
    email:string;
    admissionType:string;
    admissionSource:string;
    physician:Physician;
    insurances: Insurance[];
    visitNotes: VisitNote[];
    procedures: Procedure[];
}

export function DefaultSchedule():Schedule{
    return {
        id:'',center:'',lastName:'',firstName:'',middleName:'',accountNumber:'',visitKey:'',
        serviceDate:undefined, birthDate:new Date('01/01/1900'),streetAddress1:'',
        streetAddress2:'',city:'',state:'',zipCode:'',homePhone:'',
        mobilePhone:'',email:'', insurances:[],visitNotes:[],procedures:[],
        physician:defaultPhysician(), admissionType:'',admissionSource:''
    }
}