import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Center } from "../orgService";
import { getToken } from "../../utils/AdalConfig";
import { OrganizationService } from "../../utils/ServiceLocator";
import CenterList from "./model/CenterList";
import { PayerForCenterRequest, PayerSaveCommand, PhysicianSaveCommand, ProcedureSaveCommand, RefundApproverPostCommand, ServiceDeleteCommand, ServicePostCommand } from "./commands";
import { Physician } from "./model/physician/Physician";
import { Payer, Procedure, RefundApprover, Service, Specialty } from "./model";
import { ProcedureLookupRequest } from "./commands/ProcedureLookupRequest";
import { MedicareRate } from "./model/center/MedicareRate";
import { MedicareRateSaveCommand } from "./commands/MedicareRateSaveCommand";
import { IncidentConfigCommand as IncidentConfigCommand } from "./commands/IncidentConfigCommand";
import { IncidentNotification } from "./model/variance/IncidentNotification";
import { IncidentConfigSaveCommand } from "./commands/IncidentConfigSaveCommand";

export const orgApi = createApi({
    reducerPath:'orgApi',
    baseQuery: fetchBaseQuery({
        baseUrl: OrganizationService,
        prepareHeaders:(headers,{getState}) =>{
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }
    }),
    tagTypes:['center','centers','physicians','physician','payer','payers','procedure','procedures','specialty','lookup',
        'services', 'refundApprovers', 'refundApprover','incidentConfig'],
    endpoints:(builder)=>({
        centerFind: builder.query<Center, string>({
            query:(request: string) => 'centerForCode?request=' + request,
            providesTags:['center']
        }),
        centerList:builder.query<CenterList[],void>({
            query:() => 'centers',
            providesTags: ['centers']
        }),
        center:builder.query<Center,string>({
            query:(id:string) => 'center?id='+id,
            providesTags: ['center']
        }),
        saveCenter:builder.mutation<Center,Center>({
            query:(body) =>({url:'CenterSave', method:("POST"), body,}),
            invalidatesTags:['centers']
        }),
        updateCenter:builder.mutation<Center,Center>({
            query:(body) =>({url:'CenterUpdate', method:('POST'),body,}),
            invalidatesTags:['center','centers']
        }),
        physicianList:builder.query<Physician[],string>({
            query:(request:string)=>'physicians?request='+ request,
            providesTags:['physicians']
        }),
        physician:builder.query<Physician,string>({
            query:(id:string)=>'physician?id='+id,
            providesTags:['physician']
        }),
        physicianSave:builder.mutation<Physician,PhysicianSaveCommand>({
            query:(body:PhysicianSaveCommand)=>({url:'Physician',method:('POST'),body,}),
            invalidatesTags:['physicians','physician']
        }),
        payerList:builder.query<Payer[],string>({
            query:(center:string) => 'payers?center='+center,
            providesTags:['payer']
        }),
        payer:builder.query<Payer,string>({
            query:(id:string) => 'payer?id=' + id,
            providesTags:["payer"]
        }),
        payerDetails:builder.query<Payer,PayerForCenterRequest>({
            query:(request:PayerForCenterRequest) => 'payerForCenter?centerId=' + request.centerId + '&payerCode=' + request.payerCode,
            providesTags:['payer']
        }),
        savePayer:builder.mutation<Payer,PayerSaveCommand>({
            query:(body:PayerSaveCommand) => ({url:'Payer',method:('POST'),body,}),
            invalidatesTags:['payer']
        }),
        procedure:builder.query<Procedure, string>({
            query:(id:string) => 'procedure?id=' + id,
            providesTags:["procedure"]
        }),
        procedureList:builder.query<Procedure[],string>({
            query:(center:string) => 'procedures?center=' + center,
            providesTags:['procedures']
        }),
        saveProcedure: builder.mutation<Procedure,ProcedureSaveCommand>({
            query:(body:ProcedureSaveCommand) =>({url:'Procedure',method:('POST'),body,}),
            invalidatesTags:['procedure','procedures']
        }),
        specialties:builder.query<Specialty[],void>({
            query:() => 'specialty',
            providesTags:['specialty']
        }),
        procedureLookup:builder.query<Procedure[],ProcedureLookupRequest>({
            query:(request:ProcedureLookupRequest) => 'procedureLookup?centerId=' + request.centerId + '&searchCriteria=' + request.searchCriteria,
            providesTags:['lookup']
        }),
        saveMedicareRate:builder.mutation<MedicareRate,MedicareRateSaveCommand>({
            query:(body:MedicareRateSaveCommand) => ({url:'MedicareRate',method:('POST'),body,})
        }),
        serviceList:builder.query<Service[],string>({
            query:(center:string) => 'services?center=' + center,
            providesTags:['services']
        }),
        saveService:builder.mutation<Service,ServicePostCommand>({
            query:(body:ServicePostCommand) => ({url:'service',method:('POST'),body,}),
            invalidatesTags:['services']
        }),
        deleteService:builder.mutation<Service,ServiceDeleteCommand>({
            query:(body:ServiceDeleteCommand)=>({url:'serviceDelete',method:('POST'),body,}),
            invalidatesTags:['services']
        }),
        refundApproverSave:builder.mutation<RefundApprover,RefundApproverPostCommand>({
            query:(body:RefundApproverPostCommand)=>({url:'RefundApproverPost',method:('POST'),body,}),
            invalidatesTags:['refundApprovers','refundApprover']
        }),
        refundApproverRemove:builder.mutation<RefundApprover,RefundApproverPostCommand>({
            query:(body:RefundApproverPostCommand)=>({url:'RefundApproverRemove',method:('POST'),body,}),
            invalidatesTags:['refundApprovers','refundApprover']
        }),
        refundApproversAll:builder.query<RefundApprover[],string>({
            query:(center:string) => 'RefundApprovers?center=' + center,
            providesTags:['refundApprovers']
        }),
        refundApproverSingle:builder.query<RefundApprover,string>({
            query:(id:string) => 'RefundApprover?id=' + id,
            providesTags:['refundApprover']
        }),
        incidentConfigCenter:builder.query<IncidentNotification,string>({
            query:(id: string) => 'incidentConfig?id=' + id,
            providesTags:['incidentConfig']
        }),
        incidentConfigDepartmentSave:builder.mutation<IncidentNotification,IncidentConfigSaveCommand>({
            query:(body:IncidentConfigSaveCommand)=>({url:'IncidentConfigPost',method:('POST'),body,}),
            invalidatesTags:['incidentConfig']
        }),
        incidentConfigEventSave:builder.mutation<IncidentNotification,IncidentConfigSaveCommand>({
            query:(body:IncidentConfigSaveCommand)=>({url:'IncidentConfigPost',method:('POST'),body,}),
            invalidatesTags:['incidentConfig']
        }),
        incidentConfigRemove:builder.mutation<IncidentNotification,IncidentConfigCommand>({
            query:(body:IncidentConfigCommand)=>({url:'IncidentConfigRemove',method:('POST'),body,}),
            invalidatesTags:['incidentConfig']
        }),

    })
})

export const {useCenterFindQuery, useCenterListQuery,useSaveCenterMutation, useUpdateCenterMutation,
    usePhysicianListQuery, usePhysicianQuery, 
    usePhysicianSaveMutation, usePayerListQuery, usePayerQuery, useSavePayerMutation,
    useProcedureQuery, useProcedureListQuery, useSaveProcedureMutation,
    useSpecialtiesQuery, useCenterQuery, useProcedureLookupQuery, usePayerDetailsQuery,
    useSaveMedicareRateMutation, useServiceListQuery,useSaveServiceMutation, useDeleteServiceMutation,
    useRefundApproverSaveMutation, useRefundApproverRemoveMutation, useRefundApproversAllQuery,
    useRefundApproverSingleQuery, useIncidentConfigCenterQuery, useIncidentConfigDepartmentSaveMutation, 
    useIncidentConfigEventSaveMutation, useIncidentConfigRemoveMutation} = orgApi;