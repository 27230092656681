import {
  TextField,
  FormGroup,
  FormHelperText,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../app/hooks";
import { IncidentTypeDetail } from "../../../../../services/varianceService/model/varianceRequest/IncidentTypeDetail";
import { editIncidentTypeDetails } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";

interface ISubtypeOptions {
  options: { name: string; description: string }[];
  formName: string;
  freeformName?: string;
  freeformLabel?: string;
  helperText?: number;
  canEdit: boolean;
}

function DirResponseSubTypes({
  options,
  formName,
  freeformName,
  freeformLabel,
  helperText = 0,
  canEdit,
}: ISubtypeOptions) {
  const dispatch = useDispatch();
  const incidentTypeDetails = useAppSelector(
    (state) => state.varianceRequest.varianceRequest.incidentTypeDetails
  );
  const DirResponseSubTypes: IncidentTypeDetail[] = options.map((option) => ({
    name: option.name,
    checked:
      incidentTypeDetails.find((x) => x.name === option.name)?.checked ?? false,
    description: option.description,
  }));
  const [SubTypeOptions, setSubTypeOptions] = React.useState({
    DirResponseSubTypes,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const incidentTypeDetail: IncidentTypeDetail = {
      name: event.target.name,
      checked: event.target.checked,
      description:
        (event.target.labels && event.target.labels[0]?.textContent) || "",
    };
    setSubTypeOptions((prevOptions) => ({
      ...prevOptions,
      DirResponseSubTypes: prevOptions.DirResponseSubTypes.map((option) =>
        option.name === event.target.name
          ? { ...option, checked: event.target.checked }
          : option
      ),
    }));
    dispatch(editIncidentTypeDetails(incidentTypeDetail));
  };
  const [freeForm, setFreeForm] = React.useState(
    incidentTypeDetails?.find((x) => x.name === freeformName)?.description
  );
  const setOther = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const incidentTypeDetail: IncidentTypeDetail = {
      name: event.target.name,
      checked: true,
      description: event.target.value,
    };
    setFreeForm(incidentTypeDetail.description);
    dispatch(editIncidentTypeDetails(incidentTypeDetail));
  };

  return (
    <React.Fragment>
      <FormHelperText>
        {helperText === 0
          ? ""
          : helperText === 1
          ? "Please select only one option"
          : "Select One or More"}
      </FormHelperText>
      <FormLabel component="legend" style={{ margin: "10px" }}>{formName}</FormLabel>
      <FormGroup>
        <Stack
          margin={"10px"}
          direction="row"
          justifyContent="space-between"
          useFlexGap
          flexWrap="wrap"
        >
          {SubTypeOptions.DirResponseSubTypes.map((value) => (
            <FormControlLabel
              key={value.name}
              label={value.description}
              control={
                <Checkbox
                  disabled={!canEdit}
                  size="small"
                  checked={value.checked || false}
                  name={value.name}
                  onChange={(e) => handleChange(e)}
                />
              }
            />
          ))}
          {freeformName !== undefined && (
            <TextField
              disabled={!canEdit}
              id="standard-basic"
              name={freeformName}
              label={freeformLabel || "Other"}
              variant="standard"
              value={freeForm}
              onChange={setOther}
            />
          )}
        </Stack>
      </FormGroup>
    </React.Fragment>
  );
}

export { DirResponseSubTypes };
