import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, MenuItem, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleCptScheduleItemEditModal } from "../../../../../app/slices/ContractModuleSlice";
import { CptEditCommand } from "../../../../../services/contractService/commands";
import { Cpt } from "../../../../../services/contractService/model";
import { contractApi, useCptDeleteMutation, useCptEditMutation, useCptFindQuery, useFeeScheduleFindQuery } from "../../../../../services/contractService/contract-api";


function CptItemEdit(){
    const dispatch = useAppDispatch();
    const {feeScheduleId} = useParams();
    const feeSchedule = useFeeScheduleFindQuery(feeScheduleId!)
    const isOpen = useAppSelector((state) => state.contractModule.cptScheduleItemEditModal);
    const skip = !useAppSelector((state) => state.contractModule.cptScheduleItemEditModal);
    const selectedCpt = useAppSelector((state)=>state.contractModule.selectedCpt);
    const [editCpt] = useCptEditMutation();
    const [deleteCpt] = useCptDeleteMutation();
    const {data} = useCptFindQuery(selectedCpt,{skip,});
    const [cpt, setCpt] = useState(new Cpt());
    const handleChange=(e:React.ChangeEvent<HTMLTextAreaElement|HTMLInputElement>|SelectChangeEvent<string>) =>{
        const {name,value} = e.target;
        let newCpt:Cpt = data!;
        newCpt = ({...newCpt,[name]:value});
        setCpt(newCpt);
    }
    const handleMpd = (e:React.ChangeEvent<HTMLInputElement>) =>{
        const {name,checked} = e.target;
        let newCpt = cpt;
        newCpt = ({...newCpt,[name]:checked})
        setCpt(newCpt)
    }
    useEffect(()=>{setCpt(data?? new Cpt())},[data]);
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6'>{'Edit Code: ' + data?.code}</Typography>
                    <Button color='error' onClick={()=>{deleteCpt(cpt.id); dispatch(toggleCptScheduleItemEditModal(''));dispatch(contractApi.util.invalidateTags(['feeScheduleItemCount']))}}>Delete</Button>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='10px'>
                    <TextField label='Description' name='name' value={cpt.name} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}} fullWidth multiline/>
                </Stack>
                <Stack direction='row' gap={1} marginTop='10px'>
                    <TextField label='Category' name='category' select size='small' sx={{width:'200px',marginRight:'15px'}} value={cpt.category} onChange={(e)=>handleChange(e)}>
                        <MenuItem value='Unknown'>Unknown</MenuItem>
                        <MenuItem value='Ortho Proc'>Ortho Proc</MenuItem>
                        <MenuItem value='Cath/PRCA'>Cath/PRCA</MenuItem>
                        <MenuItem value='APC Grouped'>APC Grouped</MenuItem>
                        <MenuItem value='Office Procedure'>Office Procedure</MenuItem>
                        <MenuItem value='Non Grouped'>Non Grouped</MenuItem>
                        <MenuItem value='Incidental'>Incidental</MenuItem>
                    </TextField>
                </Stack>
                <Stack direction='row' gap={1} marginTop='10px'>
                <TextField label='Apc Code' name='apcCode' value={cpt.apcCode} onChange={(e)=>handleChange(e)}
                        size='small' InputLabelProps={{shrink:true}}/>
                    {feeSchedule.data?.feeSubType === 'Grouped' ?
                        <TextField label='Grouper' name='grouperPointer' value={cpt.grouperPointer} onChange={(e)=>handleChange(e)}
                            size='small' InputLabelProps={{shrink:true}}/>:
                        <TextField type='number' label='Rate' name='baseRate' value={cpt.baseRate} onChange={(e)=>handleChange(e)}
                            size='small' InputLabelProps={{shrink:true}}/>
                    } 
                <TextField type='number' label='Relative Weight' name='relativeWeight' value={cpt.relativeWeight} onChange={(e)=>handleChange(e)}
                    size='small' InputLabelProps={{shrink: true}}/>             
                </Stack>
                <Stack direction='row' marginTop='10px'>
                <FormControlLabel
                     control = {<Switch size="small" value={cpt.multiProcedureDiscount} checked={cpt.multiProcedureDiscount} onChange={(e)=>handleMpd(e)} name='multiProcedureDiscount'/>}
                     label='MPD' labelPlacement="top"/> 
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>dispatch(toggleCptScheduleItemEditModal(''))}>Close</Button>
                <Button
                    onClick={()=>{editCpt(new CptEditCommand(feeScheduleId!,cpt.id,cpt.code,cpt.name,cpt.category,cpt.apcCode,cpt.baseRate.toString(),cpt.grouperPointer, cpt.multiProcedureDiscount ?? false, cpt.relativeWeight));
                                dispatch(toggleCptScheduleItemEditModal(''))}}
                >Save</Button>
            </DialogActions>
        </Dialog>    
    </React.Fragment>
}

export {CptItemEdit}