import { Button, Dialog, DialogContent, MenuItem, Stack, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleProcedureDetailDialog } from "../../../../../app/slices/ScheduleModuleSlice";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DefaultProcedure } from "../../../../../services/scheduleService/model/schedule/Procedure";
import { useCenterFindQuery, useServiceListQuery } from "../../../../../services/orgService/org-api";
import { NestedUpdate,editProcedure, removeProcedure } from "../../../../../services/scheduleService/model/ScheduleSlice";
import dayjs from "dayjs";


type ownProps={
    center:string;
}

function ProcedureDetail(props:ownProps){
    const dispatch=useAppDispatch();
    const isOpen = useAppSelector((state)=>state.scheduleModule.ProcedureDetailDialog);
    const services = useServiceListQuery(props.center);
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;
    let servicesCopy = services.data === undefined?[]:[...services.data];

    const [proc,setProc] = useState(DefaultProcedure());
    const selectedProc = useAppSelector((state)=>state.scheduleModule.SelectedProcedure);
    const data = useAppSelector((state)=>state.schedule.schedule.procedures.find(p => p.id == selectedProc));
    useEffect(()=>setProc(data?? DefaultProcedure()),[data,selectedProc]);

    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogContent>
                <Stack direction='row' gap={1} marginBottom='10px'>
                    <TextField label='Room' name='room' value={data?.room} disabled={readonly}
                        onChange={(e)=>dispatch(editProcedure(new NestedUpdate(selectedProc,'room',e.target.value)))}/>
                </Stack>

                <Stack gap={1} justifyContent='flex-start'>
                    <TextField label='Service' sx={{width:'225px'}} value={proc.serviceCode} select disabled={readonly}
                        onChange={(e)=> dispatch(editProcedure(new NestedUpdate(selectedProc,'serviceCode',e.target.value)))}>
                        {servicesCopy?.sort((a,b)=>a.code>b.code?1:-1).map(i =>
                            (<MenuItem key={i.code} value={i.code}>{i.code}</MenuItem>))}
                    </TextField>
                    <Typography variant='body2'>{servicesCopy.find(p => p.code == data?.serviceCode)?.description}</Typography>
                </Stack>


                <Stack direction='row' gap={1} marginTop='10px' paddingTop='10px' borderTop='solid grey 3px'>
                    <DateTimePicker label='Start Time' value={dayjs(data?.startTime)} disabled={readonly}
                            onChange={(e)=>dispatch(editProcedure(new NestedUpdate(selectedProc,'startTime', e!)))}/>
                    <DateTimePicker label='End Time' value={dayjs(data?.endTime) || null} disabled={readonly}
                            onChange={(e)=>dispatch(editProcedure(new NestedUpdate(selectedProc,'endTime', e!)))}/>         
                </Stack>


            </DialogContent>
            <Stack direction='row' justifyContent='space-between' marginLeft='10px'>
                    <div>
                        {!readonly &&
                        <Button color='error' onClick={()=>{dispatch(removeProcedure(selectedProc)); dispatch(toggleProcedureDetailDialog(''))}}>Delete</Button>}
                    </div>
                    <Button onClick={()=>dispatch(toggleProcedureDetailDialog(''))}>{readonly?'Close':'Save'}</Button>
                </Stack>
        </Dialog>
    </React.Fragment>
}

export{ProcedureDetail}

