import React, { useState } from "react";
import { useAppSelector } from "../../../../../../app/hooks";
import { process, State } from "@progress/kendo-data-query";
import { ColumnMenu } from "../../../../../../utils/ColumnMenu";
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { VarianceDocumentCommands } from "./VarianceDocumentCommands";
import { VarianceUploadBtn } from "./VarianceUploadBtn";
import { Stack } from "@mui/material";

function VarianceDocumentList() {
const variance = useAppSelector ((state)=>state.varianceRequest.varianceRequest);
const [dataState, setDataState] = useState<State>({});
const mapDocuments=()=>{
    return variance.documents.map(i => ({...i, uploadDate: new Date(i.uploadDate ?? '')}));
}
const createDataState = (dataState: State) => {
    return {
      dataState: dataState === undefined ? {} :dataState,};
  }; 
const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setDataState(updatedState.dataState);
  };

return (
    <React.Fragment>
        <Stack direction='row' spacing={2} justifyContent='flex-end' margin='20px'>
        <VarianceUploadBtn/>
        </Stack>
       <Grid data={process(mapDocuments().filter(p => p.isDeleted !== true), dataState)} {...dataState}
            onDataStateChange={dataStateChange} style={{height:'500px'}}>
                <Column cell={VarianceDocumentCommands} width='100px'/>
                <Column title='File Name' field='fileName' columnMenu={ColumnMenu} width='450px'/>
                <Column title='Upload By' field='uploadedBy' columnMenu={ColumnMenu} width='250px'/>
                <Column title='Upload Date' field='uploadDate' columnMenu={ColumnMenu} width='250px' format='{0:MM/dd/yyyy}'/>
        </Grid>
    </React.Fragment>
);
}

export { VarianceDocumentList };