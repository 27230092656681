import { Chip, Stack, Typography } from "@mui/material"
import { formatNumber } from "@progress/kendo-intl"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks";

function TotalImplantCost(){
    const account = useAppSelector((state)=> state.account.account);
    return <React.Fragment>
        <Stack alignItems='center'>
            <Typography sx={{fontWeight:'500', color:'gray'}} variant="body1">Implant Cost</Typography>
            <Chip variant='outlined' 
                label={formatNumber(account.expectedProcedures.map(i => i.implantCost).reduce((a,b) => a+b,0),'c')}
                sx={{color:'#62BCBD'}}/>
        </Stack>
    </React.Fragment>
}
export {TotalImplantCost}