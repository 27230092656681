import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { GridButton } from "../../../../components"
import { useNavigate } from "react-router"

function AccountCenterSummaryCommandCell(props: GridCellProps){
    const navigate = useNavigate();
    return <React.Fragment>
        <GridButton onClick={() => {navigate('/RevRecCenterDashboard/' + props.dataItem.center + '/' + props.dataItem.period + '/' + props.dataItem.year)}}>View</GridButton>
    </React.Fragment>
}
export {AccountCenterSummaryCommandCell}