import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { Avatar, Stack, Typography } from "@mui/material";
import { Assignment } from "@mui/icons-material";
import { formatDate } from "@progress/kendo-intl";

function RefundNoteResults(){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const convertedNotes = () => {
        let p = refund?.notes.map((obj) => {
            return {...obj, addDate: new Date(obj.addDate)};
        }); return p;}

    return <React.Fragment>
        {convertedNotes().slice()
        .sort((a,b) => b.addDate.getTime() - a.addDate.getTime())
        .map(i => (
            <div key={i.id} style={{borderBottom:'2px solid #62BCBD', marginBottom:'5px', paddingBottom:'5px'}}>
              <Stack direction='row' alignItems='center' gap={2} marginLeft='10px'>
                <Avatar style={{backgroundColor:'#62BCBD'}}><Assignment/></Avatar>
                <Typography>{i.addedBy + ' on ' + formatDate(new Date(i.addDate ??''),'MM/dd/yyyy hh:mm')}</Typography>
              </Stack>
              <Typography marginLeft='70px'>{i.text}</Typography>

            </div>
        ))}
    </React.Fragment>
}
export{RefundNoteResults}