import { Add, Search, Upload } from "@mui/icons-material"
import { Alert, Button, TextField } from "@mui/material"
import { Stack } from "@mui/system"
import React, { useState } from "react"
import { useParams } from "react-router"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleCptScheduleItemAddModal, toggleCptUploadModal } from "../../../../../app/slices/ContractModuleSlice"
import { CptSearchRequest } from "../../../../../services/contractService/commands";
import { useCptSearchQuery } from "../../../../../services/contractService/contract-api";
import { CptItemAdd } from "./CptItemAdd";
import { CptItemEdit } from "./CptItemEdit";
import { CptItemList } from "./CptItemList";
import { CptUpload } from "./CptUpload";

function CptCodes(){
    const {feeScheduleId} = useParams();
    const feeSummary = useAppSelector((state)=> state.feeSchedule.feeSchedule.feeSummary)
    const dispatch = useAppDispatch();
    const [criteria,setCriteria] = useState('');
    const [skip,setSkip] = useState(true);
    const {data,isLoading} = useCptSearchQuery(new CptSearchRequest(feeScheduleId!,criteria),{skip,})

    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' marginRight='10px'
            marginTop='10px' marginBottom='10px' marginLeft='5px' gap={1}>
            <div>
                <TextField label='Code Lookup' size='small' value={criteria} 
                    onChange={(e)=>{setSkip(true); setCriteria(e.target.value)}}
                    onKeyDown={(e)=>{if(e.key==='Enter') setSkip(false)}} sx={{marginRight:'5px'}}/>
                <Button startIcon={<Search/>} size='large' variant="outlined" disabled={criteria.length <3} onClick={()=>setSkip(false)}></Button>
            </div>
            <div>
                <Button variant='outlined' startIcon={<Add/>} onClick={()=>dispatch(toggleCptScheduleItemAddModal())} sx={{marginRight:'5px'}}>Add Code</Button>
                <Button variant='outlined' startIcon={<Upload/>} onClick={()=>dispatch(toggleCptUploadModal())}>Upload Codes</Button>
            </div>
        </Stack>
        {(data?.length??0) > 0 &&
        <CptItemList data={data ?? []}/>
        }
        {((data?.length??0) === 0) && 
            <Alert color="info">Enter a code or description to search.</Alert>}

        
        <Stack marginTop='10px' marginLeft='10px' marginRight='10px' gap={2}>
            {feeSummary.uploadInProgress === true &&
                <Alert color='warning'>A fee schedule upload is currently in progress.  Refresh this page for updates.</Alert>
            }
            {feeSummary.isLastUploadSuccess === false &&
                <Alert color='error'>An error occured during the last fee schedule upload. Please check your upload file and resubmit.<br/> {feeSummary.uploadErrorMessage}</Alert>
            }
        </Stack>
        <CptItemAdd/>
        <CptItemEdit/>
        <CptUpload/>
    </React.Fragment>
}   

export{CptCodes}