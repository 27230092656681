import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { MenuItem, TextField } from "@mui/material";
import { editRefundDetails } from "../../../../../services/refundService/model/refundRequest/RefundRequestSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { RefundLocked } from "../../../specs/RefundLocked";

function OpenBalancesSelector(){
    const dispatch = useAppDispatch();
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    
    return <React.Fragment>
        <TextField label='Open balances' select sx={{width:'225px'}}
            value={refund.refundDetail.openBalances}
            disabled={new RefundLocked().IsSatisfiedBy(refund)}
            onChange={(e)=>dispatch(editRefundDetails(new ReduxUpdate('openBalances',e.target.value)))}>
                <MenuItem value={false as any}>No</MenuItem>
                <MenuItem value={true as any}>Yes</MenuItem>
            </TextField>
    </React.Fragment>
}

export {OpenBalancesSelector}