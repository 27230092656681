import React, { useState } from "react"
import { useMyProfileQuery } from "../../services/profileService/profile-api";
import { useNavigate } from "react-router";
import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material";

type ownProps={
    navigateTo: string;
}

function CenterSelector(props:ownProps){
    const profile = useMyProfileQuery();
    const access = profile.data === undefined ? [] : [...profile.data?.centerAccess];
    const [selectedCenter, setSelectedCenter] = useState('Unk');
    const navigate = useNavigate();
    
    return <React.Fragment>
        <Stack alignItems='center' gap={2}>
            <Typography variant='h6'>Select a Center</Typography>
            <Stack direction='row' justifyContent='center' justifyItems='center' gap={1}>
                <TextField select sx={{width:'500px',background:'white','& legend': { display: 'none' }, '& fieldset': { top: 0 }}} 
                    value={selectedCenter} 
                    onChange={(e)=>setSelectedCenter(e.target.value)}>
                        <MenuItem value='Unk'> </MenuItem>
                            {access !== undefined && access?.sort((a,b) => a.name > b.name ? 1 : -1).map(i => (
                            <MenuItem key={i.code} value={i.code}>{i.name + ' - ' + i.code}</MenuItem>
                        ))}   
                </TextField>
                <Button variant="outlined" onClick={()=> navigate(props.navigateTo +'/' + selectedCenter)}>Select</Button>
                
                </Stack>
        </Stack>
    </React.Fragment>
}

export {CenterSelector}