import { GridCellProps } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toggleIncidentAssignUserDialog } from "../../../../../../../app/slices/VarianceModuleSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { GridButton } from "../../../../../../../components";
import { IncidentAssignUserCommand } from "../../../../../../../services/varianceService/commands/IncidentAssignUserCommand";
import { useIncidentAssignUserMutation } from "../../../../../../../services/varianceService/variance-api";
import { Alert, Snackbar } from "@mui/material";
import { AssignedUser } from "../../../../../../../services/varianceService/model/varianceRequest/AssignedUser";
import { useMyProfileQuery } from "../../../../../../../services/profileService/profile-api";

function LookupIncidentAssignUserGridCommand(props: GridCellProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [assign, status] = useIncidentAssignUserMutation();
  const variance = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const profile = useMyProfileQuery();
  const onSelect = () => {
    setTimeout(() => {
      dispatch(toggleIncidentAssignUserDialog());
      navigate(-1);
    }, 3000);
    let assignedTo: AssignedUser = {
      upn: props.dataItem?.upn,
      displayName: props.dataItem?.identity,
      email: props.dataItem?.email,
      assignedBy: profile.data?.identity ?? "",
      assignedByEmail: profile.data?.email ?? "",
    };
    let command = new IncidentAssignUserCommand(variance.id, assignedTo);
    assign(command)
      .unwrap()
      .then(() => {});
  };
  const [notify, setNotify] = useState(false);
  useEffect(() => {
    setNotify(status.isSuccess);
  }, [status.isSuccess]);

  return (
    <React.Fragment>
      <GridButton
        className="ms-1 mt-1"
        variant="outlined"
        color="primary"
        onClick={() => {
          onSelect();
        }}
      >
        Assign
      </GridButton>
      <Snackbar
        open={notify}
        onClose={() => setNotify(false)}
        color="success"
        autoHideDuration={6000}
        message={"Incident Assigned to " + props.dataItem?.identity}
      />
      <Snackbar open={status.isError}>
        <Alert color="error">
          {"An error has occured: " + JSON.stringify(status.error)}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export { LookupIncidentAssignUserGridCommand };
