import { Stack, Typography } from "@mui/material"
import { MultiSelect } from "@progress/kendo-react-dropdowns"
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { editPolicyDetails } from "../../../../services/caseManService/model/policy/PolicySlice";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";

function InsuranceLinking(){
    const insurances = ['Aetna','Aetna Medicare','Aetna Medicaid','Amerigroup','BCBS BAV','BCBS Blue Essential','BCBS HMO','BCBS Medicaid','BCBS Medicare',
    'BCBS NWB','BCBS PPO','BCBS Trad','Bundled','Care N Care','Cigna','Cigna Healthspring','Cigna Medicare','Corvel','Endeavor','HD Research','Humana',
    'Humana Medicare','LOP','Medicaid','Medicare','MPower','Multiplan','Multiplan Comm','Multiplan WC','Oscar','Other Commercial','Research Source',
    'Superior Health Plan','Self-Pay','SWHP Comm','SWHP Managed Medicare','SWHR','Three Rivers','Tricare','TriWest','UHC','UHC Medicaid','UHC Medicare',
    'Wellcare','Wellmed','Workers Comp'];

    const dispatch = useAppDispatch();
    const policy = useAppSelector((state)=>state.policy.policy);

    return <React.Fragment>
        
        <Stack>
            <div style={{color:'grey'}}>Enter all insurances this policy applies to</div>
            <MultiSelect fillMode='outline' rounded='large' style={{width:'450px',marginTop:'0px'}}  placeholder='None' value={policy.effectiveInsurances}
                 data={insurances} onChange={(e)=>dispatch(editPolicyDetails(new ReduxUpdate("effectiveInsurances",e.target.value)))}/>
        </Stack>
    </React.Fragment>
}
export{InsuranceLinking}