import { Schedule } from "../../../services/scheduleService/model/schedule/Schedule";
import { CompositeSpecification } from "../../../utils/Specification";

export class ContainsAtLeastOneService extends CompositeSpecification<Schedule>{
    IsSatisfiedBy(candidate: Schedule): boolean {
        if(candidate.procedures.length >0)
            return true;
        else
            return false;
    }
}