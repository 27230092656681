import {
  FormGroup,
  Box,
  FormLabel,
  TextField,
  FormHelperText,
  Stack,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { editMedicationEventMedDetails } from "../../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { MedicationDetail } from "../../../../../../services/varianceService/model/varianceRequest/MedicationDetail";

function MedicationEventMedDetails() {
  const dispatch = useAppDispatch();
  const medDetailState = useAppSelector(
    (state) => state.varianceRequest.varianceRequest.medicationDetail
  );
  const [medDetail, setMedMedDetail] = useState(medDetailState);
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    let newMedicationEventMedDetail: MedicationDetail = medDetail;
    newMedicationEventMedDetail = { ...newMedicationEventMedDetail, [e.target.name]: e.target.value };
    setMedMedDetail(newMedicationEventMedDetail);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", ml: 3, mr: 3 }}>
        <FormHelperText></FormHelperText>
        <FormLabel component="legend">Medication Details</FormLabel>
        <FormGroup>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "10px",
            }}
          >
            <Stack direction="row" spacing={3}>
              <TextField
                id="standard-basic"
                name="name"
                label="Name of Medication"
                variant="standard"
                value={medDetail.name}
                onChange={(e) => {
                  handleChange(e);
                  dispatch(
                    editMedicationEventMedDetails({name: e.target.name, value: e.target.value})
                  );
                }}
              />
              <TextField
                id="standard-basic"
                name="route"
                label="Route"
                variant="standard"
                select
                value={medDetail.route}
                onChange={(e) => {
                  handleChange(e);
                  dispatch(
                    editMedicationEventMedDetails({name: e.target.name, value: e.target.value})
                  );
                }}
              >
                <MenuItem value="PO">PO</MenuItem>
                <MenuItem value="IM">IM</MenuItem>
                <MenuItem value="SQ">SQ</MenuItem>
                <MenuItem value="IV">IV</MenuItem>
                <MenuItem value="Inhaled">Inhaled</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
              <TextField
                id="standard-basic"
                name="dose"
                label="Total Doses Involved"
                variant="standard"
                type="number"
                inputProps={{ min: 0 }}
                value={medDetail.dose}
                onChange={(e) => {
                  handleChange(e);
                  dispatch(
                    editMedicationEventMedDetails({name: e.target.name, value: e.target.value})
                  );
                }}
              />
            </Stack>
          </Box>
        </FormGroup>
      </Box>
    </React.Fragment>
  );
}
export { MedicationEventMedDetails };
