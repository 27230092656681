import React from "react"
import { useAppSelector } from "../../../../../../app/hooks"
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatDate } from "@progress/kendo-intl";
import { CaseManCallDetailModal } from "../callDetail/CaseManCallDetailModal";

function CaseManCallList(){
    const caseMan = useAppSelector((state)=> state.caseManagement.caseManagement);
    const convertedCalls = () => {
        let calls = caseMan?.callLogs?.map((obj) => {
            return {...obj, callDate: new Date(obj.callDate)};
        })
        return calls;
    }

    return <React.Fragment>
        {caseMan?.callLogs?.length >0 &&
        <TableContainer component={Paper}>
            <Table size='small'>
                <TableHead sx={{backgroundColor:'whiteSmoke'}}>
                    <TableRow>
                        <TableCell width='100px'></TableCell>
                        <TableCell>Call To</TableCell>
                        <TableCell>Reached</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Called By</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {convertedCalls().sort((a,b)=>b.callDate.getTime() - a.callDate.getTime()).map( call =>(
                        <TableRow>
                            <TableCell><CaseManCallDetailModal selectedCall={call.callId}/></TableCell>
                            <TableCell>{call?.callTo}</TableCell>
                            <TableCell>{call?.isCallAnswered===true?'Yes':'No'}</TableCell>
                            <TableCell>{formatDate(new Date(call?.callDate)??'',"MM/dd/yyyy")}</TableCell>
                            <TableCell>{call.callBy}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>}

    </React.Fragment>
}
export {CaseManCallList}