import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@mui/material"
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleRefundDocumentDialog } from "../../../../../app/slices/RefundModuleSlice";
import { Upload } from "@mui/icons-material";
import { RefundService } from "../../../../../utils/ServiceLocator";
import { useParams } from "react-router";
import { DocumentSelector } from "./DocumentSelector";
import { addRefundDocument } from "../../../../../services/refundService/model/refundRequest/RefundRequestSlice";
import { RefundDocument } from "../../../../../services/refundService/model/refundRequest/RefundDocuments";
import { Loading } from "../../../../shared";
import { getToken } from "../../../../../utils/AdalConfig";

function RefundUploadDialog(){
    const {caseId, refundId}=useParams();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state)=>state.refundModule.RefundDocumentDialogOpen);
    const [selectedFile, setSelectedFile] = useState<File|null>();
    const [notify, setNotify] = useState(false)
    const [uploading, setUploading] = useState(false)

    const handleSubmission= async()=>{
        try{
        setUploading(true);
        const formData = new FormData();
        formData.append('File',selectedFile!);
        const response = await fetch(RefundService + 'DocumentUploadEndpoint/DocumentUpload/'+caseId +'/'+refundId,{method:'POST',body:formData, headers:{'Authorization': 'Bearer ' + getToken()}});
        const data:RefundDocument = await response.json();
        dispatch(addRefundDocument(data));
        dispatch(toggleRefundDocumentDialog());
        setUploading(false);
        }
        catch(error){
            setUploading(false);
            setNotify(true);
        }
    };

    return <React.Fragment>
        <Button
            variant='outlined' startIcon={<Upload/>} 
            onClick={()=>dispatch(toggleRefundDocumentDialog())}>Upload</Button>

        <Dialog open={isOpen}>
            <DialogTitle>Upload Document</DialogTitle>
            <DialogContent>
                {uploading && <Loading/>}
                {!uploading &&
                <DocumentSelector file={selectedFile!} setFile={setSelectedFile}/>}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>dispatch(toggleRefundDocumentDialog())}>Cancel</Button>
                <Button onClick={()=>handleSubmission()}>Save</Button>
            </DialogActions>
        </Dialog>
        <Snackbar open={notify} onClose={()=>setNotify(false)} color='error' autoHideDuration={6000}>
            <Alert color='error'>{'Cannot upload this document.'}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {RefundUploadDialog}