import React from "react"
import ApprovedIcon from '../../../../../images/ApprovedStamp.png';
import DeniedIcon from '../../../../../images/DeniedStamp.png';
import PendingIcon from '../../../../../images/PendingStamp.png';
import { useAppSelector } from "../../../../../app/hooks";
import { Stack, Typography} from "@mui/material";
import { formatDate } from "@progress/kendo-intl";

function StatusIndicator(){
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    return <React.Fragment>
        <Stack>
        {caseManage.clinicalReviewStatus === 'Approved' ? <img src={ApprovedIcon}  alt='Approved' width='200px'/> : null}
        {caseManage.clinicalReviewStatus === 'Denied' ? <img src={DeniedIcon}  alt='Denied' width='200px'/> : null}
        {caseManage.clinicalReviewStatus === 'Pending' ? <img src={PendingIcon}  alt='Pending' width='200px'/> : null}
        {caseManage.clinicalReviewBy != '' && caseManage.clinicalReviewBy != undefined &&
            <div>
                <Typography variant='subtitle1' color='lightgray'>{'Reveiwed By: ' + caseManage.clinicalReviewBy}</Typography>
                <Typography variant='subtitle1' color='lightgray'>{'Reveiwed On: ' + formatDate(new Date(caseManage.clinicalReviewDate ?? ''),'MM/dd/yyyy')}</Typography>
            </div>
        }
        </Stack>
    </React.Fragment>
}
export {StatusIndicator}