
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { contractApi } from "../../contract-api";
import { FeeSchedule } from "./FeeSchedule";

interface FeeScheduleState{
    feeSchedule:FeeSchedule;
}

const initialState:FeeScheduleState = {feeSchedule: new FeeSchedule()}
export const FeeScheduleSlice = createSlice({
    name:'feeSchedule',
    initialState,
    reducers:{
        editFeeScheduleDetails:(state, action:PayloadAction<React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | MultiSelectChangeEvent>)=>{
            const {name,value} = action.payload.target;
            //@ts-ignore
            state.feeSchedule[name] = value;
        },
        editfeeSchedEffectiveDate:(state, action:PayloadAction<Date|null>) => {
            state.feeSchedule.effectiveDate = action.payload === null ? undefined : new Date(action.payload);
        },
        editFeeSchedEndDate:(state, action:PayloadAction<Date|null>) => {
            state.feeSchedule.endDate = action.payload === null ? undefined : new Date(action.payload);
        }

    },
    extraReducers:(builder) => {
        builder.addMatcher(
            contractApi.endpoints.grouperAdd.matchFulfilled,
            (state,{payload}) => {state.feeSchedule.groupers.push(payload)}
        ).addMatcher(
            contractApi.endpoints.feeScheduleFind.matchFulfilled,
            (state,{payload})=>{state.feeSchedule = payload}
        ).addMatcher(
            contractApi.endpoints.grouperEdit.matchFulfilled,
            (state,{payload}) => {
                const newGrouper = state.feeSchedule.groupers.find(i => i.id === payload.id);
                if(newGrouper){
                    newGrouper.code = payload.code;
                    newGrouper.rate = payload.rate;
                    newGrouper.isPerDiem = payload.isPerDiem;
                }          
            }
        ).addMatcher(
            contractApi.endpoints.grouperDelete.matchFulfilled,
            (state,{payload}) => {
                let newGroupers = state.feeSchedule.groupers.filter(p => p.id != payload.id)
                state.feeSchedule.groupers = newGroupers;
            }
        )
    }
})

export const {editFeeScheduleDetails, editFeeSchedEndDate, editfeeSchedEffectiveDate} = FeeScheduleSlice.actions;
export default FeeScheduleSlice.reducer;
