import { Button, Stack } from "@mui/material"
import React from "react"
import { useParams } from "react-router"
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";

function AuthInitiateBtn(){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    const showButton=()=>{
        if(schedule.data?.admissionSource?.toUpperCase() === 'CAT SCAN OUTPATIENT'){return true;}
        if(schedule.data?.admissionSource?.toUpperCase() === 'CT SCAN'){return true;}
        if(schedule.data?.admissionSource?.toUpperCase() === 'MRI'){return true;}
        if(schedule.data?.admissionSource?.toUpperCase() === 'MRI OUTPATIENT'){return true;}
        if(schedule.data?.admissionSource?.toUpperCase() === 'RADIOLOGY'){return true;}
        return false;
    }

    return <React.Fragment>
        {showButton() &&
        <Stack marginRight='10px'>
        <a href={"https://psn-services-api.recondohealth.net/ai/#/worklist/" + id} target="_blank">
            <Button sx={{color:'#62BCBD'}} variant='outlined' color='primary'>Initiate Auth</Button>
        </a>
        </Stack>}
    </React.Fragment>
}
export {AuthInitiateBtn}