import {
  TextField,
  Box,
  FormGroup,
  FormHelperText,
  FormLabel,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../app/hooks";
import { IncidentTypeDetail } from "../../../../../services/varianceService/model/varianceRequest/IncidentTypeDetail";
import { editIncidentTypeDetails } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import "../../../styles/IncidentSubTypeStyle.css";

interface ISubtypeOptions {
  options: { name: string; description: string }[];
  formName: string;
  freeformName?: string;
  freeformLabel?: string;
  helperText?: number;
  canEdit: boolean;
}

function IncidentSubTypes({
  options,
  formName,
  freeformName,
  freeformLabel,
  helperText = 0,
  canEdit,
}: ISubtypeOptions) {
  const dispatch = useDispatch();
  const incidentTypeDetails = useAppSelector(
    (state) => state.varianceRequest.varianceRequest.incidentTypeDetails
  );
  const IncidentSubTypes: IncidentTypeDetail[] = options.map((option) => ({
    name: option.name,
    checked:
      incidentTypeDetails.find((x) => x.name === option.name)?.checked ?? false,
    description: option.description,
  }));
  const [SubTypeOptions, setSubTypeOptions] = React.useState({
    IncidentSubTypes,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const incidentTypeDetail: IncidentTypeDetail = {
      name: event.target.name,
      checked: event.target.checked,
      description:
        (event.target.labels && event.target.labels[0]?.textContent) || "",
    };
    setSubTypeOptions((prevOptions) => ({
      ...prevOptions,
      IncidentSubTypes: prevOptions.IncidentSubTypes.map((option) =>
        option.name === event.target.name
          ? { ...option, checked: event.target.checked }
          : option
      ),
    }));
    dispatch(editIncidentTypeDetails(incidentTypeDetail));
  };
  const [freeForm, setFreeForm] = React.useState(
    incidentTypeDetails?.find((x) => x.name === freeformName)?.description
  );
  const setOther = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const incidentTypeDetail: IncidentTypeDetail = {
      name: event.target.name,
      checked: true,
      description: event.target.value,
    };
    setFreeForm(incidentTypeDetail.description);
    dispatch(editIncidentTypeDetails(incidentTypeDetail));
  };

  return (
    <React.Fragment>
      <Box
        mt={2}
        mb={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: 3,
          overflowX: "auto",
        }}
      >
        <FormHelperText>
          {helperText === 0
            ? ""
            : helperText === 1
            ? "Please select only one option"
            : "Select One or More"}
        </FormHelperText>
        <FormLabel component="legend">{formName}</FormLabel>
        <FormGroup>
          {SubTypeOptions.IncidentSubTypes.map((value) => (
            <FormControlLabel
              key={value.name}
              label={value.description}
              control={
                <Checkbox
                  disabled={!canEdit}
                  checked={value.checked || false}
                  name={value.name}
                  onChange={(e) => handleChange(e)}
                />
              }
            />
          ))}
          {freeformName !== undefined && (
            <TextField
              disabled={!canEdit}
              id="standard-basic"
              name={freeformName}
              label={freeformLabel || "Other"}
              variant="standard"
              value={freeForm}
              onChange={setOther}
            />
          )}
        </FormGroup>
      </Box>
    </React.Fragment>
  );
}

export { IncidentSubTypes };
