import { Button, Snackbar, Stack } from "@mui/material"
import React, { useState } from "react"
import { RefundRequestBack } from "./RefundRequestBack"
import { RefundRequestSave } from "./RefundRequestSave"
import { useAppSelector } from "../../../../../app/hooks"
import { useMyProfileQuery } from "../../../../../services/profileService/profile-api"
import { RefundApproveBtn, RefundCancelBtn, RefundCompletedBtn, RefundHeldBtn, RefundReIssueBtn, RefundRejectBtn, RefundResubmitBtn, RefundSubmitForApproval, RefundToApBtn, RefundToPostingBtn } from "./statusChanges"

function RefundRequestCommands(){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const [notify, setNotify] = useState(false);
    const [message, setMessage] = useState('');
    const profile = useMyProfileQuery();
    const isApprover=()=>{
        let me = profile.data?.identity;
        let isApprover = refund?.approvals.find((i)=>i.approver == me);
        if(!isApprover) return false;
        return true;
    }
    
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' gap={1} marginBottom={'5px'}>
            <RefundRequestBack/>
            <Stack direction='row' gap={1}>
            {refund.status === 'STARTED' && 
            <Stack direction='row' gap={1}>
                <RefundRequestSave setNotify={setNotify} setMessage={setMessage}/>
                <RefundSubmitForApproval setNotify={setNotify} setMessage={setMessage}/>
                <RefundCancelBtn setNotify={setNotify} setMessage={setMessage}/>
            </Stack>}
            
        {(refund.status == 'APPROVE')&& isApprover() &&
        <Stack direction='row' gap={1}>
            <RefundRequestSave setNotify={setNotify} setMessage={setMessage}/>
            <RefundHeldBtn setNotify={setNotify} setMessage={setMessage}/>
            <RefundApproveBtn setNotify={setNotify} setMessage={setMessage}/>
            <RefundRejectBtn setNotify={setNotify} setMessage={setMessage}/>
        </Stack>}   

        {refund.status == 'REJECT' &&
            <Stack direction='row' gap={1}>
                <RefundRequestSave setNotify={setNotify} setMessage={setMessage}/>
                <RefundResubmitBtn setNotify={setNotify} setMessage={setMessage}/>
                <RefundCancelBtn setNotify={setNotify} setMessage={setMessage}/>
            </Stack>
        }
        {refund.status == 'AP' &&
            <Stack direction='row' gap={1}>
                <RefundRequestSave setNotify={setNotify} setMessage={setMessage}/>
            </Stack>
        }
        {refund.status == 'POSTING' &&
            <Stack direction='row' gap={1}>
                <RefundRequestSave setNotify={setNotify} setMessage={setMessage}/>
                <RefundCompletedBtn setNotify={setNotify} setMessage={setMessage}/>
            </Stack>
        }
{/*         {refund.status == 'COMPLETE' &&
            <Stack direction='row' gap={1}>
                <RefundReIssueBtn setNotify={setNotify} setMessage={setMessage}/>
            </Stack>
        }
        {refund.status == 'REISSUE' &&
            <Stack direction='row' gap={1}>
                <RefundCompletedBtn setNotify={setNotify} setMessage={setMessage}/>
            </Stack>
        } */}
        {refund.status == 'HOLD' &&
            <div>
            {(refund.approvals.filter(p => p.isApproved == false).length > 0) &&
                <Stack direction='row' gap={1}>
                    <RefundSubmitForApproval setNotify={setNotify} setMessage={setMessage}/>
                </Stack>}
            {(refund.approvals.filter(p => p.isApproved == false).length == 0) && 
                <Stack direction='row' gap={1}>
                    <RefundToApBtn setNotify={setNotify} setMessage={setMessage}/>
                </Stack>}
            </div>
        }
        {refund.status === 'CREDIT_CARD' &&
            <Stack direction='row' gap={1}>
                <RefundRequestSave setNotify={setNotify} setMessage={setMessage}/>
                <RefundToPostingBtn setNotify={setNotify} setMessage={setMessage} Label='Card Refunded'/>
            </Stack>
        }
        
        <Snackbar open={notify} onClose={()=>setNotify(false)} color='success' autoHideDuration={6000} message={message}/>
            </Stack>
        </Stack>
    </React.Fragment>
}

export {RefundRequestCommands}