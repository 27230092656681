import { Alert, Button, Snackbar } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { useSaveAccountMutation } from "../../../../../services/accountService/account-api";
import { AccountSaveCommand } from "../../../../../services/accountService/commands/AccountSaveCommand";

function RevRecSaveCommand(){
    const account = useAppSelector((state) =>state.account.account);
    const [saveAccount, status] = useSaveAccountMutation();
    const [notify, setNotify] = useState(false);
    useEffect(()=>{setNotify(status.isSuccess)},[status.isSuccess]);

    const handleSave = ()=>{
        let command = new AccountSaveCommand(account);
        saveAccount(command);
    }
    return <React.Fragment>
        <Button variant='outlined' color="primary" onClick={()=>handleSave()}>Save</Button>

        <Snackbar open={notify} onClose={()=>setNotify(false)} color='success' autoHideDuration={6000} message='Case Saved Successfully!'/>
        <Snackbar open={status.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(status.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}
export {RevRecSaveCommand}