import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { AccountFinalReimbursementList } from "./AccountFinalReimbursementList";
import { AccountFinalImplantCostEdit } from "./AccountFinalImplantCostEdit";
import { Stack } from "@mui/material";

function FinalReimbursement(){
    return <React.Fragment>
        <br/>
        <Stack direction='row' marginBottom='10px' marginLeft='10px' gap={1}>
            <AccountFinalImplantCostEdit/>
        </Stack>
    
        <AccountFinalReimbursementList/>
    </React.Fragment>
}
export {FinalReimbursement}