import React from "react"
import { DrgHeader } from "./DrgHeader"
import { FeeScheduleAdd } from "../feeSchedules/FeeScheduleAdd"
import { FeeScheduleList } from "../feeSchedules/FeeScheduleList"
import { useAppSelector } from "../../../../../app/hooks"
import { Alert } from "@mui/material"

function Drg(){
    const contract = useAppSelector((state)=>state.contract.contract);
    return <React.Fragment>
        {contract.medicareBasedConfig.basedOnMedicare === true &&
            <Alert color='info'>This contract has fee schedules based on Medicare rates. </Alert>
        }
        {contract.medicareBasedConfig.basedOnMedicare === false &&
            <div>
                <DrgHeader/>
                <FeeScheduleList feeScheduleType="DRG"/>
                <FeeScheduleAdd feeScheduleType="DRG"/>
            </div>
        }
    </React.Fragment>
}

export {Drg}