import { CaseManagement } from "../model/caseMan/CaseManagement";

export class CaseManStatusUpdateCommand{
    message: CaseManagement;
    statusTypecode : string;
    newStatus : string;

    constructor(message: CaseManagement, statusTypecode: string, newStatus: string){
        this.message = message;
        this.statusTypecode = statusTypecode;
        this.newStatus = newStatus;
    }
}