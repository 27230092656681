import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Button, Dialog, DialogContent, MenuItem, Stack, TextField } from "@mui/material";
import { toggleInsuranceDetailDialog } from "../../../../../app/slices/ScheduleModuleSlice";
import { useCenterFindQuery, usePayerListQuery } from "../../../../../services/orgService/org-api";
import { DefaultInsurance} from "../../../../../services/scheduleService/model/schedule/Insurance";
import { ScheduleNestedUpdate, editInsurance, removeInsurance } from "../../../../../services/scheduleService/model/ScheduleSlice";

type ownProps ={
    center:string;
}
function InsuranceDetail(props:ownProps){
    const dispatch = useAppDispatch();
    const payers = usePayerListQuery(props.center);
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;
    let payerCopy= payers.data === undefined?[]:[...payers.data];
    const isOpen = useAppSelector((state)=>state.scheduleModule.InsuranceDetailDialog);

    const [insurance, setInsurance] = useState(DefaultInsurance())
    const selectedInsurance = useAppSelector((state)=>state.scheduleModule.SelectedInsurance);
    const data = useAppSelector((state)=>state.schedule.schedule.insurances.find(p => p.id === selectedInsurance));
    useEffect(()=>setInsurance(data ?? DefaultInsurance()),[data,selectedInsurance])

    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogContent>
                <Stack direction='row'>
                    <TextField name='priority' select label='Priority' sx={{width:'225px'}} value={data?.priority}
                        disabled={readonly}
                        onChange={(e)=>dispatch(editInsurance(new ScheduleNestedUpdate(selectedInsurance,e)))}>
                        <MenuItem value={1}>Primary</MenuItem>
                        <MenuItem value={2}>Secondary</MenuItem>
                        <MenuItem value={3}>Tertiary</MenuItem>
                    </TextField>
                </Stack>
                <Stack marginTop='10px'>
                    <TextField name='payerCode' label='Payer' select value={data?.payerCode ??''}
                        disabled={readonly}
                        onChange={(e)=>dispatch(editInsurance(new ScheduleNestedUpdate(selectedInsurance,e)))}>
                        {payerCopy?.sort((a,b)=>a.name > b.name ?1:-1).map(i => 
                            (<MenuItem key={i.code} value={i.code}>{i.name}</MenuItem>))}
                    </TextField>
                </Stack>
                <Stack direction='row' marginTop='10px' gap={1}>
                    <TextField label='Policy' value={data?.policy} name='policy' disabled={readonly}
                        onChange={(e)=>dispatch(editInsurance(new ScheduleNestedUpdate(selectedInsurance,e)))}/>
                    <TextField label='Group' value={data?.groupNumber} name='groupNumber' disabled={readonly}
                        onChange={(e)=>dispatch(editInsurance(new ScheduleNestedUpdate(selectedInsurance,e)))}/>
                </Stack>
            </DialogContent>

                <Stack direction='row' justifyContent='space-between' marginLeft='10px'>
                    <div>
                        {!readonly &&
                        <Button color='error' onClick={()=>{dispatch(removeInsurance(selectedInsurance));dispatch(toggleInsuranceDetailDialog(''))}}>Delete</Button>}
                    </div>
                    <Button color='primary' onClick={()=>dispatch(toggleInsuranceDetailDialog(''))}>{readonly?"Close":"Save"}</Button>
                </Stack>
        </Dialog>
    </React.Fragment>
}

export {InsuranceDetail}