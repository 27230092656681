import { Button, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { RefundDashboardCards } from "../components/RefundDashboardCards"
import { RefundInitiate } from "../features/initiate/RefundInitiate"
import { RefundSearch } from "../features/search/RefundSearch"
import { CenterNameView } from "../../../components/orgComponents"
import { useNavigate, useParams } from "react-router"
import { KeyboardDoubleArrowLeft } from "@mui/icons-material"

function RefundDashboard(){
    const {center} = useParams();
    const navigate = useNavigate();
    return <React.Fragment>
        <Grid container justifyContent='space-between'>
            <Grid item sm={3}>
                <CenterNameView centerId={center!}/>
            </Grid>
            <Grid item sm={3}>
                <RefundSearch/>
            </Grid>
            <Grid item sm={3}>
            <Stack direction='row' gap={1}>
                <RefundInitiate/>
                <Button variant='outlined' startIcon={<KeyboardDoubleArrowLeft/>} color='success' onClick={()=>navigate(-1)}>Back</Button>
            </Stack>
            </Grid>
        </Grid>
        <Stack marginTop={'20px'} >
        <Typography variant='h5' className='psn-green' textAlign='center'>In Progress</Typography>
        <Stack direction='row' justifyContent='center' gap={2}>
            <RefundDashboardCards title='In Process' queryParam='In Process'/>
            <RefundDashboardCards title='Approvals' queryParam='Approval'/>
            <RefundDashboardCards title='Credit Card Refund' queryParam="Credit Card"/>
            <RefundDashboardCards title='Accounts Payable' queryParam='Accounts Payable'/>
            <RefundDashboardCards title='Posting' queryParam='Posting'/>
        </Stack>
        <Typography variant='h5' className='psn-green' textAlign='center' marginTop='20px'>Needs Attention</Typography>
        <Stack direction='row' justifyContent='center' gap={2}>
            <RefundDashboardCards title='Rejected' queryParam='Rejected'/>
            <RefundDashboardCards title='My Approvals' queryParam='My Approvals'/>
            <RefundDashboardCards title='Held' queryParam='Held'/>
        </Stack>
        </Stack>
    </React.Fragment>
}

export {RefundDashboard}