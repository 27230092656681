import { DateField } from "@mui/x-date-pickers"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { editPolicyDetails } from "../../../../services/caseManService/model/policy/PolicySlice";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";
import dayjs from "dayjs";

function PolicyEffDateEdit() {
    const dispatch = useAppDispatch();
    const policy = useAppSelector((state) => state.policy.policy);
    return <React.Fragment>
        <DateField label='Effective Date' value={dayjs(policy.effectiveDate)} 
            onChange={(e)=>dispatch(editPolicyDetails(new ReduxUpdate('effectiveDate',e)))}/>
    </React.Fragment>
}
export {PolicyEffDateEdit}