import React from "react"
import { useProcedureListQuery } from "../../../../services/orgService/org-api";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";

type ownProps={
    center:string;
}

function ProcedureExport(props:ownProps){
    const {data} = useProcedureListQuery(props.center);

    const headers =[
        {label:'CenterId',key:'centerId'},
        {label:'Code',key:'code'},
        {label:'Description',key:'description'},
        {label:'CodeType',key:'codeType'}
    ]
    return <React.Fragment>
        <CSVLink data={data ?? []} headers={headers} filename={'procedure-export.csv'} className='btn' style={{margin:'0px', padding:'0px'}}>
            <Button startIcon={<Download/>} variant='outlined'>Export</Button>
        </CSVLink>
    </React.Fragment>
}

export{ProcedureExport}