import { Alert, Stack, Typography } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { EAR_Alerts } from "./EAR_Alerts";
import { EAR_PayerInfo } from "./EAR_PayerInfo";
import { EAR_Procedures } from "./EAR_Procedures";
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import { useParams } from "react-router";
import { usePayerDetailsQuery } from "../../../../../services/orgService/org-api";
import { PayerForCenterRequest } from "../../../../../services/orgService/commands";

function ElectronicAuthRetrieval(){
    const {id} = useParams();
    const verification = useAppSelector((state)=>state.verification.verification);
    const schedule = useCaseFindQuery(id!);
    const primaryPayer = schedule.data?.insurances.find(p => p.priority === 1)
    const recId= usePayerDetailsQuery(new PayerForCenterRequest(verification.center, primaryPayer?.payerCode??'')).data?.waystarAuthPayer;
    return <React.Fragment>
        {recId === '' &&
            <Alert color='info'>{'Electronic Authorization Retrieval not available for payer ' + primaryPayer?.payer}</Alert>
        }
        {verification?.waystarAuthStatus != null && 
        <div>
        <Stack marginTop={'15px'} gap={1}>
            <Typography className="psn-green" variant='h6'>Electronic Authorization Retrieval</Typography>
            <EAR_Alerts/>
            <EAR_PayerInfo/>
            <EAR_Procedures/>
        </Stack>
        </div>
        }

    </React.Fragment>
}

export {ElectronicAuthRetrieval}