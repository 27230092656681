import React from "react"
import { useParams } from "react-router"
import { useFindCaseManWorklistQuery } from "../../../services/caseManService/caseManage-api";
import { CaseManWorklistRequest } from "../../../services/caseManService/commands";
import { Loading } from "../../shared";
import { CaseManWorklistResults } from "../features/worklist/CaseManWorklistResults";

function CaseManWorklist(){
    const {type,center} = useParams();
    const {isLoading} = useFindCaseManWorklistQuery(new CaseManWorklistRequest(type!,center!));

    return <React.Fragment>
        {isLoading ? <Loading/> :
            <div>
                <CaseManWorklistResults/>
            </div>
        }
    </React.Fragment>
}

export {CaseManWorklist}