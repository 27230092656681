import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { getToken } from "../../utils/AdalConfig";
import { CaseManagementService } from "../../utils/ServiceLocator";
import { CaseManagement } from "./model/caseMan/CaseManagement";
import { CaseManWorklist } from "./model/worklist/CaseManWorklist";
import { CaseManCallAddCommand, CaseManNoteAddCommand, CaseManSearchRequest, CaseManStatusUpdateCommand, CaseManWorklistRequest, PolicyPostCommand } from "./commands";
import { CaseManPostCommand } from './commands/CaseManPostCommand';
import { CaseManNote } from './model/caseMan/CaseManNote';
import { Policy } from './model/policy/Policy';
import { CaseManCallLog } from './model/caseMan/CaseManCallLog';
import { CaseManSearchResults } from './model/searchResults/CaseManSearchResults';

export const caseManageApi = createApi({
    reducerPath: 'caseManagerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: CaseManagementService,
        prepareHeaders:(headers,{getState}) =>{
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }
    }),
    tagTypes:['CaseMan','CaseManWorklist','Policy','CaseManSearch'],
    endpoints:(builder)=>({
        findCaseMan:builder.query<CaseManagement,string>({
            query:(id:string) => 'CaseManage?request=' + id,
            providesTags:['CaseMan']
        }),
        findCaseManWorklist:builder.query<CaseManWorklist[],CaseManWorklistRequest>({
            query:(request:CaseManWorklistRequest) => 'CaseManWorklist?type='+request.type + '&center=' + request.center,
            providesTags:['CaseManWorklist']
        }),
        caseManSearch:builder.query<CaseManSearchResults[],CaseManSearchRequest>({
            query:(request:CaseManSearchRequest) => 'Search?centerId='+request.centerId + '&searchCriteria=' + request.searchCriteria,
            providesTags:['CaseManSearch']
        }),
        caseManageSave:builder.mutation<CaseManagement,CaseManPostCommand>({
            query:(body:CaseManPostCommand) => {
               return {url:'CaseManagement',method:'POST',body:body} 
            },
            invalidatesTags:['CaseMan']
        }),
        caseManNoteAdd:builder.mutation<CaseManNote,CaseManNoteAddCommand>({
            query:(body:CaseManNoteAddCommand) => {
                return{url:'NoteAdd',method:'POST',body:body}
            }
        }),
        caseManCallAdd:builder.mutation<CaseManCallLog, CaseManCallAddCommand>({
            query:(body:CaseManCallAddCommand) => {
                return {url:'AddCall',method:'POST',body:body}
            }
        }),
        caseManStatusUpdate:builder.mutation<CaseManagement,CaseManStatusUpdateCommand>({
            query:(body:CaseManStatusUpdateCommand) => {
                return {url:'StatusChange',method:'POST',body:body}}
        }),
        findPolicy:builder.query<Policy,string>({
            query:(id:string) => 'PolicyDetail?request='+ id,
            providesTags:['Policy']
        }),
        findPolicyWorklist:builder.query<Policy[],string>({
            query:(id:string) => 'PolicyWorklist?request='+ id,
            providesTags:['Policy']
        }),
        policySave:builder.mutation<Policy,PolicyPostCommand>({
            query:(body:PolicyPostCommand) => {
                return {url:'PolicyDetail',method:'POST',body:body}
            },
            invalidatesTags:['Policy']    
        })
    })
})

export const {useFindCaseManQuery,useFindCaseManWorklistQuery, useCaseManageSaveMutation,
                useCaseManNoteAddMutation, useFindPolicyQuery, usePolicySaveMutation,
                useFindPolicyWorklistQuery, useCaseManStatusUpdateMutation,
                useCaseManCallAddMutation, useCaseManSearchQuery} = caseManageApi;