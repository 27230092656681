import { Stack } from "@mui/system";
import React from "react"
import { useParams } from "react-router";
import { Profile } from "../../../services/profileService";
import { useUserProfileQuery } from "../../../services/profileService/profile-api";
import { Loading } from "../../shared";
import { CenterAccess, ClaimsAccess, ProfileHeader } from "../features";

function UserProfile(){
    const {id} = useParams()
    const {data,isLoading} = useUserProfileQuery(id!);
    return <React.Fragment>
         {isLoading === true ?
        <Loading/> :
        <div>
            <ProfileHeader profile={data === undefined ? new Profile() : data}/>
            <br/>
            <Stack>
                <CenterAccess profile={data === undefined ? new Profile() : data}/>
                <br/><br/>
                <ClaimsAccess profile={data === undefined ? new Profile() : data}/>
            </Stack>
        </div>
        }
    </React.Fragment>
}

export {UserProfile}