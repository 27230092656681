import { CenterAccess } from "./CenterAccess";
import { ClaimsAccess } from "./ClaimsAccess";

export class Profile {
    id:string = '';
    upn:string='';
    identity:string='';
    lastName:string='';
    firstName:string='';
    fullName:string='';
    role:string='';
    roleDescription:string='';
    isAdmin:boolean=false;
    email:string='';
    centerAccess:CenterAccess[] = [];
    claimsAccess:ClaimsAccess[] = [];
}