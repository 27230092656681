import React from "react"
import { Lookup } from "../features/dashboard/Lookup";
import { Stack} from '@mui/system';
import { DashboardCard } from "../../../components";
import { Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useCenterFindQuery } from "../../../services/orgService/org-api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Dashboard(){
    const {center} = useParams();
    const selectedCenter = useCenterFindQuery(center!);
    const navigate = useNavigate();
    return <React.Fragment>
        <div>
            <Stack direction='row' justifyContent='space-around' alignItems='center'>                
                <Typography color='#62BCBD' variant="h5">{selectedCenter.data?.shortName}</Typography>
                <Lookup/>
                <Button variant='outlined' onClick={()=>navigate('/VerifyHome')} color='success'><FontAwesomeIcon icon={'arrow-left'}/><span className="ms-1">Go Back</span></Button>
            </Stack>
            
            <Stack direction='column' justifyContent='center' alignItems='center'>
                <Typography variant='h6' color='#62BCBD' sx={{marginTop:'10px'}}>In Process</Typography>
                <Stack direction='row' gap={2} alignItems='center'>
                    <DashboardCard title="Total In Process" queryParam="In Process" center={center!}/>
                    <DashboardCard title='Benefits' queryParam="Benefits" center={center!}/>
                    <DashboardCard title='Authorization' queryParam="Authorization" center={center!}/>
                    <DashboardCard title='Financial' queryParam="Financial Orientation" center={center!}/>
                    <DashboardCard title='Awaiting Service' queryParam="Complete" center={center!}/>
                </Stack>
                <Typography variant='h6' color='#62BCBD' sx={{marginTop:'10px'}}>Needs Attention</Typography>
                <Stack direction='row' gap={2} alignItems='center'>
                    <DashboardCard title="Patient Info" queryParam="Invalid Patient" center={center!}/>
                    <DashboardCard title='Invalid Insurance' queryParam="Invalid Insurance" center={center!}/>
                    <DashboardCard title='Denied Auth' queryParam="Authorization Denied" center={center!}/>
                    <DashboardCard title='Same Day Add' queryParam="Same Day Add" center={center!}/>
                    <DashboardCard title='Low Contribution' queryParam="Low Contribution Margin" center={center!}/>
                </Stack>
                <Stack direction='row' gap={2} alignItems='center'>
                    <DashboardCard title='Single Case Agree.' queryParam="Single Case Agreement" center={center!}/>
                    <DashboardCard title='$0 Reimb. Cases' queryParam="Zero Reimbursement" center={center!}/>
                    <DashboardCard title='Implant Cost' queryParam="Implant Cost" center={center!}/>
                </Stack>
            </Stack>
            <br/>

            {/* <PriorityVerification/> */}
        </div>
    </React.Fragment>
}

export {Dashboard}