import { Stack } from "@mui/material"
import React from "react"
import { AccountOPAAddDialog } from "./AccountOPAAddDialog"
import { useFindAdjustmentReasonsQuery } from "../../../../../services/accountService/account-api"
import { AccountAdjustmentList } from "./AccountAdjustmentList"

function OutOfPeriodAdjustment(){
    

    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' margin='5px'>
            <AccountOPAAddDialog/>
        </Stack>
        <AccountAdjustmentList/>
    </React.Fragment>
}
export {OutOfPeriodAdjustment}