import { Stack } from "@mui/material"
import React from "react"
import { CheckPayableToEdit } from "./CheckPayableToEdit"
import { CheckAmountView } from "./CheckAmountView"
import { CheckPayAddressEdit } from "./CheckPayAddressEdit"
import { CheckNumberEdit } from "./CheckNumberEdit"
import { CheckDateEdit } from "./CheckDateEdit"
import { CheckReissueAlert } from "./CheckReissueAlert"

function CheckInfo(){
    return <React.Fragment>
        <CheckReissueAlert/>
        <Stack direction='row' gap={1} marginTop='20px' marginLeft='10px'>
            <CheckNumberEdit/>
            <CheckDateEdit/>
        </Stack>
        <Stack direction='row' gap={1} marginTop='20px' marginLeft='10px'>
            <CheckPayableToEdit/>
            <CheckAmountView/>
        </Stack>
        <Stack direction='row' gap={1} marginTop='20px' marginLeft='10px'>
            <CheckPayAddressEdit/>
        </Stack>
    </React.Fragment>
}

export {CheckInfo}