import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { MenuItem, TextField } from "@mui/material";
import { useCenterFindQuery } from "../../../../../services/orgService/org-api";
import { editScheduleV2 } from "../../../../../services/scheduleService/model/ScheduleSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

type ownProps={center:string}

function AdmissionSourceSelector(props:ownProps){
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state)=>state.schedule.schedule);
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;
    
    return <React.Fragment>
        <TextField label='AdmissionSource' select sx={{width:'225px',maxWidth:'100%'}}
            value={schedule.admissionSource} disabled={readonly}
            onChange={(e)=>dispatch(editScheduleV2(new ReduxUpdate('admissionSource',e.target.value)))}>
                <MenuItem value='OP Surgery'>OP Surgery</MenuItem>
                <MenuItem value='Inpatient'>Inpatient</MenuItem>
                <MenuItem value='Cancelled'>Cancelled</MenuItem>
                <MenuItem value='ER'>ER</MenuItem>
                <MenuItem value='Imaging'>Imaging</MenuItem>
                <MenuItem value='Other'>Other</MenuItem>
                <MenuItem value='Therapy'>Therapy</MenuItem>
                <MenuItem value='Surgery'>Surgery</MenuItem>
            </TextField>
    </React.Fragment>
}

export {AdmissionSourceSelector}