import React from "react"
import { useMyProfileQuery } from "../../../../services/profileService/profile-api";
import { toggleCenterAccessModal } from "../../../../app/slices/ProfileModuleSlice";
import { useAppDispatch } from "../../../../app/hooks";
import { CenterAccessEdit } from "./CenterAccessEdit";
import { CenterAccessList } from "./CenterAccessList";
import { Profile } from "../../../../services/profileService";
import { Button, Card, Stack } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

type ownProps ={
    profile:Profile;
}
function CenterAccess(props:ownProps){
    const {data} = useMyProfileQuery();
    const dispatch = useAppDispatch();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin' || data?.role === 'RevCycle Admin'|| data?.role==='Incident Admin'){
            return true;
        }
        return false;
    }
    return <React.Fragment>
        <Card sx={{padding:'5px', borderRadius:'10px'}}>
                <Stack direction='row' justifyContent='space-between' alignItems='flex-end' marginTop='10px' marginBottom='5px' paddingBottom='5px' borderBottom='3px solid gray'>
                    <h5>Center Access</h5>
                    {canAccess() && <Button variant="outlined" color='primary' size='large' 
                        onClick={()=> dispatch(toggleCenterAccessModal())} startIcon={<AddCircleOutlineIcon/>}>
                        Add</Button>}    
                </Stack>
                <CenterAccessList profile={props.profile}/>
        </Card>
        <CenterAccessEdit profile={props.profile}/>
    </React.Fragment>
}

export {CenterAccess}