import { SelectChangeEvent } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { contractApi } from "../../contract-api";
import { Contract } from "./Contract";
import dayjs from "dayjs";

interface ContractState{
    contract:Contract;
}

const initialState:ContractState = {contract: new Contract()}
export const ContractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers:{
        editEffectiveDate:(state, action:PayloadAction<Date|null>) => {
            state.contract.effectiveDate = action.payload === null ? undefined : new Date(action.payload!);
        },
        editEndDate:(state, action:PayloadAction<Date|null>) =>{
            state.contract.endDate = action.payload === null ? undefined : new Date(action.payload!);
        },
        editContractDetails:(state,action:PayloadAction<React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>| SelectChangeEvent<string>>) =>{
            const{name,value} = action.payload.target;
            //@ts-ignore
            state.contract[name] = value;
        },
        editMultipliers:(state, action:PayloadAction<React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>>) =>{
            const{name,value} = action.payload.target;
            //@ts-ignore
            state.contract.multipliers[name] = value == '' ? 0 : value;
        },
        editUnspecified:(state,action:PayloadAction<React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>>)=>{
            const{name,value} = action.payload.target;
            //@ts-ignore
            state.contract.unspecified[name] = value == '' ? null : value;
        },
        editImplants:(state,action:PayloadAction<React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|SelectChangeEvent<string>>) =>{
            const{name,value} = action.payload.target;
            //@ts-ignore
            state.contract.implants[name]=value == '' ? null : value;
        },
        editMedicareBase:(state,action:PayloadAction<React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement> |SelectChangeEvent<string>>) => {
            const{name,value} = action.payload.target;
            if(name === 'basedOnMedicare') {state.contract.medicareBasedConfig.basedOnMedicare = !state.contract.medicareBasedConfig.basedOnMedicare}
            else{
            //@ts-ignore
            state.contract.medicareBasedConfig[name] = value}
        }
    },
    extraReducers:(builder) => {
        builder.addMatcher(
            contractApi.endpoints.contractFind.matchFulfilled,
            (state,{payload}) => {state.contract = payload}
        ).addMatcher(
            contractApi.endpoints.centerAttach.matchFulfilled,
            (state,{payload}) => {state.contract.centers.push(payload)}
        ).addMatcher(
            contractApi.endpoints.centerDetach.matchFulfilled,
            (state,{payload}) => {
                let newCenters = state.contract.centers.filter(p => p.code !== payload.code);
                state.contract.centers = newCenters;}
        ).addMatcher(
            contractApi.endpoints.contractUpdate.matchFulfilled,
            (state,{payload}) => {state.contract = payload}
        )
    }
})

export const {editEffectiveDate, editEndDate,editContractDetails, editMultipliers,
    editUnspecified, editImplants, editMedicareBase} = ContractSlice.actions;
export default ContractSlice.reducer;