import React from "react"
import { useAppSelector } from "../../../../../../app/hooks"
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"

function ApprovalNoteView(){
    const verify = useAppSelector((state) => state.verification.verification)
    const [isOpen, setIsOpen] = React.useState(false)

    return <React.Fragment>
        <Button variant = 'outlined' onClick={()=>{setIsOpen(true)}}>
            View Note</Button>    

        <Dialog open={isOpen} onClose={()=>setIsOpen(false)} maxWidth='xl'>
            <DialogTitle>Approval Note</DialogTitle>
            <DialogContent>
                <TextField multiline minRows={5} value={verify.approval.note} sx={{width:'500px'}} disabled/>
                <Stack direction='row' gap={1} justifyContent='flex-end' alignItems='flex-end' marginTop='10px'>
                    <Button variant="outlined" onClick={()=>setIsOpen(false)}>Close</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}
export {ApprovalNoteView}