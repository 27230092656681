import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react"
import { useAppDispatch } from "../../../../../../app/hooks";
import { toggleRuleModal } from "../../../../../../app/slices/ContractModuleSlice";

type ownProps={
    isNewRule:boolean;
    saveCommand: Function;
    deleteCommand:Function;
}

function RuleCommands(props: ownProps){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        {!props.isNewRule &&
        <Stack direction='row' justifyContent='space-between' marginTop='10px'>
            <Button color='error' onClick={()=>{props.deleteCommand();dispatch(toggleRuleModal('0'))}}>Delete</Button>
            <Button onClick={()=>{props.saveCommand();dispatch(toggleRuleModal('0'))}}>Save</Button>
        </Stack>
        }
        {props.isNewRule &&
        <Stack direction='row' justifyContent='flex-end' marginTop='10px'>
            <Button onClick={()=>{props.saveCommand();dispatch(toggleRuleModal('0'))}}>Save</Button>
        </Stack>
        }
        
    </React.Fragment>
}

export {RuleCommands}