import { Card, CardContent, CardHeader, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editImplants } from "../../../../../services/contractService/model/contract/ContractSlice";


function Implants(){
    const dispatch = useAppDispatch();
    const data = useAppSelector((state)=>state.contract.contract);

    return <React.Fragment>
        <Card>
            <CardHeader sx={{borderBottom:'1px solid gray'}} title={<Typography  className='psn-green' variant='body1'>Implants</Typography>}/>
            <CardContent>
            <Stack direction='row' gap={1} marginTop='10px'>
                <TextField label='% of Cost' size='small' InputLabelProps={{shrink:true}} name='percentOfCost'
                    value={data.implants.percentOfCost} onChange={(e)=>dispatch(editImplants(e))}/>
                <TextField label='Threshold Above/Below' size='small'name='thresholdType' select sx={{width:'200px'}} value={data.implants.thresholdType} onChange={(e)=>dispatch(editImplants(e))}>
                    <MenuItem value='NA'>NA</MenuItem>
                    <MenuItem value='Above'>Above</MenuItem>
                    <MenuItem value='Below'>Below</MenuItem>
                </TextField>
                <TextField type='number' label='Threshold $ Amount' size='small' InputLabelProps={{shrink:true}} name='thresholdAmount'
                    value={data.implants.thresholdAmount} onChange={(e)=>dispatch(editImplants(e))} defaultValue={0}/> 
            </Stack>
            </CardContent>
        </Card>
    </React.Fragment>
}

export {Implants}