import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack,TextField,Typography } from "@mui/material";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleCallEntryDialog } from "../../../../../app/slices/RadModuleSlice";
import { Switch} from "@progress/kendo-react-inputs";
import { useParams } from "react-router";
import { useCreateCallMutation } from "../../../../../services/radiologyService/radiology-api";
import { CallCreateCommand } from "../../../../../services/radiologyService/commands";

function CallEntry(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const isOpen = useAppSelector((state) => state.radModule.CallEntryDialog);
    const [isReached,setIsReached] = useState(false);
    const [callNote, setCallNote] = useState('');
    const [saveCall] = useCreateCallMutation();
    const handleSave=()=>{
        let command = new CallCreateCommand(id!,isReached,callNote)
        saveCall(command);
    }
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>Log a new call</DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='10px'>
                    <div>
                        <Typography>Patient Reached</Typography>
                        <Switch value={isReached} checked={isReached} onChange={()=>setIsReached(!isReached)}/>
                    </div>
                </Stack>
                <Stack direction='row' marginTop='10px'>
                    <TextField label='Note' multiline value={callNote} onChange={(e)=>setCallNote(e.target.value)}
                        sx={{width:'400px',maxWidth:'100%'}} InputLabelProps={{shrink:true}}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction='row' gap={1}>
                    <Button variant="outlined" color='secondary' onClick={()=>{dispatch(toggleCallEntryDialog());setCallNote('')}}>Close</Button>
                    <Button variant='outlined' onClick={()=>{handleSave();dispatch(toggleCallEntryDialog());setCallNote('');}}>Submit</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {CallEntry}