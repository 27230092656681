import { Chip } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

function AdjustmentGridApprovalStatusCell(props:GridCellProps){
    return <React.Fragment>
        {props.dataItem.isAllApproved ?
            <Chip label='Approved' variant='outlined' style={{color:'green',marginTop:'5px'}}/>:
            <Chip label='Pending' variant='outlined' style={{color:"orangered",marginTop:'5px'}}/>}
    </React.Fragment>
}
export {AdjustmentGridApprovalStatusCell};