import { Card, CardContent, CardHeader, Stack, TextField, Typography } from "@mui/material";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { CreateCommandBar } from "./commandBar/CreateCommandBar";
import { editSchedule } from "../../../../services/scheduleService/model/ScheduleSlice";
import { UpdateCommandBar } from "./commandBar/UpdateCommandBar";
import { useCenterFindQuery } from "../../../../services/orgService/org-api";
import { BirthDate } from "./header/BirthDate";
import { PhysicianSelector } from "./header/PhysicianSelector";
import { AdmissionTypeSelector } from "./header/AdmissionTypeSelector";
import { AdmissionSourceSelector } from "./header/AdmissionSourceSelector";

type ownProps = {
    center:string;
    isCreate:boolean;
}
function Header(props:ownProps){
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state)=> state.schedule.schedule);
    const config = useCenterFindQuery(props.center);
    const readOnly = !config.data?.allowManualScheduling;

    return <React.Fragment>       
        <Card>
            <CardHeader title={
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='h6' color='gray'>Patient Detail</Typography>
                {props.isCreate ? <CreateCommandBar center={props.center}/> : <UpdateCommandBar center={props.center}/>}
            </Stack>
            } sx={{borderBottom:'3px solid grey'}}/>
            <CardContent>                    
                <Stack direction='row' gap={1}>
                    <TextField label='Encounter#' InputLabelProps={{style:{color: schedule.accountNumber.length === 0?'red':'gray' },shrink:true}}
                        name='accountNumber' value={schedule.accountNumber} onChange={(e)=>dispatch(editSchedule(e))} disabled={!props.isCreate}/>
                    <TextField label='Last Name' name='lastName' value={schedule.lastName} onChange={(e)=>dispatch(editSchedule(e))}
                        disabled={readOnly}/>
                    <TextField label='First Name' name='firstName' value={schedule.firstName} onChange={(e)=>dispatch(editSchedule(e))}
                        disabled={readOnly}/>
                    <TextField label='Middle Name' name='middleName' value={schedule.middleName} onChange={(e)=>dispatch(editSchedule(e))}
                        disabled={readOnly}/>                           
                </Stack> 
                <Stack direction='row' gap={1} marginTop={1}>
                    <BirthDate center={props.center}/>
                    <PhysicianSelector center={props.center}/>
                    <AdmissionTypeSelector center={props.center}/>
                    <AdmissionSourceSelector center={props.center}/>
                </Stack>
            </CardContent>
        </Card>

    </React.Fragment>
}

export {Header}