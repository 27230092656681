import { Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editVisit } from "../../../../../services/radiologyService/model/radRequest/RadRequestSlice";
import dayjs from "dayjs";


function VisitDetails(){
    const dispatch = useAppDispatch();
    const visit = useAppSelector((state) => state.radRequest.radRequest);

    return <React.Fragment>
            <Stack gap={2} marginTop='10px' marginLeft='10px'>
                <DatePicker label='Requested By Date' sx={{width:'200px'}}
                    value={dayjs(visit.requestByDate || null)} 
                    onChange={(e)=>dispatch(editVisit({name:"requestByDate",value:e!.toDate()}))}
                />

                <TextField name='procedureDescription' type='text' label='Procedure Description'
                     value={visit.procedureDescription}
                     onChange={(e)=>dispatch(editVisit({name:e.target.name,value:e.target.value}))}
                     size='small' sx={{width:'750px'}} InputLabelProps={{shrink:true}} multiline rows={2}/>
                    
            </Stack>
    </React.Fragment>
}

export {VisitDetails}