import { Button } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { useCaseManStatusUpdateMutation } from "../../../../../../services/caseManService/caseManage-api";
import { CaseManStatusUpdateCommand } from "../../../../../../services/caseManService/commands";

function CodingDenyBtn(){
    const caseManage = useAppSelector((state)=>state.caseManagement.caseManagement);
    const [saveStatus] = useCaseManStatusUpdateMutation();
    const handleSave=()=>{
        let command = new CaseManStatusUpdateCommand(caseManage,'Coding','Denied');
        saveStatus(command);
    }

    return <React.Fragment>
        <Button color='error' variant='outlined'
            onClick={()=>handleSave()}
            disabled={caseManage.codingReviewStatus === 'Denied'}
        >DENY</Button>
    </React.Fragment>
}
export {CodingDenyBtn}