import { adjustForTimezone } from "../../../utils/DateConversions";
import { ScheduledCases } from "../model/ScheduledCases";

export class ProcedureUpdateCommand{
    schedulerView:ScheduledCases;

    constructor(item:ScheduledCases){
        let newItem = {...item, start: adjustForTimezone(item.start), end: adjustForTimezone(item.end)}
        this.schedulerView = newItem;
    }
}