import React from "react"
import { useAppSelector } from "../../../../../../app/hooks"
import { useRefundApproveMutation, useRefundSaveMutation } from "../../../../../../services/refundService/refund-api";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";
import { Alert, Button, Snackbar } from "@mui/material";
import { RefundApprovalPostCommand, RefundPostCommand } from "../../../../../../services/refundService/commands";
import { ApprovalRounded } from "@mui/icons-material";
import { RefundCheckInfoValid } from "../../../../specs/RefundCheckInfoValid";

type ownProps = {
    setNotify:Function;
    setMessage:Function;
}

function RefundApproveBtn(props:ownProps){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const [refundSave] = useRefundSaveMutation();
    const [refundApprove,result] = useRefundApproveMutation();
    const [isValid, setIsValid] = React.useState(true);
    const profile = useMyProfileQuery();
    const handleApprove=async ()=>{
        await refundSave(new RefundPostCommand(refund));
        if(new RefundCheckInfoValid().IsSatisfiedBy(refund)){
            setIsValid(true);
        await refundApprove(new RefundApprovalPostCommand(refund.caseId,refund.id,profile.data?.identity!))
        .unwrap()
        .then(()=>{props.setNotify(true);props.setMessage("Refund Approved Successfully")});}
        else{setIsValid(false);}
    }
    return <React.Fragment>
        <Button variant='outlined' color='success' startIcon={<ApprovalRounded/>}
            onClick={()=>handleApprove()}>Approve</Button>

        <Snackbar open={result.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(result.error)}</Alert>
        </Snackbar>
        <Snackbar open={!isValid} onClose={()=>setIsValid(true)} color='error' autoHideDuration={6000}>
            <Alert severity='error'>{'Invalid Refund: Confirm Check Info'}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {RefundApproveBtn}