import { AddCircleOutline} from "@mui/icons-material"
import { Button, Dialog, DialogContent, DialogTitle, MenuItem, Stack, TextField } from "@mui/material"
import React from "react"
import { DefaultOutOfPeriodAdjustment, OutOfPeriodAdjustment } from "../../../../../services/accountService/model/account/OutOfPeriodAdjustment"
import { accountApi, useFindAdjustmentReasonsQuery, useSaveOutOfPeriodAdjustmentMutation } from "../../../../../services/accountService/account-api"
import { useAppDispatch } from "../../../../../app/hooks"
import { addOutOfPeriodAdjustment } from "../../../../../services/accountService/model/account/AccountSlice"
import { AccountOutOfPeriodAdjustmentPostCommand } from "../../../../../services/accountService/commands/AccountOutOfPeriodAdjustmentPostCommand"
import { useParams } from "react-router"

function AccountOPAAddDialog(){
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const [open, setOpen] = React.useState(false)
    const[reason,setReason] = React.useState('OTHER')
    const [adjustment, setAdjustment] = React.useState<OutOfPeriodAdjustment>(DefaultOutOfPeriodAdjustment)
    const {data} = useFindAdjustmentReasonsQuery();
    const [saveAdjustment] = useSaveOutOfPeriodAdjustmentMutation();
    const handleSubmit = ()=>{
        var command = new AccountOutOfPeriodAdjustmentPostCommand(id!,adjustment.period,adjustment.year,adjustment.amount,reason);
        saveAdjustment(command).unwrap().then((data)=>{
            setOpen(false);setAdjustment(DefaultOutOfPeriodAdjustment);
            dispatch(accountApi.util.invalidateTags(['AdjustmentSummary']));
        });
    }


    return <React.Fragment>
        <Button variant='outlined' color='primary' onClick={()=>{setOpen(true)}}><AddCircleOutline/></Button>

        <Dialog open={open} onClose={()=>{setOpen(false)}} maxWidth='xl'>
            <DialogTitle>Add Out of Period Adjustment</DialogTitle>
            <DialogContent>
                <Stack direction='row' gap={1} marginTop='10px'>
                <TextField label='Amount' value={adjustment.amount} onChange={(e) => {
                    if (e.target.value === "-" || /^-?\d*$/.test(e.target.value)) {
                    setAdjustment(i => ({...i, amount: e.target.value==="-" ? 0 : Number(e.target.value)}));
                    }
                    }}/>
                    <TextField label='Period' value={adjustment.period} onChange={(e)=>{setAdjustment(i => ({...i,period:e.target.value}))}}/>
                    <TextField label='Year' value={adjustment.year} onChange={(e)=>{setAdjustment(i => ({...i,year:e.target.value}))}}/>
                    <TextField select label='Reason' value={reason} sx={{width:'300px'}} onChange={(e)=>setReason(e.target.value)}>
                        {data?.map((i) => <MenuItem key={i.value} value={i.value}>{i.name}</MenuItem>)}
                    </TextField>
                    <Button variant='outlined' color='primary' size='small' onClick={()=>handleSubmit()}>Add</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}
export {AccountOPAAddDialog}