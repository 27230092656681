export class VarianceApprovalPostCommand{
    varianceId:string;
    approver:string;
    approverEmail:string;

    constructor(varianceId:string,approver:string, approverEmail:string){
        this.varianceId = varianceId;
        this.approver = approver;
        this.approverEmail = approver;
    }
}