import { Verification } from "../../../services/verifyService/model";
import { CompositeSpecification } from "../../../utils/Specification";

export class RequiresAetnaAuth extends CompositeSpecification<Verification>{
    IsSatisfiedBy(candidate: Verification): boolean {
        let authRequired = false;
        candidate.procedures.forEach(procedure => {
            if(codeArray.includes(procedure.code)) {
                authRequired = true;
            }
        });
        return authRequired;
    }
    
}

const codeArray = ['11950','11951','11952','11954','15771','15772','15775','15776','15780','15781','15782','15783','15786',
'15787','15788','15789','15792','15793','15820','15821','15822','15823','15824','15825','15826','15828','15830','15832',
'15833','15834','15835','15836','15837','15838','15839','15847','15876','15877','15878','15879','17380','19316','19318',
'19318','19325','19328','19330','19340','19342','19350','19355','19357','19364','19370','19371','19380','19396','21010',
'21050','21060','21070','21073','21120','21121','21122','21123','21125','21127','21141','21142','21143','21145','21146',
'21147','21150','21151','21154','21159','21193','21194','21195','21196','21198','21199','21206','21208','21209','21210',
'21215','21240','21242','21243','21244','21245','21246','21247','21248','21249','21255','21270','21480','21485','21490',
'21497','22210','22214','22220','22222','22224','22510','22511','22512','22513','22514','22515','22532','22533','22534',
'22548','22551','22552','22554','22556','22558','22585','22590','22595','22600','22610','22612','22614','22630','22632',
'22633','22634','22800','22802','22804','22808','22810','22812','22818','22819','22830','22840','22841','22842','22843',
'22844','22845','22846','22847','22848','22849','22850','22852','22853','22854','22855','22856','22857','22858','22859',
'22860','22861','22862','22865','23470','23472','23473','23474','27090','27091','27096','27125','27130','27132','27134',
'27137','27138','27279','27280','27412','27415','27437','27438','27440','27441','27442','27443','27445','27446','27447',
'27486','27487','27488','29800','29804','29860','29861','29862','29863','29914','29915','29916','30400','30410','30420',
'30430','30435','30450','31253','31254','31255','31256','31257','31259','31267','31276','31287','31288','31295','31296',
'31297','31298','33206','33207','33208','33212','33213','33214','33221','33224','33225','33227','33228','33229','33230',
'33231','33240','33249','33262','33263','33264','33270','33271','33272','33273','33274','33275','33289','33975','33976',
'33977','33978','33979','33980','33981','33982','33983','33990','33991','33992','33993','33995','33997','36475','36476',
'36478','36479','37220','37221','37222','37223','37224','37225','37226','37227','37228','37229','37230','37231','37232',
'37233','37234','37235','37236','37237','37500','37700','37718','37722','37735','37760','37761','37780','37785','42140',
'42145','42299','43631','43632','43633','43634','43644','43645','43659','43770','43771','43772','43773','43774','43775',
'43842','43843','43845','43846','43847','43848','43886','43887','43888','43999','49999','53430','54125','54400','54401',
'54405','54406','54408','54410','54411','54415','54416','54417','54520','54660','54690','55175','55180','55970','55980',
'56625','56800','56805','56810','57106','57107','57110','57111','57291','57292','57335','58150','58180','58260','58262',
'58275','58280','58285','58290','58291','58321','58321','58322','58322','58323','58323','58541','58542','58543','58544',
'58550','58552','58553','58554','58570','58571','58572','58573','58661','58720','58970','58970','58974','58974','58976',
'58976','61343','62320','62321','62322','62323','62324','62325','62326','62327','63001','63003','63005','63011','63012',
'63015','63016','63017','63020','63030','63035','63040','63042','63043','63044','63045','63046','63047','63048','63050',
'63051','63052','63053','63055','63056','63057','63064','63066','63075','63076','63077','63078','63081','63082','63085',
'63086','63090','63091','63185','63190','63200','63265','63266','63267','63650','63655','63663','63664','63685','63688',
'64479','64480','64483','64484','64490','64491','64492','64493','64494','64495','64510','64520','64633','64634','64635',
'64636','67900','67901','67902','67903','67904','67906','67908','69714','69716','69930','70336','70450','70460','70470',
'70480','70481','70482','70486','70487','70488','70490','70491','70492','70496','70498','70540','70542','70543','70544',
'70545','70546','70547','70548','70549','70551','70552','70553','70554','70555','71250','71260','71270','71271','71275',
'71550','71551','71552','71555','72125','72126','72127','72128','72129','72130','72131','72132','72133','72141','72142',
'72146','72147','72148','72149','72156','72157','72158','72159','72191','72192','72193','72194','72195','72196','72197',
'72198','73200','73201','73202','73206','73218','73219','73220','73221','73222','73223','73225','73700','73701','73702',
'73706','73718','73719','73720','73721','73722','73723','73725','74150','74160','74170','74174','74175','74176','74177',
'74178','74181','74182','74183','74185','74261','74262','74263','74712','74713','75557','75559','75561','75563','75565',
'75571','75572','75573','75574','75635','76380','76390','76948','76948','77014','77021','77022','77046','77047','77048',
'77049','77084','77371','77372','77373','77385','77386','77387','77401','77402','77407','77412','77423','77424','77425',
'77520','77520','77522','77522','77523','77523','77525','77525','77600','77605','77610','77615','77620','77750','77761',
'77762','77763','77767','77768','77770','77771','77772','77778','78429','78430','78431','78432','78433','78434','78451',
'78451','78452','78452','78453','78453','78454','78454','78459','78459','78466','78466','78468','78468','78469','78469',
'78472','78472','78473','78473','78481','78481','78483','78483','78491','78491','78492','78492','78494','78494','78496',
'78496','78608','78609','78811','78812','78813','78814','78815','78816','79005','79101','79403','81162','81163','81165',
'81212','81215','81216','81217','81432','81433','89250','89250','89251','89251','89253','89253','89254','89254','89255',
'89255','89257','89257','89258','89258','89264','89264','89268','89268','89272','89272','89280','89280','89281','89281',
'89290','89290','89291','89291','89337','89337','89342','89342','89346','89346','89352','89352','89353','89353','90935',
'90937','90999','92970','93350','93351','93451','93452','93453','93454','93455','93456','93457','93458','93459','93460',
'93461','93593','93594','93595','93596','93597','95782','95783','95805','95807','95808','95810','95811','99183',' 0042T',
'0214T','0215T','0216T','0217T','0218T','0238T','0394T','0395T','0501T','0502T','0503T','0504T','0515T','0516T','0517T',
'0518T','0519T','0520T','0524T','0609T','0610T','0611T','0612T','0614T','0627T','0628T','0629T','0630T','0633T','0634T',
'0635T','0636T','0637T','0638T','0710T','0711T','0712T','0713T','0742T','0747T','A9513','A9543','A9590','A9606','C8900',
'C8901','C8902','C8903','C8905','C8906','C8908','C8909','C8910','C8911','C8912','C8913','C8914','C8918','C8919','C8920',
'C8931','C8932','C8933','C8934','C8935','C8936','C9762','C9763','D6050','D7296','D7297','D7810','D7820','D7830','D7840',
'D7850','D7852','D7854','D7856','D7858','D7860','D7865','D7870','D7871','D7872','D7873','D7874','D7875','D7876','D7877',
'D7899','D7940','D7941','D7943','D7944','D7945','D7946','D7947','D7948','D7949','D7950','D7951','D7952','D7955','D7991',
'D7995','D7996','G0152','G0153','G0155','G0156','G0157','G0158','G0159','G0160','G0161','G0162','G0260','G0299','G0300',
'G0339','G0340','G0493','G0494','G0495','G0496','G6001','G6002','G6003','G6004','G6005','G6006','G6007','G6008','G6009',
'G6010','G6011','G6012','G6013','G6014','G6015','G6016','G6017','L5782','L5856','L5857','L5858','L5859','L5968','L5969',
'L5980','L5987','L5999','L8690','L8691','L8692','L8693','S2066','S2067','S2068','S2080','S2118','S4011','S4011','S4013',
'S4013','S4014','S4014','S4015','S4016','S4016','S4017','S4017','S4018','S4018','S4020','S4020','S4021','S4021','S4022',
'S4022','S4023','S4023','S4025','S4025','S4035','S4035','S8035','S8037','S8042','S8092','S9124','T1000','T1030','T1031',
'0213T','0357T','0357T','A0140','A0430','A0435','A0999','C1767','C1816','C1820','C1821','C1822','E0983','E0984','E1007',
'E1230','G0151','G0259','G0277','J7330','K0010','K0011','K0012','K0013','K0014','K0800','K0801','K0802','K0806','K0807',
'K0808','K0812','K0813','K0814','K0815','K0816','K0820','K0821','K0822','K0823','K0824','K0825','K0826','K0827','K0828',
'K0829','K0830','K0831','K0835','K0836','K0837','K0838','K0839','K0840','K0841','K0842','K0843','K0848','K0849','K0850',
'K0851','K0852','K0853','K0854','K0855','K0856','K0857','K0858','K0859','K0860','K0861','K0862','K0863','K0864','K0868',
'K0869','K0870','K0871','K0877','K0878','K0879','K0880','K0884','K0885','K0886','K0890','K0891','K0898','K0899','K1007',
'L5781','L8614','L8619','S2112','S4015','S9123','S9960','T2004','T2007'];