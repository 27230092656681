import React from "react";
import { useAppSelector } from "../../../../../../app/hooks";
import { useMyProfileQuery } from "../../../../../../services/profileService/profile-api";
import { Alert, Button, Snackbar } from "@mui/material";
import { VariancePostCommand } from "../../../../../../services/varianceService/commands/VariancePostCommand";
import {
  useVarianceCompleteMutation,
  useVarianceSaveMutation,
} from "../../../../../../services/varianceService/variance-api";
import { VarianceCompletePostCommand } from "../../../../../../services/varianceService/commands/VarianceCompletePostCommand";
import { useNavigate } from "react-router";
import { Verified } from "@mui/icons-material";

type ownProps = {
  setNotify: Function;
  setMessage: Function;
};

function VarianceCompleteBtn(props: ownProps) {
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const myProfile = useMyProfileQuery();
  const [varianceSave] = useVarianceSaveMutation();
  const [saveStatus, result] = useVarianceCompleteMutation();
  const command = new VarianceCompletePostCommand(incident?.id, myProfile.data?.identity!, myProfile.data?.email!);
  const navigate = useNavigate();
  const handleComplete = async () => {
    await varianceSave(new VariancePostCommand(incident));
    await saveStatus(command).unwrap()
    .then(()=>{props.setNotify(true);
    props.setMessage('Incident Completed')});
    setTimeout(() => {
      navigate(-1);
    }, 3000);
  };
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="success"
        startIcon={<Verified />}
        onClick={() => handleComplete()}
      >
        Complete
      </Button>

      <Snackbar open={result.isError}>
        <Alert color="error">
          {"An error has occured: " + JSON.stringify(result.error)}
        </Alert>
      </Snackbar>      
    </React.Fragment>
  );
}

export { VarianceCompleteBtn };
