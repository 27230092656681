import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { contractApi, useContractFindQuery, useFeeScheduleUpdateMutation } from "../../../../../services/contractService/contract-api";
import { Stack } from "@mui/system";
import { Alert, Button, Card, CardContent, CardHeader, Snackbar, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import {KeyboardDoubleArrowLeft, Save } from "@mui/icons-material"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editfeeSchedEffectiveDate, editFeeSchedEndDate, editFeeScheduleDetails } from "../../../../../services/contractService/model/feeSchedule/FeeScheduleSlice";
import { FeeSchedulePostCommand } from "../../../../../services/contractService/commands";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import dayjs from "dayjs";

function FeeScheduleHeader(){
    const navigate = useNavigate();
    const dispatch= useAppDispatch();
    const {contractId} = useParams();
    const contract = useContractFindQuery(contractId!);
    const feeSchedule = useAppSelector((state) => state.feeSchedule.feeSchedule);
    const [feeScheduleUpdate,result] = useFeeScheduleUpdateMutation();
    const [notify, setNotify] = useState(false)
    useEffect(()=>{setNotify(result.isSuccess)},[result.isSuccess]);
    const save=()=>{
        let command = new FeeSchedulePostCommand(feeSchedule);
        feeScheduleUpdate(command);
    }
    
    function adjustForTimezone(date:Date):Date{
        var timeOffsetInMS:number = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() + timeOffsetInMS);
        return date
    }

    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h6'>{contract.data?.payer}</Typography>
            <Typography variant='h6'>{contract.data?.product}</Typography>
        </Stack>
        <Card>
            <CardHeader sx={{borderBottom:'2px solid'}} title={
                <Stack direction='row' justifyContent='space-between'>
                    <Typography className="psn-green" variant='h6'>Fee Schedule Edit</Typography>
                    <Stack direction='row' gap={1}>
                        <Button startIcon={<KeyboardDoubleArrowLeft/>} color='success' variant='outlined' onClick={()=>{navigate(-1);dispatch(contractApi.util.invalidateTags(['feeSchedule','feeScheduleList']));}}>Back</Button>
                        <Button startIcon={<Save/>} variant='outlined' onClick={()=>save()}>Save</Button>
                    </Stack>
                </Stack>} />
            <CardContent>
                <Stack direction='row' gap={1}>
                    <TextField label='Descripion' name='description' sx={{width:'500px'}} value={feeSchedule?.description} onChange={(e)=> dispatch(editFeeScheduleDetails(e))} InputLabelProps={{shrink:true}}/>
                    <TextField label='Fee Schedule Type' name='feeType' value={feeSchedule?.feeType} InputLabelProps={{shrink:true}} disabled={true} sx={{width:'150px'}}/>
                    <DatePicker label='Effective Date' value={dayjs(feeSchedule.effectiveDate!)} onChange={(e)=>{dispatch(editfeeSchedEffectiveDate(e!.toDate() ?? new Date()))}}/>
                    <DatePicker label='Renewal Date' value={dayjs(feeSchedule.endDate!)} onChange={(e)=> {dispatch(editFeeSchedEndDate(e!.toDate() ?? new Date()))}}/>
                    <TextField label='Category' name='category' value={feeSchedule.category} onChange={(e)=>dispatch(editFeeScheduleDetails(e))}/>
                </Stack>
                <Stack marginTop='10px'>
                    <Typography>Restricted for use by specific Revenue Codes</Typography>
                    <MultiSelect name='revCodeRestrictions' allowCustom placeholder="None" value={feeSchedule.revCodeRestrictions} onChange={(e)=>dispatch(editFeeScheduleDetails(e))}/>
                </Stack>
            </CardContent>
        </Card>
        <Snackbar open={notify} onClose={()=>setNotify(false)} color='success' autoHideDuration={6000} message='Fee Schedule successfull saved'/>
        <Snackbar open={result.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(result.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {FeeScheduleHeader}