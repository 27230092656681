import { Stack, TextField, Typography } from "@mui/material";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editCallInfo } from "../../../../../services/verifyService/model/verification/VerificationSlice";

function CallInfo(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);
    return <React.Fragment>
        <Typography variant='h6' className='psn-green'>Payer Call Info</Typography>
        <Stack direction='row' gap={2} sx={{marginTop:'10px'}}>
            <TextField label='Payer Representative' name='payerRepresentative' value ={verification.callInfo.payerRepresentative} onChange={(e)=>dispatch(editCallInfo(e))} size='small' InputLabelProps={{shrink:true}}/>
            <TextField label='Phone Call Date/Time' name='phoneCallDate' value={verification.callInfo.phoneCallDate} onChange={(e)=>dispatch(editCallInfo(e))} size='small' InputLabelProps={{shrink:true}}/>
            <TextField label='Phone Dialed' name='phoneDialed' value={verification.callInfo.phoneDialed} onChange={(e) => dispatch(editCallInfo(e))} size='small' InputLabelProps={{shrink:true}}/>
        </Stack>
        <Stack direction='row' gap={2} sx={{marginTop:'10px'}}>
            <TextField label='Call Reference #' name='callReference' value={verification.callInfo.callReference} onChange={(e) => dispatch(editCallInfo(e))} size='small' InputLabelProps={{shrink:true}}/>
            <TextField label='EDI Number' name='ediNumber' value={verification.callInfo.ediNumber} onChange={(e)=>dispatch(editCallInfo(e))} size='small' InputLabelProps={{shrink:true}}/>
        </Stack>
    </React.Fragment>
}

export {CallInfo}