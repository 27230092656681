export class Cpt{
    id:string= '';
    feeScheduleId:string='';
    code:string='';
    name:string='';
    category:string='Unknown';
    apcCode:string='';
    baseRate:number=0;
    grouperPointer:string = '';
    multiProcedureDiscount?:boolean = false;
    relativeWeight?: number = 100; 
}