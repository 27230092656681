

export interface MedicareConfig{
    medicareBaseRate:number;
    wageIndex:number;
}

export function DefaultMedicareConfig():MedicareConfig{
    return{
        medicareBaseRate:0,wageIndex:0
    }
}