import { Button } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useAppDispatch } from "../../../../app/hooks";
import { togglePhysicianEditModal } from "../../../../app/slices/OrgModuleSlice";


function ViewCommandCell(props:GridCellProps){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <Button size='small' className="mt-1" onClick={()=>{dispatch(togglePhysicianEditModal(props.dataItem.id))}}>View</Button>
    </React.Fragment>
}

export {ViewCommandCell}