import { Save } from "@mui/icons-material"
import { Alert, Button, Snackbar } from "@mui/material"
import React from "react"
import { useRefundSaveMutation } from "../../../../../services/refundService/refund-api"
import { useAppSelector } from "../../../../../app/hooks";
import { RefundPostCommand } from "../../../../../services/refundService/commands";

type ownProps={
    setNotify:Function;
    setMessage:Function;}

function RefundRequestSave(props:ownProps){
    const refund = useAppSelector((state) => state.refundRequest.refundRequest);
    const [refundSave,status] = useRefundSaveMutation();
    const handleSave=()=>{
        let command = new RefundPostCommand(refund);
        refundSave(command)
        .unwrap()
        .then(()=>{props.setNotify(true);props.setMessage("Refund Saved")});
    }

    return <React.Fragment>
        <Button variant='outlined' startIcon={<Save/>}
            onClick={()=>handleSave()}>Save</Button>


        <Snackbar open={status.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(status.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {RefundRequestSave}