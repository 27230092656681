import { Button } from "@mui/material"
import React from "react"
import { usePayerListQuery } from "../../../../services/orgService/org-api";
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";

type ownProps= {
    center:string;
}
function PayerExport(props:ownProps){
    const {data} = usePayerListQuery(props.center);

    const headers = [
        {label: 'CenterId', key:'centerId'},
        {label: 'Code', key:'code'},
        {label: 'Name', key:'name'},
        {label: 'FinancialClass', key:'financialClass'},
        {label: 'Phone', key:'phone'},
        {label: 'PortalUrl', key:'portalUrl'},
        {label: 'EcsPayerId', key:'ecsPayerId'},
        {label: 'WaystarAuthCode',key:'waystarAuthPayer'}
    ]

    return <React.Fragment>
        <CSVLink data={data ?? []} headers={headers} filename={'payer-export.csv'} className='btn' style={{margin:'0px', padding:'0px'}}>
            <Button startIcon={<Download/>} variant='outlined'>Export</Button>
        </CSVLink>
    </React.Fragment>
}

export {PayerExport}