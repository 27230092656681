export const ProvisionOfCareOptions = [
    {
        description: "Cardiopulmonary Arrest Outside of ICU Setting",
        name: "CardiopulmonaryArrestOutsideOfICUSetting",
    },
    {
        description: "Delay/Lack of Response to Patient Condition",
        name: "DelayLackOfResponseToPatientCondition",
    },
    {
        description: "Failure to Follow order",
        name: "FailureToFollowOrder",
    },
    {
        description: "Failure to Obtain Appropriate Assistance",
        name: "FailureToObtainAppropriateAssistance",
    },
    {
        description: "Failure to Respond to Request for Service",
        name: "FailureToRespondToRequestForService",
    },
    {
        description: "Inappropriate Discharge",
        name: "InappropriateDischarge",
    },
    {
        description: "Onset of hypoglycemia During Care",
        name: "OnsetOfHypoglycemiaDuringCare",
    },
    {
        description: "Patient Death (unexpected)",
        name: "PatientDeathUnexpected",
    },
    {
        description: "Potential EMTALA Violation",
        name: "PotentialEMTALAViolation",
    },
    {
        description: "Slow/No Response to Call Light",
        name: "SlowNoResponseToCallLight",
    },
    {
        description: "Timeliness of Response to Order",
        name: "TimelinessOfResponseToOrder",
    },
    {
        description: "Transfer Issues",
        name: "TransferIssues",
    },
    {
        description: "Unplanned or Inappropriate Transfer",
        name: "UnplannedOrInappropriateTransfer",
    },
];
// Cardiopulmonary Arrest Outside of ICU Setting    
// Delay/Lack of Response to Patient Condition                  
// Electric Shock to Patient                                                            Failure to Follow order
// Failure to Obtain Appropriate Assistance                             Failure to Respond to Request for Service Inappropriate Discharge                                                                          Onset of hypoglycemia During Care 
// Patient Death (unexpected)
// Potential EMTALA Violation                                                             Midline, etc                                                                                                          Referral Issue
// Slow/No Response to Call Light                                           Timeliness of Response to Order                                             Transfer Issues                                                                                  Unplanned or Inappropriate Transfer 