import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useNavigate } from "react-router"
import { GridButton } from "../../../../components";
import { useAppDispatch } from "../../../../app/hooks";
import { toggleVerifyLookupModal } from "../../../../app/slices/VerificationModuleSlice";

function LookupCommandCell(props:GridCellProps){
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return<React.Fragment>
        <GridButton  size='small' className="mt-1 ms-2" color="primary" 
            onClick={()=>{navigate('/Verification/'+props.dataItem.id); dispatch(toggleVerifyLookupModal()) }}>View</GridButton>
    </React.Fragment>
}
export{LookupCommandCell}