import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface RadModuleState{
    CaseLookupDialog: boolean;
    TabStripPosition:number;
    CallEntryDialog: boolean;
    CallDetailDialog:boolean;
    SelectedCall:string;
}

const initialState:RadModuleState={CaseLookupDialog:false, TabStripPosition:0, CallEntryDialog: false, CallDetailDialog: false, SelectedCall:''};

export const RadModuleSlice = createSlice({
    name:'RadModuleSlice',
    initialState,
    reducers:{
        toggleCaseLookup:(state) => {
            state.CaseLookupDialog = !state.CaseLookupDialog;
        },
        setTabStripPosition:(state, action:PayloadAction<number>) =>{
            state.TabStripPosition = action.payload;
        },
        toggleCallEntryDialog:(state) => {
            state.CallEntryDialog = !state.CallEntryDialog;
        },
        toggleCallDetailDialog:(state, action:PayloadAction<string>) => {
            state.CallDetailDialog = !state.CallDetailDialog;
            state.SelectedCall = action.payload;
        }
    }
})

export const {toggleCaseLookup, setTabStripPosition, toggleCallEntryDialog,
    toggleCallDetailDialog} = RadModuleSlice.actions;

export default RadModuleSlice.reducer;