import { Card, CardContent, CardHeader, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editUnspecified } from "../../../../../services/contractService/model/contract/ContractSlice";

function UnspecifiedRates(){
    const dispatch = useAppDispatch();
    const data = useAppSelector((state)=>state.contract.contract);
    return <React.Fragment>
        <Card>
        <CardHeader sx={{borderBottom:'1px solid gray'}} title={<Typography className='psn-green'>Unspecified</Typography>}/>
            <CardContent>
                <Stack direction='row' gap={1}>
                    <TextField label='Flat Rate' size='small' InputLabelProps={{shrink:true}} name='flatRate' 
                        value={data.unspecified.flatRate} onChange={(e)=>dispatch(editUnspecified(e))}/>

                    <TextField label='% Billed' size='small' InputLabelProps={{shrink:true}} name='percentBilled'
                        value={data.unspecified.percentBilled} onChange={(e)=>dispatch(editUnspecified(e))}/>

                    <TextField label='$ Max' size='small' InputLabelProps={{shrink:true}} name='maxRate'
                        value={data.unspecified.maxRate} onChange={(e)=>dispatch(editUnspecified(e))}/>
                </Stack>
            </CardContent>
        </Card>
    </React.Fragment>
}

export {UnspecifiedRates}