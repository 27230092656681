import React from "react"
import { useParams } from "react-router";
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import { RefundPatient } from "./refundPatient/RefundPatient";

function VisitDetails(){
    const {caseId} = useParams();
    const {} = useCaseFindQuery(caseId!);

    return <React.Fragment>
            <RefundPatient/>
    </React.Fragment>
}

export {VisitDetails}