import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import {formatNumber} from '@telerik/kendo-intl';

function DrgRateCalcCell(props: GridCellProps){
    const feeSchedule = useAppSelector((state)=>state.feeSchedule.feeSchedule);
    const calculateRate=()=>{
        let baseRate = feeSchedule.groupers.find(p => p.code === props.dataItem.grouperCode)?.rate ?? 0 
        return baseRate * props.dataItem.weight;
    }
    return <React.Fragment>  
        <td colSpan={props.colSpan} className='k-table-td' role='gridcell' aria-colindex={props.ariaColumnIndex} aria-selected={props.isSelected}>
            <div>{formatNumber(calculateRate(),'c')}</div>
        </td>
    </React.Fragment>
}

export {DrgRateCalcCell}