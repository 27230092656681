import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { orgApi } from "../../../../services/orgService/org-api";
import { OrganizationService } from "../../../../utils/ServiceLocator";
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { toggleProcedureUploadModal } from "../../../../app/slices/OrgModuleSlice";

type ownProps={
    center:string;
}
function ProcedureImport(props:ownProps){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state)=>state.orgModule.procedureUploadModal);
    const [selectedFile, setSelectedFile] = useState();
	const [uploadComplete, setUploadComplete] = useState(false);
	const [uploadInProgress, setUploadInProgress] = useState(false);
    const changeHandler = (event:any) =>{
        setSelectedFile(event.target.files[0]);
    }
    const handleSubmission=()=>{
        const formData = new FormData();
        setUploadInProgress(true);
        formData.append('File',selectedFile!);
        fetch(OrganizationService + 'UploadProcedureEndpoint/UploadProcedure/' + props.center,{method:'POST',body:formData})
            .then((response) => {response.json(); setUploadComplete(true); setUploadInProgress(false);dispatch(orgApi.util.invalidateTags(['procedures']))})
            .catch((error) => {console.log('Error',error);});
    }   
    return <React.Fragment>
                <Button variant='outlined' startIcon={<Upload/>} onClick={()=>dispatch(toggleProcedureUploadModal())}>Import</Button>

<Dialog open={isOpen} maxWidth='lg'>
<DialogTitle>Upload Procedures</DialogTitle>
<DialogContent>
    <DialogContent>
        <Stack gap={2}>
            <Alert color='info'>Procedures must be uploaded in the same format as the export file. <br></br>
            </Alert>
            {uploadInProgress &&
                <Alert color='warning'>We're processing your request. This may take a few minutes.</Alert>}
            {uploadComplete === true &&
                <Alert color="success">Upload Completed Successfully</Alert>}
            <input type='file' name='file' onChange={changeHandler}/>
        </Stack>
    </DialogContent>
</DialogContent>
<DialogActions>
    <Button color='secondary' onClick={()=>{setUploadComplete(false);dispatch(toggleProcedureUploadModal());}}>Close</Button>
    <Button 
        onClick={handleSubmission} 
        disabled={selectedFile === undefined || uploadInProgress}>
            {!uploadInProgress ? 'Submit' : <CircularProgress/>}</Button>
</DialogActions>
</Dialog>
    </React.Fragment>
}

export{ProcedureImport}