import { Button, Stack, Typography } from "@mui/material";
import React from "react"
import { useNavigate, useParams } from "react-router"
import { CenterNameView } from "../../../components/orgComponents";
import { CaseManDashboardCards } from "../components/CaseManDashboardCards";
import { useMyProfileQuery } from "../../../services/profileService/profile-api";
import { CaseManSearch } from "../features/search/CaseManSearch";
import { CaseManSplitDashboardCards } from "../components/CaseManSplitDashboardCards";

function CaseManDashboard(){
    const {center}= useParams();
    const {data} = useMyProfileQuery();
    const navigate = useNavigate();
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' marginBottom='20px'>
            <CenterNameView centerId={center!}/>
            <CaseManSearch/>
            <Button variant='outlined' onClick={()=>navigate(-1)}>Go Back</Button>
        </Stack>     
        <Typography variant='h5' className='psn-green' textAlign='center'>Reviews In Process</Typography>
        <Stack direction='row' justifyContent='center' gap={2}>
            <CaseManDashboardCards title='Coding Review' queryParam='Coding Review'/>
            <CaseManDashboardCards title='Clinical Review' queryParam='Clinical Review'/>
            <CaseManDashboardCards title='Docs Required' queryParam='Documents Needed'/>
            <CaseManSplitDashboardCards title='Pending' queryParam1='Coding Pending' queryParam2='Clinical Pending'/>
            <CaseManSplitDashboardCards title='Denied' queryParam1='Coding Denied' queryParam2='Clinical Denied'/>
            <CaseManSplitDashboardCards title='Approved' queryParam1='Coding Approved' queryParam2='Clinical Approved'/>
        </Stack>
{/*         {data?.role.includes('Admin') &&
            <div style={{marginTop:'10px'}}>
                <Stack direction='row' justifyContent='center' gap={2}>
                <Stack>
                <Typography variant='h5' className='psn-green' textAlign='center'>Medical Necessity Alerts</Typography>
                <Stack direction='row' justifyContent='center' gap={2}>
                    <CaseManDashboardCards title='Med Nec Fail' queryParam='MedNec Fail'/>
                    <CaseManDashboardCards title='Med Nec Pass' queryParam='MedNec Pass'/>
                </Stack>
                </Stack>
                <Stack>
                <Typography variant="h5" className='psn-green' textAlign='center'>Notice of Admission</Typography>
                <Stack direction='row' justifyContent='center' gap={2}>
                    <CaseManDashboardCards title='Needs Review' queryParam='NoaReview'/>
                    <CaseManDashboardCards title='Notice Complete' queryParam='NoaComplete'/>
                </Stack>
                </Stack>
                </Stack>
                    
            </div>
        } */}
    </React.Fragment>
}

export {CaseManDashboard}