import { Switch } from "@progress/kendo-react-inputs"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editBenefits } from "../../../../../services/verifyService/model/verification/VerificationSlice";

function ComboCaseAttribute(){
    const dispatch = useAppDispatch();
    const verify = useAppSelector((state) => state.verification.verification);
    return <React.Fragment>
        <div>
            <label>Combo Case</label><br/>
            <Switch name="isComboCase" value={verify.benefits.isComboCase} 
                checked={verify.benefits.isComboCase} onChange={(e)=>dispatch(editBenefits(e))}/>
        </div>
    </React.Fragment>
}
export {ComboCaseAttribute}