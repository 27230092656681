import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { useRefundSaveMutation, useRefundStatusUpdateMutation } from "../../../../../../services/refundService/refund-api";
import { RefundPostCommand, RefundStatusPostCommand } from "../../../../../../services/refundService/commands";
import { Cancel } from "@mui/icons-material";
import { Alert, Button, Snackbar } from "@mui/material";

type ownProps={
    setNotify:Function;
    setMessage:Function;}

function RefundCancelBtn(props:ownProps){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const [refundSave] = useRefundSaveMutation();
    const [saveStatus, result] = useRefundStatusUpdateMutation();
    const handleCancel=async ()=>{
        await refundSave(new RefundPostCommand(refund));
        await saveStatus(new RefundStatusPostCommand(refund?.caseId,refund?.id,"CANCEL"))
        .unwrap()
        .then(()=>{props.setNotify(true);props.setMessage("Refund Cancelled")})
    }

    return <React.Fragment>
        <Button variant='outlined' color='error' startIcon={<Cancel/>}
            onClick={()=>handleCancel()}>Cancel Refund</Button>
        
        <Snackbar open={result.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(result.error)}</Alert>
        </Snackbar>
        
    </React.Fragment>
}

export {RefundCancelBtn}