import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { Alert, Avatar, Stack, Typography } from "@mui/material";
import { Assignment } from "@mui/icons-material";
import { formatDate } from "@progress/kendo-intl";

function IncidentCommentList(){
    const incident = useAppSelector((state)=>state.varianceRequest.varianceRequest);
    const convertedComments = ()=>{
        let p = incident?.comments.map((obj => {return {...obj,addDate:new Date(obj.addDate)}}));
        return p;}

        // Sort the comments in descending order based on the addDate
        const sortedComments = convertedComments().slice().sort((a,b)=>b.addDate.getTime() - a.addDate.getTime());

        return (
            <React.Fragment>
                {incident?.comments?.length === 0 && <Alert color='warning'>This Incident currently has no comments.</Alert>}

                {sortedComments.map(i => (
                    <div key={i.noteId} style={{borderBottom:'2px solid #62BCBD', marginBottom:'5px', paddingBottom:'5px'}}>
                        <Stack direction='row' alignItems='center' gap={2} marginLeft='10px'>
                            <Avatar style={{backgroundColor:'#62BCBD'}}><Assignment/></Avatar>
                            <Typography>{i.addedBy + ' on ' + formatDate(new Date(i.addDate ??''),'MM/dd/yyyy hh:mm')}</Typography>
                        </Stack>
                        <Typography marginLeft='70px'>{i.text}</Typography>
                    </div>
                ))}
            </React.Fragment>
        );
    }

export {IncidentCommentList}