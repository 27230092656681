import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { Grid, TextField, Checkbox } from "@mui/material";
import { formatNumber } from "@progress/kendo-intl";

function AccountExpectedProceduresList(){
    const account = useAppSelector((state)=> state.account.account);
    return <React.Fragment>
        {account.expectedProcedures.length > 0 && <div>
        <Grid container spacing={1} marginLeft='5px'>
            <Grid item sm={.75}><h6>Priority</h6></Grid>
            <Grid item sm={1}><h6>Code</h6></Grid>
            <Grid item sm={4}><h6>Description</h6></Grid>
            <Grid item sm={1}><h6>Bill Amount</h6></Grid>
            <Grid item sm={1}><h6>Reimbursement</h6></Grid>
            <Grid item sm={1}><h6>Implant Cost</h6></Grid>
            <Grid item sm={1}><h6>Self Pay</h6></Grid>
        </Grid>
        {account.expectedProcedures.map(i=>(
            <Grid container spacing={1} sx={{mb:1}} marginLeft='5px'>
                <Grid item sm={.75}>
                    <TextField value={i.priority} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/>
                </Grid>
                <Grid item sm={1}>
                    <TextField value={i.procedureCode} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/>
                </Grid>
                <Grid item sm={4}>
                    <TextField value={i.procedureDescription} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/>
                </Grid>
                <Grid item sm={1}>
                    <TextField value={formatNumber(i.billAmount,'c')} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/>
                </Grid>
                <Grid item sm={1}>
                    <TextField value={formatNumber(i.reimbursement,'c')} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/>
                </Grid>
                <Grid item sm={1}>
                    <TextField value={formatNumber(i.implantCost,'c')} sx={{backgroundColor:'#E9ECEF', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} fullWidth/>
                </Grid>
                <Grid item sm={1}>
                    <Checkbox value={i.isSelfPay} checked={i.isSelfPay} disabled/>
                </Grid>
            </Grid>
        ))}
        </div>}
    </React.Fragment>
}
export {AccountExpectedProceduresList}