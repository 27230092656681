export interface CheckInfo{
    checkNumber?:string;
    checkDate?:Date;
    payableTo:string;
    streetAddress1:string;
    streetAddress2:string;
    city:string;
    state:string;
    zip:string;
}

export function DefaultCheckInfo():CheckInfo{
    return {payableTo:'', streetAddress1:'', streetAddress2:'', city:'', state:'', zip:''}
}