import { Stack } from "@mui/material"
import React from "react"
import { AccountNoteAddDialog } from "./AccountNoteAddDialog"
import { AccountNoteList } from "./AccountNoteList"

function AccountNotes(){
    return <React.Fragment>
        <Stack direction='row' gap={1} justifyContent='flex-end' margin='5px'>
            <AccountNoteAddDialog/>
        </Stack>
        <AccountNoteList/>

    </React.Fragment>
}
export {AccountNotes}