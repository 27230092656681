export const SterileOptions = [
    {
        description: "Appropriate Sterilization Not Done",
        name: "AppropriateSterilizationNotDone",
    },
    {
        description: "Bioburden",
        name: "Bioburden",
    },
    {
        description: "Delay due to Equipment Malfunction",
        name: "DelayDueToEquipmentMalfunction",
    },
    {
        description: "Delay due to Instrument Availability",
        name: "DelayDueToInstrumentAvailability",
    },
    {
        description: "Flash Required",
        name: "FlashRequired",
    },
    {
        description: "Improper Decontamination",
        name: "ImproperDecontamination",
    },
    {
        description: "Improper Transport of Contaminated Instruments",
        name: "ImproperTransportOfContaminatedInstruments",
    },
    {
        description: "Routine Equipment Maintenance/Service Not Performed or Overdue",
        name: "RoutineEquipmentMaintenanceServiceNotPerformedOrOverdue",
    },
    {
        description: "Sterilizer Inoperable",
        name: "SterilizerInoperable",
    },
    {
        description: "Sterilizer not working appropriately",
        name: "SterilizerNotWorkingAppropriately",
    },
    {
        description: "Washer malfunction",
        name: "WasherMalfunction",
    },
];