import React from "react"
import { useAppSelector } from "../../../../../app/hooks"
import { formatDate } from "@telerik/kendo-intl";
import { Alert, Avatar, Typography } from "@mui/material";
import { Assignment } from "@mui/icons-material";
import { Stack } from "@mui/system";

function AccountNoteList(){
    const notes = useAppSelector((state) => state.account.account.notes);
    const convertedNotes = () => {
        let p = notes.map((obj) => {
            return {...obj, addDate: new Date(obj.addDate!)};
        })
        return p;
    }

    return <React.Fragment>
        {notes.length === 0 &&
            <Alert color='warning'>This Case currently has no notes.</Alert>
        }
        {convertedNotes().slice().sort((a,b)=> b.addDate.getTime() - a.addDate.getTime()).map(i => (
            <div>
            <Stack direction='row' alignItems='center' gap={2} marginLeft='10px'>
                <Avatar><Assignment/></Avatar>
                <Typography>{i.addedBy + ' on ' +  formatDate(new Date(i.addDate ?? ''),'MM/dd/yyyy hh:mm')}</Typography>
            </Stack>
            <Typography marginLeft='20px'>{i.text}</Typography>
            <hr/>
            </div>
        ))}

    </React.Fragment>
}
export {AccountNoteList}