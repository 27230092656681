import React from "react"
import { useCaseFindQuery } from "../../../services/scheduleService/schedule-api"
import { Stack, TextField } from "@mui/material";

type ownProps={visitId:string}
function InsuranceView(props:ownProps){
    const {data} = useCaseFindQuery(props.visitId);
    let insurances = [...data!.insurances];

    return <React.Fragment>
        <Stack gap={1}>
        {insurances.sort((a,b) => a.priority - b.priority).map(i => (
            <Stack direction='row' gap={2} key={i.priority}>
                <TextField label='Priority' value={i.priority} disabled size='small'/>
                <TextField label='Payer' value={i.payer} disabled size='small' style={{width:400}}/>
                <TextField label='Policy'value={i.policy} disabled size='small'/>
                <TextField label='Group' value={i.groupNumber} disabled size='small' />
            </Stack>
        ))}
        </Stack>
    </React.Fragment>
}

export {InsuranceView}