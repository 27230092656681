import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router";
import { useMyProfileQuery } from "../../../../services/profileService/profile-api";

function CenterSelector(){
    const profile = useMyProfileQuery();
    const [selectedCenter, setSelectedCenter] = useState('Unk');
    const navigate = useNavigate();
    return <React.Fragment>
        <Stack alignItems='center' gap={2}>
        <Typography variant='h6'>Select a Center</Typography>
        <Stack direction='row' justifyContent='center' justifyItems='center' gap={1}>
            <TextField select size='small'  sx={{width:'500px',background:'white','& legend': { display: 'none' }, '& fieldset': { top: 0 }}} value={selectedCenter} onChange={(e)=>setSelectedCenter(e.target.value)}
                 InputLabelProps={{shrink:false}}>
                <MenuItem value='Unk'> </MenuItem>
                <MenuItem value='MBM'>Carrollton - MBM</MenuItem>
                <MenuItem value='Y16'>Tomball - Y16</MenuItem>
            </TextField>
            <Button variant="outlined" onClick={()=> navigate('/RadHome/' + selectedCenter)}>Select</Button>
        </Stack>
        </Stack>
    </React.Fragment>
}

export {CenterSelector}