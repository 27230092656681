import { Approval, PendingActions, ThumbDown } from "@mui/icons-material"
import { Button, Stack } from "@mui/material"
import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"

function ApprovalStatusIndicator(props:GridCellProps){
    return <React.Fragment>
        <Stack direction='row' gap={1}>
            {props.dataItem.isApproved && <div>
                    <Button color='success' startIcon={<Approval/>}>Approved</Button>
                </div>}
            {props.dataItem.isRejected && <div>
                    <Button color='error' startIcon={<ThumbDown/>}>Rejected</Button>
                </div>}
            {!props.dataItem.isApproved && !props.dataItem.isRejected && <div>
                    <Button color='warning' startIcon={<PendingActions/>}>Pending</Button>
                </div>}
        </Stack>
    </React.Fragment>
}
export {ApprovalStatusIndicator}