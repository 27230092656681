import React from "react"
import { Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router";

function LowContributionCard(){
    const navigate = useNavigate();
    return<React.Fragment>
    <Card sx={{width:'300px', position:'relative', overflow: 'visible'}}>
    <Button 
        variant='contained' 
        color="primary" 
        sx={{
            position: 'absolute', 
            bottom: '-15px', 
            right: '-15px', 
            backgroundColor:'#62BCBD',
            borderRadius: '20px',
        }} onClick={()=>navigate('/LowContribution')}>View</Button>
    <CardHeader title="Low Contribution" sx={{backgroundColor:'#62BCBD', color:'white', height:'40px'}}/>
    <CardContent>
        <Stack direction="row" spacing={2}>
            <Typography variant="body1">Low Contribution Cases that have been Approved</Typography>
        </Stack>
    </CardContent>
    </Card>      
    </React.Fragment>
}
export {LowContributionCard}