import React from "react"
import { useParams } from "react-router"
import { GridCellProps } from "@progress/kendo-react-grid";
import { VerifyService } from "../../../../../utils/ServiceLocator";
import { Stack } from "@mui/material";
import { GridButton } from "../../../../../components";
import { Delete, Download } from "@mui/icons-material";
import { VerificationDocRemoveCommand } from "../../../../../services/verifyService/commands";
import { useVerifyDocRemoveMutation } from "../../../../../services/verifyService/verify-api";

function VerifyDocCommands(props:GridCellProps){
    const {id} = useParams();
    const [remove] = useVerifyDocRemoveMutation();
    const handleDownload=()=>{
        fetch(VerifyService + 'DocumentDownloadEndpoint/DocumentDownload/'+props.dataItem.id,{method:'GET'})
            .then((response)=>response.blob())
            .then((blob)=>{
                if(props.dataItem.extension==='.pdf'){
                    const file = new Blob([blob], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(file);
                    window.open(url);
                }
                else{
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',props.dataItem.fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                }
            });
    };

    return <React.Fragment>
        <Stack direction='row' spacing={'-20px'}>
            <GridButton
                onClick={()=>handleDownload()}>
                    {<Download/>}
            </GridButton>
            <GridButton color='error'
                onClick={()=>remove(new VerificationDocRemoveCommand(id!,props.dataItem.id))}>
                {<Delete/>}
            </GridButton>
        </Stack>
    </React.Fragment>
}

export{VerifyDocCommands}