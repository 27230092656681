import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useRefundWorklistSearchQuery } from "../../../../services/refundService/refund-api";
import { RefundWorklistSearchRequest } from "../../../../services/refundService/commands";
import { useParams } from "react-router";
import { RefundSearchResults } from "./RefundSearchResults";


function RefundSearch(){
    const {center} = useParams();
    const [searchCriteria, setSearchCriteria] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [skip, setSkip] = useState<boolean>(true);
    const {data} = useRefundWorklistSearchQuery(new RefundWorklistSearchRequest(center!,searchCriteria), {skip,})
    useEffect(()=>{setSkip(!isOpen)},[isOpen])

    return <React.Fragment>
    

        <Stack direction='row' gap={.5}>
            <TextField placeholder="account or name" value={searchCriteria}
                onChange={(e)=>setSearchCriteria(e.target.value)}/>

            <Button variant="outlined" disabled={searchCriteria.length === 0}
                onClick={()=>setIsOpen(true)}>Search</Button>


            <Dialog open={isOpen} maxWidth='xl'>
                <DialogTitle>Search Results</DialogTitle>
                <DialogContent>
                    <RefundSearchResults data={data!}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setIsOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>

        </Stack>
    </React.Fragment>
}

export {RefundSearch}