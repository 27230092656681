import React from "react";
import { Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useAppDispatch } from "../../../../../../app/hooks";
import { Profile } from "../../../../../../services/profileService";
import { toggleIncidentAssignUserDialog } from "../../../../../../app/slices/VarianceModuleSlice";

type ownProps={
    profile?:Profile;
    setNotify:Function;
    setMessage:Function;
}
function IncidentAssignUserBtn(props:ownProps){
    const dispatch = useAppDispatch();
    return <React.Fragment>
                <Button variant='outlined' size='large' onClick={()=>dispatch(toggleIncidentAssignUserDialog())}><SearchIcon/>Assign</Button>           
    </React.Fragment>
}

export {IncidentAssignUserBtn};