import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { usePayerListQuery } from "../../../../../services/orgService/org-api";
import { useParams } from "react-router";
import { Button, Dialog, DialogContent, MenuItem, Stack, TextField } from "@mui/material";
import { toggleInsuranceCreateDialog } from "../../../../../app/slices/ScheduleModuleSlice";
import { DefaultInsurance } from "../../../../../services/scheduleService/model/schedule/Insurance";
import { createInsurance } from "../../../../../services/scheduleService/model/ScheduleSlice";

type ownProps={
    center:string;
}
function InsuranceCreate(props:ownProps){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) =>state.scheduleModule.InsuranceCreateDialog);
    const payers = usePayerListQuery(props.center);
    const payerCopy= payers.data === undefined?[]:[...payers.data];
    const [insurance, setInsurance] = useState(DefaultInsurance())

    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogContent>
                <TextField select label='Priority' sx={{width:'225px'}} value={insurance.priority}
                    onChange={(e)=>setInsurance({...insurance, priority:Number(e.target.value)})}>
                        <MenuItem value={0}>Not Set</MenuItem>
                        <MenuItem value={1}>Primary</MenuItem>
                        <MenuItem value={2}>Secondary</MenuItem>
                        <MenuItem value={3}>Tertiary</MenuItem>
                </TextField>
                <Stack marginTop='10px'>
                    <TextField select label='Payer' value={insurance.payerCode}
                        onChange={(e)=>setInsurance({...insurance, payerCode:e.target.value, payer:payerCopy.find(p => p.code===e.target.value)?.name??''})}>
                        {payerCopy?.sort((a,b)=>a.name >b.name?1:-1).map(i =>
                            (<MenuItem key={i.code} value={i.code}>{i.name}</MenuItem>))}
                    </TextField>
                </Stack>
                <Stack direction='row' marginTop='10px' gap={1}>
                    <TextField label='Policy' value={insurance.policy}
                        onChange={(e)=>setInsurance({...insurance,policy:e.target.value})}/>
                    <TextField label='Group' value={insurance.groupNumber}
                        onChange={(e)=>setInsurance({...insurance,groupNumber:e.target.value})}/>
                </Stack>
            </DialogContent>
            <Stack direction='row' marginTop='10px' justifyContent='space-between'>
                <Button color='secondary' onClick={()=>dispatch(toggleInsuranceCreateDialog())}>Cancel</Button> 
                <Button onClick={()=>{dispatch(createInsurance(insurance));
                    dispatch(toggleInsuranceCreateDialog()); setInsurance(DefaultInsurance());}}>Save</Button>
            </Stack>
        </Dialog>
    </React.Fragment>
}

export {InsuranceCreate}