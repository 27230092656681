export const SterilityOptions = [
    {
        description: "Break in Sterile Technique",
        name: "BreakInSterileTechnique",
    },
    {
        description: "Contamination",
        name: "Contamination",
    },
    {
        description: "Foreign Item Retained Post Procedure",
        name: "ForeignItemRetainedPostProcedure",
    },
    {
        description: "Surgical Site Infection",
        name: "SurgicalSiteInfection",
    },
];