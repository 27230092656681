import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { radiologyApi } from '../../radiology-api';
import { DefaultRadReqeust, RadRequest } from './RadRequest';

interface RadRequestState{
    radRequest:RadRequest;
}

const initialState: RadRequestState = {radRequest:DefaultRadReqeust()}

export const RadRequestSlice = createSlice({
    name:'radRequest',
    initialState,
    reducers:{
        resetRadRequest:(state) =>{
            state.radRequest = initialState.radRequest;
        },
        editPatient:(state, action:PayloadAction<{name:string, value:string|Date|null}>) =>{
            const {name,value} = action.payload;
            //@ts-ignore
            state.radRequest.patient[name] = value;
        },
        editVisit:(state,action:PayloadAction<{name:string,value:string|Date|null}>) =>{
            const {name,value} = action.payload;
            //@ts-ignore
            state.radRequest[name] = value;
        },
        editStatus:(state,action:PayloadAction<string>) =>{
            state.radRequest.status = action.payload;
        }
    },
    extraReducers:(builder) =>{
        builder.addMatcher(
            radiologyApi.endpoints.findRadRequest.matchFulfilled,
            (state,{payload}) => {state.radRequest = payload}
        )
        .addMatcher(
            radiologyApi.endpoints.createCall.matchFulfilled,
            (state,{payload}) => {state.radRequest.callLog.push(payload)}
        )
    }
})

export const {editPatient, resetRadRequest, editVisit, editStatus} = RadRequestSlice.actions;
export default RadRequestSlice.reducer;