import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react"
import { Link, useParams } from "react-router-dom";
import { useFindWorklistQuery } from "../../../services/refundService/refund-api";
import { RefundWorklistRequest } from "../../../services/refundService/commands";
import { formatNumber } from "@progress/kendo-intl";

type ownProps={
    title:string;
    queryParam:string;
}

function RefundDashboardCards(props:ownProps){
    const {center} = useParams();
    const {data} = useFindWorklistQuery(new RefundWorklistRequest(center!, props.queryParam));
    return <React.Fragment>
        <Card  className='card' sx={{width:'200px', borderRadius:'5pt'}}>
            <CardHeader className='card-header' title={<Typography color='#62BCBD' variant='body2'>{props.title}</Typography>}/>
                <CardContent>
                    <h2 className="center-align">
                        <Link style={{color:SetDashboardColor(props.queryParam,data?.length ?? 0)}} 
                            to={"/RefundWorklist/"+center + '/' + props.queryParam}>{data?.length ?? 0}
                        </Link>
                    </h2>
                    <Typography variant="body2" className='psn-green center-align'>
                        { data &&
                        formatNumber(data!.reduce((acc, item)=>acc+item.amount, 0), 'c')}
                    </Typography>
            </CardContent>
        </Card>
    </React.Fragment>
}

function SetDashboardColor(param:string, itemCount:number){
    return 'green'
}

export {RefundDashboardCards}