import React, { useState } from "react"
import { useAppDispatch } from "../../../../app/hooks";
import { Button, Stack, TextField } from "@mui/material";
import { CaseManSearchResults } from "./CaseManSearchResults";

function CaseManSearch(){
    const dispatch = useAppDispatch();
    const [searchText, setSearchText] = useState('');
    const [skip, setSkip] = React.useState(true)
    const [open, setOpen] = React.useState(false);
    
    return <React.Fragment>
        <Stack direction='row'>
            <TextField label='Name or Account #' name='searchText' value={searchText}
             onChange={(e)=>{setSearchText(e.currentTarget.value);setSkip(true);}}
             onKeyDown={(e)=>{if(e.key==='Enter'){ setSkip(false)}}}
             sx={{backgroundColor:'white'}} size='small'/>

            <Button color='primary' variant="outlined" 
                onClick={()=>{ setSkip(false); setOpen(true);}} 
                disabled={searchText === ''} size='small'>Search</Button>

        </Stack>
        <CaseManSearchResults searchText={searchText} skip={skip} open={open} setOpen={setOpen}/>
    </React.Fragment>
}

export {CaseManSearch}