import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { useRefundSaveMutation, useRefundStatusUpdateMutation } from "../../../../../../services/refundService/refund-api";
import { RefundPostCommand, RefundStatusPostCommand } from "../../../../../../services/refundService/commands";
import { NoAccounts } from "@mui/icons-material";
import { Button } from "@mui/material";

type ownProps={
    setNotify:Function;
    setMessage:Function;}

function RefundToApBtn(props:ownProps){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const [refundSave] = useRefundSaveMutation();
    const [saveStatus, result] = useRefundStatusUpdateMutation();
    const handleToAp=async ()=>{
        await refundSave(new RefundPostCommand(refund));
        await saveStatus(new RefundStatusPostCommand(refund?.caseId,refund?.id,"AP"))
        .unwrap()
        .then(()=>{props.setNotify(true);props.setMessage("Refund Sent to AP")})
    }

    return<React.Fragment>
        <Button variant='outlined' color='success' startIcon={<NoAccounts/>}
            onClick={()=>handleToAp()}>Send to AP</Button>
    </React.Fragment>
}

export {RefundToApBtn}