import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { VarianceWorklistResults } from "../features/worklist/VarianceWorklistResults";
import { CenterNameView } from "../../../components/orgComponents";

function VarianceWorklist() {
  const { status } = useParams();
  const navigate = useNavigate();
  const { center } = useParams();
  const title = () => {
    if(status === "Started") return "In Progress";
    if(status === "Pending") return "Under Review";
    if(status === "Assigned") return "Assigned to Department";
    if(status === "PendingApproval") return "Awaiting Approval";
    if(status === "Approved") return "Requires Completion";
    if(status === "Complete") return "Complete";
    return "";
  }

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" marginBottom="10px">
        <Grid item sm={4.75}>
          <CenterNameView centerId={center!} />
        </Grid>
        <Grid item sm={4}>
          <Typography variant="h5" className="psn-green">
            {title() + " Worklist"}
          </Typography>
        </Grid>
        <Grid item sm={3}>
          <Stack direction="row" gap={1} justifyContent="flex-end">
            <Button
              variant="outlined"
              startIcon={<KeyboardDoubleArrowLeft />}
              color="success"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <VarianceWorklistResults />
    </React.Fragment>
  );
}

export { VarianceWorklist };
