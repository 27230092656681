import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ContractModuleState{
    contractDetailTab:number;
    feeScheduleTab:number;
    centerLookupModal:boolean;
    quickAddModal:boolean;
    FeeScheduleAddModal: boolean;    
    feeScheduleItemAddModal: boolean;
    feeScheduleItemEditModal:boolean;
    drgUploadModal:boolean;
    cptScheduleItemAddModal:boolean;
    cptScheduleItemEditModal:boolean;
    cptUploadModal: boolean;
    grouperAddModal:boolean;
    grouperEditModal:boolean;
    selectedGrouper:string;
    selectedCode:string;
    selectedCpt:string;
    carveOutAddModal:boolean;
    carveOutEditModal:boolean;
    selectedCarveOut:string;
    carveOutUploadModal:boolean;
    grouperUploadModal:boolean;
    ruleModal:boolean;
    ruleEditModal:boolean;
    selectedRule:string;
}

const initialState:ContractModuleState={contractDetailTab:0, feeScheduleTab:0, centerLookupModal:false, 
    quickAddModal: false, FeeScheduleAddModal:false, feeScheduleItemAddModal:false, feeScheduleItemEditModal:false,
    cptScheduleItemEditModal:false, drgUploadModal:false, cptScheduleItemAddModal:false,cptUploadModal:false, grouperAddModal:false,
    grouperEditModal:false, selectedGrouper:'', selectedCode:'', selectedCpt:'', carveOutAddModal:false, carveOutEditModal:false,
    selectedCarveOut:'', carveOutUploadModal:false, grouperUploadModal:false, ruleModal:false, ruleEditModal:false, selectedRule:'0'}
export const contractModuleSlice = createSlice({
    name:'contractModule',
    initialState,
    reducers:{
        toggleContractDetailTab:(state,action:PayloadAction<number>) =>{
            state.contractDetailTab = action.payload;
        },
        toggleFeeScheduleTab:(state,action:PayloadAction<number>) =>{
            state.feeScheduleTab = action.payload;
        },
        toggleCenterLookupModal:(state)=>{
            state.centerLookupModal = !state.centerLookupModal;
        },
        toggleQuickAddModal:(state)=>{
            state.quickAddModal = !state.quickAddModal;
        },
        toggleFeeScheduleAddModal:(state)=>{
            state.FeeScheduleAddModal = !state.FeeScheduleAddModal;
        },
        toggleFeeScheduleItemAddModal:(state) =>{
            state.feeScheduleItemAddModal = !state.feeScheduleItemAddModal;
        },
        toggleFeeScheduleItemEditModal:(state, action:PayloadAction<string>) =>{
            state.feeScheduleItemEditModal = !state.feeScheduleItemEditModal;
            state.selectedCode = action.payload;
        },
        toggleDrgUploadModal:(state)=>{
            state.drgUploadModal = !state.drgUploadModal;
        },
        toggleCptScheduleItemAddModal:(state)=>{
            state.cptScheduleItemAddModal = !state.cptScheduleItemAddModal;
        },
        toggleCptUploadModal:(state) => {
            state.cptUploadModal = !state.cptUploadModal;
        },
        toggleGrouperAddModal:(state)=>{
            state.grouperAddModal = !state.grouperAddModal;
        },
        toggleGrouperEditModal:(state, action:PayloadAction<string>) =>{
            state.grouperEditModal = !state.grouperEditModal;
            state.selectedGrouper = action.payload;
        },
        toggleCptScheduleItemEditModal:(state, action:PayloadAction<string>) =>{
            state.cptScheduleItemEditModal = !state.cptScheduleItemEditModal;
            state.selectedCpt = action.payload;
        },
        toggleCarveOutAddModal:(state) =>{
            state.carveOutAddModal = !state.carveOutAddModal;
        },
        toggleCarveOutEditModal:(state,action:PayloadAction<string>)=>{
            state.carveOutEditModal = !state.carveOutEditModal;
            state.selectedCarveOut = action.payload;
        },
        toggleCarveOutUploadModal:(state) =>{
            state.carveOutUploadModal = !state.carveOutUploadModal;
        },
        toggleGrouperUploadModal:(state) => {
            state.grouperUploadModal = !state.grouperUploadModal;
        },
        toggleRuleModal:(state, action:PayloadAction<string>) =>{
            state.ruleModal = !state.ruleModal;
            state.selectedRule = action.payload;
        },
        toggleRuleEditModal:(state, action:PayloadAction<string>) =>{
            state.selectedRule = action.payload;
            state.ruleEditModal = !state.ruleEditModal;          
        }
    }
})

export const {toggleContractDetailTab, toggleFeeScheduleTab, toggleCenterLookupModal, toggleQuickAddModal,
    toggleFeeScheduleAddModal, toggleFeeScheduleItemAddModal, 
    toggleFeeScheduleItemEditModal, toggleDrgUploadModal, toggleCptScheduleItemAddModal, toggleCptUploadModal,
    toggleGrouperAddModal, toggleGrouperEditModal, toggleCptScheduleItemEditModal, toggleCarveOutAddModal,
    toggleCarveOutEditModal, toggleCarveOutUploadModal, toggleGrouperUploadModal, toggleRuleModal,
    toggleRuleEditModal} = contractModuleSlice.actions;
export default contractModuleSlice.reducer;