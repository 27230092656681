export const FallPostReasonOptions = [
    {
        description: "Bowel/bladder problem",
        name: "BowelBladderProblem",
    },
    {
        description: "Call light not used",
        name: "CallLightNotUsed",
    },
    {
        description: "Refused to follow orders",
        name: "RefusedToFollowOrders",
    },
];