import React from "react"
import { Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import { Typography } from "@mui/material";
import { LookupResultsCommand } from "./LookupResultsCommand";
import { RadWorklist } from "../../../../services/radiologyService";

type ownProps = {
    searchResults:RadWorklist[];
}

function LookupResultsGrid(props:ownProps){
    return <React.Fragment>
        <Typography variant='h6' marginBottom='5px'>Search Results</Typography>
        <Grid className='card-body-grid' data={props.searchResults} style={{height:'500px'}}>
            <Column cell={LookupResultsCommand} width='75px'/>
            <Column title='Center' field="centerId"/>
            <Column title='Last Name' field='lastName'/>
            <Column title='First Name' field='firstName'/>
            <Column title='Status' field='status'/>
        </Grid>
    </React.Fragment>
}

export{LookupResultsGrid}