import React from "react"
import { Card, CardContent, CardHeader, Typography } from "@mui/material"
import { useWorklistQuery } from "../services/verifyService/verify-api";
import { Link } from "react-router-dom"
import { WorklistRequest } from "../services/verifyService/commands";

type ownProps={
    title:string;
    queryParam:string;
    center:string
}

function DashboardCard(props:ownProps){
    const {data} = useWorklistQuery(new WorklistRequest(props.queryParam,props.center));

    return <React.Fragment>
                <Card  className='card' sx={{width:'200px', borderRadius:'5pt'}}>
                    <CardHeader className='card-header' title={<Typography color='#62BCBD' variant='body2'>{props.title}</Typography>}/>
                        <CardContent>
                            <h2 className="center-align"><Link style={{color:SetDashboardColor(props.queryParam,data?.length ?? 0)}} to={"/Worklist/" + props.center + '/' + props.queryParam}>{data?.length ?? 0}</Link></h2>
                        </CardContent>
                </Card>
    </React.Fragment>
}

function SetDashboardColor(param:string, itemCount:number){
    if(param === 'Low Contribution Margin' && itemCount > 0)
        return 'red';
    if(param === 'Authorizaton Denied' && itemCount > 0)
        return 'red';
    return 'green'
}

export {DashboardCard}