import React from "react"
import { Alert } from "@mui/material";
import { useAppSelector } from "../../../../../app/hooks";
import { CoInsMustBeLessThan100Percent } from "../../../specs/CoInsMustBeLessThan100Percent";
import { DeductibleMetMustBeLessThanDeductible } from "../../../specs/DeductibleMetMustBeLessThanDeductible";
import { DeductibleMustBeLessThanOOP } from "../../../specs/DeductibleMustBeLessThanOOP";
import { OOPMetMustBeLessThanOOPMax } from "../../../specs/OOPMetMustBeLessThanOOPMax";

function Validation(){
    const verification = useAppSelector((state) => state.verification.verification);

    return <React.Fragment>
        {!new DeductibleMetMustBeLessThanDeductible().IsSatisfiedBy(verification) &&
            <Alert color="error">Deductible met can not be greater than deductible</Alert>
        }
        {!new DeductibleMustBeLessThanOOP().IsSatisfiedBy(verification) &&
            <Alert color='error'>Deductible must be less than Out of Pocket</Alert>
        }
        {!new OOPMetMustBeLessThanOOPMax().IsSatisfiedBy(verification) &&
            <Alert color='error'>Out of pocket met must be less than out of pocket max</Alert>
        }
        {!new CoInsMustBeLessThan100Percent().IsSatisfiedBy(verification) &&
            <Alert color='error'>Co Insurance must be less than 100%</Alert>
        }
    </React.Fragment>
}

export{Validation}