import { Verification } from "../../../services/verifyService/model";
import { CompositeSpecification } from "../../../utils/Specification";

export class RequiresTriwestAuth extends CompositeSpecification<Verification>{
    IsSatisfiedBy(candidate: Verification): boolean {
        let authRequired = false;
        if(candidate.procedures.length > 0)
            authRequired = true;
        return authRequired;
    }

    
}