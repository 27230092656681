import { Stack } from "@mui/material"
import React from "react"
import { VerifyDocUploadDialog } from "./VerifyDocUploadDialog"
import { VerifyDocList } from "./VerifyDocList"

function VerifyDocuments(){
    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' margin='5px'>
            <VerifyDocUploadDialog/>
        </Stack>
        <VerifyDocList/>
    </React.Fragment>
}
export {VerifyDocuments}