import { TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editCheckInfo } from "../../../../../services/refundService/model/refundRequest/RefundRequestSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";

function CheckNumberEdit(){
    const dispatch = useAppDispatch();
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const isHidden=()=>{
        if(refund.status==='STARTED' || refund.status==='APPROVE' || refund.status==='REJECT')
            return true;
        return false;}

    return <React.Fragment>
        {!isHidden() &&
        <TextField label='Check number' value={refund.checkInfo.checkNumber}
            onChange={(e)=>dispatch(editCheckInfo(new ReduxUpdate('checkNumber',e.target.value)))}/>}
    </React.Fragment>
}

export {CheckNumberEdit}