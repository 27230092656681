import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useDeleteServiceMutation, useSaveServiceMutation, useServiceListQuery } from "../../../../services/orgService/org-api";
import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { toggleServiceEditModal } from "../../../../app/slices/OrgModuleSlice";
import { DefaultService } from "../../../../services/orgService/model/service/Service";
import { Stack} from "@mui/system";
import { ServiceDeleteCommand, ServicePostCommand } from "../../../../services/orgService/commands";

type ownProps={
    center:string;
}

function ServiceEdit(props:ownProps){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state)=>state.orgModule.serviceEditModal);
    const serviceId = useAppSelector((state)=>state.orgModule.selectedService);
    const [saveService] = useSaveServiceMutation();
    const [deleteService] = useDeleteServiceMutation();
    const {data} = useServiceListQuery(props.center);
    const [service, setService] = useState(data?.find(p => p.id === serviceId)??DefaultService());
    useEffect(()=>{setService(data?.find(p => p.id === serviceId)??DefaultService())},[data,serviceId]);
    const save=()=>{
        let command = new ServicePostCommand(service);
        command.service.centerId = props.center;
        saveService(command);
        dispatch(toggleServiceEditModal(''));
        setService(DefaultService());
    }

    return <React.Fragment>

        <Dialog open={isOpen}>
            <DialogTitle>{'Edit Service: ' + service?.code}</DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='10px'>
                    <TextField label='Description' value={service.description} sx={{width:'500px', maxWidth:'100%'}}
                        onChange={(e)=>setService({...service,description:e.target.value})}/>
                </Stack>
                <Stack direction='row' justifyContent='space-between' marginTop='10px' paddingBottom='0px'>
                    <Button color='error'onClick={()=>{deleteService(new ServiceDeleteCommand(props.center,service.code));dispatch(toggleServiceEditModal(''))}}>Delete</Button>
                    <div>
                    <Button color='secondary' onClick={()=>dispatch(toggleServiceEditModal(''))}>Close</Button>
                    <Button onClick={()=>save()}>Save</Button>
                    </div>
                </Stack>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}

export {ServiceEdit}