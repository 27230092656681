import { Stack } from "@mui/material"
import React from "react"

function CpsiPurchaseOrders(){
    return <React.Fragment>
        <Stack justifyContent='center' alignItems='center'>
            <iframe title="CPSI_PurchaseOrders" width="1400" height="800" src="https://app.powerbi.com/reportEmbed?reportId=c5f16eb0-a27e-46fb-bb11-311ccb901d38&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" allowFullScreen={true}></iframe>
        </Stack>
    </React.Fragment>
}

export{CpsiPurchaseOrders}