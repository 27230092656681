import { Verification } from "../../../services/verifyService/model";
import { CompositeSpecification } from "../../../utils/Specification";

export class RequiresCignaAuth extends CompositeSpecification<Verification>{
    IsSatisfiedBy(candidate: Verification): boolean {
         let authRequired = false;
        candidate.procedures.forEach(procedure => {
            if(codeArray.includes(procedure.code)) {
                authRequired = true;
            }
        });
        return authRequired;
    }
    
}

const codeArray = ['11950','11951','11952','11954','11980','15150','15151','15152','15155','15156','15157','15271','15272',
'15273','15274','15275','15276','15277','15278','15769','15771','15772','15773','15774','15777','15778','15786','15787','15819',
'15820','15821','15822','15823','15824','15825','15828','15829','15830','15832','15833','15834','15835','15836','15837','15838',
'15839','15847','15876','15877','15878','15879','15999','17106','17107','17108','17999','19105','19294','19296','19297','19298',
'19300','19316','19318','19325','19328','19330','19340','19342','19350','19355','19357','19370','19371','19380','19499','20527',
'20910','20912','20930','20931','20936','20937','20938','20939','20975','20999','21025','21032','21050','21060','21073','21076',
'21077','21079','21080','21081','21082','21083','21085','21088','21089','21110','21120','21121','21122','21123','21125','21127',
'21137','21138','21139','21141','21142','21143','21145','21146','21147','21150','21151','21154','21155','21159','21160','21172',
'21179','21180','21181','21182','21188','21193','21194','21195','21196','21198','21199','21206','21208','21209','21210','21215',
'21230','21235','21240','21242','21243','21244','21245','21246','21247','21248','21249','21270','21280','21282','21299','21325',
'21347','21348','21432','21433','21435','21436','21497','21499','21685','21740','21742','21743','21899','22102','22207','22208',
'22210','22214','22216','22220','22224','22226','22325','22326','22328','22505','22510','22511','22512','22513','22514','22515',
'22526','22527','22533','22534','22551','22552','22554','22558','22585','22586','22600','22612','22614','22630','22632','22633',
'22634','22840','22841','22842','22843','22844','22845','22846','22847','22848','22849','22850','22852','22853','22854','22855',
'22856','22857','22858','22859','22860','22861','22862','22867','22868','22869','22870','22899','22999','23000','23020','23106',
'23120','23130','23145','23155','23172','23174','23410','23412','23415','23420','23430','23440','23450','23455','23460','23462',
'23465','23466','23470','23472','23473','23474','23700','23802','23929','24300','24999','25259','25999','26341','26989','27033',
'27096','27125','27130','27132','27134','27137','27138','27175','27197','27198','27275','27279','27280','27299','27331','27332',
'27333','27334','27335','27340','27347','27355','27356','27357','27358','27360','27403','27405','27407','27409','27412','27415',
'27416','27418','27420','27422','27424','27425','27427','27428','27429','27438','27440','27441','27442','27443','27445','27446',
'27447','27486','27487','27570','27599','27702','27703','27860','27899','28446','28890','28899','29799','29800','29804','29805',
'29806','29807','29819','29820','29821','29822','29823','29824','29825','29826','29827','29828','29850','29851','29855','29856',
'29860','29861','29862','29863','29866','29867','29868','29870','29871','29873','29874','29875','29876','29877','29879','29880',
'29881','29882','29883','29884','29885','29886','29887','29888','29889','29914','29915','29916','29999','30150','30400','30410',
'30420','30430','30435','30450','30460','30462','30465','30468','30469','30620','30999','31295','31296','31297','31298','31299',
'31599','31643','31647','31651','31660','31661','31899','32491','32553','32664','32850','32851','32852','32853','32854','32999',
'33206','33207','33208','33224','33225','33230','33231','33240','33249','33254','33255','33258','33265','33266','33267','33268',
'33269','33270','33271','33274','33275','33285','33289','33340','33361','33362','33363','33364','33365','33366','33367','33368',
'33369','33418','33419','33477','33548','33880','33881','33883','33884','33886','33927','33928','33929','33935','33945','33975',
'33976','33979','33981','33990','33991','33993','33995','33999','34717','34718','34841','34842','34843','34844','34845','34846',
'34847','34848','35884','36299','36465','36466','36468','36470','36471','36473','36474','36475','36476','36478','36479','36482',
'36483','36514','36516','36522','37215','37216','37217','37218','37220','37221','37222','37223','37224','37225','37226','37227',
'37228','37229','37230','37231','37232','37233','37234','37235','37238','37239','37241','37244','37248','37249','37500','37501',
'37700','37718','37722','37735','37760','37761','37765','37766','37780','37785','37790','37799','38129','38204','38205','38206',
'38207','38208','38209','38210','38211','38212','38213','38214','38215','38230','38232','38240','38241','38242','38243','38589',
'38999','39499','39599','40799','40899','41019','41512','41530','41599','41820','41874','41899','42140','42145','42160','42299',
'42699','42950','42975','42999','43200','43201','43202','43204','43205','43206','43210','43211','43212','43213','43214','43215',
'43216','43217','43220','43226','43227','43229','43233','43235','43236','43239','43241','43243','43244','43245','43246','43247',
'43248','43249','43250','43251','43252','43254','43255','43257','43266','43270','43284','43285','43289','43290','43291','43497',
'43499','43631','43632','43633','43634','43644','43645','43647','43648','43659','43770','43771','43772','43773','43774','43775',
'43842','43843','43845','43846','43847','43848','43855','43860','43865','43881','43886','43888','43999','44132','44133','44135',
'44136','44238','44705','44715','44720','44721','44799','44899','44979','45399','45999','46707','46999','47135','47140','47141',
'47142','47143','47144','47145','47146','47147','47379','47399','47579','47999','48160','48550','48551','48552','48554','48556',
'48999','49329','49411','49412','49659','49999','50320','50325','50327','50328','50329','50340','50360','50365','50370','50380',
'50547','50549','50949','52441','52442','53451','53452','53453','53454','53854','53860','53899','54125','54161','54400','54401',
'54405','54408','54410','54416','54417','54699','55875','55876','55880','55899','55920','55970','55980','56620','56805','57110',
'57155','57156','57291','57292','57335','57700','58346','58578','58579','58679','58999','59897','59898','59899','60699','61624',
'61630','61635','61736','61737','61796','61797','61798','61799','61800','61863','61867','61885','61886','61888','62263','62264',
'62280','62281','62282','62287','62290','62320','62321','62322','62323','62324','62325','62326','62327','62350','62351','62360',
'62361','62362','62380','63005','63012','63017','63030','63042','63047','63048','63056','63057','63087','63088','63090','63091',
'63267','63620','63621','63650','63655','63685','64451','64479','64480','64483','64484','64490','64491','64492','64493','64494',
'64495','64510','64520','64553','64555','64561','64566','64568','64569','64575','64581','64582','64590','64611','64612','64615',
'64620','64625','64628','64629','64633','64634','64635','64636','64714','64804','64912','64913','64999','65710','65760','65771',
'65772','65785','66174','66175','66179','66183','66184','66999','67299','67399','67599','67900','67901','67902','67903','67904',
'67906','67908','67909','67911','67999','68899','69300','69399','69705','69706','69714','69716','69717','69719','69729','69730',
'69799','69930','69949','69979','70336','70450','70460','70470','70480','70481','70482','70486','70487','70488','70490','70491',
'70492','70496','70498','70540','70542','70543','70544','70545','70546','70547','70548','70549','70551','70552','70553','70554',
'70555','71250','71260','71270','71271','71275','71550','71551','71552','71555','72125','72126','72127','72128','72129','72130',
'72131','72132','72133','72141','72142','72146','72147','72148','72149','72156','72157','72158','72159','72191','72192','72193',
'72194','72195','72196','72197','72198','73200','73201','73202','73206','73218','73219','73220','73221','73222','73223','73225',
'73700','73701','73702','73706','73718','73719','73720','73721','73722','73723','73725','74150','74160','74170','74174','74175',
'74176','74177','74178','74181','74182','74183','74185','74261','74262','74263','75557','75559','75561','75563','75565','75571',
'75572','75573','75635','76380','76390','76391','76497','76498','76499','76873','76965','77011','77014','77046','77047','77048',
'77049','77078','77084','77261','77262','77263','77280','77285','77290','77293','77295','77300','77301','77306','77307','77316',
'77317','77318','77321','77331','77332','77333','77334','77336','77338','77370','77371','77372','77373','77385','77386','77387',
'77399','77401','77402','77407','77412','77417','77423','77424','77425','77427','77431','77432','77435','77469','77470','77499',
'77520','77522','77523','77525','77600','77605','77610','77615','77620','77750','77761','77762','77763','77767','77768','77770',
'77771','77772','77778','77789','77790','77799','78199','78399','78429','78430','78431','78432','78433','78434','78451','78452',
'78453','78454','78456','78459','78466','78468','78469','78472','78473','78481','78483','78491','78492','78494','78496','78499',
'78608','78609','78811','78812','78813','78814','78815','78816','79005','81162','81163','81164','81165','81166','81167','81173',
'81176','81185','81201','81202','81203','81210','81212','81215','81216','81217','81219','81223','81226','81228','81229','81232',
'81233','81235','81236','81237','81277','81288','81292','81293','81294','81295','81296','81297','81298','81299','81300','81306',
'81307','81308','81309','81313','81317','81318','81319','81321','81322','81323','81325','81326','81328','81331','81335','81349',
'81407','81408','81410','81411','81413','81414','81415','81416','81417','81425','81426','81427','81430','81431','81434','81437',
'81438','81439','81440','81442','81448','81449','81451','81455','81456','81460','81465','81470','81471','81479','81506','81518',
'81523','81529','81538','81539','81542','81551','81552','81554','81560','81595','81599','85999','86486','86849','88299','88375',
'89240','89251','89329','89335','89344','89346','89354','89356','89398','90281','90283','90284','90378','90399','90749','90867',
'90868','90869','90899','91110','91111','91112','91113','91117','91299','92499','92507','92508','92700','92971','93264','93350',
'93351','93451','93452','93453','93454','93455','93456','93457','93458','93459','93460','93461','93569','93573','93574','93575',
'93580','93581','93582','93583','93590','93591','93592','93593','93594','93595','93596','93597','93619','93620','93621','93622',
'93623','93624','93644','93653','93654','93655','93656','93657','93662','93799','93998','94799','95782','95783','95805','95807',
'95808','95810','95811','96920','96921','96922','97153','97154','97155','97156','97157','97158','97610','99183','99199','99500',
'99501','99502','99503','99504','99505','99506','99507','99509','99510','99511','99512','99600','0003U','0007M','0007U','0010U',
'0011U','0012U','0013M','0014M','0014U','0016U','0018M','0021U','0022U','0023U','0025U','0034U','0037U','0046U','0048U','0049U',
'0050U','0051U','0053U','0054U','0055U','0056U','0058U','0059U','0060U','0061U','0062U','0067U','0068U','0069U','0070U','0071T',
'0071U','0072T','0072U','0073U','0074U','0075T','0075U','0076T','0076U','0078U','0079U','0080U','0082U','0083U','0087U','0088U',
'0089U','0090U','0091U','0093U','0094U','0096U','0098T','0101U','0102U','0103U','0105U','0107U','0108U','0109U','0110U','0111U',
'0112U','0113U','0114U','0116U','0117U','0118U','0120U','0121U','0122U','0123U','0129U','0130U','0131U','0132U','0133U','0134U',
'0135U','0136U','0137U','0138U','0140U','0141U','0142U','0143U','0144U','0145U','0146U','0147U','0148U','0149U','0150U','0157U',
'0158U','0159U','0160U','0161U','0162U','0163T','0163U','0169U','0172U','0177U','0179U','0191T','0200T','0201T','0204U','0211U',
'0212U','0213T','0213U','0214T','0214U','0215T','0215U','0216T','0217T','0218T','0219T','0221T','0222T','0227U','0230U','0231U',
'0232T','0232U','0233U','0234U','0235U','0236U','0237U','0238U','0239U','0242U','0244U','0245U','0258U','0260U','0261U','0262U',
'0263T','0264U','0265U','0266U','0267U','0274T','0275T','0287U','0288U','0289U','0290U','0291U','0292U','0293U','0294U','0295U',
'0296U','0297U','0298U','0299U','0300U','0306U','0307U','0308T','0312U','0313U','0314T','0314U','0315U','0317U','0318U','0319U',
'0320U','0321U','0326U','0328U','0329U','0331T','0331U','0332T','0335T','0337U','0338U','0339U','0342U','0344U','0346U','0357U',
'0358U','0359U','0361U','0364U','0365U','0366U','0367U','0373T','0378U','0384U','0385U','0394T','0395T','0402T','0421T','0424T',
'0426T','0431T','0440T','0441T','0442T','0446T','0447T','0448T','0449T','0450T','0474T','0479T','0480T','0481T','0483T','0494T',
'0495T','0496T','0523T','0537T','0538T','0539T','0540T','0545T','0572T','0584T','0585T','0586T','0594T','0596T','0597T','0600T',
'0601T','0602T','0603T','0609T','0610T','0611T','0612T','0613T','0616T','0617T','0618T','0619T','0623T','0624T','0625T','0626T',
'0627T','0628T','0629T','0630T','0633T','0634T','0635T','0636T','0637T','0638T','0648T','0649T','0656T','0657T','0664T','0665T',
'0666T','0667T','0668T','0669T','0670T','0671T','0692T','0695T','0696T','0697T','0698T','0707T','0710T','0711T','0712T','0713T',
'0714T','0715T','0717T','0718T','0719T','0720T','0725T','0727T','0728T','0729T','0731T','0732T','0735T','0745T','0746T','0747T',
'0775T','0777T','0780T','0795T','0796T','0797T','0801T','0802T','0805T','0806T','0807T','0808T','0809T','76377','A0140','A0430',
'A0435','A0999','A2001','A2002','A2003','A2004','A2005','A2006','A2007','A2008','A2009','A2010','A2011','A2012','A2013','A2014',
'A2015','A2016','A2017','A2018','A2019','A2020','A2021','A4100','A4238','A4239','A4563','A4641','A4648','A4650','A6025','A9276',
'A9277','A9278','A9513','A9606','A9607','A9699','B4105','B4187','C1062','C1715','C1716','C1717','C1719','C1728','C1761','C1764',
'C1767','C1772','C1778','C1787','C1816','C1820','C1821','C1822','C1823','C1824','C1825','C1826','C1827','C1831','C1834','C1839',
'C1840','C1841','C1842','C1849','C1883','C1889','C1897','C2596','C2614','C2624','C2634','C2635','C2636','C2637','C2638','C2639',
'C2640','C2641','C2642','C2643','C2644','C2645','C2698','C2699','C5271','C5272','C5273','C5274','C5275','C5276','C5277','C5278',
'C7504','C7505','C7507','C7508','C7516','C7517','C7518','C7519','C7520','C7521','C7522','C7523','C7524','C7525','C7526','C7527',
'C7528','C7529','C7531','C7534','C7535','C7537','C7538','C7539','C7540','C7541','C7552','C7553','C8900','C8901','C8902','C8903',
'C8905','C8906','C8908','C8909','C8910','C8911','C8912','C8913','C8914','C8918','C8919','C8920','C8931','C8932','C8933','C8934',
'C8935','C8936','C8937','C9047','C9092','C9094','C9095','C9096','C9097','C9098','C9142','C9146','C9147','C9148','C9149','C9151',
'C9155','C9157','C9352','C9353','C9358','C9360','C9364','C9399','C9725','C9726','C9727','C9728','C9734','C9739','C9740','C9757',
'C9758','C9759','C9760','C9762','C9763','C9764','C9765','C9766','C9767','C9768','C9770','C9771','C9772','C9773','C9774','C9775',
'C9779','C9780','C9781','D5934','D5935','D5952','D5953','D5955','D5958','D5959','D5960','D5999','D7899','D8999','D9999','E0446',
'E0466','E0467','E0481','E0483','E0616','E0627','E0635','E0637','E0638','E0639','E0640','E0641','E0642','E0656','E0657','E0670',
'E0677','E0691','E0692','E0693','E0747','E0748','E0760','E0764','E0766','E0770','E0782','E0783','E0784','E0785','E0786','E1005',
'E1006','E1009','E1230','E1239','E1399','E1902','E1905','E2102','E2103','E2300','E2502','E2504','E2506','E2508','E2510','E2511',
'E2512','E2599','G0153','G0155','G0156','G0166','G0219','G0235','G0252','G0260','G0277','G0299','G0300','G0308','G0309','G0339',
'G0340','G0341','G0342','G0343','G0422','G0423','G0428','G0448','G0455','G0458','G0465','G2000','G6001','G6002','G6003','G6004',
'G6005','G6006','G6007','G6008','G6009','G6010','G6011','G6012','G6013','G6014','G6015','G6016','G6017','G9012','G9147','H0031',
'H0032','H0035','H0046','H2012','H2016','H2019','J0129','J0135','J0172','J0174','J0178','J0179','J0180','J0185','J0202','J0205',
'J0208','J0215','J0218','J0219','J0220','J0221','J0222','J0223','J0224','J0225','J0256','J0257','J0349','J0364','J0470','J0485',
'J0490','J0491','J0517','J0567','J0584','J0585','J0586','J0587','J0588','J0591','J0593','J0596','J0597','J0598','J0599','J0600',
'J0606','J0638','J0641','J0642','J0717','J0725','J0739','J0741','J0775','J0791','J0800','J0801','J0802','J0879','J0881','J0882',
'J0885','J0887','J0888','J0890','J0893','J0894','J0896','J0897','J1290','J1300','J1301','J1302','J1303','J1305','J1306','J1322',
'J1325','J1411','J1426','J1427','J1428','J1429','J1437','J1438','J1439','J1440','J1442','J1447','J1448','J1449','J1453','J1454',
'J1456','J1458','J1459','J1551','J1554','J1555','J1556','J1557','J1558','J1559','J1561','J1562','J1566','J1568','J1569','J1572',
'J1575','J1576','J1595','J1599','J1602','J1620','J1627','J1628','J1632','J1675','J1726','J1743','J1744','J1745','J1746','J1747',
'J1786','J1823','J1826','J1830','J1930','J1931','J1932','J1950','J1951','J1952','J1954','J1961','J2170','J2182','J2323','J2326',
'J2327','J2329','J2350','J2353','J2354','J2356','J2357','J2403','J2427','J2469','J2502','J2503','J2504','J2506','J2507','J2562',
'J2724','J2777','J2778','J2779','J2781','J2786','J2787','J2793','J2796','J2797','J2840','J2860','J2940','J2941','J2998','J3031',
'J3032','J3060','J3111','J3145','J3241','J3245','J3262','J3285','J3299','J3304','J3315','J3316','J3355','J3357','J3358','J3380',
'J3385','J3397','J3398','J3399','J3490','J3520','J3590','J3591','J7170','J7175','J7177','J7178','J7179','J7180','J7181','J7182',
'J7183','J7185','J7186','J7187','J7188','J7189','J7190','J7191','J7192','J7193','J7194','J7195','J7196','J7197','J7198','J7199',
'J7200','J7201','J7202','J7203','J7204','J7205','J7207','J7208','J7209','J7210','J7211','J7212','J7214','J7316','J7318','J7320',
'J7321','J7322','J7323','J7324','J7325','J7326','J7327','J7328','J7329','J7330','J7331','J7332','J7340','J7351','J7352','J7402',
'J7677','J7686','J7799','J7999','J9000','J9015','J9017','J9019','J9021','J9022','J9023','J9025','J9027','J9029','J9032','J9033',
'J9034','J9035','J9036','J9037','J9039','J9040','J9041','J9042','J9043','J9044','J9045','J9046','J9047','J9048','J9049','J9051',
'J9055','J9056','J9057','J9058','J9059','J9060','J9061','J9063','J9064','J9065','J9098','J9100','J9118','J9119','J9120','J9130',
'J9144','J9145','J9150','J9153','J9155','J9171','J9173','J9176','J9177','J9178','J9179','J9181','J9185','J9190','J9196','J9198',
'J9200','J9201','J9202','J9203','J9204','J9205','J9206','J9207','J9208','J9210','J9211','J9212','J9213','J9214','J9215','J9216',
'J9217','J9223','J9225','J9226','J9227','J9228','J9229','J9230','J9245','J9246','J9247','J9259','J9261','J9262','J9263','J9264',
'J9266','J9267','J9268','J9269','J9271','J9272','J9273','J9274','J9280','J9281','J9285','J9293','J9294','J9295','J9296','J9297',
'J9298','J9299','J9301','J9302','J9303','J9304','J9305','J9306','J9307','J9308','J9309','J9311','J9312','J9313','J9314','J9316',
'J9317','J9318','J9319','J9320','J9322','J9323','J9325','J9328','J9330','J9331','J9332','J9340','J9345','J9347','J9348','J9349',
'J9350','J9351','J9352','J9353','J9354','J9355','J9356','J9358','J9359','J9360','J9370','J9371','J9380','J9381','J9390','J9393',
'J9394','J9395','J9400','J9999','K0010','K0011','K0012','K0014','K0553','K0554','K0606','K0800','K0801','K0802','K0808','K0813',
'K0814','K0815','K0820','K0821','K0822','K0823','K0826','K0827','K0828','K0829','K0830','K0831','K0836','K0838','K0840','K0841',
'K0842','K0848','K0850','K0851','K0852','K0853','K0854','K0855','K0856','K0857','K0859','K0860','K0861','K0862','K0863','K0864',
'K0868','K0869','K0870','K0871','K0878','K0879','K0880','K0884','K0885','K0886','K0898','K0899','K0900','K1001','K1006','K1007',
'K1009','K1014','K1021','K1023','K1024','K1025','K1028','K1029','K1031','K1032','K1033','K1035','L1840','L1844','L1846','L2005',
'L2006','L5781','L5782','L5828','L5845','L5848','L5856','L5857','L5858','L5859','L5930','L5969','L5973','L5981','L5999','L6026',
'L6611','L6638','L6646','L6647','L6648','L6715','L6880','L6881','L6882','L6920','L6925','L6930','L6935','L6940','L6945','L6950',
'L6955','L6960','L6965','L6970','L6975','L7007','L7008','L7009','L7040','L7045','L7170','L7180','L7181','L7185','L7186','L7190',
'L7191','L7259','L7499','L8033','L8040','L8041','L8042','L8043','L8044','L8045','L8046','L8047','L8048','L8049','L8499','L8510',
'L8608','L8614','L8619','L8641','L8642','L8679','L8680','L8681','L8682','L8683','L8685','L8686','L8687','L8688','L8689','L8695',
'L8698','L8701','L8702','P9099','Q0138','Q1004','Q1005','Q2017','Q2026','Q2028','Q2041','Q2042','Q2043','Q2050','Q2053','Q2054',
'Q2055','Q2056','Q3027','Q3028','Q4074','Q4081','Q4082','Q4100','Q4102','Q4103','Q4106','Q4107','Q4110','Q4111','Q4112','Q4113',
'Q4114','Q4115','Q4117','Q4118','Q4121','Q4122','Q4123','Q4124','Q4125','Q4126','Q4127','Q4128','Q4130','Q4132','Q4133','Q4134',
'Q4135','Q4136','Q4137','Q4138','Q4139','Q4140','Q4141','Q4142','Q4143','Q4145','Q4146','Q4147','Q4148','Q4149','Q4150','Q4151',
'Q4152','Q4153','Q4154','Q4155','Q4156','Q4157','Q4158','Q4159','Q4160','Q4161','Q4162','Q4163','Q4164','Q4165','Q4166','Q4167',
'Q4168','Q4169','Q4170','Q4171','Q4173','Q4174','Q4175','Q4176','Q4177','Q4178','Q4179','Q4180','Q4181','Q4182','Q4183','Q4184',
'Q4185','Q4186','Q4187','Q4188','Q4189','Q4190','Q4191','Q4192','Q4193','Q4194','Q4195','Q4196','Q4197','Q4198','Q4199','Q4200',
'Q4201','Q4202','Q4203','Q4204','Q4205','Q4206','Q4208','Q4209','Q4210','Q4211','Q4212','Q4213','Q4214','Q4215','Q4216','Q4217',
'Q4218','Q4219','Q4220','Q4221','Q4222','Q4224','Q4225','Q4226','Q4227','Q4229','Q4230','Q4231','Q4232','Q4233','Q4234','Q4235',
'Q4236','Q4237','Q4238','Q4239','Q4240','Q4241','Q4242','Q4244','Q4245','Q4246','Q4247','Q4248','Q4249','Q4250','Q4251','Q4252',
'Q4253','Q4254','Q4255','Q4256','Q4257','Q4258','Q4259','Q4260','Q4261','Q4262','Q4264','Q4265','Q4266','Q4267','Q4268','Q4269',
'Q4270','Q4271','Q4272','Q4273','Q4274','Q4275','Q4276','Q5001','Q5002','Q5003','Q5004','Q5005','Q5006','Q5007','Q5008','Q5009',
'Q5010','Q5103','Q5104','Q5105','Q5106','Q5107','Q5108','Q5109','Q5111','Q5112','Q5113','Q5114','Q5115','Q5116','Q5117','Q5118',
'Q5119','Q5120','Q5121','Q5122','Q5123','Q5124','Q5125','Q5126','Q5127','Q5128','Q5129','Q5130','Q5131','S0013','S0090','S0122',
'S0126','S0128','S0132','S0145','S0148','S0189','S0201','S0800','S0810','S1034','S1040','S2053','S2054','S2060','S2061','S2065',
'S2080','S2095','S2102','S2103','S2107','S2117','S2140','S2142','S2150','S2152','S2202','S2235','S2300','S2348','S2400','S2404',
'S3840','S3854','S3861','S3865','S3866','S3870','S8030','S8035','S8037','S8042','S8080','S8085','S8092','S9055','S9122','S9123',
'S9124','S9125','S9126','S9127','S9128','S9325','S9326','S9327','S9328','S9336','S9340','S9341','S9342','S9343','S9346','S9347',
'S9348','S9351','S9353','S9355','S9357','S9359','S9361','S9363','S9364','S9365','S9366','S9367','S9368','S9370','S9372','S9379',
'S9563','S9960','S9961','S9975','T1000','T1030','T1031','V5336'];