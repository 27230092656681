import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleCarveOutUploadModal } from "../../../../../app/slices/ContractModuleSlice";
import { contractApi } from "../../../../../services/contractService/contract-api";
import { ContractService } from "../../../../../utils/ServiceLocator";
import CarveOutTemplate from '../../../../../templates/CarveOutUploadTemplate.png';

function CarveOutUpload(){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.contractModule.carveOutUploadModal);
    const [selectedFile, setSelectedFile] = useState();
	const [uploadComplete, setUploadComplete] = useState(false);
	const [uploadInProgress, setUploadInProgress] = useState(false);

    const changeHandler = (event:any) =>{
        setSelectedFile(event.target.files[0]);
    }

    const handleSubmission=()=>{
        const formData = new FormData();
        setUploadInProgress(true);
        formData.append('File', selectedFile!);
		fetch(ContractService + 'UploadCarveOutEndpoint/UploadCarveOut/'+ id,{method: 'POST', body: formData,})
			.then((response) => {response.json(); setUploadComplete(true);setUploadInProgress(false);dispatch(contractApi.util.invalidateTags(['carveOutList']));})
			.then((result) => {dispatch(contractApi.util.invalidateTags(['carveOut','carveOutList']));})
			.catch((error) => {console.error('Error:', error);});       
    };
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>Upload a Carve Out Schedule</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Alert color='info'>Carve outs must be uploaded using a specific format and file name.  Please download the template here.
                        <a href={CarveOutTemplate} download='CarveoutTemplate.csv'>Download</a>                        
                    </Alert>
                    {uploadInProgress &&
					    <Alert color='warning'>We're processing your request. This may take a few minutes.  You can close this window and continue working.</Alert>}
					{uploadComplete === true &&
						<Alert color="success">Upload Completed Successfully</Alert>}
                    <input type='file' name='file' onChange={changeHandler}/>
                    <Button variant='outlined' startIcon={uploadInProgress ? '' : ''} onClick={handleSubmission } disabled={selectedFile === undefined || uploadInProgress}>{!uploadInProgress ? 'Submit': <CircularProgress/>}</Button>
                    <Button variant='outlined' onClick={()=>{dispatch(contractApi.util.invalidateTags(['carveOut','carveOutList'])); setUploadComplete(false); dispatch(toggleCarveOutUploadModal())}}>Close</Button>
                </Stack>
            </DialogContent>
            
        </Dialog>
    </React.Fragment>
}

export {CarveOutUpload}