export class Physician{
    id:string = '';
    code:string='';
    centerId:string = '';
    lastName:string = '';
    firstName:string = '';
    middleName:string = '';
    specialty:string = '';
    npi:string = '';
}


