import React from "react"
import { Card, CardHeader, CardContent, Stack, Grid, Box } from "@mui/material";

function VOBStatus(){
    return <React.Fragment>
        <Stack justifyContent='center' alignItems='center'>
            <iframe title="VOB Status" width="1350" height="800" src="https://app.powerbi.com/reportEmbed?reportId=e223da1b-5ad7-4fe2-9f33-b374c5f2df95&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac" allowFullScreen={true}></iframe>
        </Stack>
    </React.Fragment>
}

export{VOBStatus}