import { Add} from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react"
import { useAppDispatch } from "../../../../../app/hooks"
import { toggleFeeScheduleAddModal } from "../../../../../app/slices/ContractModuleSlice";

function DrgHeader(){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{margin:'5px'}}>
            <Typography className='psn-green' variant='h6'>DRG Fee Schedules</Typography>
            <Button startIcon={<Add/>} variant='outlined' onClick={()=> dispatch(toggleFeeScheduleAddModal())}>Add Fee Schedule</Button>
        </Stack>
    </React.Fragment>
}

export {DrgHeader}