import { Stack } from "@mui/material";
import React from "react"
import { Profile } from "../../../services/profileService";
import { useMyProfileQuery } from "../../../services/profileService/profile-api";
import { Loading } from "../../shared";
import {ProfileHeader, CenterAccess, ClaimsAccess} from '../features'
import { LookupUser } from "../features/lookupUser/LookupUser";

function MyProfile(){
    const {data, isLoading} = useMyProfileQuery();
    return <React.Fragment>
        {isLoading === true ?
        <Loading/> :
        <div>
            <ProfileHeader profile={data === undefined ? new Profile() : data}/>
            <br/>
            <Stack>
                <CenterAccess profile={data === undefined ? new Profile() : data}/>
                <br/><br/>
                <ClaimsAccess profile={data === undefined ? new Profile(): data}/>
            </Stack>
        </div>
        }
        <LookupUser/>
    </React.Fragment>
}

export {MyProfile}