export const DietaryOptions = [
    {
        description: "Allergy to diet",
        name: "AllergyToDiet",
    },
    {
        description: "Incorrect Diet Delivered",
        name: "IncorrectDietDelivered",
    },
    {
        description: "Incorrect Diet Ordered",
        name: "IncorrectDietOrdered",
    },
    {
        description: "NPO patient given food",
        name: "NPOPatientGivenFood",
    },
    {
        description: "Patient did not receive meal",
        name: "PatientDidNotReceiveMeal",
    },
    {
        description: "Quality of Food (cold, hot, not cooked, etc)",
        name: "QualityOfFood",
    },
    {
        description: "Wrong Patient",
        name: "WrongPatient",
    },
];