import { caseManageApi } from "../../caseManage-api";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";
import { DefaultPolicy, Policy } from "./Policy";

interface PolicyState{
    policy:Policy;
}

const initialState:PolicyState = {policy: DefaultPolicy()}

export const policySlice = createSlice({
    name:'policy',
    initialState,
    reducers:{
        editPolicyDetails:(state, action:PayloadAction<ReduxUpdate>)=>{
            // @ts-ignore
            state.policy[action.payload.name] = action.payload.value;
        },
        resetPolicyDetails:(state) => {
            state.policy = DefaultPolicy();
        }
    },
    extraReducers: (builder) =>{
        builder.addMatcher(
            caseManageApi.endpoints.findPolicy.matchFulfilled,
            (state,{payload}) => {state.policy = payload}
        )
    }
});

export const {editPolicyDetails, resetPolicyDetails} = policySlice.actions;
export default policySlice.reducer;