import React from "react"
import { GridButton } from "../../../../../../components"
import { Details } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks"
import { toggleCaseManCallDetailModal } from "../../../../../../app/slices/CaseManModuleSlice"
import { formatDate } from "@progress/kendo-intl"

type ownProps={
    selectedCall:string;
}
function CaseManCallDetailModal(props:ownProps){
    const open = useAppSelector((state)=>state.caseManModule.CaseManCallDetailModal);
    const dispatch = useAppDispatch();
    const selectedCall = useAppSelector((state)=>state.caseManModule.CaseManSelectedCall);
    const call = useAppSelector((state)=>state.caseManagement.caseManagement?.callLogs?.find(x=>x.callId===selectedCall));

    return <React.Fragment>
        <GridButton onClick={()=>dispatch(toggleCaseManCallDetailModal(props.selectedCall))}><Details/></GridButton>

        <Dialog open={open} onClose={()=>dispatch(toggleCaseManCallDetailModal('0'))}>
            <DialogTitle>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6' className='psn-green'>Call Detail</Typography>
                    <Typography variant='h6' style={{color:call?.isCallAnswered?'green':'red'}} >{call?.isCallAnswered?'Reached':'Not Reached'}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction='row' justifyContent={'space-between'} marginTop='10px' gap={2}>
                    <TextField label='Call Date' value={formatDate(new Date(call?.callDate??''),"MM/dd/yyyy")} disabled/>
                    <TextField label='Call By' value={call?.callBy} disabled/>
                </Stack>
                <Stack direction='row' marginTop='10px'>
                    <TextField label='Call To' value={call?.callTo} disabled fullWidth/>
                </Stack>
                <Stack direction='row' marginTop='10px'>
                    <TextField multiline minRows={4} label='Note' value={call?.notes} disabled fullWidth/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>dispatch(toggleCaseManCallDetailModal('0'))}>Close</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}
export{CaseManCallDetailModal}