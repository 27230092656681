import { Approval } from "./Approval";
import { Authorization } from "./Authorization";
import { Benefits } from "./Benefits";
import { BenefitsStatus } from "./BenefitsStatus";
import { CallInfo } from "./CallInfo";
import { CallLog } from "./CallLog";
import { DiagnosisCodes } from "./DiagnosisCodes";
import { EstimatedProcedure } from "./EstimatedProcedure";
import { Note } from "./Note";
import { PatientResponsibility } from "./PatientResponsibility";
import { SingleCaseAgreement } from "./SingleCaseAgreement";
import { VerificationDocument } from "./VerificationDocument";
import { VerifyAttributes } from "./VerifyAttributes";
import { WaystarAuthStatus } from "./WaystarAuthStatus";

export class Verification{
    public id: string = '';
    public center: string = '';
    public accountNumber: string = '';
    public approval:Approval = new Approval();
    public benefits: Benefits = new Benefits();
    public callInfo: CallInfo = new CallInfo();
    public authorization: Authorization = new Authorization();
    public benefitsStatus: BenefitsStatus = new BenefitsStatus();
    public patientResponsibility: PatientResponsibility = new PatientResponsibility();
    public diagnosisCodes: DiagnosisCodes = new DiagnosisCodes();
    public procedures: EstimatedProcedure[] = [];
    public notes:Note[] = [];
    public calls:CallLog[] = [];
    public documents:VerificationDocument[] = [];
    public waystarAuthStatus: WaystarAuthStatus = new WaystarAuthStatus();
    public singleCaseAgreement?: SingleCaseAgreement = new SingleCaseAgreement();
    public implantsNotUsed: boolean = false;
    public verifyAttributes: VerifyAttributes = new VerifyAttributes();
}