import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { useRefundSaveMutation, useRefundStatusUpdateMutation } from "../../../../../../services/refundService/refund-api";
import { RefundPostCommand, RefundStatusPostCommand } from "../../../../../../services/refundService/commands";
import { Alert, Button, Snackbar } from "@mui/material";
import { PostAdd } from "@mui/icons-material";

type ownProps ={
    setNotify:Function;
    setMessage:Function;
    Label:string;
}

function RefundToPostingBtn(props:ownProps){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const [refundSave] = useRefundSaveMutation();
    const [saveStatus, result] = useRefundStatusUpdateMutation();
    const handleResubmit=async ()=>{
        await refundSave(new RefundPostCommand(refund));
        await saveStatus(new RefundStatusPostCommand(refund?.caseId,refund?.id,"POSTING"))
        .unwrap()
        .then(()=>{props.setNotify(true);props.setMessage("Refund Sent to Posting")})
    }
    return <React.Fragment>
        <Button variant='outlined' color='success' startIcon={<PostAdd/>}
            onClick={()=>handleResubmit()}>{props.Label}</Button>
        <Snackbar open={result.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(result.error)}</Alert>
        </Snackbar>
    </React.Fragment>
}

export {RefundToPostingBtn}