import { Stack, TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editApptNote } from "../../../../../services/scheduleService/model/ScheduleSlice";
import { useCenterFindQuery } from "../../../../../services/orgService/org-api";

type ownProps = {
    center:string;
}
function ApptNote(props:ownProps){
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state)=>state.schedule.schedule);
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;
    
    return <React.Fragment>
        <Stack margin='20px'>
            <TextField multiline rows={4} fullWidth label='Appointment Note' 
                value={schedule.visitNotes.find(p => p.code === 'ApptNote')?.description}
                onChange={(e)=>dispatch(editApptNote(e.target.value))}
                disabled={readonly}/>
        </Stack>
    </React.Fragment>
}

export {ApptNote}