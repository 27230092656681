import { Add } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { toggleMedicareRateModal } from "../../../../app/slices/OrgModuleSlice";
import { MedicareRateSaveCommand } from "../../../../services/orgService/commands/MedicareRateSaveCommand";
import { DefaultMedicareRate } from "../../../../services/orgService/model/center/MedicareRate";
import { useSaveMedicareRateMutation } from "../../../../services/orgService/org-api";

function MedicareRateModal(){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.orgModule.medicareRateModal);
    const [save] = useSaveMedicareRateMutation();
    const center = useAppSelector((state) => state.center.center);
    const selectedRate = useAppSelector((state) => state.orgModule.selectedMedicareRate);
    const [rate, setRate] = useState(DefaultMedicareRate());
    useEffect(() => {
        if(selectedRate === ''){
            setRate(DefaultMedicareRate())
        } else{setRate(center.medicareRates.find(p => p.id === selectedRate)!)}

    },[selectedRate])
    
    return <React.Fragment>
        <Button variant='outlined' size='small' startIcon={<Add/>} onClick={()=>dispatch(toggleMedicareRateModal(''))}>Add</Button> 

        <Dialog open={isOpen} maxWidth='xl'>
            <DialogTitle>Medicare Configuration</DialogTitle>
            <DialogContent>
                <Stack direction='row' marginTop='10px' gap={2}>
                    <TextField type='number' label='Year' size='small' sx={{width:'100px'}} 
                        value={rate.year} onChange={(e)=>setRate({...rate, year:Number(e.target.value)})} 
                        InputLabelProps={{shrink:true}} inputProps={{step:'any'}}/>
                </Stack>
                <Stack direction='row' marginTop='10px' gap={2}>
                    <TextField label='CBSA' size='small' sx={{width:'350px'}} InputLabelProps={{shrink:true}}
                        value={rate.cbsa} onChange={(e)=>setRate({...rate, cbsa:e.target.value })}/>
                </Stack>
                <Stack direction='row' marginTop='10px' gap={2}>
                    <TextField type='number' label='Wage Index' size='small' InputLabelProps={{shrink:true}} inputProps={{step:.0001 }}
                        value={rate.wageIndex} onChange={(e)=>setRate({...rate,wageIndex:Number(e.target.value)})}/>
                    <TextField type='number' label='Labor Related' size='small' InputLabelProps={{shrink:true}} inputProps={{step:.0001 }}
                        value={rate.lrop} onChange={(e)=>setRate({...rate,lrop:Number(e.target.value)})}/>
                    <TextField type='number' label='Non Labor Related' size='small' InputLabelProps={{shrink:true}} inputProps={{step:.0001 }}
                        value={rate.nlop} onChange={(e)=>setRate({...rate, nlop:Number(e.target.value)})}/>
                </Stack>
                <Stack direction='row' marginTop='10px' gap={2}>
                    <TextField type='number' label='Federal Cap Weight' size='small' InputLabelProps={{shrink:true}} inputProps={{step:.0001}}
                        value={rate.federalCapitalWeight} onChange={(e)=>setRate({...rate,federalCapitalWeight:Number(e.target.value)})}/>
                    <TextField type='number' label='GAF' size='small' InputLabelProps={{shrink:true}} inputProps={{step:.0001 }}
                        value={rate.geographicAdjustmentFactor} onChange={(e)=>setRate({...rate,geographicAdjustmentFactor:Number(e.target.value)})}/>
                    <TextField type='number' label='IP Hospital Ded' size='small' InputLabelProps={{shrink:true}} inputProps={{step:.0001 }}
                        value={rate.inpatientHospitalDeductible} onChange={(e)=>setRate({...rate,inpatientHospitalDeductible:Number(e.target.value)})}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction='row' justifyContent='flex-end'>
                    <Button color='secondary' onClick={()=>dispatch(toggleMedicareRateModal(''))}>Close</Button>
                    <Button onClick={()=>{save(new MedicareRateSaveCommand(center.id,rate)); dispatch(toggleMedicareRateModal(''))}}>Save</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {MedicareRateModal}