import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, InputAdornment, Switch, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleGrouperAddModal } from "../../../../../app/slices/ContractModuleSlice";
import { GrouperAddCommand } from "../../../../../services/contractService/commands";
import { useFeeScheduleFindQuery, useGrouperAddMutation } from "../../../../../services/contractService/contract-api";

function GrouperAdd(){
    const {contractId,feeScheduleId} = useParams();
    const feeSchedule = useFeeScheduleFindQuery(feeScheduleId!)
    const [code,setCode] = useState('');
    const [rate, setRate] = useState(0);
    const [perDiem, setPerDiem] = useState(false)
    const [saveGrouper] = useGrouperAddMutation(); 

    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.contractModule.grouperAddModal);
    const save =()=>{
        let command = new GrouperAddCommand(contractId!, feeSchedule.data?.feeType!, feeScheduleId!,code,rate,perDiem);
        saveGrouper(command);
    }

    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle sx={{borderBottom:'1px solid'}}>Add a Grouper</DialogTitle>
            <DialogContent>
                <Stack direction='row' gap={1} marginTop='10px'>
                    <TextField label='Grouper Code' name='grouperCode' size='small' sx={{width:'100px'}} value={code} onChange={(e)=>setCode(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                    <TextField label='Grouper Rate' name='grouperRate' size='small' sx={{width:'150px'}} value={rate} onChange={(e)=>setRate(Number(e.target.value))}
                        InputLabelProps={{shrink:true}} InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
                </Stack>
                <Stack direction='row' marginTop='10px'>
                    <FormControlLabel
                        control={<Switch size='small' value={perDiem} checked={perDiem} onChange={() => setPerDiem(!perDiem)}/>}
                        label='Per Diem' labelPlacement='end'/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=> dispatch(toggleGrouperAddModal())}>Close</Button>
                <Button onClick={()=> {save(); dispatch(toggleGrouperAddModal());}}>Submit</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {GrouperAdd}