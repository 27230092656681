import { TextField } from "@mui/material"
import React from "react"

type ownProps={
    note:string;
    setNote:(note:string)=>void;
}

function NoteCreateTextEdit(props:ownProps){
    return <React.Fragment>
        <TextField multiline fullWidth rows={10} 
            sx={{width:'500px'}} variant='outlined'
            value={props.note}
            onChange={(e)=>props.setNote(e.target.value)}/>
    </React.Fragment>
}

export {NoteCreateTextEdit}