import { Headset } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Switch, TextField, Typography } from "@mui/material"
import React from "react"
import { CaseManCallLog } from "../../../../../services/caseManService/model/caseMan/CaseManCallLog";
import { useCaseManCallAddMutation } from "../../../../../services/caseManService/caseManage-api";
import { CaseManCallAddCommand } from "../../../../../services/caseManService/commands";
import { useParams } from "react-router";
import { Guid } from "js-guid";

function CaseManCallAddModal(){
    const [open, setOpen] = React.useState(false);
    const [call, setCall] = React.useState({callTo:'', callId:'', isCallAnswered:false, notes:''} as CaseManCallLog);
    const {id} = useParams();
    const [saveCall] = useCaseManCallAddMutation();
    const save = ()=>{
        let newCaller = {...call};
        newCaller.callId = Guid.newGuid().toString();
        let command = new CaseManCallAddCommand(id!, newCaller);
        saveCall(command);
        setOpen(false);
    }
    return <React.Fragment>
        <Button startIcon={<Headset/>} variant='outlined' onClick={()=>setOpen(!open)}>Log a Call</Button>

        <Dialog open={open} onClose={()=>setOpen(false)} maxWidth='xl'>
            <DialogTitle>Add a Call</DialogTitle>
            <DialogContent>
                <span style={{marginTop:'0px', paddingTop:'0px', alignItems:'flex-start', justifyContent:'flex-start'}}>
                    <Typography sx={{fontSize:'11px', color:'gray',marginBottom:'0px'}}>Call Answered</Typography>
                    <Switch sx={{marginTop:'0px'}} checked={call?.isCallAnswered} value={call?.isCallAnswered} onChange={(e)=>setCall({...call,isCallAnswered:e.currentTarget.checked})}/>
                </span>
                <Stack direction='row' marginTop='10px' gap={2}>
                    <TextField label='Call To' fullWidth value={call?.callTo} onChange={(e)=>{setCall({...call,callTo:e.target.value})}}/>

                </Stack>
                <Stack direction='row' marginTop='10px' width='500px'>
                    <TextField multiline rows={4} label='Notes' fullWidth value={call?.notes} onChange={(e)=>{setCall({...call,notes:e.target.value})}}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={()=>setOpen(false)}>Cancel</Button>
                <Button variant='outlined' onClick={()=>save()}>Save</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}
export {CaseManCallAddModal}