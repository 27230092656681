import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { useState } from "react";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import React from "react";
import { editVariance } from "../../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";

function IncidentTime(props: { canEdit: boolean }){
  const dispatch = useAppDispatch();
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const [incidentEdit, setIncidentEdit] = useState(false);
  const convertToDateTimeLocalString = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const [incidentDate, setIncidentDate] = useState(
    convertToDateTimeLocalString(new Date(incident.incidentDate))
  );

  return (
    <React.Fragment>
      <Box>
        {incidentEdit === false && (
          <Stack direction="row">
            <Typography variant="h6" className="psn-green">
              Incident Time: &nbsp;
            </Typography>
            <Typography variant="h6" className="psn-green">
              {new Date(incident.incidentDate).toLocaleString()}
            </Typography>
            <IconButton
              disabled={!props.canEdit}
              size="small"
              onClick={() => setIncidentEdit(!incidentEdit)}
            >
              <ModeEditOutlinedIcon fontSize="small" />
            </IconButton>
          </Stack>
        )}
        {incidentEdit === true && (
          <Stack direction="row">
            <TextField
              id="incident-time-input"
              type="datetime-local"
              value={incidentDate}
              onChange={(e) => {
                setIncidentDate(e.target.value);
                dispatch(
                  editVariance({
                    name: "incidentDate",
                    value: e.target.value,
                  })
                );
              }}
              InputLabelProps={{
                shrink: true,
              }}             
            />
            <IconButton
              size="small"
              onClick={() => setIncidentEdit(!incidentEdit)}
            >
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
            </IconButton>
          </Stack>
        )}
      </Box>
    </React.Fragment>
  );
}

export { IncidentTime };
