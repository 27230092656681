import { Individual } from "../model";

export class IncidentWitnessAddCommand {
    message: Individual;
    constructor(witness: Individual) {

        let newWitness = new Individual();
        newWitness = { ...newWitness, ...witness };
        newWitness.individualType = "Other";
        this.message = Object.assign({}, newWitness);
    }
}