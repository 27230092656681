import React from "react"
import { FormElement, Field } from "@progress/kendo-react-form";
import { DateTimePicker, DatePicker } from "@progress/kendo-react-dateinputs";
import { SchedulerFormEditorProps } from "@progress/kendo-react-scheduler";
import { Stack, TextField} from "@mui/material";

function ScheduleFormEditor(props:SchedulerFormEditorProps){
    return <React.Fragment>
        <FormElement horizontal={true}>
            <Stack direction='row' gap={1}>
                <Field name={"lastName"} label={'Last Name'} component={TextField}/>
                <Field name={"firstName"} label={'First Name'} component={TextField}/>
                <Field name={'middleName'} label={'Middle Name'} component={TextField}/>
            </Stack> 
            <div className="k-form-field k-form-field-wrap">
            <Field name={'birthDate'} label='Birth Date' component={props.startEditor || DatePicker} as={DateTimePicker} format='t'/>
            </div>
            <Stack direction='row' gap={1} sx={{display:"flex"}}>
                
            </Stack>
    </FormElement>
    </React.Fragment>
}

export {ScheduleFormEditor}