export class RefundDocRemoveCommand{
    caseId:string;
    refundId:string;
    documentId:string;

    constructor(caseId:string, refundId:string, documentId:string){
        this.caseId = caseId;
        this.refundId = refundId;
        this.documentId = documentId;
    }
}