export const AdmissionConditionOptions = [
    {
        description: "Abrasion",
        name: "Abrasion",
    },
    {
        description: "Blister",
        name: "Blister",
    },
    {
        description: "Bruise",
        name: "Bruise",
    },
    {
        description: "Burn",
        name: "Burn",
    },
    {
        description: "Dehiscence/Evisceration",
        name: "DehiscenceEvisceration",
    },
    {
        description: "Diaper Dermatitis",
        name: "DiaperDermatitis",
    },
    {
        description: "Friction/Shear",
        name: "FrictionShear",
    },
    {
        description: "Incision",
        name: "Incision",
    },
    {
        description: "Laceration",
        name: "Laceration",
    },
    {
        description: "Phlebitis",
        name: "Phlebitis",
    },
    {
        description: "Puncture",
        name: "Puncture",
    },
    {
        description: "Rash/Hives",
        name: "RashHives",
    },
    {
        description: "Skin Tear/Cut",
        name: "SkinTearCut",
    },
    {
        description: "Ulcer",
        name: "Ulcer",
    },
    {
        description: "Wound",
        name: "Wound",
    },
    {
        description: "Infection",
        name: "Infection",
    },
    {
        description: "IV line/Central Line/Midline",
        name: "IVLineCentralLineMidline",
    },
    {
        description: "Urinary catheter",
        name: "UrinaryCatheter",
    },
];