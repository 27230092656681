import React from "react";
import { Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import { LookupIncidentAssignUserGridCommand } from "./LookupIncidentAssignUserGridCommand";
import { Profile } from "../../../../../../../services/profileService";


type ownProps={
    searchResults:Profile[];
}

function LookupIncidentAssignUserSearchResults(props:ownProps){
    return <React.Fragment>
        <Grid className="card-body-grid" data={props.searchResults} style={{height:'500px'}}>
            <Column cell={LookupIncidentAssignUserGridCommand} width={'75px'}/>
            <Column title='Name' field='identity' width={'300px'}/>
        </Grid>
    </React.Fragment>
}

export {LookupIncidentAssignUserSearchResults}