import { InputAdornment, TextField } from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editBenefits } from "../../../../../services/verifyService/model/verification/VerificationSlice";

function PaidToPhysician(){
    const verification = useAppSelector((state) => state.verification.verification);
    const dispatch = useAppDispatch();
    return <React.Fragment>
        <TextField label='Deductible Paid to Physician' name='paidToPhysician'
            value={verification.benefits.paidToPhysician} onChange={(e)=>dispatch(editBenefits(e))}
            size="small" InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}} 
            InputLabelProps={{shrink:true}}/>
    </React.Fragment>
}

export {PaidToPhysician}