import { DatePicker } from "@mui/x-date-pickers"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editScheduleV2 } from "../../../../../services/scheduleService/model/ScheduleSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import dayjs from "dayjs";
import { useCenterFindQuery } from "../../../../../services/orgService/org-api";

type ownProps={
    center:string}

function BirthDate(props:ownProps){
    const dispatch = useAppDispatch();
    const schedule = useAppSelector((state)=>state.schedule.schedule);
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;
    
    return <React.Fragment>
        <DatePicker label='Birth Date' sx={{width:'225px',maxWidth:'100%'}}
            value={dayjs(schedule.birthDate)} defaultValue={dayjs(new Date('01/01/1900'))}
            onChange={(e)=>dispatch(editScheduleV2(new ReduxUpdate('birthDate',e!)))}
            disabled={readonly}/>
    </React.Fragment>
}
export {BirthDate}