import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useAppDispatch } from "../../../../../app/hooks"
import { toggleCptScheduleItemEditModal } from "../../../../../app/slices/ContractModuleSlice";
import { GridButton } from "../../../../../components"

function CptItemListCommandCell(props: GridCellProps){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <GridButton size='small'
            onClick={()=>dispatch(toggleCptScheduleItemEditModal(props.dataItem.id))}
        >View</GridButton>
    </React.Fragment>
}

export{CptItemListCommandCell}