import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { GridButton } from "../../../../components"
import { useNavigate } from "react-router"

function RefundWorklistCommandCell(props:GridCellProps){
    const navigate = useNavigate();

    return <React.Fragment>
        <GridButton 
            onClick={()=>navigate('/RefundRequestDetail/'+props.dataItem.caseId + '/' + props.dataItem.refundId)}>
                Select
            </GridButton>
    </React.Fragment>
}

export {RefundWorklistCommandCell}