import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks"
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import { useFindPolicyQuery } from "../../../../../../services/caseManService/caseManage-api";
import { editPolicyLinks } from "../../../../../../services/caseManService/model/caseMan/CaseManagementSlice";
import { ReduxNestedUpdate } from "../../../../../../utils/ReduxUpdateCommands";
import { Window } from "@progress/kendo-react-dialogs";

function PolicyLinks(){
    const dispatch = useAppDispatch();
    const caseMan = useAppSelector((state) => state.caseManagement.caseManagement);
    const [open,setopen] = React.useState(false);
    const [skip, setskip] = React.useState(true);
    const[selectedPolicy, setselectedPolicy] = React.useState('' as string);
    const {data} = useFindPolicyQuery(selectedPolicy,{skip: skip})

    return <React.Fragment>
        <Stack>
            <Typography variant='h6' className='psn-green' textAlign='left'>Policies</Typography>
            <Typography variant='body2' textAlign='left'>Please review the following policies to ensure that the patient's treatment plan is in compliance with the latest medical guidelines and standards.</Typography>
            <Stack direction='row' gap={2}>
            {caseMan?.policyLinks?.map((link, index) => {
            return <Stack key={link.policyId} direction='row' gap={0} marginTop='0px' paddingTop='0px'>
                    <Button variant='text' size='small' color='primary' onClick={()=>{setopen(true);setselectedPolicy(link.policyId);setskip(false)}}>{link.policyName}</Button>
                    <Checkbox checked={link.reviewed} value={link.reviewed} onChange={(e)=>dispatch(editPolicyLinks(new ReduxNestedUpdate(link.policyId,'reviewed',e.target.checked)))} color='primary'/>
                </Stack>
            })}
            </Stack>
        </Stack>

        {open &&(
        <Window title={data?.policyName} onClose={()=>{setopen(false);setskip(true);}}
            initialTop={0} initialLeft={window.visualViewport!.width - 800} initialHeight={800} initialWidth={800}>
            <div dangerouslySetInnerHTML={{ __html: data?.policySummary || '' }}></div>
        </Window>)}
    </React.Fragment>
}
export{PolicyLinks}