import React from "react"
import { useAppDispatch } from "../../../../../app/hooks"
import { toggleNoteAddModal } from "../../../../../app/slices/VerificationModuleSlice";
import { NoteAdd } from "./NoteAdd";
import { NoteList } from "./NoteList";
import {Add} from '@mui/icons-material';
import { Stack } from "@mui/system";
import { Button } from "@mui/material";

function Notes(){
    const dispatch = useAppDispatch();
    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' marginRight='10px' marginBottom='10px'>
            <Button startIcon={<Add/>} variant='outlined'onClick={()=>dispatch(toggleNoteAddModal())}>Add a Note</Button>
        </Stack>
        <NoteList/>
        <NoteAdd/>
    </React.Fragment>
}

export {Notes}