import { Alert, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editAuthorization } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { AuthorizationWarnings } from "./AuthorizationWarnings";
import { ElectronicAuthRetrieval } from "../electronicAuthRetrieval/ElectronicAuthRetrieval";

function Authorization(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);
    return <React.Fragment>
            {!verification.benefitsStatus.complete &&
                <Alert color="warning">Benefits have not been verified.</Alert>
            }
        <Stack direction='row' gap={1} marginTop='15px'>
            <TextField label='Authorization #' name='authorizationNumber' value={verification.authorization.authorizationNumber} onChange={(e) => dispatch(editAuthorization(e))}
                InputLabelProps={{shrink:true}} size='small'/>
            <TextField label='Referral #' name='referralNumber' value={verification.authorization.referralNumber} onChange={(e) =>dispatch(editAuthorization(e))}
                InputLabelProps={{shrink:true}} size='small'/>
            <TextField label='Date Range (if applicable)' name='dateRange' value={verification.authorization.dateRange} onChange={(e) => dispatch(editAuthorization(e))}
                InputLabelProps={{shrink:true}} size='small'/>
            
        </Stack>
        <Stack direction='row' gap={1} marginTop='15px'>
            <TextField label='Authorization Method' name='method' value={verification.authorization.method} onChange={(e) => dispatch(editAuthorization(e))}
                InputLabelProps={{shrink:true}} size='small'/>
            <TextField label='Phone Rep and Ref#' name='phoneRep' value= {verification.authorization.phoneRep} onChange={(e) => dispatch(editAuthorization(e))}
                InputLabelProps={{shrink:true}} size='small'/>
            <TextField name='stayTypeApproved' select label='Approved For Stay Type' 
                value={verification.authorization.stayTypeApproved} 
                onChange={(e)=> dispatch(editAuthorization(e))} size='small'
                InputLabelProps={{shrink:true}} sx={{width:'225px'}}>
                    <MenuItem value='None'>None</MenuItem>
                    <MenuItem value='IP'>IP</MenuItem>
                    <MenuItem value='OP'>OP</MenuItem>
                    <MenuItem value='OBS'>OBS</MenuItem>
            </TextField>
        </Stack>
        <Stack marginTop='15px' marginRight='50px'>
            <AuthorizationWarnings/>
        </Stack>
        <Stack marginTop='15px' marginRight='50px'>
            <ElectronicAuthRetrieval/>
        </Stack>

    </React.Fragment>
}

export{Authorization}