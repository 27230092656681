import { Alert, Stack, TextField } from "@mui/material"
import React from "react"
import { useAppSelector } from "../../../../../app/hooks"


function MedNecAlertDetail(){
    const selectedProcedure = useAppSelector((state)=>state.caseManModule.CaseManSelectedProcedure);
    const procDetail = useAppSelector((state)=>state.caseManagement.caseManagement.codingDetails.cptCodes.find(i=>i.priority === selectedProcedure));
    const getClickableLink = (link: string) => {
        return link.startsWith("http://") || link.startsWith("https://") ?
          link
          : `http://${link}`;
      };
    return <React.Fragment>
        <Alert color={procDetail?.medNecReview==='Fail'?'error':procDetail?.medNecReview==='Pass Info'?'warning': procDetail?.medNecReview==='Pass'?'success':'info'}>
            {procDetail?.medNecReview===''?'No Info':procDetail?.medNecReview}</Alert>
        <Stack direction='row' gap={1} marginTop='20px' marginLeft='10px'>
            <TextField label='Procedure' value={procDetail?.code} disabled/>
            <TextField label='Frequency' value={procDetail?.frequencyIndicator} disabled/>
            <TextField label='Diagnosis' value={procDetail?.diagnosisCodes} disabled/>
        </Stack>
        <Stack gap={2} marginTop='20px' marginLeft='10px'>
            <a href={getClickableLink(procDetail?.policyLink??'')} target="_blank" rel="noopener noreferrer">{procDetail?.policyLink}</a>
            <TextField label='Policy Message' multiline minRows={5} fullWidth value={procDetail?.policyMessage} disabled/>
            <TextField label='Additional Info' multiline minRows={5} fullWidth value={procDetail?.additionalInfo} disabled/>
        </Stack>
    </React.Fragment>
}
export {MedNecAlertDetail}