export const IncidentTypeOptions = [
    { value: 'ADVERSE_DRUG', label: 'Adverse Drug Reaction' },
    { value: 'AIRWAY', label: 'Airway Management' },
    { value: 'ANESTHESIA', label: 'Anesthesia Event' },
    { value: 'BLOOD_PRODUCT', label: 'Blood Product/Transfusion' },
    { value: 'CODE_BLUE', label: 'Code Blue/Rapid Response' },
    { value: 'COMPLAINT', label: 'Complaints/Grievances' },
    { value: 'CONSENT', label: 'Consents' },
    { value: 'DELAY', label: 'Delay of Care' },
    { value: 'DIAGNOSIS', label: 'Diagnosis/Treatment' },
    { value: 'DIAGNOSTIC_TEST', label: 'Diagnostic Test Error' },
    { value: 'DIETARY', label: 'Dietary' },
    { value: 'DISCHARGE', label: 'Discharge Issue' },
    { value: 'EQUIPMENT_FAILURE', label: 'Equipment failure / User failure' },
    { value: 'ELOPEMENT', label: 'Elopement from Unit' },
    { value: 'FACILITIES', label: 'Facilities' },
    { value: 'FALL', label: 'Fall' },
    { value: 'HEALTHCARE_IT', label: 'Healthcare I.T.' },
    { value: 'HOSPITAL_ACQUIRED_CONDITION', label: 'Hospital Acquired Condition' },
    { value: 'IMAGING', label: 'Imaging' },
    { value: 'INFECTION_CONTROL', label: 'Infection Control' },
    { value: 'INJURY', label: 'Injury (example, burns, lacerations)' },
    { value: 'IV_ACCESS', label: 'IV/Vascular Access Device' },
    { value: 'LABORATORY', label: 'Laboratory' },
    { value: 'MATERIALS_MANAGEMENT', label: 'Materials Management/Supply Chain' },
    { value: 'MEDICATION', label: 'Medication' },
    { value: 'OTHER', label: 'Other Event' },
    { value: 'PATIENT_IDENTIFICATION', label: 'Patient Identification' },
    { value: 'PHYSICIAN_BEHAVIOR', label: 'Physician Behavior' },
    { value: 'PRESENT_ON_ADMISSION', label: 'Present on Admission Condition' },
    { value: 'PROCEDURE', label: 'Procedure Complication' },
    { value: 'PROPERTY_LOSS', label: 'Property Loss' },
    { value: 'PROVISION_OF_CARE', label: 'Provision of Care' },
    { value: 'READMISSION', label: 'Re-admission Unplanned within 30 days' },
    { value: 'RETURN_TO_SURGERY', label: 'Return to Surgery, Unplanned' },
    { value: 'SAFETY', label: 'Safety/Security' },
    { value: 'STERILE_PROCESSING', label: 'Sterile Processing' },
    { value: 'SURGICAL_PROCEDURE', label: 'Surgical Procedure Issue' },
    { value: 'UTILIZATION_REVIEW', label: 'Utilization Review/Case Management' },
];
/*
Adverse Drug Event
Airway Management
Anesthesia Event
Blood Product/Transfusion
Code Blue/Rapid Response 
Complaints/Grievances
Consents
Delay of Care
Diagnosis/Treatment
Diagnostic Test Error
Dietary
Discharge Issue
Elopement/AMA/LWBS (Left Without Being Seen)A14:D19
Equipment/Medical Device
Facilities 
Falls
Healthcare I.T.
Hospital Acquired Condition
Imaging
Infection Control
IV/Vascular Access Device
Laboratory
Materials Management/Supply Chain
Medication Event
Other Event
Patient Identification
Patient Injury
Physician Behavior
Present on Admission Condition
Procedure Complication
Property Loss
Provision of Care
Re-admission Unplanned within 30 days
Return to Surgery, Unplanned
Safety/Security
Sterile Processing
Surgical Procedure Issue
Utilization Review/Case Management
*/