import { GridCellProps } from "@progress/kendo-react-grid";
import { formatNumber } from "@telerik/kendo-intl";
import React from "react"
import { useParams } from "react-router"
import { useFeeScheduleFindQuery } from "../../../../../services/contractService/contract-api";

function CptRateCalcCell(props: GridCellProps){
    const {feeScheduleId} = useParams();
    const {data} = useFeeScheduleFindQuery(feeScheduleId!);
    const calculateRate=()=>{
        if(data?.feeSubType === 'Flat Rate'){
            return props.dataItem.baseRate;
        }
        else if(data?.feeSubType === 'Grouped'){
            return data?.groupers.find(p => p.code === props.dataItem.grouperPointer)?.rate ?? 0
        }
        else{return 0;}
    }
    return <React.Fragment>
        <td>
            {formatNumber(calculateRate(),'c')}
        </td>
    </React.Fragment>
}

export {CptRateCalcCell}