import React from "react"
import { useAppSelector } from "../../../../../../app/hooks";
import { useRefundResubmitMutation, useRefundSaveMutation } from "../../../../../../services/refundService/refund-api";
import { RefundPostCommand, RefundResubmitPostCommand } from "../../../../../../services/refundService/commands";
import { Alert, Button, Snackbar } from "@mui/material";
import { Redo } from "@mui/icons-material";

type ownProps ={
    setNotify:Function;
    setMessage:Function;}

function RefundResubmitBtn(props:ownProps){
    const refund = useAppSelector((state)=>state.refundRequest.refundRequest);
    const [refundSave] = useRefundSaveMutation();
    const [refundResubmit, result] = useRefundResubmitMutation();
    const handleResubmit=async ()=>{
        await refundSave(new RefundPostCommand(refund));
        await refundResubmit(new RefundResubmitPostCommand(refund.caseId,refund.id))
        .unwrap()
        .then(()=>{props.setNotify(true);props.setMessage("Refund Resubmitted")})
    }

    return <React.Fragment>
        <Button variant='outlined' color='success' startIcon={<Redo/>}
            onClick={()=>handleResubmit()}>Resubmit Approvals</Button>
        <Snackbar open={result.isError}>
            <Alert color='error'>{'An error has occured: ' + JSON.stringify(result.error)}</Alert>
        </Snackbar>    
    </React.Fragment>
}

export {RefundResubmitBtn}