import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {toggleInsuranceDetail} from '../../../../../app/slices/VerificationModuleSlice';
import { usePayerDetailsQuery } from "../../../../../services/orgService/org-api";
import { Loading } from "../../../../shared/features/Loading";

function InsuranceDetail(){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.verificationModule.InsuranceDetailModal);
    const payerRequest = useAppSelector((state) => state.verificationModule.SelectedPayer);  
    const [skip] = React.useState(isOpen);
    const payer = usePayerDetailsQuery(payerRequest, {skip,});

    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle><Typography variant='h6'>{payer.data?.code + '-' + payer.data?.name}</Typography></DialogTitle>
            <DialogContent>
                {payer.isLoading === false ? <div>
                <Alert color='info'>Payer Details can be edited in Center Configuration</Alert>
                <Stack marginTop='10px' gap={2}>
                    <TextField label='Financial Class' value={payer.data?.financialClass} InputLabelProps={{shrink:true}}/>
                    <TextField label='Phone' value={payer.data?.phone} InputLabelProps={{shrink:true}}/>
                    <Button><a href={'https://' + payer.data?.portalUrl} target="_blank">{payer.data?.portalUrl}</a></Button>
                </Stack>
                </div> :
                <Loading/>}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>dispatch(toggleInsuranceDetail(payerRequest))}>Close</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}
export {InsuranceDetail}