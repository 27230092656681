import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { useContractListQuery } from "../../../../services/contractService/contract-api";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { ContractSelectCommandCell } from "./ContractSelectCommandCell";
import { Contract } from "../../../../services/contractService/model";
import { process, State } from "@progress/kendo-data-query";
import { Loading } from "../../../shared";

function ContractList(){
    const {data, isLoading} = useContractListQuery();
    const [dataState, setDataState] = useState({});
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
    const mapContract=():Contract[] | undefined=>{
        return data?.map(i =>({...i, effectiveDate: new Date(i.effectiveDate!), endDate: new Date(i.endDate!)}))
    }
    return <React.Fragment>
        {isLoading ? <Loading/> :
        <Grid className="mt-3" data={process(mapContract() === undefined?[]:mapContract()!.slice(0),dataState)} style={{height:'500px'}}
            onDataStateChange={dataStateChange}>
            <Column cell={ContractSelectCommandCell} width='75px'/>
            <Column title = 'Payer' field='payer' width='250px' columnMenu={ColumnMenu} />
            <Column title='Product' field='product' columnMenu={ColumnMenu} width='300px'/>
            <Column title='Effective' field='effectiveDate' columnMenu={ColumnMenu} format='{0:MM/dd/yyyy}' width='150px'/>
            <Column title='Expiration' field='endDate' columnMenu={ColumnMenu} format='{0:MM/dd/yyyy}' width='150px'/>
            <Column title='Financial Class' field='financialClass' columnMenu={ColumnMenu} width='175px'/>
            <Column title='Calc Type' field='calctype' columnMenu={ColumnMenu} width='175px'/>
            <Column title='Facility Type' field='facilityType' columnMenu={ColumnMenu} width='150px'/>
        </Grid>}
    </React.Fragment>
}

export {ContractList}

