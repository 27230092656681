export interface FollowUp {
  physicianNotified: boolean;
  physicanNotifiedName: string;

  physicianExamined: boolean;
  physicianExaminedName: string;

  labOrdered: boolean;
  labList: string;

  familyNotified: boolean;
  familyNotifiedName: string;

  chargeNurseName: string;
  chargeNurseNotified: boolean;

  otherActions: string;
}

export function DefaultFollowUp(): FollowUp {
  return {
    physicianNotified: false,
    physicanNotifiedName: "",
    physicianExamined: false,
    physicianExaminedName: "",
    labOrdered: false,
    labList: "",
    familyNotified: false,
    familyNotifiedName: "",
    chargeNurseNotified: false,
    chargeNurseName: "",
    otherActions: "",
  };
}
