export class Benefits{
    deductible:number;
    deductibleMet:number;
    outOfPocket:number;
    outOfPocketMet:number;
    coPay:number;
    coInsurance:number;
    dedApplytoOOP: boolean;
    coPayApplyToOOP:boolean;
    paidToPhysician:number;
    uncoveredAmount:number;

    constructor(deductible:number, deductibleMet:number, outOfPocket:number,
        outOfPocketMet:number, coPay:number, coInsurance:number,
        dedApplyToOOP:boolean, coPayApplyToOOP:boolean, paidToPhysician:number, uncoveredAmount:number){
            this.deductible = deductible;
            this.deductibleMet = deductibleMet;
            this.outOfPocket = outOfPocket;
            this.outOfPocketMet = outOfPocketMet;
            this.coPay = coPay;
            this.coInsurance = coInsurance;
            this.dedApplytoOOP = dedApplyToOOP;
            this.coPayApplyToOOP = coPayApplyToOOP;
            this.paidToPhysician = paidToPhysician;
            this.uncoveredAmount = uncoveredAmount;
        }
}