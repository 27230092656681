import { Card, CardHeader, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { PolicyNumberEdit } from "../features/policyDetail/PolicyNumberEdit";
import { PolicyNameEdit } from "../features/policyDetail/PolicyNameEdit";
import { PolicyEffDateEdit } from "../features/policyDetail/PolicyEffDateEdit";
import { PolicyReviewDateEdit } from "../features/policyDetail/PolicyReviewDateEdit";
import { PolicySummaryEdit } from "../features/policyDetail/PolicySummaryEdit";
import { PolicyDetailCommands } from "../features/policyDetail/policyDetailCommands/PolicyDetailCommands";
import { ProcedureLinking } from "../features/policyDetail/ProcedureLinking";
import { InsuranceLinking } from "../features/policyDetail/InsuranceLinking";
import { CardBody } from "@progress/kendo-react-layout";
import { PolicyCategory } from "../features/policyDetail/PolicyCategory";
import { useParams } from "react-router";
import { useFindPolicyQuery } from "../../../services/caseManService/caseManage-api";
import { Loading } from "../../shared";

function PolicyDetail(){
    const {id} = useParams();
    const [skip,setskip] = useState(id==='new'?true:false);
    const {data, isFetching} = useFindPolicyQuery(id!,{skip:skip});

    return <React.Fragment>
        {isFetching && <div><Loading/></div>}
        {!isFetching &&
        <div>
        <Stack direction='row' justifyContent='flex-end' gap={1} marginTop='20px' marginRight='10px' marginBottom='10px'>
            <PolicyDetailCommands/>
        </Stack>
        <Card>
            <CardHeader sx={{marginBottom:'0px', paddingBottom:'3px',paddingTop:'3px', borderBottom:'solid', borderColor:'gray'}} title ={<Typography variant='h6' className='psn-green'>Policy Setup</Typography>}/>
            <CardBody>
            <Stack direction='row' gap={1} marginTop='5px' marginLeft='10px'>
            <PolicyNumberEdit/>
            <PolicyNameEdit/>
            <PolicyEffDateEdit/>
            <PolicyReviewDateEdit/>
            <PolicyCategory/>
        </Stack>
        <Stack direction='row' gap={2} marginTop='10px' marginLeft='10px'>
        <ProcedureLinking/>
        <InsuranceLinking/>
        </Stack>

            </CardBody>
        </Card>

        <PolicySummaryEdit/></div>}
    </React.Fragment>
}
export {PolicyDetail}