export const LabOptions = [
    {
        description: "Collection Issue",
        name: "CollectionIssue",
    },
    {
        description: "Critical Value Not Called from Lab",
        name: "CriticalValueNotCalledFromLab",
    },
    {
        description: "Critical Value Not Called from Nurse to Provider",
        name: "CriticalValueNotCalledFromNurseToProvider",
    },
    {
        description: "Documentation of Critical Value Call Missing",
        name: "DocumentationOfCriticalValueCallMissing",
    },
    {
        description: "Label Missing on Specimen",
        name: "LabelMissingOnSpecimen",
    },
    {
        description: "Label Incomplete (i.e., no initials, date, time)",
        name: "LabelIncomplete",
    },
    {
        description: "Mislabeled Specimen",
        name: "MislabeledSpecimen",
    },
    {
        description: "Specimen Not Labeled at Bedside",
        name: "SpecimenNotLabeledAtBedside",
    },
    {
        description: "Specimen Acceptability Issue",
        name: "SpecimenAcceptabilityIssue",
    },
    {
        description: "Specimen Processing - Block/Cassette Missing",
        name: "SpecimenProcessingBlockCassetteMissing",
    },
    {
        description: "Specimen Processing - Delay",
        name: "SpecimenProcessingDelay",
    },
    {
        description: "Specimen Processing - Delivery Problem",
        name: "SpecimenProcessingDeliveryProblem",
    },
    {
        description: "Specimen Processing - Specimen Mishandled",
        name: "SpecimenProcessingSpecimenMishandled",
    },
    {
        description: "Specimen Processing - Specimen not Aliquoted between Sections",
        name: "SpecimenProcessingSpecimenNotAliquotedBetweenSections",
    },
    {
        description: "Specimen Processing - Storage Requirements not met",
        name: "SpecimenProcessingStorageRequirementsNotMet",
    },
    {
        description: "Specimen Processing Issue",
        name: "SpecimenProcessingIssue",
    },
    {
        description: "Supply Issue",
        name: "SupplyIssue",
    },
    {
        description: "Testing Issue - Ambient Temperature Humidity Extremes",
        name: "TestingIssueAmbientTemperatureHumidityExtremes",
    },
    {
        description: "Testing Issue - Delay in Testing",
        name: "TestingIssueDelayInTesting",
    },
    {
        description: "Testing Issue - Equipment Failure",
        name: "TestingIssueEquipmentFailure",
    },
    {
        description: "Testing Issue - Test Not Performed",
        name: "TestingIssueTestNotPerformed",
    },
    {
        description: "Testing Issue - Test Not Resulted",
        name: "TestingIssueTestNotResulted",
    },
    {
        description: "Testing Issue - Test Result Incorrect",
        name: "TestingIssueTestResultIncorrect",
    },
    {
        description: "Testing Issue - Wrong Test Performed",
        name: "TestingIssueWrongTestPerformed",
    },
    {
        description: "Wrong Patient Selected",
        name: "WrongPatientSelected",
    },
    {
        description: "Wrong Results Provided",
        name: "WrongResultsProvided",
    },
    {
        description: "Wrong Test Ordered",
        name: "WrongTestOrdered",
    },
];