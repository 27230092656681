import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { toggleCaseManNoteAddModal } from "../../../../../app/slices/CaseManModuleSlice";
import { Add } from "@mui/icons-material";
import { useCaseManNoteAddMutation } from "../../../../../services/caseManService/caseManage-api";
import { CaseManNoteAddCommand } from "../../../../../services/caseManService/commands";

function CaseManNoteAddDialog(){
    const dispatch = useAppDispatch();
    const modalOpen = useAppSelector((state)=>state.caseManModule.CaseManNoteAddModal);
    const caseId = useAppSelector((state)=>state.caseManagement.caseManagement.id);
    const [note,setNote] = React.useState("");
    const [save] = useCaseManNoteAddMutation();
    const saveNote = ()=>{
        let command = new CaseManNoteAddCommand(caseId!,note);
        save(command);
    }

    return <React.Fragment>

        <Button startIcon={<Add/>} variant='outlined'onClick={()=>dispatch(toggleCaseManNoteAddModal())}>Add a Note</Button>
        <Dialog open={modalOpen} onClose={()=>setNote('')}>
            <DialogTitle>Add a Note</DialogTitle>
            <DialogContent>
                <TextField label='Note' value={note} onChange={(e)=>setNote(e.target.value)} multiline fullWidth
                   sx={{width:'500px',marginTop:'10px'}} minRows='3'/>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{dispatch(toggleCaseManNoteAddModal());setNote('');}}>Cancel</Button>
                <Button onClick={()=>{saveNote();dispatch(toggleCaseManNoteAddModal())}}>Save</Button>
            </DialogActions>
            
        </Dialog>
    </React.Fragment>
}

export {CaseManNoteAddDialog}