
export class VarianceMyWorklistRequest{
    center:string;
    isAdmin:boolean;
    user:string;

    constructor(center:string,isAdmin:boolean,user:string){
        this.center = center;
        this.isAdmin = isAdmin;
        this.user = user;
    }
}