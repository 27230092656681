import React, { useState } from "react"
import {process, State } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../../utils/ColumnMenu';
import { useAppSelector } from "../../../../app/hooks";
import { useRefundApproversAllQuery } from "../../../../services/orgService/org-api";
import { RefundApproverCommands } from "./RefundApproverCommands";

function RefundApproversList(){
    const centerCode = useAppSelector((state)=>state.center.center.code);
    const {data} = useRefundApproversAllQuery(centerCode);
    const defaultGridState:State = {sort:[{field:'priority',dir:'asc'}]};
    const [dataState, setDataState] = useState(defaultGridState);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };

      
    return <React.Fragment>
        <Grid data = {process(data??[],dataState)}
         style={{height:'500px'}} onDataStateChange={dataStateChange}>
            <Column cell={RefundApproverCommands} width={'100px'}/>
            <Column title='Priority' field='priority' columnMenu={ColumnMenu} width={'125px'}/>
            <Column title='Approver' field='approver' columnMenu={ColumnMenu} width={'300x'}/>
            <Column title='Approve Greater Than' field='approveGreaterThan' columnMenu={ColumnMenu} width={'200px'}/>
        </Grid>
    </React.Fragment>
}

export {RefundApproversList}