import { Grid, Stack, Typography} from "@mui/material"
import React from "react"
import { ApproveReviewBtn } from "./commands/ApproveReviewBtn"
import { DenyReviewBtn } from "./commands/DenyReviewBtn"
import { StatusIndicator } from "./StatusIndicator"
import { AdditionalCommentsEdit } from "./AdditionalCommentsEdit"
import { PendingReviewBtn } from "./commands/PendingReviewBtn"
import { HistoryAndPhysicalEdit } from "./HistoryAndPhysicalEdit"
import { ImagingReportEdit } from "./ImagingReportEdit"
import { ClinicalRecordsEdit } from "./ClinicalRecordsEdit"
import { PhysicalTherapyNotesEdit } from "./PhysicalTherapyNotesEdit"
import { PathologyLabEdit } from "./PathologyLabEdit"
import { PsycologicalEvalEdit } from "./PsychologicalEvalEdit"
import { PolicyLinks } from "./policyLinks/PolicyLinks"
import { NotRequiredAlert } from "./NotRequiredAlert"

function ClinicalReview(){
    return <React.Fragment>

        <Grid container>
            <Grid item sm={8}>
                <Stack justifyContent='start' alignItems='flex-start' marginLeft='10px' marginTop='10px'>
                    <NotRequiredAlert/>
                </Stack>
                <Stack justifyContent='start' alignItems='flex-start' marginLeft='10px' marginTop='40px'>
                    <Typography variant='h6' className='psn-green' textAlign='center'>Documents on File</Typography>
                    <Typography variant='body2' textAlign='left'>To ensure comprehensive coverage of medical necessity, please make sure to carefully review and check all relevant document categories. This includes verifying the presence of information related to current conditions, underlying factors, patient history, physical assessments, and any other pertinent details. By thoroughly examining each category, you contribute to a complete and accurate assessment, facilitating the necessary medical interventions and ensuring the best possible patient care.</Typography>
                </Stack>
                <Stack gap={2} marginTop='40px' marginLeft='10px'>
                <Stack direction='row' gap={1}>
                    <HistoryAndPhysicalEdit/>
                    <ImagingReportEdit/>
                    <ClinicalRecordsEdit/>
                </Stack>
                <Stack direction='row' gap={1} marginTop='10px'>
                    <PhysicalTherapyNotesEdit/>
                    <PathologyLabEdit/>
                    <PsycologicalEvalEdit/>
                </Stack>
                <PolicyLinks/>
                <AdditionalCommentsEdit/>
                </Stack>
            </Grid>
            <Grid item sm={4}>
                <Stack direction='row' justifyContent='flex-end' gap={1} marginTop='10px' marginRight='10px'>
                    <ApproveReviewBtn/>
                    <DenyReviewBtn/>
                    <PendingReviewBtn/>
                </Stack>
                <Stack direction='row' justifyContent='flex-end' gap={1} marginTop='30px' marginRight='10px'>
                    <StatusIndicator/>
                </Stack>
            </Grid>
        </Grid>
        
        
    </React.Fragment>
}

export {ClinicalReview}