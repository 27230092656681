import React, { useEffect, useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../app/hooks";
import { contractApi, useRuleDeleteMutation, useRuleSaveMutation } from "../../../../../../services/contractService/contract-api";
import { RuleDeleteCommand, RulePostCommand } from "../../../../../../services/contractService/commands";
import { RuleCommands } from "./RuleCommands";
import { Stack } from "@mui/system";
import { TextField } from "@mui/material";

type ownProps={
    ruleId:string;
    ruleString?:string;
    ruleDescription?:string;    
}

function DrgConversions(props:ownProps){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [rule,setRule] = useState({drg:'',description:'',rate:0})
    const [saveRule] = useRuleSaveMutation();
    const [deleteRule] = useRuleDeleteMutation();
    const save=()=>{
        let command = new RulePostCommand(id!,props.ruleId,'DrgConversion',0, JSON.stringify(rule),props.ruleDescription ?? '');
        saveRule(command).unwrap().then(()=>{dispatch(contractApi.util.invalidateTags(['ruleList']))});
    }
    const ruleDelete=()=>{deleteRule(new RuleDeleteCommand(props.ruleId))};
    useEffect(() => {
        if(props.ruleString !== ''){
            let r:{drg:string,description:string,rate:number} = JSON.parse(props.ruleString!);
            setRule(r);
        }},[props.ruleString, props.ruleId]
    )   
    return <React.Fragment>
        <Stack direction='row' gap={1}>
            <TextField label='Drg Code' value={rule.drg} sx={{width:'100px'}}
                onChange={(e)=>setRule({...rule,drg:e.target.value})}/>
            <TextField label='Description' value={rule.description} sx={{width:'300px'}}
                onChange={(e)=>setRule({...rule,description:e.target.value})}/>
            <TextField type='number' label='Rate' value={rule.rate} sx={{width:'100px'}}
                onChange={(e)=>setRule({...rule,rate:Number(e.target.value)})}/>
        </Stack>
        <RuleCommands saveCommand={save} deleteCommand={ruleDelete} isNewRule={props.ruleId === '0' ? true:false}/>
    </React.Fragment>
}

export {DrgConversions}