export const FallActivityOrderedOptions = [
    {
        description: "Ambulating w/Assist",
        name: "AmbulatingWithAssist",
    },
    {
        description: "Ambulating w/o assist",
        name: "AmbulatingWithoutAssist",
    },
    {
        description: "Ambulating w/Gait aid",
        name: "AmbulatingWithGaitAid",
    },
    {
        description: "Bathroom privileges",
        name: "BathroomPrivileges",
    },
    {
        description: "BSC",
        name: "BSC",
    },
    {
        description: "Bed rest",
        name: "BedRest",
    },
    {
        description: "WC",
        name: "WC",
    },
];