export const EquipmentOptions = [
    {
        description: "Bed Malfunction",
        name: "BedMalfunction",
    },
    {
        description: "Biomed check past due/not performed",
        name: "BiomedCheckPastDue",
    },
    {
        description: "Broken Item",
        name: "BrokenItem",
    },
    {
        description: "Disconnected",
        name: "Disconnected",
    },
    {
        description: "Electrical Issue",
        name: "ElectricalIssue",
    },
    {
        description: "Equipment - Faulty/Malfunction",
        name: "EquipmentFaulty",
    },
    {
        description: "Equipment - Not Available",
        name: "EquipmentNotAvailable",
    },
    {
        description: "Malfunctioning equipment not properly tagged or removed from service.",
        name: "MalfunctioningEquipment",
    },
    {
        description: "Outdated Item",
        name: "OutdatedItem",
    },
    {
        description: "Preventative Maintenance Issue",
        name: "PreventativeMaintenance",
    },
    {
        description: "Sterilization/Cleanliness Issue",
        name: "SterilizationCleanlinessIssue",
    },
];