import { Stack, TextField} from "@mui/material"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { useParams } from "react-router"
import { editSchedule } from "../../../../../services/scheduleService/model/ScheduleSlice"
import { useCenterFindQuery } from "../../../../../services/orgService/org-api"

type ownProps={
    center:string;
}
function ContactInfo(props:ownProps){
    const readonly = !useCenterFindQuery(props.center).data?.allowManualScheduling;
    const dispatch = useAppDispatch();
    const patient = useAppSelector((state)=>state.schedule.schedule)
    
    return <React.Fragment>
            <Stack direction='row' gap={20} marginTop='20px' marginLeft='10px' marginBottom='30px'>
                <Stack gap={1}>
                        <TextField label='Address1' name='streetAddress1' value={patient.streetAddress1} sx={{width:'400px'}}
                            onChange={(e)=>dispatch(editSchedule(e))} disabled={readonly}/>
                        <TextField label='Address2' name='streetAddress2' value={patient.streetAddress2} sx={{width:'400px'}}
                            onChange={(e)=>dispatch(editSchedule(e))} disabled={readonly}/>
                        <Stack direction='row' gap={1}>
                            <TextField label='City' name='city' value={patient.city}
                                onChange={(e)=>dispatch(editSchedule(e))} disabled={readonly}/>
                            <TextField label='State' name='state' value={patient.state} sx={{width:'50px'}}
                                onChange={(e)=>dispatch(editSchedule(e))} disabled={readonly}/>
                            <TextField label='Zip' name='zipCode' value={patient.zipCode} sx={{width:'110px'}}
                                onChange={(e)=>dispatch(editSchedule(e))} disabled={readonly}/>
                        </Stack>
                    </Stack>
                    <Stack gap={1}>
                        <TextField label='Home Phone' name='homePhone' sx={{width:'200px'}} value={patient.homePhone}
                            onChange={(e)=>dispatch(editSchedule(e))} disabled={readonly}/>
                        <TextField label='Work Phone' name='mobilePhone' sx={{width:'200px'}} value={patient.mobilePhone}
                            onChange={(e)=>dispatch(editSchedule(e))} disabled={readonly}/>
                        <TextField label='Email' name='email' sx={{width:'350px'}} value={patient.email}
                            onChange={(e)=>dispatch(editSchedule(e))} disabled={readonly}/>
                    </Stack>
                </Stack>
    </React.Fragment>
}

export {ContactInfo}