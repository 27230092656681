import React from "react";
import { SeverityGroup } from "./SeverityGroup";

function Severity(props: { canEdit: boolean }) {

const group = [
    { value: "NO_ERROR", displayName: "No Error" },
    { value: "ERROR_INTERCEPTED", displayName: "Error Intercepted" },
    { value: "ERROR_NO_HARM", displayName: "Error with No Harm" },
    { value: "ERROR_HARM", displayName: "Error with Harm" },
    { value: "ERROR_DEATH", displayName: "Error with Death" }
];
  return (<React.Fragment>
    {group.map((item) => (
        <SeverityGroup canEdit={props.canEdit} severityGroup={item} />
        ))}
  </React.Fragment>
  );
}

export {Severity};