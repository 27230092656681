import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { toggleRefundNoteDialog } from "../../../../../../app/slices/RefundModuleSlice";
import { NoteCreateTextEdit } from "./NoteCreateTextEdit";
import { NotePostCommand } from "../../../../../../services/refundService/commands";
import { useParams } from "react-router";
import { Guid } from "js-guid";
import { useNoteCreateMutation } from "../../../../../../services/refundService/refund-api";


function NoteCreateDialog(){
    const {caseId,refundId}=useParams()
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state)=>state.refundModule.RefundNoteDialogOpen)
    const [note, setNote] = useState('');
    const [save] = useNoteCreateMutation();
    const saveNote = ()=>{
        let command = new NotePostCommand(caseId!,refundId!,Guid.newGuid().toString(),note);
        save(command);
    }
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>Create Note</DialogTitle>
            <DialogContent>
                <NoteCreateTextEdit note={note} setNote={setNote}/>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={()=>dispatch(toggleRefundNoteDialog())} color='secondary'>Cancel</Button>
                <Button variant='outlined' onClick={()=>{saveNote();dispatch(toggleRefundNoteDialog())}}>Save</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export{NoteCreateDialog}