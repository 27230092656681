import { Button } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router"

function PolicyGoBackBtn(){
    const navigate = useNavigate();

    return <React.Fragment>
        <Button variant='outlined' color='success' onClick={()=>navigate('/policies')}>Go Back</Button>
    </React.Fragment>
}
export {PolicyGoBackBtn}