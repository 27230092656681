import { SelectChangeEvent } from '@mui/material';
import { SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { ProcedureUpdate } from '../../commands';
import { CanCompleteBenefits } from '../../../../modules/verification/features/detail/benefitsStatus/specs/CanCompleteBenefits';
import { verifyApi } from '../../verify-api';
import { AmountDue } from '../patRespCalc/AmountDue';
import { EstimatedProcedure } from './EstimatedProcedure';
import { Note } from './Note';
import { Verification } from './Verification'
import { VerificationDocument } from './VerificationDocument';
import { calculatorApi } from '../../../calculatorService/calculator-Api';
import { ReduxNestedUpdate, ReduxUpdate } from '../../../../utils/ReduxUpdateCommands';

interface VerificationState{
    verification:Verification;
}

const initialState: VerificationState = {verification: new Verification()}
export const VerificationSlice = createSlice({
    name:'verification',
    initialState,
    reducers:{
        editVerify:(state, action:PayloadAction<ReduxUpdate>) => {
            //@ts-ignore
            state.verification[action.payload.name] = action.payload.value;
        },
        editAttributes:(state, action:PayloadAction<ReduxUpdate>) => {
            //@ts-ignore
            state.verification.verifyAttributes[action.payload.name] = action.payload.value;
        },
        editBenefits:(state, action:PayloadAction<React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>|SwitchChangeEvent>) =>{
            const {name, value} = action.payload.target;
            //@ts-ignore
            state.verification.benefits[name] = value;
        },
        editCallInfo:(state, action:PayloadAction<React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>>) => {
            const {name, value} = action.payload.target;
            //@ts-ignore
            state.verification.callInfo[name] = value;
        },
        editBenefitsStatus:(state, action: PayloadAction<SwitchChangeEvent>) =>{
            const{name, value} = action.payload.target;
            //@ts-ignore
            state.verification.benefitsStatus[name] = Boolean(value);
            if(!new CanCompleteBenefits().IsSatisfiedBy(state.verification))
                state.verification.benefitsStatus.complete = false;
        },
        editAuthorization:(state, action: PayloadAction<React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|SelectChangeEvent<string>>) => {
            const {name, value} = action.payload.target;
            //@ts-ignore
            state.verification.authorization[name] = value;
        },
        editAuthorizationStatus:(state,action: PayloadAction<React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|SelectChangeEvent<string>>) => {
            const {name, value} = action.payload.target;
            //@ts-ignore
            state.verification.authorization[name] = value;
            if(name==='authRequired'){state.verification.authorization.authStep='Not Started';}
        },
        editBenefitsStatusReasons:(state, action: PayloadAction<React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>>) =>{
            const{name, value} = action.payload.target;
            //@ts-ignore
            state.verification.benefitsStatus[name] = value;
        },
        editDiagnosisCodes:(state, action: PayloadAction<ReduxUpdate>) =>{
            //@ts-ignore
            state.verification.diagnosisCodes[action.payload.name] = action.payload.value;
        },
        editSingleCaseAgreement:(state, action: PayloadAction<ReduxUpdate>) =>{
            //@ts-ignore
            state.verification.singleCaseAgreement[action.payload.name] = action.payload.value;
        },
        addNote:(state, action: PayloadAction<Note>) =>{
            if(state.verification.notes === undefined)
                state.verification.notes = [];
            state.verification.notes.push(action.payload);
        },
        addProcedure:(state, action: PayloadAction<EstimatedProcedure>) =>{
            if(state.verification.procedures === undefined)
                state.verification.procedures = [];
            let maxPriority = Math.max(...state.verification.procedures.map(o => o.priority),0);
            action.payload.priority =  maxPriority + 1;
            state.verification.procedures.push(action.payload);
        },
        removeProcedure:(state, action:PayloadAction<number>) => {
            let newProcs = state.verification.procedures.filter(p => p.priority !== action.payload);
            newProcs = resetPriorities(newProcs, action.payload);
            if(newProcs){
                state.verification.procedures = newProcs;
            }       
        },
        updateProcedure:(state, action: PayloadAction<ReduxNestedUpdate>) =>{
            let newProcs = state.verification.procedures.filter(p => 1 === 1);
            const index = newProcs.findIndex(p => p.priority.toString() === action.payload.index);
            //@ts-ignore
            newProcs[index][action.payload.name] = action.payload.value;
            if(newProcs){
                state.verification.procedures = newProcs;
            }        
        },
        promoteProcedure:(state, action:PayloadAction<number>)=>{
            let newProcs = state.verification.procedures.filter(p => 1 === 1);
            newProcs = promote(newProcs,action.payload);
            if(newProcs){
                state.verification.procedures = newProcs.sort((a, b) => a.priority - b.priority);
            }
        },
        demoteProcedure:(state, action:PayloadAction<number>)=>{
            let newProcs = state.verification.procedures.filter(p => 1 === 1);
            newProcs = demote(newProcs,action.payload);
            if(newProcs){
                state.verification.procedures = newProcs.sort((a, b) => a.priority - b.priority);
            }
        },
        updateResponsibility:(state,action:PayloadAction<AmountDue>) =>{
            state.verification.patientResponsibility.deductibleDue = action.payload.deductibleDue;
            state.verification.patientResponsibility.coInsuranceDue = action.payload.coInsuranceDue;
            state.verification.patientResponsibility.copayDue = action.payload.coPayDue;
            state.verification.patientResponsibility.collectFromPatient = action.payload.totalPatientDue;
        },
        addVerifyDocument:(state, action:PayloadAction<VerificationDocument>) =>{
            state.verification.documents.push(action.payload);}
    },
    extraReducers:(builder) =>{
        builder.addMatcher(
            verifyApi.endpoints.verificationFind.matchFulfilled,
            (state,{payload}) => {state.verification = payload}
        ).addMatcher(
            verifyApi.endpoints.saveCallLog.matchFulfilled,
            (state,{payload}) => {state.verification.calls.push(payload)}
        ).addMatcher(
            verifyApi.endpoints.saveApproval.matchFulfilled,
            (state,{payload}) => {
                state.verification.approval = payload;
            }
        ).addMatcher(verifyApi.endpoints.verifyDocRemove.matchFulfilled,
            (state,{payload}) => {state.verification.documents = state.verification.documents.filter(x=>x.id != payload.id)}
        ).addMatcher(calculatorApi.endpoints.calculateEligibility.matchFulfilled,
            (state,{payload}) => {state.verification.benefits.deductible = payload.deductible;
                state.verification.benefits.deductibleMet = payload.deductibleMet;
                state.verification.benefits.outOfPocket = payload.outOfPocket;
                state.verification.benefits.outOfPocketMet = payload.outOfPocketMet;
                state.verification.benefits.coInsurance = payload.coInsurance;
                state.verification.benefits.coPay = payload.coPay;
            })
    }
})

export const {editBenefits,editCallInfo, editBenefitsStatus, editBenefitsStatusReasons, 
                addProcedure, removeProcedure, updateProcedure, promoteProcedure,
                demoteProcedure, editAuthorization, editAuthorizationStatus, addNote,
                updateResponsibility, addVerifyDocument, editDiagnosisCodes, editSingleCaseAgreement,
                editVerify, editAttributes
             } = VerificationSlice.actions;
export default VerificationSlice.reducer;

function resetPriorities(procs: EstimatedProcedure[], priorityDeleted: number):EstimatedProcedure[]{
    procs.map(function (item) {
        if(item.priority > priorityDeleted)
            item.priority = item.priority - 1;      
    });
    return procs;
}

function promote(procs: EstimatedProcedure[], priorityPromoted:number):EstimatedProcedure[]{
    let proc1 = procs.find(p => p.priority === priorityPromoted);
    let proc2 = procs.find(p => p.priority === priorityPromoted -1)
    proc1!.priority = proc1!.priority - 1
    proc2!.priority = proc2!.priority + 1
    return procs;
}
function demote(procs: EstimatedProcedure[], priorityDemoted:number):EstimatedProcedure[]{
    let proc1 = procs.find(p => p.priority === priorityDemoted);
    let proc2 = procs.find(p => p.priority === priorityDemoted + 1)
    proc1!.priority = proc1!.priority + 1;
    proc2!.priority = proc2!.priority - 1
    return procs;
}