import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React from "react";
import { Procedure } from "../../../../../services/orgService/model";
import { ProcedureAddCommandCell } from "./ProcedureAddCommandCell";

type ownProps={
    procedures: Procedure[];
}
function CodeLookupResults(props:ownProps){
    return <React.Fragment>
        <Grid className='mt-3 grid-embed' data={props.procedures} style={{height:'300px'}}>
            <Column cell={ProcedureAddCommandCell} width='105px'/>
            <Column title='Code' field='code' width='150px'/>
            <Column title='Description' field='description' width='600px'/>
        </Grid>
    </React.Fragment>
}

export {CodeLookupResults}