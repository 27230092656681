import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Switch} from "@progress/kendo-react-inputs";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleCallAddModal } from "../../../../../app/slices/VerificationModuleSlice";
import { CallPostCommand } from "../../../../../services/verifyService/commands";
import { useSaveCallLogMutation } from "../../../../../services/verifyService/verify-api";

function CallLog(){
    const dispatch = useAppDispatch();
    const verify = useAppSelector((state) => state.verification.verification);
    const modalOpen = useAppSelector((state) => state.verificationModule.CallAddModal);
    const [patientReached, setPatientReached] = useState(false);
    const [callNote, setCallNote] = useState('');
    const [outcome, setOutcome] = useState('Not Set');
    const [saveCallLog] = useSaveCallLogMutation();
    const handleSave=()=>{
        let command = new CallPostCommand(verify.id,patientReached,callNote,outcome);
        saveCallLog(command);
    }
    const reset=()=>{
        setCallNote('');
        setOutcome('Not Set');
        setPatientReached(false);
    }

    return <React.Fragment>
        <Dialog open={modalOpen}>
            <DialogTitle>Log Call</DialogTitle>
            <DialogContent>
                <Stack direction='column' gap={2} marginTop='10px'>
                    <div>
                        <Typography>Patient Reached</Typography>
                        <Switch value ={patientReached} checked={patientReached} onChange={()=>setPatientReached(!patientReached)}/>
                    </div>
                    <div>
                        {patientReached === false &&
                            <FormControl>
                                <InputLabel shrink={true}>Outcome</InputLabel>
                                <Select name='outcome' value={outcome} onChange={(e) => setOutcome(e.target.value)} sx={{width:'250px', maxWidth:'100%'}} size='small'>
                                    <MenuItem value='Not Set'>Not Set</MenuItem>
                                    <MenuItem value='Left Message'>Left Message</MenuItem>
                                    <MenuItem value='VoiceMail Unavailable'>VoiceMail Unavailable</MenuItem>
                                    <MenuItem value='Call Failed'>Call Failed</MenuItem>
                                    <MenuItem value='Wrong Number'>Wrong Number</MenuItem>
                                    <MenuItem value="Patient Owes 0">Patient Owes $0</MenuItem>
                                </Select>
                            </FormControl>
                        }
                        {patientReached === true &&
                            <FormControl>
                                <InputLabel shrink={true}>Outcome</InputLabel>
                                <Select name='outcome' value={outcome} onChange={(e)=>setOutcome(e.target.value)} sx={{width:'250px', maxWidth:'100%'}} size='small'>
                                    <MenuItem value='Not Set'>Not Set</MenuItem>
                                    <MenuItem value='Patient Paid'>Patient Paid</MenuItem>
                                    <MenuItem value = 'Will Pay At Center'>Will Pay At Time of Service</MenuItem>
                                    <MenuItem value='Refused Payment'>Refused Payment</MenuItem>
                                    <MenuItem value='Care Credit'>Care Credit</MenuItem>
                                    <MenuItem value='Payment Plan'>Payment Plan</MenuItem>
                                    <MenuItem value="Patient Owes 0">Patient Owes $0</MenuItem>
                                </Select>
                            </FormControl>
                        }
                    </div>
                    <TextField label='Call Notes' name='callNote'multiline value={callNote} onChange={(e)=> setCallNote(e.target.value)} sx={{width:'400px', maxWidth:'100%'}} InputLabelProps={{shrink:true}}/>
                </Stack>
                <DialogActions>
                    <Button color='secondary' onClick={()=>{dispatch(toggleCallAddModal()); reset();}}>Close</Button>
                    <Button onClick={()=>{dispatch(toggleCallAddModal()); handleSave();}}>Save</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}

export {CallLog}