import React from "react"
import { useParams } from "react-router";
import { useFindCaseManWorklistQuery } from "../../../services/caseManService/caseManage-api";
import { CaseManWorklistRequest } from "../../../services/caseManService/commands";
import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

type ownProps ={
    title:string;
    queryParam1:string;
    queryParam2:string;
}

function CaseManSplitDashboardCards(props:ownProps){
    const {center} = useParams();
    const split1 = useFindCaseManWorklistQuery(new CaseManWorklistRequest(props.queryParam1, center!));
    const split2 = useFindCaseManWorklistQuery(new CaseManWorklistRequest(props.queryParam2, center!));
    return <React.Fragment>
        <Card  className='card' sx={{width:'200px', borderRadius:'5pt'}}>
            <CardHeader className='card-header' sx={{textAlign:'center'}} title={<Typography color='#62BCBD' fontWeight={700} variant='body2'>{props.title}</Typography>}/>
                <CardContent sx={{paddingTop:'0px'}}>
                    <Stack direction="row" spacing={2} justifyContent='space-around' marginTop='0px' padding='0px' paddingLeft='3px'>
                        <div style={{'paddingTop':'0px'}}>
                        <Typography className='psn-green' sx={{textDecoration:'underline'}} fontWeight={700} marginTop='0px' paddingTop='0px' variant='body2'>CODING</Typography>
                        <h2 className="center-align">
                            <Link 
                                style={{color:props.queryParam1.includes('Denied') ? 'red' : 'green'}}
                                to={"/CaseManWorklist/"+center + '/' + props.queryParam1}>{split1.data?.length ?? 0}
                            </Link>
                        </h2>
                        </div>
                        <div style={{borderRightColor:'lightgray',borderRightWidth:'3px', borderRightStyle:'solid', marginTop:'10px'}}/>
                        <div style={{'paddingTop':'0px'}}>
                        <Typography className='psn-green' sx={{textDecoration:'underline'}} fontWeight={700} marginTop='0px' paddingTop='0px' variant='body2'>CLINICAL</Typography>
                        <h2 className="center-align">
                            <Link 
                                style={{color:props.queryParam2.includes('Denied') ? 'red' : 'green'}}
                                to={"/CaseManWorklist/"+center + '/' + props.queryParam2}>{split2.data?.length ?? 0}
                            </Link>
                        </h2>
                        </div>
                    </Stack>
            </CardContent>
        </Card>
    </React.Fragment>
}
export {CaseManSplitDashboardCards}