export class CallPostCommand{
    verificationId: string;
    patientReached: boolean;
    note: string;
    outcome: string;

    constructor(verifyId:string,patientReached:boolean,note:string,outcome:string){
        this.verificationId = verifyId;
        this.patientReached =patientReached;
        this.note = note;
        this.outcome = outcome;
    }
}