export interface AssignedUser {
  upn: string;
  displayName: string;
  email: string;
  assignedBy?: string;
  assignedByEmail?: string;
}

export function DefaultAssignedUser(): AssignedUser {
  return {
    upn: "",
    displayName: "",
    email: "",
    assignedBy: "",
    assignedByEmail: "",
  };
}
