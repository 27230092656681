export class ApprovalUpdateCommand{
    verificationId:string;
    isApproved:boolean;
    note:string;

    constructor(verificationId:string, isApproved:boolean, note:string){
        this.verificationId = verificationId;
        this.isApproved = isApproved;
        this.note = note;
    }
}