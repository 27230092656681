import { DefaultDirectorResponseFallAction, DirectorResponseFallAction } from "./DirectorResponseFallAction";

export interface DirectorResponseAction {
  isFollowup: boolean;
  hasRootCause: boolean;
  isReportable: boolean;
  reportAgency: string;
  hasBoardRecommendation: boolean;
  boardRecommendation: string;
  fallAction?: DirectorResponseFallAction;
}

export function DefaultDirectorResponseAction(): DirectorResponseAction {
  return {
    isFollowup: false,
    hasRootCause: false,
    isReportable: false,
    reportAgency: "",
    hasBoardRecommendation: false,
    boardRecommendation: "",
    fallAction: DefaultDirectorResponseFallAction(),
  };
}
