import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { GridButton } from "../../../../components";
import { useNavigate } from "react-router";

function VarianceSearchSelect(props: GridCellProps) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <GridButton
        onClick={() =>
          navigate("/VarianceRequestDetail/" + props.dataItem.id)
        }
      >
        View
      </GridButton>
    </React.Fragment>
  );
}

export { VarianceSearchSelect };
