import { Verification } from "../../../services/verifyService/model";
import { CompositeSpecification } from "../../../utils/Specification";

export class RequiresMedicareAuth extends CompositeSpecification<Verification>{
    IsSatisfiedBy(candidate: Verification): boolean {
        let authRequired = false;
        candidate.procedures.forEach(procedure => {
            const procedureCodes = ['15820','15821','15822','15823','67900','67901','67902','67903','67904','67906','67908',
            '64612','64615','J0585', 'J0586', 'J0587', 'J0588', '15830', '15847', '15877', '20912', '21210', 
            '30400', '30410', '30420', '30430', '30435', '30450', '30460', '30462', '30465', '30520', '36473', '36474',
            '36475', '36476', '36478', '36479', '36482', '36483', '22551','22552','63650','64490','64491','64492','64493',
            '64494','64495','64633','64634','64635','64636'];

        if(procedureCodes.includes(procedure.code)) {
            authRequired = true;
        }
        });
        return authRequired;
    }
}