import React from "react"
import { Dialog, DialogProps } from "@progress/kendo-react-dialogs";

function ScheduleDialog(props:DialogProps){

    return <React.Fragment>
        <Dialog{...props} title={'Appointment'}/>
    </React.Fragment>
}

export {ScheduleDialog}