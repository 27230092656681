import { Verification } from "../../../services/verifyService/model";
import { CompositeSpecification } from "../../../utils/Specification";

export class ContributionMustBeMoreThan20 extends CompositeSpecification<Verification>{
    IsSatisfiedBy(candidate: Verification): boolean {
        let reimbursement = candidate.procedures.map(i => i.reimbursement).reduce((a,b) => a+ b,0);
        let cost = candidate.procedures.map(i => i.implantCost).reduce((a,b) => a+b,0);
        let contributionMargin = reimbursement - cost;
        let contributionMarginRatio = (contributionMargin / reimbursement) * 100;

        if(reimbursement === 0)
            return true;
        
        return contributionMarginRatio >= 20;
    }
    
}