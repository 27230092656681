import React from "react"
import { useParams } from "react-router";
import { useFindCaseManWorklistQuery } from "../../../services/caseManService/caseManage-api";
import { CaseManWorklistRequest } from "../../../services/caseManService/commands";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Link } from "react-router-dom";

type ownProps ={
    title:string;
    queryParam:string;
}

function CaseManDashboardCards(props:ownProps){
    const {center} = useParams();
    const {data} = useFindCaseManWorklistQuery(new CaseManWorklistRequest(props.queryParam, center!));
    return <React.Fragment>
        <Card  className='card' sx={{width:'200px', borderRadius:'5pt'}}>
            <CardHeader className='card-header' sx={{textAlign:'center'}} title={<Typography color='#62BCBD' fontWeight={700} variant='body2'>{props.title}</Typography>}/>
                <CardContent>
                    <h2 className="center-align">
                        <Link 
                            style={{color:props.queryParam === 'Denied' ? 'red' : 'green'}}
                            to={"/CaseManWorklist/"+center + '/' + props.queryParam}>{data?.length ?? 0}
                        </Link>
                    </h2>
            </CardContent>
        </Card>

    </React.Fragment>
}

export {CaseManDashboardCards}