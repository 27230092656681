import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography,Button, FormControlLabel, Switch } from "@mui/material";
import React, { useState } from "react"
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { toggleQuickAddModal } from "../../../../app/slices/ContractModuleSlice";
import { ContractQuickAddCommand } from "../../../../services/contractService/commands";
import { useQuickAddMutation } from "../../../../services/contractService/contract-api";
import {DatePicker} from '@mui/x-date-pickers';
import dayjs from "dayjs";

function QuickAdd(){
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isOpen = useAppSelector((state)=> state.contractModule.quickAddModal)
    const [payer,setPayer] = useState('');
    const [product,setProduct] = useState('');
    const [effectiveDate, setEffectiveDate] = useState<Date|null>(new Date());
    const [endDate,setEndDate] = useState<Date|null>(new Date());
    const [isEvergreen, setIsEvergreen] = useState(false);
    const [quickAdd] = useQuickAddMutation();
    const addContract=()=>{
        const command = new ContractQuickAddCommand(payer,product, effectiveDate,endDate,isEvergreen);
        quickAdd(command).unwrap().then(fulfilled => {dispatch(toggleQuickAddModal());navigate('/contractDetail/' + fulfilled.id)});
    }
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogTitle>Let's Get Started</DialogTitle>
            <DialogContent>
                <Typography>Tell us a little about your contract.</Typography>
                <Stack direction='row' marginTop='10px' gap={1}>
                    <TextField label='Payer' name='payer' value={payer} onChange={(e)=> setPayer(e.target.value)} size='small' InputLabelProps={{shrink:true}}/>
                    <TextField label='Product' name='product' value={product} onChange={(e)=>setProduct(e.target.value)}  size='small' InputLabelProps={{shrink:true}}/>
                    
                </Stack>
                <Stack direction='row' marginTop='10px'>
                    <FormControlLabel
                        control = {<Switch size="small" value={isEvergreen} checked={isEvergreen} onChange={(e)=>setIsEvergreen(e.target.checked)} name='multiProcedureDiscount'/>}
                        label='Is contract evergreen' labelPlacement="end"/> 
                </Stack>
                <Stack direction='row' marginTop='15px' gap={1}>
                    <DatePicker label='Effective Date' value={dayjs(effectiveDate)} onChange={(e)=>{setEffectiveDate(e!.toDate())}} />
                    {!isEvergreen &&
                        <DatePicker label='End Date' value={dayjs(endDate)} onChange={(e)=>{setEndDate(e!.toDate())}}/>
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>dispatch(toggleQuickAddModal())}>Cancel</Button>
                <Button onClick={()=> addContract()}>Submit</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {QuickAdd}