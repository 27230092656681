import {process, State } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from "react"
import { usePhysicianListQuery } from "../../../../services/orgService/org-api";
import { ColumnMenu } from '../../../../utils/ColumnMenu';
import { PhysicianEditModal } from "./PhysicianEditModal";
import { ViewCommandCell } from './ViewCommandCell';
import { Stack } from '@mui/material';
import { PhysicianExport } from './PhysicianExport';
import { PhysicianImport } from './PhysicianImport';

type ownProps={
    center:string
}
function PhysicianList(props:ownProps){
    const {data} = usePhysicianListQuery(props.center);
    const [dataState,setDataState] = useState({});
    const [isNewPhysician]=useState(false);
    
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
    
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' marginTop='5px'>
            <Stack direction='row' gap={1}>
                <PhysicianExport center={props.center}/>
                <PhysicianImport center={props.center}/>
            </Stack>
        </Stack>
        <Grid className='center-list-grid card-body-grid' data={process(data===undefined ? [] : data.slice(0),dataState)} style={{height:'500px'}}
            onDataStateChange={dataStateChange}>
            <Column cell={ViewCommandCell} width='75px'/>
            <Column title='Last Name' field='lastName' columnMenu={ColumnMenu} width='200px'/>
            <Column title='First Name' field='firstName' columnMenu={ColumnMenu} width='200px'/>
            <Column title='Specialty' field='specialty' columnMenu={ColumnMenu} width='200px'/>
        </Grid>
        <PhysicianEditModal isNew={isNewPhysician} center={props.center}/>
    </React.Fragment>
}

export {PhysicianList}