import React, { useEffect, useState } from "react"
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../app/hooks";
import { contractApi, useRuleDeleteMutation, useRuleSaveMutation } from "../../../../../../services/contractService/contract-api";
import { RulePostCommand } from "../../../../../../services/contractService/commands";
import { RuleCommands } from "./RuleCommands";
import { Stack, TextField } from "@mui/material";

type ownProps={
    ruleId:string;
    ruleString?:string;
    ruleDescription?:string;
}

function PerDayAddOn(props:ownProps){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [rule,setRule] = useState({rate:0})
    const [saveRule] = useRuleSaveMutation();
    const [deleteRule] = useRuleDeleteMutation();
    const save=()=>{
        let command = new RulePostCommand(id!,props.ruleId,'PerDayAddOn',0, JSON.stringify(rule),props.ruleDescription ?? '');
        saveRule(command).unwrap().then(()=>{dispatch(contractApi.util.invalidateTags(['ruleList']))});
    }
    const ruleDelete=()=>{deleteRule({ruleId:props.ruleId})};
    useEffect(() => {
        if(props.ruleString !== ''){
            let r:{rate:number} = JSON.parse(props.ruleString!);
            setRule(r);
        }},[props.ruleString, props.ruleId]
    )

    return <React.Fragment>
        <Stack direction='row' gap={1}>
            <TextField type='number' label='Rate' size='small' value={rule.rate} 
                onChange={(e)=>setRule({...rule,rate:Number(e.target.value)})} 
                sx={{width:'100px'}} inputProps={{step:0.01}} InputLabelProps={{shrink:true}}/>            
        </Stack>
        <RuleCommands saveCommand={save} deleteCommand={ruleDelete} isNewRule={props.ruleId === '0' ? true:false}/>
    </React.Fragment>
}
export {PerDayAddOn}