import { Stack } from "@mui/material"
import React from "react"

function PatientCollections(){
    return <React.Fragment>
        <Stack justifyContent='center' alignItems='center'>
            <iframe title="Patient Collections" width="1350" height="800" 
                src="https://app.powerbi.com/reportEmbed?reportId=fd068b13-f664-4019-a68a-562c30be345a&autoAuth=true&ctid=49423d5d-8d49-4128-b53d-59537808f6ac" 
                allowFullScreen={true}></iframe>
        </Stack>
    </React.Fragment>
}

export {PatientCollections}