import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { useAppDispatch } from "../../../../app/hooks"
import { toggleMedicareRateModal } from "../../../../app/slices/OrgModuleSlice";
import { GridButton } from "../../../../components";

function MedicareRateEditCommandCell(props:GridCellProps){
    const dispatch = useAppDispatch();

    return <React.Fragment>
        <GridButton size='small' 
            onClick={()=>dispatch(toggleMedicareRateModal(props.dataItem.id))}>View</GridButton>    
    </React.Fragment>
}

export {MedicareRateEditCommandCell}