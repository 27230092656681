import { MenuItem, TextField } from "@mui/material";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editAuthorizationStatus } from "../../../../../services/verifyService/model/verification/VerificationSlice";

function AuthorizationStep(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);
 
    return <React.Fragment>
        <TextField label='Authorization Status' name='authStep' value={verification.authorization.authStep} size='small'
                onChange={(e)=> dispatch(editAuthorizationStatus(e))} disabled={verification.authorization.authRequired !== 'Required'}
                InputLabelProps={{shrink:true}} select>
                   <MenuItem value='Not Started'>Not Started</MenuItem>
                   <MenuItem value='Approved'>Approved</MenuItem>
                   <MenuItem value='Pending With Insurance'>Pending With Insurance</MenuItem>
                   <MenuItem value='Denied'>Denied</MenuItem>
                   <MenuItem value='Pending Peer to Peer'>Pending Peer to Peer</MenuItem>
                   <MenuItem value='Pending Single Case Agreement'>Pending Single Case Agreement</MenuItem>
                   <MenuItem value='Required Not Initiated'>Required Not Initiated</MenuItem>
                   <MenuItem value='Pending Facility Name Change'>Pending Facility Name Change</MenuItem>
                   <MenuItem value='Pending Member Outreach'>Pending Member Outreach</MenuItem>
                   <MenuItem value='CPT Not Authorized'>CPT Not Authorized</MenuItem>
                   <MenuItem value='Stay Type Not Authorized'>Stay Type Not Authorized</MenuItem>
                   <MenuItem value='DOS Date Incorrect'>DOS Date Incorrect</MenuItem>
                   <MenuItem value='Pending Revised Posting'>Pending Revised Posting</MenuItem>
                   <MenuItem value='Pending Disputes'>Pending Disputes</MenuItem>
                   <MenuItem value='Pending Referral'>Pending Referral</MenuItem>
       </TextField>
    </React.Fragment>
}

export {AuthorizationStep}