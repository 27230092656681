export const AnesthesiaOptions = [
    {
        description: "Airway Rescue",
        name: "AirwayRescue",
    },
    {
        description: "Difficult Intubation",
        name: "DifficultIntubation",
    },
    {
        description: "Injury, Eye",
        name: "InjuryEye",
    },
    {
        description: "Injury, Teeth",
        name: "InjuryTeeth",
    },
    {
        description: "Injury, Other",
        name: "InjuryOther",
    },
    {
        description: "Post-Op Respiratory Distress",
        name: "PostOpRespiratoryDistress",
    },
    {
        description: "Re-Intubation",
        name: "ReIntubation",
    },
    {
        description: "Reversal Required",
        name: "ReversalRequired",
    },
    {
        description: "Unable to extubate post op",
        name: "Unabletoextubatepostop",
    },
];