import { Alert, Table} from "@mui/material"
import React from "react"
import { RevRecCommands } from "../features/detail/revRecCommands/RevRecCommands"
import { EncounterHeaderView,  InsuranceViewWithCard } from "../../../components/encounterItems"
import { useParams } from "react-router";
import { useFindAccountQuery } from "../../../services/accountService/account-api";
import { Loading } from "../../shared";
import { useCaseFindQuery } from "../../../services/scheduleService/schedule-api";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { AccountAttributes, AccountPayerView, ExpectedReimbursement, NonSurgicalProcs, OutOfPeriodAdjustment } from "../features";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setAccountDetailTabStripPosition } from "../../../app/slices/AccountModuleSlice";
import { FinalReimbursement } from "../features/detail/finalReimbursement/FinalReimbursement";
import { AccountNotes } from "../features/detail/notes/AccountNotes";
import { BookedReimbursement } from "../features/detail/bookedReimbursement/BookedReimbursement";

function RevRecDetail(){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const tabIndex = useAppSelector((state)=>state.accountModule.AccountDetailTabStripPosition)
    const toggleTab=(e:TabStripSelectEventArguments)=>dispatch(setAccountDetailTabStripPosition(e.selected));
    const account = useFindAccountQuery(id!); 
    const schedule = useCaseFindQuery(id!);
    const status = useAppSelector((state)=>state.account.account.encounterInfo.status);
    return <React.Fragment>
        {account.isLoading || schedule.isLoading ? <Loading/>:
        <div>
            {status === 'CANCEL' && <Alert style={{marginBottom:'5px'}} variant='outlined' color='error'>Cancelled</Alert>}
            <RevRecCommands/>
            <EncounterHeaderView/>
            <AccountPayerView/>
            <AccountAttributes/>
            
            <TabStrip selected={tabIndex} onSelect={toggleTab}>
                <TabStripTab title="EMR Reimbursement">
                    <Table/>
                    <FinalReimbursement/>
                </TabStripTab>
                <TabStripTab title='Final Reimbursement'>
                    <Table/>
                    <BookedReimbursement/>
                </TabStripTab>
                <TabStripTab title='Projected Reimbursement'>
                    <Table/>
                    <ExpectedReimbursement/>
                </TabStripTab>
                <TabStripTab title='Non Surgical Procedures'>
                    <Table/>
                    <NonSurgicalProcs/>
                </TabStripTab>
                <TabStripTab title='Out Of Period Adjustments'>
                    <Table/>
                    <OutOfPeriodAdjustment/>
                </TabStripTab>
                <TabStripTab title='Notes'>
                    <Table/>
                    <AccountNotes/>
                </TabStripTab>
            </TabStrip>
        </div>}

    </React.Fragment>
}
export {RevRecDetail}