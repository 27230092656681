import { Button, Typography} from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch } from '../../../app/hooks';
import { Lookup, RadWorklist } from '../features';
import { resetRadRequest } from '../../../services/radiologyService/model/radRequest/RadRequestSlice';

function RadHome(){
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {center} = useParams();
    return <div>
        <Stack direction='row' justifyContent='space-between'>
            <Lookup/>
            <Button variant='outlined' onClick={()=>{navigate('/RadCreate/' + center); dispatch(resetRadRequest())}}>Create Rad Request</Button>
        </Stack>
        <br/>
        <Typography variant='h6'>Outstanding Radiology Requests</Typography>
        <RadWorklist/>
    </div>
}

export {RadHome}
