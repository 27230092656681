export class EstimatedProcedure{
    code: string = '';
    description: string = '';
    priority: number = 0;
    billAmount: number = 0;
    reimbursement: number = 0;
    implantCost: number = 0;
    selfPay: boolean = false;

    constructor(code:string, description: string){
        this.code = code;
        this.description = description;
    }

}