import React, { useState } from "react"
import { useParams } from "react-router"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import { toggleVerifyDocumentDialog } from "../../../../../app/slices/VerificationModuleSlice";
import { Upload } from "@mui/icons-material";
import { VerifyDocSelector } from "./VerifyDocSelector";
import { Loading } from "../../../../shared";
import { VerifyService } from "../../../../../utils/ServiceLocator";
import { getToken } from "../../../../../utils/AdalConfig";
import { addVerifyDocument } from "../../../../../services/verifyService/model/verification/VerificationSlice";

function VerifyDocUploadDialog(){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state)=>state.verificationModule.VerifyDocumentDialog);
    const [selectedFile, setSelectedFile] = useState<File|null>();
    const [notify, setNotify] = useState(false)
    const [uploading, setUploading] = useState(false)
  
    const handleSubmission= async()=>{
        try{
        setUploading(true);
        const formData = new FormData();
        formData.append('File',selectedFile!);
        const response = await fetch(VerifyService + 'DocumentUploadEndpoint/DocumentUpload/'+id,{method:'POST',body:formData, headers:{'Authorization': 'Bearer ' + getToken()}});
        const data = await response.json();
        dispatch(addVerifyDocument(data));
        dispatch(toggleVerifyDocumentDialog());
        setUploading(false);
        }
        catch(error){
            setUploading(false);
            setNotify(true);
        }
    };
    return <React.Fragment>
        <Button
            variant='outlined' startIcon={<Upload/>}
            onClick={()=>dispatch(toggleVerifyDocumentDialog())}>Upload</Button>

        <Dialog open={isOpen}>
            <DialogTitle>Upload Document</DialogTitle>
            <DialogContent>
                {uploading && <Loading/>}
                {!uploading &&
                <VerifyDocSelector file={selectedFile!} setFile={setSelectedFile}/>}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>dispatch(toggleVerifyDocumentDialog())} color='secondary'>Cancel</Button>
                <Button onClick={()=>handleSubmission()}>Save</Button>
            </DialogActions>
        </Dialog>
        <Snackbar open={notify} onClose={()=>setNotify(false)} color='error' autoHideDuration={6000}>
            <Alert color='error'>{'Cannot upload this document.'}</Alert>
        </Snackbar>
    </React.Fragment>
}
 
export {VerifyDocUploadDialog}