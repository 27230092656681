import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CaseManModuleState{
    TabStripPosition:number;
    CaseManNoteAddModal:boolean;
    CaseManMedNecDetailModal:boolean;
    CaseManSelectedProcedure:number;
    CaseManCallDetailModal:boolean;
    CaseManSelectedCall:string;
}

const initialState:CaseManModuleState={TabStripPosition:0,CaseManNoteAddModal:false,CaseManMedNecDetailModal:false,CaseManSelectedProcedure:0,
        CaseManCallDetailModal:false,CaseManSelectedCall:'0'};

export const CaseManModuleSlice = createSlice({
    name:'CaseManModuleSlice',
    initialState,
    reducers:{
        setTabStripPosition:(state, action:PayloadAction<number>) =>{
            state.TabStripPosition = action.payload;
        },
        toggleCaseManNoteAddModal:(state) =>{
            state.CaseManNoteAddModal = !state.CaseManNoteAddModal;
        },
        toggleCaseManMedNecDetailModal:(state, action:PayloadAction<number>) =>{
            state.CaseManMedNecDetailModal = !state.CaseManMedNecDetailModal;
            state.CaseManSelectedProcedure = action.payload;
        },
        toggleCaseManCallDetailModal:(state, action:PayloadAction<string>) =>{
            state.CaseManCallDetailModal = !state.CaseManCallDetailModal;
            state.CaseManSelectedCall = action.payload;
        }
    }
});

export const {setTabStripPosition,toggleCaseManNoteAddModal,toggleCaseManMedNecDetailModal,toggleCaseManCallDetailModal}= CaseManModuleSlice.actions;
export default CaseManModuleSlice.reducer;