import { Button, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../../../app/hooks";
import { toggleProcedureEditModal } from "../../../../app/slices/OrgModuleSlice";
import { ProcedureSaveCommand } from "../../../../services/orgService/commands";
import { useProcedureListQuery, useSaveProcedureMutation } from "../../../../services/orgService/org-api";

type ownProps = {
    center: string;
}
function ProcedureEdit(props:ownProps){
    const dispatch = useDispatch();
    const isOpen = useAppSelector((state) => state.orgModule.procedureEditModal);
    const procedureId = useAppSelector((state) => state.orgModule.selectedProcedure);
    const [saveProcedure] = useSaveProcedureMutation();
    const {data} = useProcedureListQuery(props.center)
    const [proc, setProc] = useState(data?.find(p => p.id === procedureId));
    useEffect(()=>{setProc(data?.find(p => p.id === procedureId))},[data,procedureId]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) =>{
        const {name,value} = e.target;
        let newProc = proc;
        newProc=({...newProc, [name]:value});
        setProc(newProc);
    }
    
    return <React.Fragment>
        <Dialog open={isOpen}>
            <DialogContent>{'Edit Code ' + proc?.code}</DialogContent>
            <DialogContent>
                <Stack gap={2}>
                    <TextField label='Description' name='description' value={proc?.description} onChange={(e)=>handleChange(e)} InputLabelProps={{shrink:true}} sx={{width:'500px', maxWidth:'100%'}}/>
                    <TextField select label='Type' name='codeType' value={proc?.codeType} onChange={(e)=>handleChange(e)} sx={{width:'100px',maxWidth:'100%'}}>
                        <MenuItem value='DRG'>DRG</MenuItem>
                        <MenuItem value='CPT'>CPT</MenuItem>
                    </TextField>
                </Stack>    
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={()=>dispatch(toggleProcedureEditModal(''))}>Cancel</Button>
                <Button onClick={()=>{saveProcedure(new ProcedureSaveCommand(proc!)); dispatch(toggleProcedureEditModal(''));}}>Save</Button>
            </DialogActions>
        </Dialog>

    </React.Fragment>
}

export {ProcedureEdit}