import { Alert } from "@mui/material";
import React from "react"
import { useMyProfileQuery } from "../../../services/profileService/profile-api";
import { CenterSelector } from "../features/centerSelector/CenterSelector";

function ScheduleHome(){
    const profile = useMyProfileQuery();
    return <React.Fragment>
        {profile.data?.centerAccess.length === 0 ?
            <Alert color="error">You do not have access to any centers.</Alert> :
            <CenterSelector/>
        }
    </React.Fragment>
}

export {ScheduleHome}